<script>
import { onMounted } from "@vue/composition-api";
import EventBus from "src/services/util/event-bus";

import ExtraInfoModal from "src/views/auth/components/ExtraInfoModal.vue";

import { loadUserData } from "src/plugins/prototype";

export default {
  components: { ExtraInfoModal },
  setup(props, ctx) {
    const updateMissingFields = () => {
      const { extra } = loadUserData();
      if (extra && extra.length > 0) {
        ctx.root.$openNotification(
          "Missing Fields",
          "Please provide us extra info before proceeding",
          10,
          "info"
        );
        EventBus.$emit("open-auth-extra-info");
      }
    };
    onMounted(() => {
      updateMissingFields();
    });
    return {};
  }
};
</script>
<template>
  <div class="fixed inset-0 z-30">
    <div class="w-screen h-screen flex bg-gray-50">
      <div
        class="absolute transform left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 flex flex-col space-y-4 justify-center items-center">
        <svg-icon icon-class="PARQAY-logo" class="h-20 w-auto" />
        <svg-icon icon-class="circular-loader" class="w-8 h-8 animate-spin text-blue-500" />
      </div>
    </div>
    <extra-info-modal />
  </div>
</template>
