<script>
import EventBus from "src/services/util/event-bus";
import {
  reactive,
  ref,
  watch,
  onMounted,
  nextTick,
  onBeforeUnmount
} from "@vue/composition-api";

import TabHeader from "src/views/components/TabHeader.vue";
import SendInvites from "./SendInvites.vue";
import TemplateRequestModal from "./TemplateRequestModal.vue";
import AdminTemplateRequestsList from "./AdminTemplateRequestsList.vue";
import Dropzone, {
  dropboxDocUploader,
} from "src/views/documents/components/Dropzone.vue";
import AdminCredentialsView from "./AdminCredentialsView.vue";

import videojs from 'video.js';
import { API } from "src/views/documents/api";
import { stripeClient } from "src/plugins/prototype";
import { deepGet } from "../../../plugins/prototype";

import startCase from "lodash/startCase";
import cloneDeep from "lodash/cloneDeep";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import transform from "lodash/transform";
import merge from "lodash/merge";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import { openNotification } from "src/plugins/prototype";

export const startSignatureRequest = async (doc, role) => {
  try {
    const { data } = await API.getSignatureSignUrl({
      document_id: doc.id,
      role
    });
    EventBus.$emit("toggle-document-sign-embed-modal", true, data.sign_url);
  } catch (error) {
    openNotification("Document Sign", error.message, 10, "error");
    console.log("document settings preview api >> error: ", error);
    return;
  }
};

export const startVerifyIdentity = async ({ is_host } = { is_host: false }) => {
  let resp, temp, result;
  try {
    resp = await API.getIdentityVerificationTemplate();
    temp = resp.data;
  } catch (error) {
    console.error(error);
    EventBus.$emit("show-identity-verification-result", false, error);
    return;
  }
  const allowed_types = temp.allowed_doc_types
    .map((v) => {
      if (v.checked) {
        return v.value;
      } else {
        return false;
      }
    })
    .filter((v) => v);
  const payload = {
    allowed_types,
    selfie_check: temp.selfie_check,
    id_number_check: temp.id_number_check,
    is_host,
    is_preview: false,
  };
  try {
    resp = await API.verifyIdentity(payload);
  } catch (error) {
    console.error(error);
    EventBus.$emit("show-identity-verification-result", false, error);
    return;
  }
  try {
    const stripe = stripeClient();
    if (stripe) {
      // Show the verification modal.
      result = await stripe.verifyIdentity(resp.data.client_secret);
    }
  } catch (error) {
    console.error(error);
    EventBus.$emit("show-identity-verification-result", false, error);
    return;
  }
  if (result.error) {
    console.error(result.error.message);
    return;
  } else {
    console.log("stripe.verifyIdentity >> result: ", result);
    EventBus.$emit("show-identity-verification-result", true);
  }
  EventBus.$emit("identity-verification-refresh");
};

export default {
  name: "doc-templates",
  components: {
    TabHeader,
    "send-invites-view": SendInvites,
    TemplateRequestModal,
    AdminTemplateRequestsList,
    Dropzone,
    AdminCredentialsView
  },
  setup(props, ctx) {
    const templates = ref([]);
    const templatesOriginalCopy = ref([]);

    const videoPlayer = ref(null);
    const videoPlayerRef = ref(null);
    const videoPlayerOptions = reactive({
      autoplay: false,
      controls: true,
      preload: "auto",
      width: "360",
      height: "280",
      // poster: "/video/tutorial-1.png",
      sources: [
        // {
        //   src:
        //     '/video/tutorial-create-template.mp4',
        //   type: 'video/mp4'
        // }
      ]
    })

    const controls = ref(null);
    const loading = ref(false);
    // const getStoredTutorialVisibility = () => {
    //   let tutorialVisible = localStorage.getItem("PQSettings.documents.templetes.tutorialVisible");
    //   if (tutorialVisible) {
    //     return JSON.parse(tutorialVisible);
    //   }
    //   return true;
    // }
    const visible = reactive({
      sendInvites: false,
      footer: false,
      tutorial: false,
      readMore: false,
      templateRequest: false
    })
    watch(() => visible.tutorial, (val) => {
      if (!val) {
        localStorage.setItem("PQSettings.documents.templetes.tutorialVisible", val);
      }
    })
    const templatesSettings = ref([]);
    const activeTab = ref(ctx.root.$isAdmin() ? "requests" : "all");

    const loadingDelete = ref(false);

    const deletedDict = reactive({
      templates: [],
    });

    watch(() => activeTab.value, (val) => {
      if (val === "all") {
        visible.footer = true;
      } else {
        visible.footer = false;
      }
    })

    const shouldDisablePreviewButton = (temp) => {
      return temp.docs_to_sign.length === 0;
    };
    const openPreview = async (i, temp) => {
      if (temp.is_reviewed) {
        ctx.root.$bus.$emit("toggle-document-sign-embed-preview", true, temp.id);
      }
    };

    const toggleClose = (i, val) => {
      controls.value.show_close_index = val ? i : -1;
    };

    const getDocumentTemplates = async () => {
      loading.value = true;
      try {
        const { data } = await API.getDocumentTemplates();
        console.log("getDocumentTemplates >> resp:", data);
        templates.value = data.templates;
        templatesSettings.value = data.templates.map(t => {
          return {
            expand: {
              message: false,
              intervalMode: false,
              listingType: false
            }
          }
        })
        console.log("templates.value ---> ", templates.value);
        templatesOriginalCopy.value = cloneDeep(templates.value);
        console.log("templatesOriginalCopy.value ---> ", templatesOriginalCopy.value);
        controls.value = data.controls;
      } catch (error) {
        console.log("getDocumentTemplates >> error:", error);
      } finally {
        loading.value = false;
      }
    };
    function diffArray(updated, original) {
      return differenceWith(updated, original, isEqual);
    }
    function diffObj(object, base) {
      function changes(object, base) {
        return transform(object, function (result, value, key) {
          if (!isEqual(value, base[key])) {
            result[key] =
              isObject(value) && isObject(base[key])
                ? changes(value, base[key])
                : value;
          }
        });
      }
      return changes(object, base);
    }
    const getTemplatesUpdateDict = () => {
      let updateDict = {
        identity_verification_options: {},
        templates: {},
        docs_to_sign: {}
      };
      const ds = diffArray(templates.value, templatesOriginalCopy.value);

      if (ds.length > 0) {
        console.log("diffArray: ", ds);

        ds.forEach((d, i) => {
          const di = templates.value.indexOf(d);
          console.log("di >> ", di);
          let dc;
          if (di > templatesOriginalCopy.value.length - 1) {
            console.log("addition detected");
            dc = d;
          } else {
            console.log("update detected");
            dc = cloneDeep(diffObj(d, templatesOriginalCopy.value[di]));
          }
          console.log("dc >> ", dc);
          console.log("d: ", d);
          if (deepGet(d, "type") === "identity") {
            const ud = {};
            ud[d["id"]] = dc;
            const allowedDocTypes = deepGet(dc, "allowed_doc_types");
            if (allowedDocTypes) {
              // Update all allowed_doc_types values if changes have been detected
              ud[d["id"]]["allowed_doc_types"] = deepGet(d, "allowed_doc_types");
            }
            merge(updateDict["identity_verification_options"], ud);
          } else {
            const ud = {};

            if (!isEmpty(dc)) {
              ud[d["id"]] = dc;
            }
            const docsToSign = deepGet(dc, "docs_to_sign");

            // Note: BOTH Dropbox file url and binary file blob are required since Zoho Sign only accept binary format...
            if (docsToSign) {
              let udc = [];
              cloneDeep(deepGet(d, "docs_to_sign", []))
                .filter((v) => v) //Remove undefined values
                .forEach((v) => {
                  if (v.file) {
                    updateDict['docs_to_sign'][v.id] = v.file;
                    delete v["file"];
                    delete v["upload_pending"];
                  }
                  udc.push(v);
                });
              console.log("udc ---> ", udc);
              const cdc = cloneDeep(dc);
              cdc["docs_to_sign"] = udc;
              ud[d["id"]] = cdc;
            }

            const subtypes = deepGet(dc, "subtypes");
            if (subtypes) {
              ud[d["id"]]['subtypes'] = d['subtypes'];
            }

            const intervalModes = deepGet(dc, "interval_modes");
            if (intervalModes) {
              ud[d["id"]]['interval_modes'] = d['interval_modes'];
            }

            merge(updateDict["templates"], ud);
          }
        });
      }
      console.log("getTemplatesUpdateDict >>", updateDict);
      return updateDict;
    };
    const disableSaveChanges = () => {
      try {
        const updateDict = getTemplatesUpdateDict();
        return (
          isEmpty({
            ...updateDict.identity_verification_options,
            ...updateDict.templates,
          }) && isEmpty(deletedDict.templates)
        );
      } catch (error) {
        return true;
      }
    };
    const resetChanges = () => {
      templates.value = [];
      templatesSettings.value = [];
      deletedDict.templates = [];
      nextTick(() => {
        getDocumentTemplates();
        validateUpdateForm();
      });
    };
    const tabs = ref(
      ctx.root.$isAdmin() ? [
        { name: "requests", label: "Host Requests" },
        { name: "credentials", label: "Credentials" }
      ] : [
        { name: "all", label: "All" },
        { name: "upload", label: "Upload" },
        { name: "sign", label: "Sign" }
      ]
    );
    const uploadFiles = async () => {
      // const files = getAllFilesToUpload();
      // console.log("allFiles", files);
      let tasks = [];
      for (let temp of templates.value) {
        deepGet(temp, "docs_to_sign", []).forEach((v) => {
          if (v.upload_pending) {
            tasks.push(dropboxDocUploader(v));
          }
          if (temp.editable) {
            temp.is_reviewed = false;
          }
        });
      }

      return await Promise.all(tasks);
    };
    const saveChanges = () => {
      for (const t of templates.value) {
        if (t.type === 'sign') {
          if (t.docs_to_sign.length == 0) {
            ctx.root.$openNotification("Missing PDF files", `At least one PDF file is required to upload for template: "${t.name}"`, 10, "info");
            return;
          }
        }
      }
      for (const t of templates.value) {
        if (t.type === 'sign') {
          if (!t.is_reviewed) {
            visible.templateRequest = true;
            return;
          }
        }
      }

      updateDocumentTemplates();
    }
    const deleteDocumentTemplates = async () => {
      loadingDelete.value = true;
      for (let tempId of deletedDict.templates) {
        await API.deleteDocumentTemplate(tempId);
      }
      loadingDelete.value = false;
      resetChanges();
    }
    const updateDocumentTemplates = async () => {
      controls.value.loading.save_changes = true;
      validateUpdateForm();
      if (deepGet(controls.value, "errors", []).length > 0) {
        setTimeout(() => {
          controls.value.loading.save_changes = false;
        }, 500);
        return;
      }
      await uploadFiles();
      // const results = await uploadFiles();
      // const tempLinks = results.filter((v) => !v.error);
      // const uploadErrors = results.filter((v) => v.error);
      // console.log("tempLinks", tempLinks);
      // console.log("uploadErrors", uploadErrors);
      const updates = getTemplatesUpdateDict();
      let resp;
      try {
        resp = await API.updateDocumentTemplatesBlob(updates);
        console.log("updateDocumentTemplates >> resp:", resp.data);
      } catch (error) {
        console.log("updateDocumentTemplates >> error:", error);
        return;
      } finally {
        controls.value.loading.save_changes = false;
      }
      resetChanges();
    };
    const verifyIdentity = async (temp) => {
      controls.value.loading.identity_verification = true;
      let resp, result;
      const allowed_types = temp.allowed_doc_types
        .map((v) => {
          if (v.checked) {
            return v.value;
          } else {
            return false;
          }
        })
        .filter((v) => v);
      const payload = {
        allowed_types,
        selfie_check: temp.selfie_check,
        id_number_check: temp.id_number_check,
        is_preview: true,
      };
      try {
        resp = await API.verifyIdentity(payload);
      } catch (error) {
        console.error(error);
        EventBus.$emit("show-identity-verification-result", false, error);
        controls.value.loading.identity_verification = false;
        return;
      }
      try {
        const stripe = stripeClient();
        if (stripe) {
          // Show the verification modal.
          result = await stripe.verifyIdentity(resp.data.client_secret);
        }
      } catch (error) {
        console.error(error);
        EventBus.$emit("show-identity-verification-result", false, error);
        controls.value.loading.identity_verification = false;
        return;
      }
      if (result.error) {
        console.error(result.error.message);
        controls.value.loading.identity_verification = false;
        return;
      } else {
        console.log("stripe.verifyIdentity >> result: ", result);
        EventBus.$emit("show-identity-verification-result", true);
        controls.value.loading.identity_verification = false;
      }
    };
    const addNewTemplate = () => {
      templates.value.push(cloneDeep(controls.value.new_template_model));
      controls.value.editing.name_index = templates.value.length - 1;

      nextTick(() => {
        document.getElementById(controls.value.new_template_model.id).scrollIntoView({ behavior: "smooth" });
      });
    };
    const validateUpdateForm = () => {
      controls.value.errors = [];
      const errors = controls.value.errors;
      for (const temp of templates.value) {
        if (temp.type !== "identity") {
          if (!temp.name) {
            errors.push({
              id: temp.id,
              message: "Template name is required",
            });
          }
          if (!temp.signer_message) {
            errors.push({
              id: temp.id,
              message: '"Message for signers" is required',
            });
          }
          if (temp.doc_sign_required && temp.docs_to_sign.length === 0) {
            errors.push({
              id: temp.id,
              message: "At least one document template file is required to upload",
            });
          }
        }
      }
      nextTick(() => {
        if (deepGet(errors, "[0].id")) {
          document
            .getElementById(deepGet(errors, "[0].id"))
            .scrollIntoView({ behavior: "smooth", block: "center" });
        }
      });
    };
    const initVideoPlayer = () => {
      videoPlayer.value = videojs(videoPlayerRef.value, videoPlayerOptions, () => {

      });
    }
    const deinitVideoPlayer = () => {
      if (videoPlayer.value) {
        videoPlayer.value.dispose();
      }
    }

    onMounted(() => {
      ctx.root.$bus.$on("refresh-document-templates-list", resetChanges);
      if (activeTab.value === "all") {
        visible.footer = true;
      } else {
        visible.footer = false;
      }
      initVideoPlayer()
      getDocumentTemplates();
    });
    nextTick(() => {
      // ctx.root.$toggleIntercomPosition(true, { x: 0, y: 80 });
    });
    onBeforeUnmount(() => {
      ctx.root.$bus.$off("refresh-document-templates-list", resetChanges);
      // ctx.root.$toggleIntercomPosition(false);
      deinitVideoPlayer();
    });
    return {
      templates,
      templatesSettings,
      templatesOriginalCopy,
      controls,
      visible,
      loading,
      videoPlayerRef,
      tabs,
      activeTab,
      deletedDict,
      loadingDelete,
      shouldDisablePreviewButton,
      openPreview,
      toggleClose,
      saveChanges,
      deleteDocumentTemplates,
      updateDocumentTemplates,
      verifyIdentity,
      disableSaveChanges,
      resetChanges,
      startCase,
      cloneDeep,
      console
    };
  },
};
</script>
<template>
  <div class="doc-template pt-2 pb-12">
    <div class="flex justify-between items-center pb-4">
      <div class="flex items-center space-x-2">
        <a class="text-parqay-primary font-medium text-lg"
          @click.prevent="$router.push('/dashboard/documents')">Documents</a>
        <span class="font-medium text-lg pr-2">
          / Templates
        </span>
        <a-icon type="reload" :spin="loading" @click="resetChanges()" />
      </div>
      <!-- <button v-show="activeTab === 'all' && !visible.tutorial"
        class="flex items-center space-x-2 px-3 py-2 text-sm whitespace-nowrap text-orange-500 hover:text-white font-medium bg-orange-200 hover:bg-orange-300 border border-orange-500 focus:outline-none rounded-md"
        @click="() => visible.tutorial = true">
        <span>Tutorial: Create Template</span>
        <span>|</span>
        <a-icon type="play-square" class="video-icon animate__animated animate__tada" />
      </button> -->
    </div>
    <tab-header v-model="activeTab" :tabs="tabs" disable-hash-detect>
      <template #right-actions>
        <div v-if="!$isAdmin()" class="flex items-center space-x-4">
          <a-button icon="mail" @click.stop="() => visible.sendInvites = true">
            Send Invites
          </a-button>
          <a-modal v-model="visible.sendInvites" title="Document Invitation" destroy-on-close :footer="null">
            <send-invites-view :templates="templates.filter(v => {
              if (v.type === 'sign' && !v.is_reviewed) {
                return false
              }
              if (v.type === 'identity') {
                return false
              }
              return true
            })" @cancel="() => visible.sendInvites = false" @dismiss="() => visible.sendInvites = false" />
          </a-modal>
          <a-button type="primary" icon="plus" @click.stop="$bus.$emit('open-new-document-template-modal')">
            Create Document
          </a-button>
        </div>
      </template>
    </tab-header>
    <admin-template-requests-list v-if="$isAdmin() && activeTab === 'requests'" class="pt-4" />
    <admin-credentials-view v-if="$isAdmin() && activeTab === 'credentials'" class="pt-4" />
    <div v-if="!$isAdmin()" class="flex flex-col space-y-4">
      <div v-show="visible.tutorial && activeTab === 'all'" class="flex relative mt-4">
        <a-alert type="info" show-icon class="tutorial-alert">
          <template #icon>
            <svg-icon icon-class="treasure" class="h-10 w-10 pt-2 pl-1" />
          </template>
          <template #message>
            <div class="flex items-start justify-between space-y-2 space-x-5 pl-3 pr-3 pb-1">
              <div class="flex flex-col justify-start space-y-3 pl-6 pt-3">
                <span class="text-base font-medium text-blue-600">How to create a template?</span>
                <p class="text-sm">Document templates define the documents to collect for each reservation made from
                  the
                  marketplace or
                  API request. Document templates have <a class="text-blue-600 font-semibold mr-1">two</a>types:
                  <a-button :type="visible.readMore ? 'secondary' : 'primary'" size="small"
                    @click="() => (visible.readMore = !visible.readMore)">{{
                      visible.readMore ? "Show less" : "Read more"
                    }}</a-button>
                </p>
                <p v-if="visible.readMore" class="text-sm text-gray-600 italic p-2 bg-gray-50 border rounded">
                  <a-tag color="geekblue">Upload Only</a-tag>: Prompt tenant to upload their documents as PDF
                  files,
                  <a-tag color="green">Signature Required</a-tag>: Invite tenant to sign on the document uploaded by
                  you
                  (as
                  host)
                </p>
                <div class="w-full border-t border-gray-400" :style="{ height: '1px' }" />
                <p class="text-sm">
                  Click <a-button size="small">+ Create Document</a-button>, enter a doc
                  name, a message for tenant and use "Require Signature?" <a-switch size="small" /> toggle to switch
                  between 2 types. For <a-tag color="green">Signature Required</a-tag>document, drag & drop
                  image/PDF files into the right panel to display tenant the files to sign.
                </p>
                <p class="text-sm pb-3">Finally, click <a-button type="primary" size="small">Save Changes</a-button>
                  to
                  save updates.</p>
              </div>
              <div class="flex flex-col space-y-2 flex-shrink-0">
                <video ref="videoPlayerRef" class="video-js">
                  <p class="vjs-no-js">
                    To view this video please enable JavaScript, and consider upgrading to a
                    web browser that
                    <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                  </p>
                </video>
                <span class="text-center text-gray-600 text-xs font-medium italic">Tutorial: create new
                  template</span>
              </div>
            </div>
          </template>
        </a-alert>
        <a-button type="link" icon="close" class="absolute" :style="{
          right: '1.5rem',
          top: '0.25rem'
        }" size="small" @click="() => visible.tutorial = false"></a-button>
      </div>
      <div class="bg-white px-8 py-6 rounded-md border" style="min-height: 400px;">
        <div v-if="loading" class="flex items-center justify-center" style="height: 300px;">
          <a-icon type="loading" style="font-size: 35px;" />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <a-card v-for="(temp, i) in templates.filter(t => {
            if (activeTab === 'all') {
              return ['upload', 'sign'].includes(t.type);
            }
            return activeTab === t.type;
          })" :id="temp.id" :key="`doc-template-${i}`" hoverable size="small"
            @click.stop="$bus.$emit('open-document-template-modal', temp)">
            <template #title>
              <div class="flex items-center justify-between px-2 py-1">
                <div class="flex items-center space-x-4">
                  <span class="text-sm font-medium">{{ temp.name }}</span>
                  <a-tag color="green">{{ startCase(temp.type) }}</a-tag>
                  <a-tag v-if="deletedDict.templates.includes(temp.id)" color="pink" class="absolute"
                    style="left: 0.5rem; top: -0.5rem">
                    To-remove
                  </a-tag>
                </div>
                <a-icon type="arrow-right" />
              </div>
            </template>
            <div class="flex flex-wrap items-center gap-2">
              <a-tag v-if="temp.is_reviewed || temp.type === 'upload'" color="green" class="template-status-tag">
                <span>Ready to use</span>
                <a-icon type="check-circle" />
              </a-tag>
              <a-tag v-if="temp.doc_sign_required && !temp.is_reviewed" color="orange" class="template-status-tag">
                <span>Under review</span>
                <a-tooltip>
                  <template #title>
                    Your new template is under review by our team, we'll get back to you or approve it
                    directly after the review.
                  </template>
                  <a-icon type="info-circle" />
                </a-tooltip>
              </a-tag>
              <a-tag v-if="temp.interval_modes.length != 1" color="geekblue">{{ temp.interval_modes.length }}
                durations</a-tag>
              <a-tag v-else color="geekblue">{{ startCase(temp.interval_modes[0]) }}</a-tag>
              <a-tag v-if="temp.subtypes.length != 1" color="geekblue">{{ temp.subtypes.length }} types</a-tag>
              <a-tag v-else color="geekblue">{{ startCase(temp.subtypes[0]) }}</a-tag>
              <a-button v-if="temp.doc_sign_required && temp.is_reviewed" type="primary" size="small"
                :loading="controls.loading.preview_index === i" :disabled="shouldDisablePreviewButton(temp)"
                @click.stop="openPreview(i, temp)">
                Preview
              </a-button>
            </div>
          </a-card>
        </div>
        <div class="flex flex-col items-center justify-between space-y-8">
          <div v-for="(temp, i) in templates.filter(t => {
            // if (activeTab === 'identity') {
            //   return t.type === activeTab;
            // }
            return false;
          })" :id="temp.id" :key="`identity-template-${i}`" class="flex w-full h-72 rounded-xl border"
            :class="{ 'border-4 border-pink-500 bg-pink-200 pointer-events-none': deletedDict.templates.includes(temp.id) }">
            <div class="w-1/3 h-full border-gray-400 border-dashed p-4">
              <div
                class="flex flex-col space-y-3 hover:shadow-xl shadow-inner border bg-white h-full justify-center items-center rounded-xl relative"
                @mouseover="toggleClose(i, true)" @mouseleave="toggleClose(i, false)">
                <svg-icon class="w-12 h-12 text-parqay-primary" icon-class="document" />
                <div v-if="controls.editing.name_index !== i" class="flex items-center space-x-4">
                  <p class="text-black" :style="{ margin: 0 }">
                    {{ temp.name }}
                  </p>
                  <a-button v-if="temp.editable" size="small" @click="() => (controls.editing.name_index = i)">
                    Edit
                  </a-button>
                </div>
                <div v-else>
                  <a-input v-model="temp.name" placeholder="Enter document name" class="mr-6">
                    <a-button slot="suffix" type="primary" size="small" class="text-xs" @click="() => {
                      if (temp.name) {
                        controls.editing.name_index = -1;
                      }
                    }
                      ">
                      Done
                    </a-button>
                  </a-input>
                </div>
                <!-- <a-button v-if="temp.type === 'identity'" type="primary" :loading="controls.loading.identity_verification"
                  @click="verifyIdentity(temp)">
                  Preview as Tenant
                </a-button> -->
                <a-tag v-if="temp.is_reviewed" color="green" class="template-status-tag">
                  <span>Ready to use</span>
                  <a-icon type="check-circle" />
                </a-tag>
                <a-button v-if="temp.doc_sign_required && temp.is_reviewed" type="primary"
                  :loading="controls.loading.preview_index === i" :disabled="shouldDisablePreviewButton(temp)"
                  @click.stop="openPreview(i, temp)">
                  Preview as Signer
                </a-button>
                <a-tag v-if="temp.doc_sign_required && !temp.is_reviewed" color="orange" class="template-status-tag">
                  <span>Under review</span>
                  <a-tooltip>
                    <template #title>
                      Your new template is under review by our team, we'll get back to you or approve it
                      directly after the review.
                    </template>
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </a-tag>
                <a-tag v-if="deletedDict.templates.includes(temp.id)" color="pink" class="absolute"
                  style="left: 0.5rem; top: -0.5rem">
                  To-remove
                </a-tag>
                <button v-show="controls.show_close_index === i && temp.editable"
                  class="absolute w-8 h-8 bg-red-500 rounded-full focus:outline-none"
                  style="right: -0.5rem; top: -1.35rem" @click="() => {
                    deletedDict.templates.push(temp.id);
                  }
                    ">
                  <svg-icon icon-class="close" class="w-8 h-8 text-white" />
                </button>
              </div>
            </div>
            <template v-if="temp.type === 'identity'">
              <div class="w-2/3 h-full overflow-hidden px-4 py-2 relative">
                <div class="absolute top-10 w-full left-0">
                  <div class="flex flex-col space-y-2 px-6">
                    <label-item label="Verification Options" desc="We'll ask your tenant to go through
                      identity verification flow and provide you a verification result">
                      <div class="flex flex-col items-start space-y-4 pt-4">
                        <div class="flex items-center space-x-2">
                          <span>Selfie Check</span>
                          <a-switch v-model="temp.selfie_check" />
                          <span>ID Number Check</span>
                          <a-switch v-model="temp.id_number_check" />
                        </div>
                        <div class="flex items-center space-x-2">
                          <span>Allowed Doc Types:</span>
                          <a-checkbox v-for="(v, i) in temp.allowed_doc_types" :key="`allowed-doc-type-${i}`"
                            v-model="v.checked">
                            {{ startCase(v.name) }}
                          </a-checkbox>
                        </div>
                      </div>
                    </label-item>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="flex w-1/3 h-full items-center border-gray-400 border-dashed overflow-y-auto">
                <div class="flex flex-col mx-4 flex-grow space-y-3">
                  <div class="flex justify-between items-center">
                    <span class="font-medium text-xs">Enable template</span>
                    <a-switch :checked="temp.subtypes.length > 0" @click="() => {
                      if (temp.subtypes.length > 0) {
                        temp.subtypes = [];
                      } else {
                        temp.subtypes = ['parking'];
                      }
                    }" />
                  </div>
                  <a-divider />
                  <div class="flex items-center justify-between">
                    <span class="font-medium text-xs">Message for Tenants</span>
                    <a-button v-if="!templatesSettings[i].expand.message" size="small"
                      @click="() => templatesSettings[i].expand.message = true">{{ temp.editable ? "Edit" :
                        "View" }}</a-button>
                    <a-button v-else type="primary" size="small"
                      @click="() => templatesSettings[i].expand.message = false">Confirm</a-button>
                  </div>
                  <a-textarea v-if="templatesSettings[i].expand.message" v-model="temp.signer_message"
                    placeholder="Enter any instructions or guidance for your signer to pay attention to"
                    :auto-size="{ minRows: 3, maxRows: 6 }" :disabled="!temp.editable" />
                  <a-divider />
                  <div class="flex justify-between items-center">
                    <span class="font-medium text-xs">Template type</span>
                    <a-radio-group v-if="temp.editable" v-model="temp.type" button-style="solid" size="small"
                      class="template-type-segmented-control" @change="(e) => {
                        console.log(`checked = ${e.target.value}`);
                        if (e.target.value === 'upload') {
                          temp.doc_sign_required = false;
                        }
                        if (e.target.value === 'sign') {
                          temp.doc_sign_required = true;
                        }
                      }">
                      <a-radio-button value="upload">
                        Upload
                      </a-radio-button>
                      <a-radio-button value="sign">
                        Sign
                      </a-radio-button>
                    </a-radio-group>
                    <a-tag v-else color="geekblue">{{ startCase(temp.type) }}</a-tag>
                  </div>
                  <a-divider />
                  <div v-if="!templatesSettings[i].expand.intervalMode" class="flex justify-between items-center">
                    <span class="font-medium text-xs">Allowed interval</span>
                    <div class="flex items-center">
                      <a-tag v-if="temp.interval_modes.length != 1" color="geekblue">{{ temp.interval_modes.length }}
                        selected</a-tag>
                      <a-tag v-else color="geekblue">{{ startCase(temp.interval_modes[0]) }}</a-tag>
                      <a-button v-if="temp.editable" size="small"
                        @click="() => templatesSettings[i].expand.intervalMode = true">Edit</a-button>
                    </div>
                  </div>
                  <template v-else>
                    <div class="flex items-center justify-between">
                      <span class="font-medium text-xs">This template applies to:</span>
                      <a-button type="primary" size="small"
                        @click="() => templatesSettings[i].expand.intervalMode = false">Confirm</a-button>
                    </div>
                    <a-checkbox-group class="listing-type-checkbox-group" v-model="temp.interval_modes">
                      <div class="flex flex-wrap">
                        <a-checkbox value="monthly" class="mx-2 my-1">
                          Monthly
                        </a-checkbox>
                        <a-checkbox value="daily" class="mx-2 my-1">
                          Daily
                        </a-checkbox>
                        <a-checkbox value="hourly" class="mx-2 my-1">
                          Hourly
                        </a-checkbox>
                      </div>
                    </a-checkbox-group>
                  </template>
                  <a-divider />
                  <div v-if="!templatesSettings[i].expand.listingType" class="flex justify-between items-center">
                    <span class="font-medium text-xs">Listing type</span>
                    <div class="flex items-center">
                      <a-tag v-if="temp.subtypes.length != 1" color="geekblue">{{ temp.subtypes.length }}
                        selected</a-tag>
                      <a-tag v-else color="geekblue">{{ startCase(temp.subtypes[0]) }}</a-tag>
                      <a-button v-if="temp.editable" size="small"
                        @click="() => templatesSettings[i].expand.listingType = true">Edit</a-button>
                    </div>
                  </div>
                  <template v-else>
                    <div class="flex items-center justify-between">
                      <span class="font-medium text-xs">This template applies to:</span>
                      <a-button type="primary" size="small"
                        @click="() => templatesSettings[i].expand.listingType = false">Confirm</a-button>
                    </div>
                    <a-checkbox-group class="listing-type-checkbox-group" v-model="temp.subtypes">
                      <div class="flex flex-wrap">
                        <a-checkbox value="parking" class="mx-2 my-1">
                          Parking
                        </a-checkbox>
                        <a-checkbox value="stay" class="mx-2 my-1">
                          Stay
                        </a-checkbox>
                        <a-checkbox value="coworking" class="mx-2 my-1">
                          Coworking
                        </a-checkbox>
                        <a-checkbox value="car-rental" class="mx-2 my-1">
                          Car Rental
                        </a-checkbox>
                      </div>
                    </a-checkbox-group>
                  </template>
                </div>
              </div>
              <div v-show="temp.type === 'sign'"
                class="flex flex-col w-1/3 h-full overflow-hidden px-4 py-2 overflow-y-auto">
                <dropzone :template-id="temp.id" :docs="temp.docs_to_sign" :is-editable="temp.editable" class="mt-2" />
                <div v-if="temp.editable" class="flex flex-col items-center justify-between space-y-2">
                  <svg-icon icon-class="up-arrow" class="w-5 h-5 text-gray-400" />
                  <span class="text-xs text-gray-500">Drop / Paste the PDF file to sign into the top box, at least one
                    PDF file is required</span>
                </div>
                <div v-else class="flex flex-col items-center justify-between space-y-2">
                  <svg-icon icon-class="up-arrow" class="w-5 h-5 text-gray-400 transform -rotate-90" />
                  <span class="text-xs text-gray-500">Use the preview button on the left to preview how this file looks
                    in a signature request your tenant received</span>
                </div>
              </div>
              <div v-if="temp.type === 'upload'" class="w-1/3 h-full overflow-hidden px-4 py-2 relative">
                <div class="absolute top-1/2 transform -translate-y-1/2 w-full left-0">
                  <div class="flex flex-col space-y-2 items-center px-4">
                    <span class="font-medium text-lg text-gray-500">Upload Only</span>
                    <p class="leading-6 font-light text-sm text-gray-700 text-center">We'll prompt your tenants to upload
                      the
                      <a-tag v-if="temp.name" color="geekblue">{{ temp.name }}</a-tag>document you specified
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-if="false" class="fixed bottom-0 right-0 bg-white border-t border-l z-10"
      :style="{ left: `${$menuWidth()}px` }">
      <div v-if="visible.footer" class="flex justify-between items-center px-4 py-8">
        <a-button type="primary" @click.stop="$bus.$emit('open-new-document-template-modal')">
          + Create Document
        </a-button>
        <smooth-scroll style="max-width: 600px" :ops="{
          vuescroll: {},
          scrollPanel: {},
          rail: {},
          bar: {
            size: '6px',
            onlyShowBarOnScroll: false,
            opacity: 0.6
          }
        }">
          <div class="flex items-center space-x-2 py-2 px-2">
            <a-tag v-for="(error, i) in $deepGet(controls, 'errors', [])" :key="`template-validate-error-${i}`"
              color="red">
              {{ error.message }}
            </a-tag>
          </div>
        </smooth-scroll>
        <div class="flex items-center space-x-4">
          <a-button type="link" :disabled="disableSaveChanges()" @click="resetChanges()">
            Reset Changes
          </a-button>
          <a-button v-if="deletedDict.templates.length > 0" type="danger" :loading="loadingDelete"
            @click.stop="deleteDocumentTemplates()">
            Delete {{ deletedDict.templates.length }} templates
          </a-button>
          <a-button v-else type="primary" :loading="$deepGet(controls, 'loading.save_changes', false)
            " :disabled="disableSaveChanges()" @click.stop="saveChanges()">
            Save Changes
          </a-button>
          <div class="w-16" />
        </div>
      </div>
    </div>
    <template-request-modal :visible="visible.templateRequest"
      :templates-to-review="cloneDeep(templates.filter(t => t.type === 'sign' && !t.is_reviewed))"
      @continue="updateDocumentTemplates()" @close="() => visible.templateRequest = false" />
    <new-document-template-modal />
    <document-template-modal />
  </div>
</template>
<style lang="scss" scoped>
::v-deep {

  .ant-btn {
    @apply flex items-center space-x-2;
  }

  .doc-name-input {
    .ant-input {
      @apply text-xs px-1.5 py-0.5;
    }
  }

  .label-item {
    .label {
      @apply text-base text-black font-semibold;
    }

    .desc {
      @apply text-sm;
    }
  }
}
</style>
<style lang="scss" scoped>
.video-icon {
  animation-iteration-count: infinite;
  font-size: 20px;
}

.tutorial-alert {
  .ant-btn {
    @apply text-xs;
  }

  .ant-tag {
    @apply text-xs my-1;
  }
}

.template-status-tag {
  @apply flex items-center justify-center space-x-2;
}

.listing-type-checkbox-group::v-deep {
  .ant-checkbox-wrapper {
    span {
      @apply text-xs;
    }
  }
}

.template-type-segmented-control::v-deep {
  .ant-radio-button-wrapper {
    span {
      @apply text-xs;
    }
  }
}

.ant-btn-sm {
  @apply text-xs;
}

.doc-template {
  @import url("~video.js/dist/video-js.css");
}
</style>
