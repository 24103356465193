function aQuarterOfHour(hour = "") {
  return [":00", ":15", ":30", ":45"].map((q) => `${hour}`.padStart(2, "0") + `${q}`);
}

export function getTime() {
  const amhours = Array.from({ length: 13 }, (_, i) => i);
  const pmhours = Array.from({ length: 11 }, (_, i) => i + 1);
  const am = amhours.map((h) => aQuarterOfHour(h).map((q) => q.padStart(2, "0") + "AM")).flat();
  const pm = pmhours.map((h) => aQuarterOfHour(h).map((q) => q + "PM")).flat();
  return [...am, ...pm, "11:59PM"];
}

export const getDefaultModelForMode = (targetMode) => {
  const simpleModelProto = {
    enabled: {
      weekdays: {
        from: "00:00AM",
        to: "11:59PM",
      },
      weekends: {
        from: "00:00AM",
        to: "11:59PM",
      },
      holidays: {
        from: "00:00AM",
        to: "11:59PM",
        include: [
        ],
      },
    },
    disabled: {},
  };
  const manualModelProto = {
    enabled: [
      {
        day: "Sunday",
        from: "00:00AM",
        to: "11:59PM",
        active: true,
      },
      // Note: days can have duplicated values
      // {
      //   day: "Sunday",
      //   from: "03:00PM",
      //   to: "11:59PM",
      //   active: true,
      // },
      {
        day: "Monday",
        from: "00:00AM",
        to: "11:59PM",
        active: true,
      },
      {
        day: "Tuesday",
        from: "00:00AM",
        to: "11:59PM",
        active: true,
      },
      {
        day: "Wednesday",
        from: "00:00AM",
        to: "11:59PM",
        active: true,
      },
      {
        day: "Thursday",
        from: "00:00AM",
        to: "11:59PM",
        active: true,
      },
      {
        day: "Friday",
        from: "00:00AM",
        to: "11:59PM",
        active: true,
      },
      {
        day: "Saturday",
        from: "00:00AM",
        to: "11:59PM",
        active: true,
      },
    ],
    disabled: [],
  };
  return {
    mode: targetMode,
    simple:
      targetMode === "simple"
        ? simpleModelProto.enabled
        : simpleModelProto.disabled,
    manual:
      targetMode === "manual"
        ? manualModelProto.enabled
        : manualModelProto.disabled,
  };
};

export function getDay() {
  return [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
}
