<script>
import { ref, reactive, watch, computed, onMounted, nextTick, onBeforeUnmount } from "@vue/composition-api";
import JSZip from "jszip";
import startCase from "lodash/startCase";
import { API } from "src/views/documents/api";
import { startSignatureRequest } from "./Templates.vue";
import { getDropboxURL, getS3DownloadUrl } from "src/views/components/util";

export default {
  props: {
    role: {
      type: String,
      default: "host",
    },
    filter: {
      type: Function,
      default: () => true
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, ctx) {
    const deepGet = ctx.root.$deepGet;
    const downloadAnchor = ref(null);
    const controls = reactive({
      loading: {
        table: false,
      },
      expandable: {
        type_index: -1,
      },
      query_filter: {}
    });

    const focus = computed(() => ctx.root.$route.query.focus);

    const pagination = reactive({
      currentPage: 1,
      perPage: 10,
      total: 0
    })

    const table = reactive({
      headers: [
        {
          title: "Name",
          align: "center",
          dataIndex: "template.name",
          scopedSlots: { customRender: "doc_name" },
        },
        (() => {
          if (props.role === "host") {
            return {
              title: "Tenant",
              align: "center",
              dataIndex: "reservation.tenant_name",
              scopedSlots: { customRender: "name" }
            };
          } else {
            return {
              title: "Host",
              align: "center",
              dataIndex: "reservation.host_name",
              scopedSlots: { customRender: "name" }
            };
          }
        })(),
        {
          title: "Status",
          align: "center",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "Type",
          align: "center",
          dataIndex: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "Reservation",
          align: "center",
          dataIndex: "reservation",
          scopedSlots: { customRender: "reservation" },
        },
        props.role === "host" && {
          title: "Template",
          align: "center",
          dataIndex: "template",
          scopedSlots: { customRender: "template" },
        },
        {
          title: "Created At",
          align: "center",
          dataIndex: "created_at",
          scopedSlots: { customRender: "created_at" },
        },
        {
          title: "Actions",
          align: "center",
          scopedSlots: { customRender: "actions" },
          fixed: "right",
          width: 180,
        },
      ],
      data: [],
    });

    if (ctx.root.$isAdmin()) {
      table.headers = [
        {
          title: "Name",
          align: "center",
          dataIndex: "template.name",
          scopedSlots: { customRender: "doc_name" },
        },
        {
          title: "Host",
          align: "center",
          dataIndex: "reservation.host_name",
          scopedSlots: { customRender: "host_name" }
        },
        {
          title: "Tenant",
          align: "center",
          dataIndex: "reservation.tenant_name",
          scopedSlots: { customRender: "name" }
        },
        {
          title: "Status",
          align: "center",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "Type",
          align: "center",
          dataIndex: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "Reservation",
          align: "center",
          dataIndex: "reservation",
          scopedSlots: { customRender: "reservation" },
        },
        {
          title: "Created At",
          align: "center",
          dataIndex: "created_at",
          scopedSlots: { customRender: "created_at" },
        },
        {
          title: "Actions",
          align: "center",
          scopedSlots: { customRender: "actions" },
          fixed: "right",
          width: 180,
        },
      ]
    }

    const downloadDocs = async (record) => {
      ctx.root.$set(record, "$loading.download", true);
      const zip = new JSZip();
      // Dropbox paths
      for (const filePath of [
        ...deepGet(record, "files", [])
          .filter((v) => v.dbx_path)
          .map((v) => v.dbx_path),
        ...deepGet(record, "signatures", [])
          .filter((v) => v.dbx_path)
          .map((v) => v.dbx_path),
      ].filter((v) => v)) {
        let resp;
        try {
          resp = await getDropboxURL(filePath);
          console.log("resp => ", resp);
          zip.file(resp.name, resp.fileBlob, { base64: true });
        } catch (error) {
          console.log("filesDownload -> Dropbox >> error: ", error);
          continue;
        }
      }
      // S3 paths
      for (const f of [
        ...deepGet(record, "files", []).filter((v) => v.s3_path),
        ...deepGet(record, "signatures", []).filter((v) => v.s3_path),
      ].filter((v) => v)) {
        let filename = f.s3_path.split("/").pop();
        let url;
        try {
          url = await getS3DownloadUrl({ path: f.s3_path });
          console.log("url => ", url);
          let blob = await fetch(url).then((r) => r.blob());
          zip.file(filename, blob, { base64: true });
        } catch (error) {
          console.log("filesDownload -> S3 >> error: ", error);
          continue;
        }
      }
      ctx.root.$set(record, "$loading.download", false);
      const content = await zip.generateAsync({ type: "blob" });
      if (downloadAnchor.value) {
        const dataURL = URL.createObjectURL(content);
        downloadAnchor.value.setAttribute("href", dataURL);
        downloadAnchor.value.setAttribute(
          "download",
          `${record.template.name}-documents-${record.tenant_name}.zip`
        );
        downloadAnchor.value.click();
      }
      ctx.root.$bus.$emit("document-download-stop");
    };

    const getDocumentsList = async (extraFilters = {}) => {
      controls.loading.table = true;
      const payload = {
        filter: {
          ...controls.query_filter,
          ...props.filters,
          ...extraFilters,
          page: pagination.currentPage
        }
      };
      let resp = {}, pag;
      try {
        if (props.role == "host") {
          const { data, pagination } = await API.listHostDocuments(payload);
          resp.data = data;
          pag = pagination;
        } else {
          const { data, pagination } = await API.listTenantDocuments(payload);
          resp.data = data;
          pag = pagination;
        }

        pagination.currentPage = pag.page;
        pagination.perPage = pag.per_page;
        pagination.total = pag.total;
        ctx.root.$set(table, "data", resp.data);
        controls.loading.table = false;

      } catch (error) {
        controls.loading.table = false;
        console.log("getDocumentsList >> error: ", error);
      }
    };

    const signDocument = async (doc) => {
      ctx.root.$bus.$emit("toggle-loading-indicator", true, "Preparing Document");
      ctx.root.$set(doc, "$loading.sign", true);
      await startSignatureRequest(doc, props.role);
      ctx.root.$bus.$emit("toggle-loading-indicator", false);
      ctx.root.$set(doc, "$loading.sign", false);
    };

    const reinviteTenant = async (doc) => {
      ctx.root.$set(doc, "$loading.reinvite", true);
      let resp;
      try {
        resp = await API.hostInviteDocumentTenant(doc.id);
        console.log("requestUpdate: ", resp.message);
        ctx.root.$openNotification(
          "Invite Tenant",
          "Invitation has been sent to tenant email address",
          5,
          "info"
        );
        ctx.root.$set(doc, "$loading.reinvite", false);
      } catch (error) {
        console.log("requestUpdate: error:", error);
        ctx.root.$openNotification("Invite Tenant", error.message, 10, "error");
      }
    };
    // watch(() => controls.currentPage, async (val) => {
    //   highlightTargetItemIfNeeded();
    //   await fetchDetailsForCurrentPage();
    //   highlightTargetItemIfNeeded();
    // })

    const onFocusChange = async val => {
      console.log("focus -> changed.", val);

      if (val) {
        controls.query_filter = {
          doc_id: val,
          // Find the page index of doc ID from backend (Instead of simple filtering out doc ID as a single result.)
          find_page: true
        };
      } else {
        controls.query_filter = {};
      }

      await getDocumentsList();

      scrollToFocusDocIfNeeded();
    }

    watch(() => props.filters, (val) => {
      getDocumentsList();
    }, { deep: true })

    watch(() => focus.value, onFocusChange);

    const scrollToFocusDocIfNeeded = () => {
      if (!focus.value) {
        return;
      }
      nextTick(() => {
        const targetTableRowSelector = `[data-row-key="${focus.value}"]`;
        const targetTableRow = document.querySelector(targetTableRowSelector);
        if (targetTableRow) {
          ctx.root.$scrollToHashSection(targetTableRowSelector);
          targetTableRow.classList.add('animate-bounce', 'bg-blue-50');
          highlightTargetItemIfNeeded();
          setTimeout(() => {
            targetTableRow.classList.remove('animate-bounce', 'bg-blue-50');
          }, 2000);
        }
      })
    }
    const highlightTargetItemIfNeeded = () => {
      if (!focus.value) {
        return;
      }
      nextTick(() => {
        const targetTableRowSelector = `[data-row-key="${focus.value}"]`;
        const targetTableRow = document.querySelector(targetTableRowSelector);
        if (targetTableRow) {
          // Remove all existed highlighted items
          document.querySelectorAll(".parqay-doc-highlighted").forEach(el => {
            el.classList.remove('parqay-doc-highlighted', 'font-bold', 'text-parqay-primary');
            el.classList.add('font-medium', 'text-black');
          })
          const targetTableRowTitle = document.querySelector(`${targetTableRowSelector} span`);
          if (targetTableRowTitle) {
            targetTableRowTitle.classList.add('parqay-doc-highlighted', 'font-bold', 'text-parqay-primary');
            targetTableRowTitle.classList.remove('font-medium', 'text-black');
          }
        }
      })
    }
    const handlePageChange = (page) => {
      if (focus.value) {
        ctx.root.$router.push({ name: ctx.root.$route.name, hash: ctx.root.$route.hash });
      }
      pagination.currentPage = page;
      getDocumentsList();
    }
    onMounted(async () => {
      ctx.emit("update-types", [...new Set(table.data.map((v) => v.type))]);
      if (ctx.root.$isDesktopView()) {
        // ONLY load documents list in desktop view since mobile view on browser is not supported yet
        // await getDocumentsList();
        await onFocusChange(focus.value);
        scrollToFocusDocIfNeeded();
      }
      ctx.root.$bus.$on("documents-refresh", getDocumentsList);
      ctx.root.$bus.$on("document-sign-refresh", getDocumentsList);
      ctx.root.$bus.$on("document-upload-refresh", getDocumentsList);
      ctx.root.$bus.$on("document-download-start", downloadDocs);
      ctx.root.$bus.$on("start-new-document-signature-request", signDocument);
    });

    onBeforeUnmount(() => {
      ctx.root.$bus.$off("documents-refresh", getDocumentsList);
      ctx.root.$bus.$off("document-sign-refresh", getDocumentsList);
      ctx.root.$bus.$off("document-upload-refresh", getDocumentsList);
      ctx.root.$bus.$off("document-download-start", downloadDocs);
      ctx.root.$bus.$off("start-new-document-signature-request", signDocument);
    });
    return {
      downloadAnchor,
      controls,
      table,
      pagination,
      downloadDocs,
      signDocument,
      reinviteTenant,
      handlePageChange,
      startCase,
      console
    };
  },
};
</script>
<template>
  <div class="w-full shadow-sm border overflow-hidden rounded-lg bg-white pb-10">
    <a ref="downloadAnchor" style="display: none" />
    <a-table :loading="controls.loading.table" :columns="table.headers" :data-source="table.data.filter(filter)"
      :scroll="{ x: true, y: false }" row-key="id" :custom-row="(record, index) => {
      return {
        on: {
          click: (event) => {
            $openDocSlideCard(
              role,
              record
            );
          },
        },
      };
    }
      " :pagination="false">
      <template slot="doc_name" slot-scope="name, record">
        <div class="flex items-center justify-center px-2 py-2" style="max-width: 180px;">
          <span class="text-left hover:text-blue-500 text-sm text-black cursor-pointer">{{ name }}</span>
        </div>
      </template>

      <!-- ADMIN ONLY -->
      <template slot="host_name" slot-scope="name, record">
        <div v-if="$isAdmin()" class="flex items-center justify-center space-x-2 py-2" @click.stop="() => {
      $router.push({
        name: 'Tenants',
        query: {
          host: record.host
        }
      })
    }">
          <avatar :user-id="record.host" relative-role="host" />
        </div>
      </template>

      <template slot="name" slot-scope="name, record">
        <div class="flex items-center justify-center space-x-2 py-2" @click.stop="() => {
      if (name && role == 'host') {
        $router.push({
          name: 'Tenants',
          query: {
            focus: $isAdmin() ? `${record.tenant},${record.host}` : record.tenant
          }
        })
      }
    }">
          <avatar :user-id="record.tenant" :admin-on-behalf-of="record.host" />
        </div>
      </template>

      <template slot="status" slot-scope="status, record">
        <div class="flex flex-col space-y-2 px-2 py-4">
          <a-tag status v-if="status == 'incomplete'" color="pink">
            {{ startCase(status) }}
          </a-tag>
          <a-tag status v-if="status == 'signing'" color="orange" class="signing-tag">
            <span>{{ startCase(status) }}</span>
            <div v-if="record.signed_by" class="flex flex-col items-center justify-center space-y-1 pt-1">
              <a-tooltip v-for="(v, k) in record.signed_by" :key="`signed-by-${k}`">
                <template #title>
                  Signed by: {{ v.performed_by_email }}, waiting for other signers to sign
                </template>
                <div
                  class="flex items-center space-x-1 text-xs px-1.5 py-1 rounded border border-orange-300 bg-orange-100 text-orange-500">
                  <span>{{ v.performed_by_name }}</span><a-icon type="check-circle" />
                </div>
              </a-tooltip>
            </div>
          </a-tag>
          <a-tag status v-if="status == 'signed'" color="cyan" class="justify-center">
            {{ startCase(status) }}
          </a-tag>
          <a-tag status v-if="status == 'verifying'" color="orange" class="justify-center">
            {{ startCase(status) }}
          </a-tag>
          <a-tag status v-if="status == 'requires_input'" color="pink" class="justify-center">
            {{ startCase(status) }}
          </a-tag>
          <a-tag status v-if="status == 'complete'" color="green" class="justify-center">
            {{ startCase(status) }}
          </a-tag>
        </div>
      </template>

      <template slot="type" slot-scope="type, record, i">
        <div class="flex flex-col space-y-2 px-2 py-4">
          <a-tag type v-if="record.template.doc_sign_required" color="green">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-3 h-3">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
            </svg>
            <span>Sign</span>
          </a-tag>
          <a-tag type v-else color="geekblue">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
            </svg>
            <span>Upload</span>
          </a-tag>
        </div>
      </template>

      <template slot="template" slot-scope="temp, record, i">
        <div class="flex items-center justify-center" style="max-width: 150px;">
          <span class="text-xs hover:text-blue-500 cursor-pointer" @click.stop="$emit('change-tab', 'templates')">{{
      temp.name }}</span>
        </div>
      </template>

      <template slot="reservation" slot-scope="reservation">
        <div class="flex flex-col space-y-2 m-2">
          <a-tag reservation color="geekblue"
            class="flex justify-between items-center cursor-pointer text-xs font-medium px-3 py-1 space-x-2"
            @click.stop="() => {
      if (role === 'tenant') {
        $router.push('/tenant/reservations/' + reservation.id);
      }
      if (role === 'host') {
        $bus.$emit('open-reservation-drawer', reservation);
      }
    }">
            <a-icon type="calendar" />
            <span class="truncate" style="max-width: 160px;">{{ $deepGet(reservation, "listing.name", "N/A") }}</span>
            <svg-icon icon-class="arrow-circle-right" class="w-5 h-5" />
          </a-tag>
        </div>
      </template>

      <template slot="created_at" slot-scope="created_at">
        <a-tag class="justify-center">
          {{ $formatDate(created_at, "MM/DD, h:mm a") }}
        </a-tag>
      </template>

      <template slot="actions" slot-scope="_, record, i">
        <div class="grid grid-cols-2 gap-2 document-actions">
          <a-popconfirm v-if="record.template.doc_sign_required && record.status === 'complete'"
            title="This document is complete. Please confirm if you want to start a new sign request" ok-text="Sign"
            @confirm="signDocument(record)">
            <a-button type="primary" :loading="record.$loading">
              Sign
            </a-button>
          </a-popconfirm>
          <a-button type="primary" v-if="record.template.doc_sign_required && record.status !== 'complete'"
            :loading="$deepGet(record, '$loading.sign', false)" @click="signDocument(record)">
            Sign
          </a-button>
          <a-button type="primary" v-if="role == 'tenant' && !record.template.doc_sign_required" @click.stop="$openDocSlideCard(
      role,
      record
    )">
            Upload
          </a-button>
          <a-button v-if="record.type == 'sign'" type="primary" :disabled="$deepGet(record, 'signatures', [])
      .filter((v) => v.dbx_path)
      .map((v) => v.dbx_path).length == 0
      " :loading="$deepGet(record, '$loading.download', false)" @click.stop="downloadDocs(record)">
            Download
          </a-button>
          <a-button v-if="record.type == 'upload'" type="primary" :disabled="!(record.files && record.files.length > 0)"
            :loading="$deepGet(record, '$loading.download', false)" @click.stop="downloadDocs(record)">
            Download
          </a-button>
          <a-button v-if="role === 'host' && record.status !== 'complete'"
            :loading="$deepGet(record, '$loading.reinvite', false)" size="large" @click.stop="reinviteTenant(record)">
            Reinvite
          </a-button>
          <a-button @click.stop="
      $openDocSlideCard(role, record)
      ">
            More
          </a-button>
          <!-- <div v-if="role === 'host'" class="flex space-x-2">
                    <a-button
                      type="primary"
                      class="w-1/2"
                      @click="() => (record.host_approval.status = 'approved')"
                    >
                      Approve
                    </a-button>
                    <a-button
                      class="w-1/2"
                      @click="() => (record.host_approval.status = 'rejected')"
                    >
                      Reject
                    </a-button>
                  </div> -->
        </div>
      </template>
    </a-table>
    <div class="flex items-center justify-center pt-4">
      <a-pagination :current="pagination.currentPage" :total="pagination.total" :page-size="pagination.perPage"
        @change="handlePageChange" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .ant-tag {
    @apply flex py-1;
  }

  .ant-tag[verify-status] {
    font-size: 0.65rem;
    line-height: 0.85rem;
    @apply flex py-1 font-semibold;
  }

  .ant-tag[status] {
    @apply flex py-1 text-xs;
  }

  .ant-tag[type] {
    @apply flex items-center px-2 py-1 space-x-2 text-xs;
  }

  .signing-tag {
    @apply flex flex-col items-center justify-center space-y-1 divide-y divide-orange-300;
  }

  .ant-tag[reservation] {
    &:hover {
      @apply bg-white border-black text-black;
    }

    @apply flex py-1;
  }

  .ant-btn[temp] {
    @apply flex items-center justify-center text-sm;
  }

  .document-actions {
    .ant-btn {
      padding: unset;
      @apply text-xs py-0.5 h-6;
    }
  }
}
</style>
