<script>
import { reactive, nextTick, watch } from "@vue/composition-api";
import moment from "dayjs";
import ClickOutside from "vue-click-outside";

export const formatMomentForBackend = (mm, fmt = "YYYY-MM-DDTHH:mm:ssZ") => {
    return mm.format(fmt);
};

const getDefaultMonthlyRange = () => {
    const now = moment();
    let startMm = now.startOf("day");
    let endMm = startMm.clone().add(1, "month");
    return [startMm, endMm];
};

export const getDefaultMonthlyDuration = location => {
    // Note: startOf, endOf will modify original moment, clone() has to be used.
    const now = moment();
    let startMm = now.startOf("day");
    let endMm = startMm.clone().add(1, "month");
    return {
        start: formatMomentForBackend(startMm, "YYYY-MM-DDTHH:mm"),
        end: formatMomentForBackend(endMm, "YYYY-MM-DDTHH:mm")
    };
};

import { API } from "./api";

export default {
    directives: {
        ClickOutside
    },
    components: {},
    props: {
        visible: {
            type: Boolean,
            require: true
        }
    },
    setup(props, ctx) {

        const controls = reactive({
            // Refer to main view component for object creation model
            loading: {
                keywords: false,
                search: false,
                confirm: false,
                searchEvents: false,
                nearbyListings: {
                    parking: true,
                    stay: false,
                    coWorking: false,
                    carRentals: false
                }
            },
            expand: {
                textarea: false
            },
            welcomeText: null,
            keywords: null,
            counts: {
                parking: 0,
                stay: 0,
                coWorking: 0,
                carRentals: 0
            },
            listing_type: "parking",
            interval_mode: "monthly",
            place: {
                type: "location",
                location: {
                    lat: 37.7749,
                    lon: -122.4194,
                },
                name: "San Francisco",
                address: "San Francisco, CA, USA",
            },
            duration: getDefaultMonthlyDuration({
                lat: 37.7749,
                lon: -122.4194,
            }),
            range: getDefaultMonthlyRange(),
            nearbyListings: {
                parking: [],
                stay: [],
                coWorking: [],
                carRentals: []
            },
            key: 0
        });

        watch(() => props.visible, visible => {
            if (visible) {
                nextTick(() => {
                    typeWelcomeText("What can I do for you today? 🤠");
                    getRandomGPTKeywordsPlaceholder();
                })
            }
        })

        const typeWelcomeText = (text) => {
            let index = 0;
            controls.welcomeText = null;
            const writeText = () => {
                if (!controls.welcomeText) {
                    controls.welcomeText = ""
                }
                controls.welcomeText += text.charAt(index);
                index++;
                if (index > text.length - 1) {
                    clearInterval(typing);
                }
            }
            const typing = setInterval(writeText, 50);
        }

        const typeKeywords = (kw) => {
            let index = 0;
            controls.keywords = null;
            const writeText = () => {
                if (!controls.keywords) {
                    controls.keywords = ""
                }
                controls.keywords += kw.charAt(index);
                index++;
                if (index > kw.length - 1) {
                    clearInterval(typing);
                }
            }
            const typing = setInterval(writeText, 50);
        }

        const getRandomGPTKeywordsPlaceholder = async () => {
            controls.loading.keywords = true;
            try {
                const { data } = await API.getRandomGPTKeywordsPlaceholder();
                controls.keywords = null;
                typeKeywords(data.message);
            } catch (error) {
                console.log("getRandomGPTKeywordsPlaceholder error: ", error);
            } finally {
                setTimeout(() => {
                    controls.loading.keywords = false;
                }, 1500);
            }
        }

        const openSearchList = async (gpt_data) => {
            const queryParams = {
                start: gpt_data.start || controls.duration.start,
                end: gpt_data.end || controls.duration.end,
                interval_mode: gpt_data.interval_mode || controls.interval_mode,
                listing_type: gpt_data.listing_type || controls.listing_type,
                place_type: "location",
                place_name: gpt_data.place_name || controls.place.name,
                place_address: gpt_data.place_address || controls.place.address,
                lat: ctx.root.$deepGet(gpt_data, "location[0]") || controls.place.location.lat,
                lon: ctx.root.$deepGet(gpt_data, "location[1]") || controls.place.location.lon,
            };
            const queryString = Object.entries(queryParams)
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join("&");

            const url = `${process.env.VUE_APP_MARKETPLACE_FRONTEND_BASE_URL}/search?${queryString}`;
            ctx.emit("close");
            return window.open(url, "_blank");
        }

        const searchGPT = async () => {
            const keywords = `${controls.keywords}`
            controls.loading.search = true;
            try {
                const { data } = await API.searchGPT({ keywords });
                await openSearchList(data);
            } catch (error) {
                console.log("searchGPT error: ", error);
            } finally {
                setTimeout(() => {
                    controls.loading.search = false;
                }, 1500);
            }
        }

        return {
            controls,
            getRandomGPTKeywordsPlaceholder,
            searchGPT
        };
    },
};
</script>
<template>
    <a-modal width="800px" :visible="visible" :footer="null" @cancel="$emit('close')">
        <div class="flex flex-col items-center space-y-3 hero-pattern-text border rounded-xl pt-10 pb-4 mt-8">
            <div class="flex items-center space-x-1 pb-6">
                <span class="font-bold" :style="{ fontSize: '2rem' }"><a class="text-parqay-primary">PARQ</a>
                    <a style="color: #57c7ff">AI</a></span>
                <img src="/static/img/PARQAY-marker-colorful.png" class="w-12 h-12 object-contain">
            </div>
            <div class="w-full px-12">
                <div class="flex items-center justify-center rounded-lg border border-blue-200 bg-blue-100 text-lg font-semibold text-parqay-primary w-full"
                    :style="{ minHeight: '80px' }">
                    <p>{{ controls.welcomeText }}</p>
                </div>
            </div>
            <!-- Small hacks to trigger textarea expand when focus and shrink when blur -->
            <div class="flex items-center space-x-4 px-6 w-full"
                @click="() => { controls.keywords += ' '; controls.expand.textarea = true; $nextTick(() => { controls.keywords = controls.keywords.slice(0, -1) }) }"
                v-click-outside="() => { controls.expand.textarea = false; controls.key += 1; }">
                <div>
                    <a-icon v-if="controls.loading.keywords" type="loading" />
                    <a-button v-else icon="reload" style="display: flex; justify-content: center; align-items: center;"
                        @click="getRandomGPTKeywordsPlaceholder()" />
                </div>
                <a-textarea :key="controls.key" v-model="controls.keywords"
                    placeholder="Can you fetch a parking space for me near Michigan Stadium on March 28th?"
                    :auto-size="{ minRows: controls.expand.textarea ? 2 : 1, maxRows: 3 }" size="large"
                    class="gpt-searchbar" :style="{ minHeight: '40px', lineHeight: '30px' }" @change="() => {
                        controls.keywords = controls.keywords.replace(/\n/g, '');
                    }" @pressEnter="searchGPT()" />
                <a-button type="primary" :loading="controls.loading.search" size="large" :disabled="!controls.keywords"
                    @click="searchGPT()">Search</a-button>
            </div>
            <a-divider :style="{ marginTop: '20px', marginBottom: 0 }"></a-divider>
            <div class="flex items-center justify-center px-4 py-2">
                <p class="text-gray-500 text-sm text-center leading-6" style="margin-bottom: 0;">
                    Please use the dialog box above to start a search using natural language.
                    <br>Feel free to use the <a-icon type="reload" style="vertical-align: unset;" /> icon to auto-generate a
                    new question.
                </p>
            </div>
        </div>
    </a-modal>
</template>
<style lang="scss">
.gpt-searchbar {
    font-size: 1rem;
}
</style>