<script>
import Card from "src/views/components/Card";
import { onMounted, reactive, ref } from "@vue/composition-api";
import { API } from "src/views/non-host/home/api";
import { API as PaymentAPI } from "src/views/non-host/payments/api";
import { API as ReservationAPI } from "../reservations/api";
import { API as DocumentAPI } from "src/views/documents/api";
import { API as MaintenanceAPI } from "src/views/maintenance/api";
import PendingTransactionsTable from "../common/PendingTransactionsTable.vue";

import startCase from "lodash/startCase";

export default {
  name: "tenant-home",
  components: {
    Card,
    PendingTransactionsTable
  },
  props: {
    searchHeaderState: {
      type: String,
      default: "none",
    },
  },
  setup(props, ctx) {
    const form = ref(null);
    const controls = reactive({
      loading: {
        balance: false,
        reservations: false,
        documents: false,
        services: false,
        maintenance: false,
        past_payments: false
      },
      balance: 0,
      transactions: {
        pending: [],
        history: []
      },
      reservations: [],
      documents: [],
      maintenances: []
    });
    const getBalanceData = async () => {
      controls.loading.past_payments = true;
      const { data } = await PaymentAPI.listTransactions({ role: "tenant" });
      controls.transactions.history = data;
      controls.loading.past_payments = false;
    }
    const getServicesData = async () => {
      controls.loading.services = true;
      const { data } = await API.summary();
      form.value = data;
      controls.loading.services = false;
    }
    const getReservationsData = async () => {
      controls.loading.reservations = true;
      const { data } = await ReservationAPI.list();
      controls.reservations = data;
      controls.loading.reservations = false;
    }
    const getDocumentsData = async () => {
      controls.loading.documents = true;
      const { data } = await DocumentAPI.listTenantDocuments();
      controls.documents = data;
      controls.loading.documents = false;
    }
    const getMaintenanceData = async () => {
      controls.loading.maintenance = true;
      const { data } = await MaintenanceAPI.list("tenant");
      controls.maintenances = data;
      controls.loading.maintenance = false;
    }
    const getUIData = async () => {
      try {
        getBalanceData();
        getServicesData();
        getReservationsData();
        getDocumentsData();
        getMaintenanceData();
      } catch (error) {
        console.log("error: ", error);
        return;
      }
    };
    const updateBalance = (data) => {
      controls.balance = data.pending + data.balance;
      controls.transactions.pending = data.transactions;
    };
    onMounted(() => {
      console.log("props.searchHeaderState", props.searchHeaderState);
      getUIData();
    });
    return {
      form,
      controls,
      updateBalance,
      startCase
    };
  },
};
</script>
<template>
  <div class="absolute inset-0">
    <div v-if="$isDesktopView()" class="border-b flex justify-between items-center px-10 pt-6 pb-2">
      <h2 class="text-dark-purple text-2xl font-medium my-auto">
        Home
      </h2>
    </div>
    <div class="flex flex-col lg:flex-row lg:justify-between px-6 py-4 lg:px-10 lg:py-0 lg:mt-10 lg:space-x-8">
      <!-- left -->
      <div class="w-full lg:w-1/2 space-y-8 flex-col lg:h-screen py-4 lg:py-0">
        <card class="border">
          <div class="pt-4 pb-4 lg:pb-2">
            <div class="flex items-center justify-center lg:justify-between px-4 pt-4 lg:pt-0 pb-4 lg:pb-8">
              <span class="lg:hidden text-gray-600 text-sm font-medium">
                Your Current Balance
              </span>
              <span class="hidden lg:block text-parqay-primary text-base font-medium">
                Your Current Balance
              </span>
              <a-button v-if="controls.loading.balance" type="link" loading></a-button>
            </div>
            <div class="text-center">
              <span class="text-4xl font-bold text-gray-900">
                {{ controls.loading.balance ? "-" :
      $formatCurrency(controls.balance) }}
              </span>
              <div class="flex items-center justify-between lg:justify-center mt-8 px-5 space-x-4">
                <button type="button"
                  class="rounded bg-blue-600 hover:bg-blue-400 text-white shadow py-2 w-full lg:w-36 lg:mr-10"
                  @click="$router.push('/tenant/payments#pay-now')">
                  Pay Now
                </button>
                <button type="button"
                  class="rounded border bg-white hover:bg-gray-100 text-black shadow py-2 w-full lg:w-36"
                  @click="$router.push('/tenant/reservations')">
                  Reservations
                </button>
              </div>
            </div>
            <div class="hidden lg:block mt-8">
              <div class="flex px-6 py-2 border-b">
                <div class="flex justify-between w-4/5 pr-6">
                  <span class="font-medium text-base text-blue-900">Description</span>
                  <span class="font-medium text-base text-blue-900">Amount</span>
                </div>
                <div class="flex justify-center w-1/5">
                  <span class="font-medium text-base text-blue-900">Action</span>
                </div>
              </div>
              <pending-transactions-table v-show="controls.transactions.pending.length > 0"
                :style="{ maxHeight: '260px' }" @loading-start="() => controls.loading.balance = true"
                @loading-stop="() => controls.loading.balance = false" @update-balance="updateBalance" />
              <div v-if="controls.transactions.pending.length == 0" class="flex items-center justify-center px-6">
                <span class="pt-5 pb-3 text-base font-medium text-gray-400">No pending/upcoming transactions yet</span>
              </div>
              <div class="flex items-center justify-center px-6 pt-2">
                <a-button type="link" @click="$router.push('/tenant/payments')">View all transactions</a-button>
              </div>
            </div>
          </div>
        </card>

        <card v-if="!$isDesktopView()">
          <template #header>
            <div class="flex items-center justify-between w-full">
              <h3 class="font-medium text-base text-gray-900">
                Latest Reservation
              </h3>
              <a-button type="link" :loading="controls.loading.documents" @click="$router.push('/tenant/reservations')">
                View All</a-button>
            </div>
          </template>
          <div v-if="controls.reservations.length > 0" class="flex flex-col">
            <div class="border-b flex justify-between items-center px-5 py-4 cursor-pointer hover:bg-blue-50"
              @click="$router.push(`/tenant/reservations/${controls.reservations[0].id}`)">
              <span class="text-parqay-primary text-base font-medium">{{ controls.reservations[0].title }}</span>
              <a-button>
                <a-icon type="arrow-right" />
              </a-button>
            </div>
          </div>
          <div v-else class="flex items-center justify-center px-6">
            <span class="py-3 text-base font-medium text-gray-400">No reservation yet</span>
          </div>
        </card>

        <card>
          <template #header>
            <div class="flex items-center justify-between w-full">
              <h3 class="font-medium text-base text-gray-900">
                Documents
              </h3>
              <a-button type="link" :loading="controls.loading.documents" @click="$router.push('/tenant/documents')">
                View All</a-button>
            </div>
          </template>
          <div v-if="controls.documents.length > 0" class="flex flex-col">
            <div v-for="(document, i) in controls.documents.slice(0, 3)" :key="`document-${i}`"
              class="border-b flex justify-between items-center py-4 px-4 cursor-pointer hover:bg-blue-50"
              @click="$router.push('/tenant/documents')">
              <span class="text-sm">{{ document.template.name }}</span>
              <a-tag v-if="document.status == 'incomplete'" color="gray" class="justify-center">
                {{ startCase(document.status) }}
              </a-tag>
              <a-tag v-if="document.status == 'signing'" color="orange" class="justify-center">
                {{ startCase(document.status) }}
              </a-tag>
              <a-tag v-if="document.status == 'signed'" color="cyan" class="justify-center">
                {{ startCase(document.status) }}
              </a-tag>
              <a-tag v-if="document.status == 'verifying'" color="orange" class="justify-center">
                {{ startCase(document.status) }}
              </a-tag>
              <a-tag v-if="document.status == 'requires_input'" color="pink" class="justify-center">
                {{ startCase(document.status) }}
              </a-tag>
              <a-tag v-if="document.status == 'complete'" color="green" class="justify-center">
                {{ startCase(document.status) }}
              </a-tag>
            </div>
          </div>
          <div v-else class="flex items-center justify-center px-6">
            <span class="py-3 text-base font-medium text-gray-400">No document yet</span>
          </div>
        </card>
      </div>
      <!-- right -->
      <div class="w-full lg:w-1/2 space-y-8 flex-col lg:h-screen py-4 lg:py-0">

        <card v-if="$isDesktopView()">
          <template #header>
            <div class="flex items-center justify-between w-full">
              <h3 class="font-medium text-base text-gray-900">
                Latest Reservation
              </h3>
              <a-button type="link" :loading="controls.loading.documents" @click="$router.push('/tenant/reservations')">
                View All</a-button>
            </div>
          </template>
          <div v-if="controls.reservations.length > 0" class="flex flex-col">
            <div class="border-b flex justify-between items-center px-5 py-4 cursor-pointer hover:bg-blue-50"
              @click="$router.push(`/tenant/reservations/${controls.reservations[0].id}`)">
              <span class="text-parqay-primary text-base font-medium">{{ controls.reservations[0].title }}</span>
              <a-button>
                <a-icon type="arrow-right" />
              </a-button>
            </div>
          </div>
          <div v-else class="flex items-center justify-center px-6">
            <span class="py-3 text-base font-medium text-gray-400">No reservation yet</span>
          </div>
        </card>

        <card>
          <template #header>
            <div class="flex items-center justify-between w-full">
              <h3 class="font-medium text-base text-gray-900">
                Services
              </h3>
              <a-button type="link" :loading="controls.loading.services"></a-button>
            </div>
          </template>
          <div class="px-4 py-6">
            <div
              class="flex flex-col lg:flex-row lg:flex-wrap items-center justify-center space-y-4 lg:space-y-0 lg:space-x-4">
              <button v-for="(service, i) in $deepGet(form, 'services', [])" :key="`service-${i}`" type="button"
                class="w-full lg:w-auto rounded bg-blue-600 hover:bg-blue-400 text-white shadow py-2 px-4"
                @click="$router.push(service.route)">
                {{ service.name }}
              </button>
            </div>
          </div>
        </card>

        <card>
          <template #header>
            <div class="flex items-center justify-between w-full">
              <h3 class="font-medium text-base text-gray-900">
                Maintenance Requests
              </h3>
              <a-button type="link" :loading="controls.loading.maintenace" @click="$router.push('/tenant/maintenance')">
                View All</a-button>
            </div>
          </template>
          <div class="pt-6">
            <div class="px-6 pb-6">
              <button type="button"
                class="w-full rounded bg-blue-600 hover:bg-blue-400 text-white shadow py-2 text-base"
                @click="$router.push('/tenant/maintenance?new=true')">
                + Request Maintenance
              </button>
            </div>
            <div v-if="controls.maintenances.length > 0" class="flex flex-col border-t">
              <div v-for="(maintenance_request, i) in controls.maintenances.slice(0, 3)"
                :key="`maintenance-request-${i}`"
                class="flex items-center justify-between rounded px-4 py-2 border-b cursor-pointer hover:bg-gray-50"
                @click="$router.push('/tenant/maintenance')">
                <span>{{ maintenance_request.title }}</span>
                <a-tag v-if="maintenance_request.status === 'working'" color="geekblue">{{ maintenance_request.status }}
                </a-tag>
                <a-tag v-if="maintenance_request.status === 'pending'" color="orange">{{ maintenance_request.status }}
                </a-tag>
                <a-tag v-if="maintenance_request.status === 'complete'" color="green">{{ maintenance_request.status }}
                </a-tag>
              </div>
            </div>
            <div v-else class="flex items-center justify-center px-6">
              <span class="py-3 text-base font-medium text-gray-400">No maintenance request yet</span>
            </div>
          </div>
        </card>

        <card>
          <template #header>
            <div class="flex items-center justify-between w-full">
              <h3 class="font-medium text-base text-gray-900">
                Past Payments
              </h3>
              <a-button type="link" :loading="controls.loading.past_payments" @click="$router.push('/tenant/payments')">
                View All</a-button>
            </div>
          </template>
          <div class="">
            <div v-if="controls.transactions.history.length > 0" class="flex flex-col">
              <div v-for="(past_payment, i) in controls.transactions.history.slice(0, 3) " :key="`past-payment-${i}`"
                class="flex justify-between border-b px-4 py-3 cursor-pointer hover:bg-blue-50"
                @click="$router.push('/tenant/payments')">
                <div class="flex items-center space-x-4">
                  <span class="rounded-full bg-blue-600 w-3 h-3" />
                  <div class="flex flex-col text-xs text-gray-900">
                    <span class="text-sm">{{ $deepGet(past_payment, "metadata.title") || $deepGet(past_payment,
      "description")
                      }}</span>
                    <span class="text-xs font-medium text-blue-500">{{ $deepGet(past_payment,
      "metadata.description") }}</span>
                  </div>
                </div>
                <template v-if="past_payment.type.includes('refunded')">
                  <span class="font-medium text-gray-500">
                    {{
      $formatCurrency(Math.abs(past_payment.final_amount))
    }}
                  </span>
                </template>
                <template v-else>
                  <span class="font-medium" :class="past_payment.final_amount > 0 ? 'text-green-400' : 'text-pink-500'">
                    {{ past_payment.final_amount > 0 ? "-" : "+" }}{{
      $formatCurrency(Math.abs(past_payment.final_amount))
                    }}
                  </span>
                </template>
              </div>
            </div>
            <div v-else class="flex items-center justify-center px-6">
              <span class="py-3 text-base font-medium text-gray-400">No past payment yet</span>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .ant-btn {
    @apply flex items-center;
  }
}
</style>