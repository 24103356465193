<script>
import { reactive, onMounted, onBeforeUnmount } from "@vue/composition-api";
import { API } from "src/views/documents/api";
import { Configs, Tokens } from "src/services/constants";

export default {
    setup(props, ctx) {
        const controls = reactive({
            loading: false,
            visible: false,
            isZohoTokensExist: false,
            consent_link: null
        })
        const getAdminZohoSignStatus = async () => {
            controls.loading = true;
            try {
                const { data } = await API.getAdminZohoSignStatus();
                controls.isZohoTokensExist = data.status === "active";
            } catch (error) {
                console.log("getAdminZohoSignStatus: ", error)
            } finally {
                controls.loading = false;
            }
        }
        const initAdminZohoSignTokens = async () => {
            controls.loading = true;
            controls.consent_link = null;
            try {
                const { data } = await API.initAdminZohoSignTokens();
                controls.consent_link = data.consent_link;
            } catch (error) {
                console.log("initAdminZohoSignTokens: ", error)
            } finally {
                controls.loading = false;
            }
        }
        const popupCenter = ({ url, title, w, h }) => {
            // Fixes dual-screen position                             Most browsers      Firefox
            const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
            const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

            const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
            const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

            const systemZoom = width / window.screen.availWidth;
            const left = (width - w) / 2 / systemZoom + dualScreenLeft
            const top = (height - h) / 2 / systemZoom + dualScreenTop
            const newWindow = window.open(url, title,
                `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
            )

            if (window.focus) newWindow.focus();
        }
        const toggleModal = (onOff) => {
            controls.visible = onOff;
            // Prevent loading preview signing URL in localhost
            if (onOff) {
                initAdminZohoSignTokens();
            }
        }
        const confirmAndProceed = async (directOpen = false) => {
            if (directOpen) {
                await initAdminZohoSignTokens();
            }
            popupCenter({ url: controls.consent_link, title: "Zoho | Parqay Dashboard", w: 800, h: 600 });
        }
        onMounted(() => {
            ctx.root.$bus.$on("toggle-zoho-sign-token-refresh-view", toggleModal);
            getAdminZohoSignStatus();
        })
        onBeforeUnmount(() => {
            ctx.root.$bus.$off("toggle-zoho-sign-token-refresh-view", toggleModal);
        })
        return {
            controls,
            initAdminZohoSignTokens,
            toggleModal,
            confirmAndProceed
        }
    }
}
</script>

<template>
    <div class="admin-credentials-view flex flex-col space-y-4">
        <div class="bg-white py-6 px-8">
            <div class="flex flex-col items-center justify-between space-y-4">
                <div class="flex space-x-2 rounded border w-full px-5 py-6">
                    <div class="flex w-full items-center justify-between px-4 py-2">
                        <div class="flex flex-col space-y-2 items-start justify-center">
                            <span class="text-base text-black font-semibold pb-1 border-b-2 border-black">Zoho Sign
                                Tokens Management</span>
                            <p class="text-sm">Generate/Refresh platform-wise Zoho Sign admin tokens.</p>
                        </div>
                        <div class="flex flex-col space-y-3" :style="{ minWidth: '260px' }">
                            <a-alert type="info">
                                <template #message>
                                    <div class="flex items-center justify-between">
                                        <span class="text-sm text-blue-600 font-medium">Tokens status: </span>
                                        <a-tag v-if="controls.isZohoTokensExist" color="green">Active</a-tag>
                                        <a-tag v-else color="pink">Inactive</a-tag>
                                    </div>
                                </template>
                            </a-alert>
                            <a-button v-if="controls.isZohoTokensExist" type="danger" icon="reload"
                                :loading="controls.loading" @click="toggleModal(true)">
                                Reset Zoho Sign OAuth Tokens
                            </a-button>
                            <a-button v-else type="primary" icon="key" :loading="controls.loading"
                                @click="confirmAndProceed(true)">
                                Generate Zoho Sign OAuth Tokens
                            </a-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a-modal :visible="controls.visible" width="50vw" :mask-closable="false" :footer="null"
            class="admin-credentials-view" @cancel="() => controls.visible = false">
            <template #title>
                <div class="flex items-center space-x-2">
                    <span class="text-base font-medium">Zoho Sign OAuth Refresh</span>
                    <a-icon type="reload" :spin="controls.loading" @click="initAdminZohoSignTokens()" />
                </div>
            </template>
            <div class="flex flex-col space-y-4 px-4 py-4">
                <a-alert message="Warning"
                    description="Heads Up! Refreshing OAuth tokens will render the currently in-use Zoho Sign tokens invalid! Use this feature ONLY when Zoho Sign refresh token is expired or invalid."
                    type="warning" show-icon />
                <div class="flex items-center justify-center space-x-4">
                    <a-button type="danger" block size="large" :loading="controls.loading"
                        @click="() => { toggleModal(false); confirmAndProceed(); }">Confirm</a-button>
                    <a-button type="primary" block size="large" @click="toggleModal(false)">Cancel</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<style lang="scss">
.admin-credentials-view {
    .ant-modal-body {
        @apply px-4 py-2;
    }

    .ant-btn {
        @apply flex items-center;
    }

    .ant-modal-close-x {
        .anticon {
            font-size: 25px;
        }
    }
}
</style>