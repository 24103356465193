var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hidden md:flex md:flex-shrink-0 overflow-hidden",attrs:{"id":"tenant-menu"}},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-col h-0 flex-1 border-r border-gray-200 bg-white",staticStyle:{"height":"100vh"}},[_c('smooth-scroll',[_c('div',{staticClass:"flex flex-col overflow-y-auto"},[_c('div',{staticClass:"flex flex-col items-center justify-center px-4 pt-6 pb-5 space-y-2"},[_c('router-link',{staticClass:"flex justify-center items-center",attrs:{"tag":"a","to":"/"}},[(_vm.$getWhiteLabelImages().logoURL)?_c('img',{staticClass:"h-16 object-contain",style:({ width: '220px' }),attrs:{"src":_vm.$getWhiteLabelImages().logoURL}}):_c('svg-icon',{staticClass:"h-16",style:({ width: '220px' }),attrs:{"icon-class":"PARQAY-logo"}})],1),_c('a-tag',{attrs:{"color":"orange"}},[_vm._v("Tenant Access")])],1),_c('nav',{staticClass:"flex-1 px-1 bg-white space-y-2"},_vm._l((_vm.menus),function(menu,index){return _c(menu.isNativeLink ? 'a' : 'router-link',{key:menu.link,tag:"component",staticClass:"\n                group\n                h-12\n                flex\n                items-center\n                relative\n                px-4\n                py-1\n                text-sm\n                leading-5\n                font-light\n                rounded\n                hover:font-medium\n                focus:outline-none\n                focus:text-white\n                focus:bg-blue-600\n                transition\n                ease-in-out\n                duration-150\n              ",class:{
                'bg-white text-gray-600 hover:text-blue-600': !_vm.isActive(menu.link),
                'bg-blue-600 text-white font-medium hover:font-semibold hover:bg-blue-500 hover:text-white': _vm.isActive(
                  menu.link
                )
              },attrs:{"href":menu.link,"to":menu.link}},[_c('svg-icon',{staticClass:"h-5 w-5 group-focus:text-white",class:{
                'text-white': _vm.isActive(menu.link),
                'mr-3': _vm.notificationReady,
                'mr-6': !_vm.notificationReady
              },attrs:{"icon-class":menu.iconName}}),_vm._v("\n              "+_vm._s(menu.label)+"\n              "),(index == 1 && _vm.$isLoggedIn())?_c('div',{staticClass:"absolute right-2 my-auto"},[_c('a-badge',{attrs:{"count":_vm.$store.getters.getUnreadMessagesCount()}})],1):_vm._e()],1)}),1)])]),(_vm.$isLoggedIn())?_c('div',{staticClass:"flex items-center justify-center border-t pt-2 pb-3 px-6"},[_c('a',{staticClass:"text-base text-gray-500 flex w-full items-center justify-center space-x-3",on:{"click":function($event){return _vm.$router.push('/dashboard')}}},[_c('svg-icon',{staticClass:"h-5 w-5 text-gray-500",attrs:{"icon-class":"switch"}}),_c('span',[_vm._v("Switch to Host")])],1)]):_c('div',{staticClass:"flex items-center justify-center border-t pt-2 pb-3 px-6"},[_c('a',{staticClass:"text-base text-gray-500 flex w-full items-center justify-center space-x-3",on:{"click":function($event){return _vm.$router.push(("/login/tenant?next=" + (_vm.$route.fullPath)))}}},[_c('span',[_vm._v("Login to View More")])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }