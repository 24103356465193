<script>
import { onMounted } from "@vue/composition-api";
export default {
  setup(props, ctx) {
    localStorage.setItem("reservation-invite", ctx.root.$route.params.invitation_code);
    onMounted(() => {
      console.log("invitation_code -> ", localStorage.getItem("reservation-invite"));
      setTimeout(() => {
        ctx.root.$router.push({
          name: "tenant-home",
        });
      }, 2000);
    });
    return {};
  },
};
</script>
<template>
  <div class="w-screen h-screen flex bg-gray-50">
    <div class="absolute transform left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 flex flex-col space-y-4 justify-center items-center">
      <svg-icon
        icon-class="PARQAY-logo"
        class="h-20 w-auto"
      />
      <svg-icon
        icon-class="circular-loader"
        class="w-8 h-8 animate-spin text-blue-500"
      />
    </div>
  </div>
</template>