<script>
import { reactive, onMounted, nextTick } from '@vue/composition-api';

import DownloadAppBadge from "src/views/components/DownloadAppBadge";

export default {
  components: {
    DownloadAppBadge,
  },
  setup(props, ctx) {
    const controls = reactive({
      loading: false,
      disabled: false,
      visible: false,
      config: {
        link: "https://dashboard.parqay.com/dashboard"
      }
    })
    nextTick(() => {
      controls.loading = true;
      setTimeout(() => {
        controls.loading = false;
      }, 1500);
    })
    return {
      controls
    };
  },
};
</script>
<template>
  <div class="w-screen h-screen absolute inset-0 overflow-y-auto">
    <div class="flex flex-col space-y-2 justify-center items-center py-16">
      <div class="cursor-pointer" @click="$router.push('/')">
        <svg-icon icon-class="PARQAY-logo" class="h-24 w-72" />
      </div>
      <p class="text-sm text-center leading-8 py-2 px-12">Access to <a-tag color="geekblue"
          class="ml-1 mr-1">Dashboard</a-tag> from mobile
        browser is temporarily not supported.</p>
      <a-divider>You may</a-divider>
      <div class="flex flex-col items-center justify-center space-y-2 w-full px-10">
        <img src="/static/img/desktop-icon.png" class="w-16 h-auto object-contain">
        <span class="text-sm text-center text-black font-medium">Open the same page with a desktop
          browser</span>
        <a-tag color="gold">Full Features</a-tag>
        <span class="text-sm text-center text-gray-600">Screen width should be greater than 1280
          pixels</span>
        <a-button :loading="controls.loading" :disabled="!controls.config.link" @click="() => {
          $copyToClipboard(controls.config.link);
          $openNotification('Copied to clipboard', 'Desktop browser link has copied to your clipboard');
        }">{{ controls.loading ? "Loading..." : "Copy Link" }}</a-button>
      </div>
      <a-divider>OR</a-divider>
      <div class="flex flex-col items-center justify-center space-y-2 w-full px-10">
        <img src="/static/img/smartphone-icon.png" class="w-12 h-auto object-contain">
        <span class="text-sm text-center text-black font-medium pt-1">Download Parqay app in App Store /
          Play
          Store</span>
        <a-tag color="geekblue">Tenant Features Only</a-tag>
        <span class="text-sm text-center text-gray-600">Access search, booking, reservations, documents,
          vehicles, payment methods, etc.</span>
        <download-app-badge />
      </div>
      <div style="height:80px;" />
    </div>
  </div>
</template>