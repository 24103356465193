<script>
import { onMounted, reactive } from "@vue/composition-api";
import { API } from "src/views/vehicles/api";
export default {
  name: "VehiclesTable",
  props: {
    role: String,
  },
  setup(props, ctx) {
    const dynamicHeaders = (r) => {
      switch (r) {
        case "host":
          return [
            {
              title: "Owner",
              align: "center",
              dataIndex: "tenant_name",
              scopedSlots: { customRender: "tenant_name" },
              width: 150,
            },
          ];
        default:
          return [];
      }
    };
    const table = reactive({
      headers: [
        {
          title: "Image",
          align: "center",
          dataIndex: "image",
          scopedSlots: { customRender: "image" },
          width: 200,
        },
        {
          title: "Smartcar",
          align: "center",
          dataIndex: "smartcar",
          scopedSlots: { customRender: "smartcar" },
          width: 120,
        },
        ...dynamicHeaders(props.role),
        {
          title: "Make",
          align: "center",
          dataIndex: "make",
          scopedSlots: { customRender: "make" },
        },
        {
          title: "Model",
          align: "center",
          dataIndex: "model",
          scopedSlots: { customRender: "model" },
        },
        {
          title: "Color",
          align: "center",
          dataIndex: "color",
          scopedSlots: { customRender: "color" },
        },
        {
          title: "Plate #",
          align: "center",
          dataIndex: "plate_number",
          scopedSlots: { customRender: "plate_number" },
        },
        {
          title: "Year",
          align: "center",
          dataIndex: "year",
          scopedSlots: { customRender: "year" },
        },
        {
          title: "Created At",
          align: "center",
          dataIndex: "created_at",
          scopedSlots: { customRender: "created_at" },
        },
        {
          title: "Actions",
          align: "center",
          scopedSlots: { customRender: "actions" },
          fixed: "right",
          width: 80,
        },
      ],
      data: [],
    });

    const controls = reactive({
      visible: false,
      form: null,
      loading: false,
    });

    const fetchData = async () => {
      controls.loading = true;
      let resp;
      try {
        resp = await API.getVehicles(props.role);
        console.log(`getVehicles[${props.role}] ==> resp`, resp.data);
        table.data = resp.data;
      } catch (error) {
        console.log("getVehicles -> error", error);
      } finally {
        controls.loading = false;
      }
    };
    onMounted(() => {
      fetchData();
    });
    return {
      table,
      controls,
      fetchData,
      console
    };
  },
};
</script>
<template>
  <div>
    <a-table :loading="controls.loading" :columns="table.headers" :data-source="table.data"
      :scroll="{ x: true, y: false }" row-key="id" :custom-row="(record, index) => {
          return {
            on: {
              click: (event) => {
                console.log(
                  'row click currentTarget',
                  event.currentTarget.tagName.toLowerCase()
                );
                console.log('row click target', event.target.tagName.toLowerCase());
                if (
                  event.target.tagName.toLowerCase() !== 'svg' &&
                  event.target.tagName.toLowerCase() !== 'use' &&
                  event.target.tagName.toLowerCase() !== 'span' &&
                  event.target.tagName.toLowerCase() !== 'a' &&
                  event.target.tagName.toLowerCase() !== 'button'
                ) {
                }
              },
            },
          };
        }
        " :pagination="false">
      <template slot="image" slot-scope="image, record">
        <div class="flex flex-col items-center justify-center py-2 space-y-2">
          <viewer v-if="image" :images="[image]">
            <lazy-img :src="image" src-placeholder="/static/img/dashboard/vehicle-placeholder.png"
              class="h-20 w-auto object-cover hover:opacity-80 cursor-pointer" />
          </viewer>
          <img v-else src="/static/img/dashboard/vehicle-placeholder.png"
            class="h-20 w-auto object-cover hover:opacity-80 cursor-pointer" />
        </div>
      </template>

      <template slot="smartcar" slot-scope="smartcar, record">
        <a-tag :color="smartcar ? 'green' : 'pink'">{{
          smartcar ? "Connected" : "Not Connected"
        }}</a-tag>
      </template>

      <template slot="tenant_name" slot-scope="tenant_name, record">
        <a-button @click="$router.push(`/dashboard/tenants?filter=${record.tenant}`)">
          {{ tenant_name }}
        </a-button>
      </template>

      <template slot="make" slot-scope="make">
        <span v-if="make" class="font-bold">
          {{ make }}
        </span>
        <a-tag v-else color="gray">N/A</a-tag>
      </template>

      <template slot="model" slot-scope="model">
        <span v-if="model" class="font-semibold text-gray-500">
          {{ model }}
        </span>
        <a-tag v-else color="gray">N/A</a-tag>
      </template>

      <template slot="plate_number" slot-scope="plate_number">
        <span v-if="plate_number" class="font-semibold text-parqay-primary">
          {{ plate_number }}
        </span>
        <a-tag v-else color="gray">N/A</a-tag>
      </template>

      <template slot="color" slot-scope="color">
        <div v-if="color" class="flex justify-center items-center border rounded w-8 h-6"
          :style="{ background: color }" />
        <a-tag v-else color="gray">N/A</a-tag>
      </template>

      <template slot="year" slot-scope="year">
        <a-tag v-if="year" color="geekblue">{{ year }}</a-tag>
        <a-tag v-else color="gray">N/A</a-tag>
      </template>

      <template slot="created_at" slot-scope="created_at">
        <a-tag v-if="created_at">{{
          $formatDate(created_at, "MM/DD, h:mm a")
        }}</a-tag>
        <a-tag v-else color="gray">N/A</a-tag>
      </template>

      <template slot="actions" slot-scope="_, record">
        <div class="space-y-2">
          <a-button type="primary" size="small" :disabled="!record.smartcar" @click="() => {
              controls.form = record;
              controls.visible = true;
            }
            ">
            Virtual Key
          </a-button>
          <a-button v-if="role === 'host'" size="small" @click.stop="$bus.$emit('open-reservation-drawer', record)">Reservation</a-button>
          <a-button type="danger" size="small">Dissociate</a-button>
        </div>
      </template>
    </a-table>
    <vehicle-modal :visible="controls.visible" :form="controls.form" @close="() => (controls.visible = false)" />
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .ant-btn {
    @apply text-xs;
  }

  .ant-table-row {
    td {
      @apply py-1;
    }
  }
}
</style>
