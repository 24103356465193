<script>
import { reactive, computed } from "@vue/composition-api";
import { getTime } from "./util";
import HolidayModal from "./HolidayModal.vue";
import startCase from 'lodash/startCase';

export default {
  name: "AvailabilitySimple",
  components: {
    HolidayModal,
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return null;
      },
    }
  },
  setup(props, ctx) {
    const options = reactive({
      time: getTime(),
      day: ["Weekdays", "Weekends", "Holidays"],
    });

    const holidayCount = computed(() => {
      return props.model.holidays.include.length;
    });

    return {
      options,
      holidayCount,
      startCase,
    };
  },
};
</script>
<template>
  <div class="flex flex-col rounded bg-white shadow-sm border">
    <div class="flex items-center px-4 py-2">
      <slot />
    </div>
    <div>
      <div class="border-t border-b bg-gray-50 mb-4">
        <div class="flex text-center text-base font-medium py-3 mx-4">
          <span class="flex-1">Day</span>
          <span class="flex-1">Open</span>
          <span class="flex-1">Close</span>
        </div>
      </div>
      <div>
        <div v-for="(val, key) in model" :key="key" class="
            flex flex-wrap
            text-center
            border
            rounded-lg
            mx-4
            mt-2
            py-3
            border-gray-100
            items-center
            bg-white
            hover:bg-blue-100
          ">
          <div class="flex-1">
            <span class="
                cursor-default
                border border-gray-300
                inline-block
                w-28
                rounded-md
                font-medium
                text-md
                py-1
                bg-white
                hover:border-parqay-primary
              ">{{ startCase(key) }}</span>
          </div>
          <div class="flex-1">
            <a-select v-model="val.from" class="w-28">
              <a-select-option v-for="t in options.time" :key="t" :value="t">
                {{ t }}
              </a-select-option>
            </a-select>
          </div>
          <div class="flex-1">
            <a-select v-model="val.to" class="w-28">
              <a-select-option v-for="t in options.time" :key="t" :value="t">
                {{ t }}
              </a-select-option>
            </a-select>
          </div>

          <div v-if="'Holidays' === startCase(key)" class="w-full text-center pt-3">
            <span class="text-blue-600 font-bold cursor-pointer underline" @click="$refs['holiday-modal'].show()">Edit
              Holidays</span>
            <div class="inline-block ml-6 rounded-full bg-gray-200 px-1 py-0.5">
              <b class="rounded-full bg-gray-500 text-white px-2">{{
                holidayCount
              }}</b>
              <span class="text-dark-purple ml-2 font-bold">Selected</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot name="footer" />
    <holiday-modal ref="holiday-modal" :model="model.holidays" />
  </div>
</template>