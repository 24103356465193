<script>
export default {
  name: "card-effect"
};
</script>
<template>
  <div class="border shadow-sm overflow-hidden rounded-lg bg-white">
    <div v-if="$slots.header" class="flex items-center px-4 py-2 border-b">
      <slot name="header" />
    </div>
    <slot />
  </div>
</template>
