<script>
import { ref, onMounted } from "@vue/composition-api";
import TabHeader from "src/views/components/TabHeader.vue";
import { API } from "src/views/payments/api";

import startCase from "lodash/startCase";
import cloneDeep from "lodash/cloneDeep";

export default {
    components: {
        TabHeader
    },
    setup(props, ctx) {
        const route = ctx.root.$route;
        const router = ctx.root.$router;
        const loading = ref(false);
        const payouts = ref([]);
        const nextAutoPayoutDate = ref(null);
        const activeTab = ref("upcoming");
        const focusPayout = ref({});
        const focusPayoutType = ref(null);

        const setupHashRoute = hashRoute => {
            router.push({ name: route.name, hash: `#${hashRoute}` });
        };
        const changeTab = async name => {
            activeTab.value = name;
            setupHashRoute(name);
            getPendingPayouts();
        };

        const getPendingPayouts = async () => {
            try {
                loading.value = true;
                const { data: { transfers: _payouts, next_auto_transfer_dt } } = await API.getPendingPayouts({
                    filter: {
                        status: activeTab.value
                    }
                })
                payouts.value = _payouts.map(t => ({
                    ...t,
                    $loading: false,
                    $expand: false
                }));
                nextAutoPayoutDate.value = next_auto_transfer_dt
                loading.value = false;
            } catch (error) {
                loading.value = false;
                console.log(error);
            }
        }

        // Method to initiate a payout
        const initPayout = async (payout, paidType = null) => {
            payout.$loading = true;
            if (paidType) {
                payout.status = `complete.${paidType}`
                if (!payout.notes) {
                    ctx.root.$openNotification(
                        "Payout Notes",
                        `Notes is required for ${paidType} payout.`
                    )
                    payout.$loading = false;
                    return
                }
            }
            try {
                const { message } = await API.initPayout(payout, paidType);
                ctx.root.$openNotification(
                    "Payout Successful",
                    message
                )
                payout.$loading = false;
                getPendingPayouts();
                focusPayout.value = {};
                focusPayoutType.value = null;
            } catch (error) {
                payout.$loading = false;
                payout.$expand = true;
                ctx.root.$openNotification(
                    "Payout Error",
                    error.message
                )
            }
        };

        const getInstallmentDescription = data => {
            const { installment_index, installments_total } = data;

            if (installments_total === 1) {
                return "Full deposit paid by tenant.";
            }

            const ordinalSuffix = (i) => {
                const j = i % 10,
                    k = i % 100;
                if (j == 1 && k != 11) {
                    return i + "st";
                }
                if (j == 2 && k != 12) {
                    return i + "nd";
                }
                if (j == 3 && k != 13) {
                    return i + "rd";
                }
                return i + "th";
            }

            const installmentOrder = ordinalSuffix(installment_index + 1);

            return `${installmentOrder} payment of ${installments_total} total installments`;
        };

        onMounted(() => {
            getPendingPayouts();
        })

        return {
            loading,
            payouts,
            nextAutoPayoutDate,
            activeTab,
            focusPayout,
            focusPayoutType,
            changeTab,
            getPendingPayouts,
            initPayout,
            getInstallmentDescription,
            startCase,
            cloneDeep
        };
    }
};
</script>

<template>
    <div class="absolute inset-0">
        <div class="pt-10 h-full flex flex-col px-10">
            <div class="mb-6">
                <tab-header v-model="activeTab" :tabs="[
                    { name: 'pending', label: 'Pending', action: getPendingPayouts },
                    { name: 'automatic', label: 'Automatic Payout', action: getPendingPayouts },
                    { name: 'complete', label: 'Completed', action: getPendingPayouts },
                    { name: 'complete.stripe', label: 'Via Stripe', action: getPendingPayouts },
                    { name: 'complete.venmo', label: 'Via Venmo', action: getPendingPayouts }
                ]" @change="changeTab">
                    <template #right-actions>
                    </template>
                </tab-header>
            </div>
            <div class="container mx-auto p-4 space-y-4">
                <a-alert v-if="activeTab === 'automatic' && nextAutoPayoutDate" type="warning" show-icon>

                    <template #message>
                        <div class="flex items-center justify-between">
                            <div class="flex items-center space-x-2">
                                <span class="font-semibold">Next Automatic Transfer is scheduled to proceed on</span>
                                <span>{{ nextAutoPayoutDate }}</span>
                                <a-badge :count="`${payouts.length} pending`"
                                    :number-style="{ backgroundColor: '#52c41a' }" />
                            </div>
                            <a-button>
                                Initiate All Payouts Now
                            </a-button>
                        </div>
                    </template>
                </a-alert>
                <div v-if="loading" class="flex items-center justify-center h-64">
                    <a-icon type="loading" style="font-size: 35px;" />
                </div>

                <template v-else>
                    <div v-for="(payout, i) in payouts" :key="`payout-${i}`"
                        class="mb-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden">
                        <div class="p-6">
                            <h2 class="text-xl font-semibold text-gray-900">{{ payout.title }}</h2>
                            <div class="flex items-start justify-between space-x-4">
                                <div class="flex flex-col space-y-3">
                                    <span class="text-base italic pb-1">{{ payout.description }}</span>
                                    <p>
                                        <strong class="mr-2">Status:</strong>
                                        <a-tag v-if="payout.status === 'pending'" color="orange">Payout Pending</a-tag>
                                        <a-tag v-if="payout.status === 'automatic'" color="orange">Automatic Payout
                                            Pending </a-tag>
                                        <a-tag
                                            v-if="['complete', 'complete.stripe', 'complete.venmo'].includes(payout.status)"
                                            color="green">Payout Complete</a-tag>
                                    </p>
                                    <p>
                                        <strong class="mr-2">Type:</strong>
                                        <a-tag v-if="payout.status === 'complete.stripe'" custom-paid>Paid via
                                            Stripe</a-tag>
                                        <a-tag v-if="payout.status === 'complete.venmo'" custom-paid>Paid via
                                            Venmo</a-tag>
                                        <a-tag color="orange">{{
                    startCase(payout.type)
                }}</a-tag>
                                        <a-tag color="cyan">
                                            <span class="font-semibold">{{ getInstallmentDescription(payout) }}</span>
                                        </a-tag>
                                        <a-tag color="geekblue">{{ startCase(payout.interval_mode) }} x {{
                    payout.interval_count }}</a-tag>
                                    </p>
                                    <div v-if="['complete', 'complete.stripe', 'complete.venmo'].includes(payout.status)"
                                        class="flex items-center space-x-2">
                                        <strong>Captured Amount</strong>
                                        <b class="text-parqay-primary">
                                            {{ $formatCurrency(payout.collected_amount) }}
                                        </b>
                                        <a-tag color="green">Stripe Fee Excluded</a-tag>
                                    </div>
                                    <p v-if="$deepGet(payout, 'created_at.$date')">
                                        <strong class="mr-2">Created
                                            At:</strong>
                                        {{ $formatDate(payout.created_at.$date) }}
                                    </p>
                                    <p v-if="$deepGet(payout, 'completed_at.$date')">
                                        <strong class="mr-2">Transferred
                                            At:</strong>
                                        {{ $formatDate(payout.completed_at.$date) }}
                                    </p>
                                    <p v-if="$deepGet(payout, 'notes')" class="">
                                        <strong class="mr-2">Payout Notes:</strong>
                                        {{ payout.notes }}
                                    </p>
                                </div>
                                <div v-if="payout.status === 'pending'" class="flex items-center space-x-2">
                                    <span class="text-black font-bold">Captured Amount</span>
                                    <b class="text-parqay-primary text-xl">
                                        {{ $formatCurrency(payout.collected_amount) }}
                                    </b>
                                    <a-tag color="green">Stripe Fee Excluded</a-tag>
                                </div>
                                <div v-if="['complete', 'complete.stripe', 'complete.venmo'].includes(payout.status)"
                                    class="flex items-center space-x-2">
                                    <div class="w-2 h-2 rounded-full bg-green-400" />
                                    <span class="text-black font-bold">Profit</span>
                                    <b class="text-green-400 text-xl">
                                        +{{ $formatCurrency(payout.collected_amount - payout.amount) }}
                                    </b>
                                </div>
                            </div>
                            <div v-if="payout.$expand" class="space-y-2">
                                <a-divider>Payment Details</a-divider>
                                <p><strong class="mr-2">Payment ID:</strong><span
                                        class="hover:underline hover:opacity-75 cursor-pointer">{{ payout.payment_id
                                        }}</span></p>
                                <p v-if="payout.status === 'complete'"><strong class="mr-2">Payout ID:</strong><span
                                        class="hover:underline hover:opacity-75 cursor-pointer">{{ payout.payout_id
                                        }}</span></p>
                                <p><strong class="mr-2">Reservation ID:</strong><span
                                        class="hover:underline hover:opacity-75 cursor-pointer">{{ payout.reservation_id
                                        }}</span></p>
                                <p><strong class="mr-2">Host Stripe Connect ID:</strong>
                                    <template v-if="payout.host_connect_id">
                                        <a-tag color="green">Connected</a-tag>
                                        <span class="hover:underline hover:opacity-75 cursor-pointer">
                                            {{ payout.host_connect_id }}
                                        </span>
                                    </template>
                                    <a-tag v-else color="pink">Not Connected</a-tag>
                                </p>
                                <p><strong class="mr-2">Host UID:</strong><span
                                        class="hover:underline hover:opacity-75 cursor-pointer">{{
                    payout.host_uid
                }}</span></p>
                                <p><strong class="mr-2">Tenant Customer ID:</strong><span
                                        class="hover:underline hover:opacity-75 cursor-pointer">{{
                        payout.tenant_customer_id }}</span></p>
                                <p><strong class="mr-2">Tenant UID:</strong><span
                                        class="hover:underline hover:opacity-75 cursor-pointer">{{
                    payout.tenant_uid
                }}</span></p>
                            </div>
                            <div v-if="payout.status === 'pending'" class="flex items-center justify-end space-x-4">
                                <a-tag color="geekblue">Host Fee: {{ Math.round(payout.fee_perc * 100) }}%</a-tag>
                                <div class="flex items-center space-x-2">
                                    <span class="text-black font-bold">Amount</span>
                                    <a-input-number v-model="payout.amount"
                                        @change="() => payout.$amountUpdate = payout.amount" />
                                </div>
                                <a-button :loading="payout.$loading" @click="initPayout(payout)">
                                    Manual Payout
                                </a-button>
                                <a-button type="primary" :loading="payout.$loading"
                                    style="display: flex; align-items: center;" @click="() => {
                    payout.status = 'automatic';
                    initPayout(payout);
                }">
                                    <span>Auto-Payout</span>
                                    <a-icon type="double-right" />
                                </a-button>
                                <a-dropdown placement="topRight" :trigger="['click']">
                                    <a-button icon="menu"
                                        style="display: flex; align-items: center; justify-content: center;" />
                                    <a-menu slot="overlay">
                                        <a-menu-item>
                                            <a target="_blank" rel="noopener noreferrer" class="px-2 py-4" @click="() => {
                    focusPayout = cloneDeep(payout);
                    focusPayoutType = 'stripe';
                    $set(focusPayout, 'notes', null);
                }">Paid Via
                                                <b>Stripe</b></a>
                                        </a-menu-item>
                                        <a-menu-item>
                                            <a target="_blank" rel="noopener noreferrer" class="px-2 py-4" @click="() => {
                    focusPayout = cloneDeep(payout);
                    focusPayoutType = 'venmo';
                    $set(focusPayout, 'notes', null);
                }">Paid Via
                                                <b>Venmo</b></a>
                                        </a-menu-item>
                                    </a-menu>
                                </a-dropdown>
                            </div>
                            <div v-if="['complete', 'complete.stripe', 'complete.venmo'].includes(payout.status)"
                                class="flex items-center justify-end space-x-4">
                                <a-tag color="geekblue">Host Fee: {{ Math.round(payout.fee_perc * 100) }}%</a-tag>
                                <div class="flex items-center space-x-2">
                                    <span class="text-black font-bold">Transferred to Host</span>
                                    <b class="text-parqay-primary text-xl">{{ $formatCurrency(payout.amount) }}</b>
                                </div>
                            </div>
                        </div>
                        <div v-if="!payout.$expand"
                            class="cursor-pointer flex items-center justify-center py-3 border-t hover:bg-gray-50"
                            @click="() => payout.$expand = true">
                            <span class="text-parqay-primary hover:opacity-75 text-sm font-medium">View All
                                Details</span>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <a-modal :visible="!!focusPayoutType" :title="`Paid via ${startCase(focusPayoutType)}`" @cancel="() => {
                    focusPayout = {};
                    focusPayoutType = null;
                }">
            <div class="flex flex-col space-y-4">
                <span>If you have paid <b class="underline"> via {{ startCase(focusPayoutType) }} Dashboard </b>, please
                    enter
                    corresponding
                    information
                    for better reference:</span>
                <div class="grid grid-cols-2 gap-y-4">
                    <div class="flex items-start space-x-4">
                        <span class="text-black font-bold">Payout Amount</span>
                        <a-input-number v-model="focusPayout.amount"
                            @change="() => focusPayout.$amountUpdate = focusPayout.amount" />
                    </div>
                    <div class="col-span-2 flex items-start space-x-4">
                        <span class="flex-shrink-0 text-black font-bold">Reference ID</span>
                        <a-input v-model="focusPayout.payout_id"
                            placeholder="Enter the Stripe / Venmo Payout ID for reference" />
                    </div>
                    <div class="col-span-2 flex items-start space-x-2">
                        <span class="text-black font-bold">Notes</span>
                        <a-textarea v-if="!!focusPayout" v-model="focusPayout.notes"
                            placeholder="Enter any extra notes to keep track of this payout" />
                    </div>
                </div>
                <span class="text-gray-500 text-sm"><b>Note</b>: by clicking "Submit" button, no further charge /
                    transfer /
                    payout action will occur. This record of payout will move to "via {{ startCase(focusPayoutType) }}"
                    tab and
                    will be marked as
                    "Completed".</span>
            </div>

            <template #footer>
                <div class="w-full px-2 py-2">
                    <a-button type="primary" :loading="focusPayout.$loading" block size="large"
                        @click="initPayout(focusPayout, focusPayoutType)">Submit</a-button>
                </div>
            </template>
        </a-modal>
    </div>
</template>

<style lang="scss" scoped>
.ant-tag[custom-paid] {
    @apply text-black font-semibold bg-white border border-black;
}
</style>