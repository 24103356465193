<script>
import { reactive, onMounted } from "@vue/composition-api";
import LeadOutreach from "src/views/messaging/LeadOutreach.vue";
import { API } from "src/views/tenants/api";

export default {
  name: "tenant-invite",
  components: {
    LeadOutreach
  },
  setup(props, ctx) {
    const form = reactive({
      first_name: null,
      last_name: null,
      address: null,
      email: null,
      invitation_message: null,
      send_invitation: true,
      send_welcome_letter: true,
      listing: null
    });

    const controls = reactive({
      loading: false,
      visible: {
        copy: false
      },
      step_index: 0,
      invite_mode_index: 1,
      invite_by: "email",
      result: null,
      invite_link: null,
      assign_listing: false
    });

    const searchInviteTenant = async () => {
      controls.loading = true;
      let resp;
      try {
        resp = await API.inviteSearch({
          ...form,
        });
        console.log("searchInviteTenant, resp: ", resp);
        controls.result = resp.data.is_existed ? "existing-info" : "inviting-info";
        form.email = resp.data.email;
        form.first_name = resp.data.first_name;
        form.last_name = resp.data.last_name;
        controls.step_index += 1;
      } catch (error) {
        console.log("searchInviteTenant -> error: ", error);
      } finally {
        controls.loading = false;
      }
    };

    const confirmInviteTenant = async () => {
      controls.loading = true;
      try {
        const { data } = await API.inviteConfirm({
          ...form,
        });
        controls.result = "success";
        controls.invite_link = data.login_url;
      } catch (error) {
        console.log("confirmInviteTenant -> error: ", error);
      } finally {
        controls.loading = false;
      }
    };

    const validateFields = () => {
      return (
        form.first_name && form.last_name && form.email && form.invitation_message
      );
    };

    const copyInviteLink = (link) => {
      controls.visible.copy = true;
      ctx.root.$copyToClipboard(link);
      setTimeout(() => {
        controls.visible.copy = false;
      }, 800);
    }

    onMounted(() => { })

    return {
      form,
      controls,
      searchInviteTenant,
      confirmInviteTenant,
      validateFields,
      copyInviteLink
    };
  },
};
</script>
<template>
  <div class="py-10 h-full flex flex-col px-10">
    <div class="flex items-center space-x-1 text-black font-semibold text-lg">
      <router-link tag="a" to="/dashboard/tenants" class="text-parqay-primary">
        Tenants & Leads
      </router-link>
      <span>
        / Invite
      </span>
    </div>
    <div class="flex flex-col py-10 px-10 space-y-6">
      <div v-if="controls.step_index === 0"
        class="grid grid-cols-3 gap-4 bg-white shadow-sm border rounded-lg py-4 mb-1 px-6">
        <div class="border rounded-xl py-16 px-6 flex flex-col space-y-4 items-center justify-center cursor-pointer"
          :class="controls.invite_mode_index === 0
            ? 'border-blue-400 bg-blue-50 hover:bg-blue-100 text-blue-500'
            : 'bg-gray-50 hover:bg-gray-100 text-gray-500'
            " @click="() => (controls.invite_mode_index = 0)">
          <img :src="`/static/img/lead-magnet.png`" class="rounded-full ring-4 w-16 h-16 object-cover"
            :class="controls.invite_mode_index === 0 ? 'ring-parqay-primary' : 'ring-gray-600'">
          <span class="text-lg text-center font-semibold">Lead Outreach</span>
          <span class="text-sm text-center">
            Start lead outreach by SMS / Email. The messages will auto-save into a conversation.</span>
        </div>
        <div class="border rounded-xl py-16 px-6 flex flex-col space-y-4 items-center justify-center cursor-pointer"
          :class="controls.invite_mode_index === 1
            ? 'border-blue-400 bg-blue-50 hover:bg-blue-100 text-blue-500'
            : 'bg-gray-50 hover:bg-gray-100 text-gray-500'
            " @click="() => (controls.invite_mode_index = 1)">
          <img :src="`/static/img/tenant-placement.png`" class="rounded-full ring-4 w-16 h-16 object-cover"
            :class="controls.invite_mode_index === 1 ? 'ring-parqay-primary' : 'ring-gray-600'">
          <span class="text-lg text-center font-semibold">Invite Tenant to Listing</span>
          <span class="text-sm text-center">
            You will invite tenant with a link to your active listing in the marketplace.
          </span>
        </div>
        <div class="border rounded-xl py-16 px-6 flex flex-col space-y-4 items-center justify-center cursor-pointer"
          :class="controls.invite_mode_index === 2
            ? 'border-blue-400 bg-blue-50 hover:bg-blue-100 text-blue-500'
            : 'bg-gray-50 hover:bg-gray-100 text-gray-500'
            " @click="() => (controls.invite_mode_index = 2)">
          <svg-icon icon-class="calendar-circle" class="h-16 w-16" />
          <span class="text-lg text-center font-semibold">Invite Tenant to Reservation</span>
          <span class="text-sm text-center">
            Create a new reservation manually and invite new tenant to finalize payment.
          </span>
        </div>
      </div>

      <div v-if="controls.step_index === 1" class="flex flex-col bg-white shadow-sm border rounded-lg">
        <div class="flex items-center justify-between border-b px-4 py-4">
          <span class="font-medium text-base">
            Tenant / Lead
          </span>
        </div>
        <div class="flex flex-col px-8 pb-6 pt-4 space-y-4">
          <div class="flex items-center justify-between">
            <span v-if="controls.invite_by === 'email'" class="text-lg font-semibold">
              New Tenant
            </span>
            <span v-if="controls.invite_by === 'sms'" class="text-lg font-semibold">
              New Lead
            </span>
            <div class="flex items-center justify-center px-4 pt-2 space-x-4">
              <span class="text-sm font-medium">Invited By</span>
              <a-radio-group v-model="controls.invite_by">
                <a-radio-button value="email">
                  Email Tenant
                </a-radio-button>
                <a-radio-button value="sms">
                  SMS Lead
                </a-radio-button>
              </a-radio-group>
            </div>
          </div>
          <div v-if="controls.invite_by === 'email'" class="grid grid-cols-4 gap-x-4 gap-y-6">
            <label-item label="First Name">
              <a-input v-model="form.first_name" placeholder="First name to display" size="large" />
            </label-item>

            <label-item label="Last Name">
              <a-input v-model="form.last_name" placeholder="Last name to display" size="large" />
            </label-item>

            <label-item label="Email" class="col-span-2">
              <a-input v-model="form.email" placeholder="Email address to send invitation" allow-clear size="large" />
            </label-item>

            <label-item class="col-span-3">
              <span slot="label">Leave invitation message for this tenant</span>
              <a-textarea v-model="form.invitation_message"
                placeholder="Your guest will see the message when they open your invitation link" allow-clear
                :auto-size="{ minRows: 2, maxRows: 4 }" />
            </label-item>

            <label-item label="Other Options" class="col-span-4">
              <div class="flex items-center space-x-2">
                <a-checkbox v-model="form.send_invitation">Send Tenant Invitation Email</a-checkbox>
                <a-checkbox v-model="form.send_welcome_letter">Send Welcome Letter</a-checkbox>
              </div>
            </label-item>

          </div>
          <lead-outreach v-if="controls.invite_by === 'sms'" size="large" @next="() => {
            controls.step_index = 2;
            controls.result = 'lead-success';
          }" />
        </div>
      </div>

      <div v-if="controls.step_index === 1 && controls.invite_by === 'email'"
        class="flex flex-col bg-white shadow-sm border rounded-lg">
        <div class="flex items-center justify-between hover:bg-gray-100 border-b px-4 py-4 cursor-pointer"
          @click="() => controls.assign_listing = true">
          <span class="font-medium text-base">
            Assign a Listing
          </span>
          <div class="flex items-center" @click.stop="() => { }">
            <a-button type="link" @click="() => controls.assign_listing = true">Send Listing Invitation?</a-button>
            <a-switch v-model="controls.assign_listing" @change="checked => {
              if (!checked) {
                form.listing = null;
              }
            }" />
          </div>
        </div>
        <div v-if="controls.assign_listing" class="flex flex-col px-4 py-4 space-y-4">
          <div class="flex items-center justify-center">
            <span class="text-lg font-semibold">
              Choose a Listing
            </span>
          </div>
          <div class="flex items-center space-x-4 px-6">
            <listing-search-input :class="{
              'w-2/5': form.listing,
            }" @select="listingId => form.listing = listingId" />
            <span v-if="form.listing" class="px-4 text-parqay-primary text-sm font-medium">
              <a class="font-semibold text-pink-500 pr-2">*</a>By selecting the listing, your tenant / lead will receive
              an SMS / email about your listing details.
            </span>
          </div>
        </div>
      </div>

      <template v-if="controls.step_index == 2">
        <a-result v-if="controls.result === 'success'" status="success" title="Successfully Invited/Added Tenant!"
          sub-title="Your tenant has received invitation, please give some time before they accepted the invitation">
          <template #extra>
            <a-button type="primary" size="large" @click="() => $router.push('/dashboard/tenants')">
              View Tenants
            </a-button>
            <a-divider style="margin-top: 30px; font-weight: 400; font-size: 0.875rem;">Or, copy following login link to
              forward to your tenant</a-divider>
            <div class="flex items-center justify-center space-x-2 mt-4">
              <a-tooltip :visible="controls.visible.copy" title="Copied!">
                <span
                  class="text-sm font-semibold px-2 py-1 text-parqay-primary hover:text-black bg-blue-100 hover:bg-white border border-blue-100 hover:border-black rounded cursor-pointer truncate"
                  :style="{ maxWidth: '260px' }" @click.stop="copyInviteLink(controls.invite_link)">{{
                    controls.invite_link
                  }}
                </span>
              </a-tooltip>
              <button class="flex items-center justify-center focus:outline-none"
                @click.stop="copyInviteLink(controls.invite_link)">
                <svg v-if="controls.visible.copy" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                  stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-parqay-primary">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                </svg>
                <svg-icon v-else icon-class="clipboard" class="w-5 h-5 text-gray-500 hover:text-parqay-primary" />
              </button>
            </div>
          </template>
        </a-result>
        <a-result v-if="controls.result === 'lead-success'" status="success" title="Successfully Sent Lead Message!"
          sub-title="Your lead has received invitation, please give some time before they respond you in the messaging page">
          <template #extra>
            <a-button type="primary" size="large" @click="() => $router.push('/dashboard/tenants#invited')">
              View Leads
            </a-button>
          </template>
        </a-result>
        <a-result v-if="controls.result === 'existing-info'" title="We've found an existing account">
          <template #extra>
            <div class="flex flex-col space-y-4 justify-center items-center">
              <span class="w-1/2 text-gray-500 text-base">We're about to add an existing Parqay tenant, please confirm the
                information below. After clicking the Confirm, this tenant will be
                added directly into your tenants list</span>
              <a target="_blank"
                class="flex space-x-4 px-4 py-4 border rounded items-center hover:shadow-sm bg-white hover:bg-blue-100">
                <div class="flex items-center justify-center border rounded bg-gray-50 w-12 h-12">
                  <span class="font-bold text-2xl text-blue-500">{{
                    form.first_name.substring(0, 1)
                  }}</span>
                </div>
                <span class="font-semibold text-base text-gray-600">{{ form.first_name }} {{ form.last_name }}</span>
                <span class="font-semibold text-base text-white bg-parqay-primary rounded-full px-4 py-2">{{ form.email
                }}</span>
              </a>
              <div class="flex space-x-4 pt-2">
                <a-button size="large" @click="() => (controls.step_index -= 1)">
                  Go Back
                </a-button>
                <a-button type="primary" size="large" :loading="controls.loading" @click="confirmInviteTenant()">
                  Add This Tenant
                </a-button>
              </div>
            </div>
          </template>
        </a-result>
        <a-result v-if="controls.result === 'inviting-info'" title="Inviting New Tenant">
          <template #extra>
            <div class="flex flex-col space-y-4 justify-center items-center">
              <span class="w-1/2 text-gray-500 text-base">We're about to add a new tenant into our system, please confirm
                the
                information below. After clicking the Confirm, a new tenant account
                will be created and a magic login link will send to the provided
                email.</span>
              <a target="_blank"
                class="flex space-x-4 px-4 py-4 border rounded items-center hover:shadow-sm bg-white hover:bg-blue-100">
                <div class="flex items-center justify-center border rounded bg-gray-50 w-12 h-12">
                  <span class="font-bold text-2xl text-blue-500">{{
                    form.first_name.substring(0, 1)
                  }}</span>
                </div>
                <span class="font-semibold text-base text-gray-600">{{ form.first_name }} {{ form.last_name }}</span>
                <span class="font-semibold text-base text-white bg-parqay-primary rounded-full px-4 py-2">{{ form.email
                }}</span>
              </a>
              <div class="flex space-x-4 pt-2">
                <a-button size="large" @click="() => (controls.step_index -= 1)">
                  Go Back
                </a-button>
                <a-button type="primary" size="large" :loading="controls.loading" @click="confirmInviteTenant()">
                  Send Invitation
                </a-button>
              </div>
            </div>
          </template>
        </a-result>
      </template>

      <div v-if="controls.step_index < 2" class="bg-white border shadow-sm rounded-lg flex justify-between py-4 px-8">
        <a-button v-if="controls.step_index > 0" type="primary" @click="() => (controls.step_index -= 1)">
          Previous
        </a-button>
        <div v-else />
        <a-button v-if="controls.step_index < 1" type="primary" @click="() => {
          if (controls.invite_mode_index == 2) {
            $router.push('/dashboard/reservations/new');
            return;
          }
          if (controls.invite_mode_index == 1) {
            controls.assign_listing = true;
            controls.invite_by = 'email';
          }
          if (controls.invite_mode_index == 0) {
            controls.assign_listing = false;
            controls.invite_by = 'sms';
          }
          controls.step_index += 1;
        }
          ">
          Next
        </a-button>
        <template v-else>
          <a-button v-if="controls.invite_by === 'email'" type="primary" :loading="controls.loading"
            :disabled="!validateFields()" @click="searchInviteTenant()">
            Invite
          </a-button>
        </template>
      </div>
    </div>
  </div>
</template>
