<script>
import Dropdown from "src/views/dropdowns/View";
import { ref } from "@vue/composition-api";

export default {
  name: "PropertyDropdown",
  components: {
    Dropdown,
  },
  setup() {
    const propertyModel = ref([
      {
        id: 0,
        name: "N La Salle Dr - Monthly Garage",
      },
    ]);

    return {
      propertyModel,
    };
  },
};
</script>
<template>
  <dropdown
    :model="propertyModel"
    :width="72"
  >
    <template v-slot:action>
      <router-link
        tag="span"
        to="/listing"
        class="whitespace-nowrap block w-full py-2 h-10 font-medium cursor-pointer text-gray-700 hover:bg-parqay-primary hover:text-white inline-flex justify-between px-4"
        role="menuitem"
      >
        <div class="w-full text-center">
          + Create New Property
        </div>
      </router-link>
    </template>
  </dropdown>
</template>