<script>
import { reactive, ref, onMounted, watch } from "@vue/composition-api";
import parsePhoneNumber from "libphonenumber-js";

export default {
  name: "SignupStep3First",
  setup(props, ctx) {
    const controls = reactive({
      country: "US",
      phoneNumberComponents: null
    });

    const phoneNumber = ref(null);

    const checkboxes = ref({
      sms_booking_notification: false,
      sms_guest_message: false,
      sms_alert: false
    })

    watch(() => controls.country, (val) => {
      updatePhoneNumberComponents();
    })

    watch(
      () => checkboxes.value.sms_booking_notification,
      (val) => {
        console.log("checkboxes.value.sms_booking_notification")
        ctx.emit("toggle-subscribe", 'sms_booking_notification', val);
      },
    );
    watch(
      () => checkboxes.value.sms_guest_message,
      (val) => {
        console.log("checkboxes.value.sms_guest_message")
        ctx.emit("toggle-subscribe", 'sms_guest_message', val);
      },
    );
    watch(
      () => checkboxes.value.sms_alert,
      (val) => {
        console.log("checkboxes.value.sms_alert")
        ctx.emit("toggle-subscribe", 'sms_alert', val);
      },
    );

    const updatePhoneNumberComponents = () => {
      try {
        controls.phoneNumberComponents = parsePhoneNumber(
          phoneNumber.value,
          controls.country
        );
        ctx.emit("full-number-updated", controls.phoneNumberComponents);
      } catch (error) {
        controls.phoneNumberComponents = null;
      }
    };

    const loadCachedPayloadFromStore = () => {
      const signUpForm = ctx.root.$store.state.signUpForm;
      if (signUpForm.phone_number) {
        const pn = parsePhoneNumber(signUpForm.phone_number);
        controls.country = pn.country;
        controls.phoneNumberComponents = pn;
        phoneNumber.value = pn.nationalNumber;
      }
      if (signUpForm.subscribe) {
        if (signUpForm.subscribe.includes("sms_booking_notification")) {
          checkboxes.value.sms_booking_notification = true
        }
        if (signUpForm.subscribe.includes("sms_guest_message")) {
          checkboxes.value.sms_guest_message = true
        }
        if (signUpForm.subscribe.includes("sms_alert")) {
          checkboxes.value.sms_alert = true
        }
      }
    };

    onMounted(() => {
      loadCachedPayloadFromStore();
    });

    return {
      controls,
      phoneNumber,
      checkboxes,
      updatePhoneNumberComponents
    };
  },
};
</script>
<template>
  <div class="flex flex-col space-y-1 w-full">
    <span class="text-base font-medium text-center">Enter your phone number below</span>
    <div class="rounded-2xl shadow-sm border bg-white hover:border-blue-300 cursor-pointer" style="min-height: 321px">
      <div class="flex flex-col px-6 pt-2 pb-5">
        <div class="px-2 md:px-6 pt-6 pb-2">
          <div class="mt-1 rounded-md shadow-sm space-y-2">

            <country-select v-model="controls.country" :country="controls.country" topCountry="US" class="w-full" />

            <a-input size="large" allow-clear required v-model="phoneNumber" placeholder="Enter phone number"
              @input="updatePhoneNumberComponents()">
              <template #prefix>
                <a-icon type="phone" />
              </template>
            </a-input>
          </div>
        </div>
        <div class="flex px-2 md:px-6 justify-center items-center">
          <span class="text-gray-600">Phone number is required and has to be reachable. Communication is
            always the spirit for sharing economy.</span>
        </div>
        <div class="flex px-6 pt-2 mt-2 justify-center items-center">
          <span class="text-parqay-primary font-medium">Choose Services</span>
        </div>
        <div class="flex space-x-2 pt-2">
          <div class="inline-flex">
            <a-checkbox v-model="checkboxes.sms_booking_notification">
              Receive incoming booking notifications
            </a-checkbox>
          </div>
        </div>
        <div class="flex space-x-2 pt-2">
          <div class="inline-flex">
            <a-checkbox v-model="checkboxes.sms_guest_message">
              Receive guest messages
            </a-checkbox>
          </div>
        </div>
        <div class="flex space-x-2 pt-2">
          <div class="inline-flex">
            <a-checkbox v-model="checkboxes.sms_alert">
              Receive alerts
            </a-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>