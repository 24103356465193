<script>
import { ref, onMounted, watch } from "@vue/composition-api";
import EventBus from "src/services/util/event-bus";
import TabHeader from "src/views/components/TabHeader";
import EventSchedule from "src/views/events/EventSchedule";
import EventPricing from "src/views/events/EventPricing";
import PropertyDropdown from "src/views/dropdowns/Property";

export default {
  name: "Events",
  components: {
    TabHeader,
    PropertyDropdown,
    EventSchedule,
    EventPricing,
  },
  setup(props, ctx) {
    const route = ctx.root.$route;
    const router = ctx.root.$router;

    const tabs = ref([
      { name: "event-schedule", label: "Event Schedule" },
      { name: "event-pricing", label: "Event Pricing" },
    ]);

    const activeTab = ref("event-schedule");

    const changeTab = (name) => {
      activeTab.value = name;
      setupHashRoute(name);
    };

    const setupHashRoute = (hashRoute) => {
      router.push({ name: route.name, hash: `#${hashRoute}` });
    };

    onMounted(() => {
      EventBus.$on("open-event-schedule", () => {
        changeTab("event-schedule")
      });
    });

    return {
      tabs,
      activeTab,
      changeTab,
    };
  },
};
</script>
<template>
  <div class="px-10 py-6">
    <div class="flex font-bold py-3">
      <tab-header
        v-model="activeTab"
        :tabs="tabs"
        @change="changeTab"
      />
      <div class="flex justify-end items-center">
        <p class="font-medium text-base text-gray-690 mx-4 my-auto">
          Property
        </p>
        <property-dropdown />
      </div>
    </div>
    <div class="flex relative h-screen">
      <event-schedule
        v-if="activeTab === 'event-schedule'"
        @change="changeTab"
      />
      <event-pricing
        v-if="activeTab === 'event-pricing'"
        @change="changeTab"
      />
    </div>
  </div>
</template>
