<script>
import { reactive, onMounted, onBeforeUnmount } from "@vue/composition-api";
import ClickOutside from "vue-click-outside";
import AccountModal from "./components/AccountModal.vue";

export default {
  directives: {
    ClickOutside,
  },
  components: {
    AccountModal
  },
  setup(props, ctx) {
    const controls = reactive({
      loading: false,
      visible: {
        menu: false
      }
    });
    const form = reactive({
      first_name: null,
      last_name: null,
      menu: [
        {
          name: "Summary",
          visible: () => ctx.root.$isLoggedIn(),
          bold: false,
          icon: "home",
          action: () => {
            ctx.root.$router.push("/tenant/home");
            closeMenu();
          }
        },
        {
          name: "Reservations",
          visible: () => true,
          bold: true,
          icon: "calendar",
          action: () => {
            ctx.root.$router.push("/tenant/reservations");
            closeMenu();
          }
        },
        // {
        //   name: "Payment Methods",
        //   visible: () => ctx.root.$isLoggedIn(),
        //   bold: false,
        //   icon: "credit-card",
        //   action: async () => {
        //     ctx.root.$bus.$emit("open-desktop-or-download", {
        //       name: "Payment Methods",
        //       link: "https://dashboard.parqay.com/tenant/settings"
        //     })
        //     closeMenu();
        //   }
        // },
        // {
        //   name: "Maintenance",
        //   visible: () => ctx.root.$isLoggedIn(),
        //   bold: false,
        //   icon: "tool",
        //   action: async () => {
        //     ctx.root.$bus.$emit("open-desktop-or-download", {
        //       name: "Maintenance",
        //       link: "https://dashboard.parqay.com/tenant/maintenance"
        //     })
        //     closeMenu();
        //   }
        // },
        {
          name: "Documents",
          visible: () => true,
          bold: false,
          icon: "file-text",
          action: async () => {
            ctx.root.$router.push("/tenant/documents");
            closeMenu();
          }
        },
        {
          name: "Payments",
          visible: () => ctx.root.$isLoggedIn(),
          bold: false,
          icon: "retweet",
          action: async () => {
            ctx.root.$router.push("/tenant/payments");
            closeMenu();
          }
        },
        // {
        //   name: "Vehicles",
        //   visible: () => ctx.root.$isLoggedIn(),
        //   bold: false,
        //   icon: "car",
        //   action: async () => {
        //     ctx.root.$bus.$emit("open-desktop-or-download", {
        //       name: "Vehicles",
        //       link: "https://dashboard.parqay.com/tenant/vehicles"
        //     })
        //     closeMenu();
        //   }
        // }
        {
          name: "Settings",
          visible: () => ctx.root.$isLoggedIn(),
          bold: false,
          icon: "sliders",
          action: async () => {
            ctx.root.$router.push("/tenant/settings");
            closeMenu();
          }
        }
      ]
    });

    const navToLogin = () => {
      ctx.root.$router.push(`/login/tenant?next=${ctx.root.$route.fullPath}`);
      closeMenu();
    }

    const scrollToSection = (anchor) => {
      document
        .getElementById(anchor)
        .scrollIntoView({ behavior: "smooth", block: "start" });
    };
    const navToFeatures = () => {
      controls.visible.menu = false;
      if (ctx.root.$route.path !== "/") {
        ctx.root.$router.push("/");
      }
      setTimeout(() => {
        scrollToSection("features");
      }, 500);
    };
    const pushToRoute = (r) => {
      closeMenu();
      window.open("https://parqay.com" + r, "_blank");
    }
    const closeMenu = () => {
      controls.visible.menu = false;
    }
    onMounted(() => {

    });
    onBeforeUnmount(() => {

    });
    return {
      controls,
      form,
      navToLogin,
      navToFeatures,
      pushToRoute,
      closeMenu,
      window,
      console
    };
  }
};
</script>
<template>
  <div :style="{ height: '65px' }">
    <div class="fixed inset-0">
      <nav id="pq-nav" class="flex items-center justify-between py-1 px-4 bg-white border-b"
        :style="{ height: '60px' }">
        <div class="flex justify-between items-center w-full relative">

          <!-- Menu Icon -->
          <div class="flex flex-grow-0 items-center justify-center z-30"
            :class="$isLoggedIn() ? 'space-x-1' : 'space-x-2'">
            <button id="menu-button" type="button" class="
              h-10
              bg-white
              rounded-md
              p-2
              inline-flex
              items-center
              justify-center
              text-gray-400
              hover:text-gray-500
              hover:bg-gray-100
              focus:outline-none
              focus:ring-2 focus:ring-inset focus:ring-indigo-500
            " aria-expanded="false" @click="() => (controls.visible.menu = !controls.visible.menu)
    ">
              <span class="sr-only">Open menu</span>
              <svg v-if="controls.visible.menu" class="w-6 h-6 text-gray-400 pointer-events-none" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"></path>
              </svg>
              <!-- Heroicon name: outline/menu -->
              <svg v-else class="h-6 w-6 pointer-events-none" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>

          <!-- App Logo -->
          <div class="absolute transform top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 cursor-pointer z-30">
            <img src="/static/img/PARQAY-logo.svg" style="max-width: 10rem;" class="px-1 py-1 rounded-xl" @click="() => {
    if ($route.path === '/tenant/home' || !$isLoggedIn()) {
      $router.push('/');
    } else {
      $router.push('/tenant/home');
    }
  }">
          </div>

          <!-- Avatar Icon -->
          <div v-if="$isLoggedIn()" class="flex flex-grow-0 items-center justify-center z-30">
            <button type="button" class="
              h-10
              bg-white
              rounded-md
              p-2
              inline-flex
              items-center
              justify-center
              text-gray-400
              hover:text-gray-500
              hover:bg-gray-100
              focus:outline-none
              focus:ring-2 focus:ring-inset focus:ring-indigo-500
            " aria-expanded="false">
              <span class="sr-only">User Profile</span>
              <avatar :user-id="$deepGet($loadUserData(), 'id')" :popover="false" @click="() => {
    $bus.$emit('open-account-modal');
    closeMenu();
  }" />
            </button>
          </div>

        </div>
      </nav>
    </div>
    <!--
            Mobile menu
        -->
    <div class="
          fixed
          z-40
          inset-x-0
          p-2
          transition
          transform
          origin-top
          md:hidden
        "
      :class="controls.visible.menu ? 'pointer-events-auto duration-100 ease-in opacity-100 scale-100' : 'pointer-events-none duration-200 ease-out opacity-0 scale-95'"
      :style="{ top: '50px' }" v-click-outside="(e) => {
    if (e.target.id !== 'menu-button') {
      // Ignore menu button touch outside since it has its own touch event handler
      console.log('click outside -> ', e.target.id);
      controls.visible.menu = false;
    }
  }">
      <div class="
            max-w-lg
            mx-auto
            rounded-lg
            shadow-lg
            border
            ring-1 ring-black ring-opacity-5
            bg-white
            divide-y-2 divide-gray-50
          ">
        <div class="overflow-y-auto flex flex-col space-y-2 pb-2.5 pt-6" style="max-height: 80vh;">
          <span class="text-center text-base font-semibold text-black">Tenant Access</span>
          <div v-if="$isLoggedIn()" class="flex flex-grow justify-center pt-3 pb-0 px-5">
            <button type="button"
              class="text-parqay-primary group inline-flex space-x-3 items-center rounded-md bg-white text-lg font-medium hover:text-parqay-primary focus:outline-none"
              aria-expanded="false" @click="() => {
    $bus.$emit('open-account-modal');
    closeMenu();
  }">
              <avatar :user-id="$deepGet($loadUserData(), 'id')" :popover="false" />
              <span>Hello, {{ $deepGet($loadUserData(), "first_name") }}</span>
            </button>
          </div>
          <div class="flex flex-col items-center justify-center px-5 py-4 space-y-4">
            <a-button v-for="(item, i) in form.menu.filter(v => v.visible())" :key="`account-item-${i}`"
              :icon="item.icon" size="large" block style="display:flex; align-items: center; justify-content: center;"
              @mousedown="item.action()">
              <a class="text-base ml-2.5" :class="item.bold ? 'font-medium text-black' : 'text-gray-600'">{{ item.name
                }}</a>
            </a-button>
          </div>
          <div v-if="!$isLoggedIn()" class="flex flex-col items-center px-5 py-2 space-y-4">
            <a class="
                    w-full
                    flex
                    items-center
                    justify-center
                    px-4
                    py-2
                    border border-transparent
                    rounded-md
                    shadow-sm
                    text-base
                    font-semibold
                    text-white
                    from-purple-700
                    to-blue-500
                    bg-gradient-to-r
                    hover:opacity-75
                  " @click="navToLogin()">
              Login as Tenant
            </a>
          </div>
          <a-divider>Info</a-divider>
          <div class="grid grid-cols-2 gap-x-2 gap-y-4 px-5 py-2">
            <button type="button" class="
                text-gray-600
                group
                bg-white
                rounded-md
                inline-flex
                items-center
                justify-center
                text-base
                font-medium
                hover:text-gray-900
                focus:outline-none
              " aria-expanded="false" @click="navToFeatures()">
              <span>Features</span>
            </button>

            <a class="text-base text-center font-medium text-gray-600 hover:text-gray-900" @click="() => {
    if ($route.path === '/faq') {
      closeMenu();
    } else {
      pushToRoute('/faq');
    }
  }
    ">
              FAQs
            </a>
            <a class="text-base text-center font-medium text-gray-600 hover:text-gray-900" @click="() => {
    if ($route.path === '/contact-us') {
      closeMenu();
    } else {
      pushToRoute('/contact-us');
    }
  }
    ">
              Contact Us
            </a>

            <a class="text-base text-center font-medium text-gray-600 hover:text-gray-900"
              @click="window.open('https://dashboard.parqay.com/docs', '_blank')">
              API Doc
            </a>

          </div>
          <a-divider></a-divider>
          <div class="flex items-center justify-center py-2.5">
            <a class="text-base text-center font-medium text-parqay-primary hover:opacity-75"
              @click="() => window.open('https://dashboard.parqay.com', '_blank')">
              Become A Host
            </a>
          </div>
          <a-divider></a-divider>
          <div class="flex items-center justify-center py-2.5">
            <a class="text-base text-center font-medium text-parqay-primary hover:opacity-75"
              @click="() => window.open('https://parqay.com', '_blank')">
              Book A Space
            </a>
          </div>
          <template v-if="$isLoggedIn()">
            <a-divider></a-divider>
            <a class="block rounded-md transition duration-150 ease-in-out hover:bg-gray-50" @click="() => {
    controls.visible.menu = false;
    $logout();
    $router.push(`/login/tenant?next=${$route.fullPath}`);
  }">
              <p align="center" class="m-0 p-3">
                <span class="text-center text-base font-medium text-pink-500">Log Out</span>
              </p>
            </a>
          </template>
        </div>
      </div>
    </div>
    <account-modal @more-settings="() => $router.push('/tenant/settings')" />
  </div>
</template>
<style lang="scss" scoped>
.ant-btn {
  @apply flex items-center justify-center;
}

.reservations-btn {
  @apply px-2 text-sm font-medium;
}
</style>