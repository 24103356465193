<script>
import { ref, onMounted } from "@vue/composition-api";
import { API } from "src/views/listings/api";

export default {
  name: "holiday-modal",
  props: {
    model: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  setup(props, ctx) {
    const visible = ref(false);
    const hide = () => {
      visible.value = false;
    };

    // TODO: Holidays need to sync with API
    const holidayList = ref([]);

    props.model.include.forEach((id) => {
      const it = holidayList.value.find((l) => l.id === id);
      console.log("checked item", it);
      if (it) {
        it.checked = true;
      }
    });

    const handleConfirm = () => {
      const checkedItems = holidayList.value
        .filter((v) => v.checked)
        .map((v) => v.id);
      props.model.include = checkedItems;
      hide();
    };

    const currentYear = () => {
      return new Date().getFullYear();
    }

    const fetchData = async () => {
      const { data } = await API.getAvailabilityHolidays(currentYear());
      holidayList.value = data.map(v => {
        return { ...v, checked: false }
      });
    }

    onMounted(() => {
      fetchData();
    });

    return {
      visible,
      holidayList,
      show() {
        visible.value = true;
      },
      hide,
      handleConfirm,
      currentYear
    };
  },
};
</script>

<template>
  <a-modal v-model="visible" :body-style="{
  height: '46vh',
  overflow: 'auto',
}" width="50vw" :keyboard="false" :mask-closable="false" :closable="false"
    :title="`Select Holidays to Include - Year ${currentYear()}`">
    <div class="border-b border-l border-r">
      <div v-for="(it, i) in holidayList" :key="`holiday-${i}`" class="flex border-t">
        <span class="w-1/2 border-r p-1">{{
    $formatDate(it.dt, "MMMM Do")
}}</span>
        <div class="w-1/2 p-1 flex justify-between">
          <span class="text-parqay-primary font-medium">{{ it.name }}</span>
          <a-checkbox v-model="it.checked" />
        </div>
      </div>
    </div>
    <template slot="footer">
      <button class="w-32 bg-parqay-primary hover:bg-blue-500 text-white font-bold py-2 px-4 rounded"
        @click="handleConfirm">
        Save
      </button>
    </template>
  </a-modal>
</template>