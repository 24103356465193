<script>
import { ref, computed } from "@vue/composition-api";
import Simple from "src/views/listings/detail/availability/Simple.vue";
import Manual from "src/views/listings/detail/availability/Manual.vue";
import { getDefaultModelForMode } from "src/views/listings/detail/availability/util.js";
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';

export default {
  components: {
    Simple,
    Manual,
  },
  props: {
    model: {
      type: Object,
      default: null,
    }
  },
  setup(props, ctx) {
    const modes = ["simple", "manual"];

    const activeMode = ref(props.model.mode);

    const title = ref(props.model.title);

    const showWarning = computed(() => {
      if (props.model.mode != lowerCase(activeMode.value)) {
        return true;
      }
      return false;
    });

    const toggleModeConfirm = () => {
      const defaultModel = getDefaultModelForMode(activeMode.value);
      console.log("getDefaultModelForMode", defaultModel);
      props.model.mode = defaultModel.mode;
      props.model.simple = defaultModel.simple;
      props.model.manual = defaultModel.manual;
    };

    return {
      title,
      modes,
      activeMode,
      showWarning,
      toggleModeConfirm,
      startCase
    };
  },
};
</script>
<template>
  <div class="text-center justify-center">
    <div v-if="activeMode != model.mode" class="rounded-lg shadow-md bg-yellow-50 p-4 mx-auto mb-8" style="width: 550px">
      <div class="flex-col">
        <div class="flex justify-center w-full">
          <svg-icon icon-class="warning" class="h-5 w-5 text-yellow-400" />

          <h3 class="text-sm font-medium text-yellow-800 ml-3">
            Attention needed
          </h3>
        </div>
        <div class="flex-col">
          <div class="mt-2 text-sm text-yellow-700">
            <p>
              This availability mode is not enabled, do you want to enable it?
            </p>
          </div>
          <div class="flex justify-between px-6 mt-4 pb-2">
            <button
              class="w-2/5 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-400 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="toggleModeConfirm">
              <div class="flex w-full justify-center space-x-2">
                <span class="my-auto">Enable</span>
                <svg-icon icon-class="check" class="h-5 w-5 my-auto text-white" />
              </div>
            </button>
            <button
              class="w-2/5 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-parqay-primary hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <div class="flex w-full justify-center space-x-2">
                <span class="my-auto">Help</span>
                <svg-icon icon-class="question" class="h-5 w-5 my-auto text-white" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 flex justify-center px-6">
      <simple v-if="model.mode === 'simple' && activeMode == model.mode" :model="model.simple" :title="title"
        @schedule-mode-update="$emit('schedule-mode-update')">
        <a-radio-group v-model="activeMode">
          <a-radio-button v-for="mode in modes" :key="mode" :value="mode">
            {{ startCase(mode) }}
          </a-radio-button>
        </a-radio-group>
      </simple>
      <manual v-if="model.mode === 'manual' && activeMode == model.mode" :model="model.manual" :title="title"
        @schedule-mode-update="$emit('schedule-mode-update')">
        <a-radio-group v-model="activeMode">
          <a-radio-button v-for="mode in modes" :key="mode" :value="mode">
            {{ startCase(mode) }}
          </a-radio-button>
        </a-radio-group>
      </manual>
    </div>
  </div>
</template>