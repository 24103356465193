<script>
import { onMounted, ref } from "@vue/composition-api";
import { API } from "src/views/towing/api";

export default {
  setup(props, ctx) {
    const towRequests = ref([]);
    const keyword = ref(null);
    const filter = (v) => {
      if (keyword.value) {
        try {
          return (
            v.title.toLowerCase().includes(keyword.value.toLowerCase()) ||
            v.status.toLowerCase().includes(keyword.value.toLowerCase()) ||
            v.desc.toLowerCase().includes(keyword.value.toLowerCase()) ||
            v.address.toLowerCase().includes(keyword.value.toLowerCase()) ||
            v.host.name.toLowerCase().includes(keyword.value.toLowerCase()) ||
            v.vehicle.make.toLowerCase().includes(keyword.value.toLowerCase()) ||
            v.vehicle.model.toLowerCase().includes(keyword.value.toLowerCase()) ||
            v.vehicle.color.toLowerCase().includes(keyword.value.toLowerCase()) ||
            v.vehicle.plate_number
              .toLowerCase()
              .includes(keyword.value.toLowerCase())
          );
        } catch (error) {
          console.log("filter error ==> ", error);
          return true;
        }
      } else {
        return true;
      }
    };
    const fetchData = async () => {
      towRequests.value = [];
      let resp = await API.list({ role: "JQL85S" });
      towRequests.value = resp.data;
    };
    onMounted(() => {
      fetchData();
    });
    return {
      towRequests,
      keyword,
      filter,
      fetchData,
    };
  },
};
</script>
<template>
  <div
    class="w-screen h-screen flex flex-col items-center space-y-6 px-10 py-8 bg-gray-50 overflow-y-auto"
  >
    <div class="flex flex-col items-center space-y-2">
      <div
        class="flex space-x-4 justify-center items-center cursor-pointer"
        @click="$router.push('/')"
      >
        <svg-icon icon-class="PARQAY-logo" class="h-20 w-auto" />
        <svg-icon
          icon-class="circular-loader"
          class="w-8 h-8 animate-spin text-blue-500"
        />
      </div>
      <span class="font-semibold text-lg">For Towing Companies</span>
      <div class="flex space-x-4 items-center px-6 py-4 border rounded bg-white">
        <span class="text-base font-semibold text-parqay-primary">TowingHero Inc</span>
        <a-tag color="geekblue" size="large">
          {{ towRequests.length }} requests
        </a-tag>
        <a-button
          class=""
          size="large"
          type="primary"
          @click="$router.push('/towing')"
        >
          Home
        </a-button>
      </div>
    </div>

    <div
      class="flex items-center justify-center border border-blue-400 bg-blue-100 text-blue-600 px-6 py-4"
    >
      <span class="text-base"
        >The latest towing requests from Parqay will be pushed immediately to the
        list below</span
      >
    </div>

    <div class="flex flex-col space-y-4 w-2/3">
      <div class="flex space-x-2 w-full">
        <div
          class="flex overflow-hidden rounded-full border justify-center flex-grow"
        >
          <a-input
            v-model="keyword"
            placeholder="Filter requests by keywords"
            size="large"
            style="margin:-1px; padding-left: 2rem;"
            class="search-input"
          />
        </div>
        <a-button type="primary" size="large" @click="fetchData()">
          Refresh
        </a-button>
      </div>
      <div
        class="flex flex-col space-y-2 px-6 py-6 rounded-lg border bg-white flex-grow overflow-y-auto"
        style="max-height:500px"
      >
        <template v-if="towRequests.filter(filter).length > 0">
          <div
            v-for="(r, i) in towRequests.filter(filter)"
            :key="`request-${i}`"
            class="flex items-center space-x-4 justify-between px-6 py-4 rounded-lg border bg-white"
          >
            <div class="flex flex-col relative space-y-2">
              <div class="flex">
                <viewer v-if="r.vehicle.image" :images="[r.vehicle.image]">
                  <lazy-img
                    :src="r.vehicle.image"
                    src-placeholder="/static/img/dashboard/vehicle-placeholder.png"
                    class="h-24 w-auto object-cover hover:opacity-80 cursor-pointer"
                  />
                </viewer>
                <img
                  v-else
                  src="/static/img/dashboard/vehicle-placeholder.png"
                  class="h-24 w-auto object-cover hover:opacity-80 cursor-pointer"
                />
                <div
                  class="flex flex-col space-y-1 bg-white px-2 py-2 border text-xs"
                >
                  <span>Make: {{ r.vehicle.make }}</span>
                  <span>Model: {{ r.vehicle.model }}</span>
                  <span>Color: {{ r.vehicle.color }}</span>
                  <span>Plate #: {{ r.vehicle.plate_number }}</span>
                </div>
              </div>
              <div class="flex space-x-1 items-center">
                <a-popconfirm
                  :title="r.host.phone_number"
                  :disabled="!r.host.phone_number"
                >
                  <a-button
                    type="primary"
                    size="small"
                    :disabled="!r.host.phone_number"
                  >
                    Call
                  </a-button>
                </a-popconfirm>
                <a-button size="small" @click="() => (r.status = 'complete')">
                  Approve
                </a-button>
                <a-button size="small" @click="() => (r.status = 'rejected')">
                  Reject
                </a-button>
                <a-button size="small" @click="() => (r.status = 'pending')">
                  Reset
                </a-button>
              </div>
              <a-tag
                v-if="r.status === 'pending'"
                color="orange"
                class="absolute"
                style="left:-0.5rem; top:-0.5rem;"
              >
                {{ r.status }}
              </a-tag>
              <a-tag
                v-if="r.status === 'rejected'"
                color="pink"
                class="absolute"
                style="left:-0.5rem; top:-0.5rem;"
              >
                {{ r.status }}
              </a-tag>
              <a-tag
                v-if="r.status === 'complete'"
                color="green"
                class="absolute"
                style="left:-0.5rem; top:-0.5rem;"
              >
                {{ r.status }}
              </a-tag>
            </div>
            <div class="flex flex-col space-y-2">
              <div class="flex space-x-2 items-center">
                <img
                  v-if="r.host.company_logo"
                  :src="r.host.company_logo"
                  class="w-8 h-8 border rounded overflow-hidden"
                />
                <div
                  v-else
                  class="w-8 h-8 rounded border bg-gray-50 uppercase flex items-center justify-center text-lg font-bold text-blue-500"
                >
                  {{ r.host.name[0] }}
                </div>
                <span class="text-base font-medium text-gray-600"
                  >{{ r.host.name }} says:
                </span>
                <span class="text-sm font-medium text-parqay-primary px-4 py-1">{{
                  r.title
                }}</span>
              </div>
              <div class="flex flex-col space-y-2 items-center">
                <span class="text-sm border px-4 py-2 w-full rounded">{{
                  r.desc
                }}</span>
                <div class="flex space-x-2 items-center">
                  <span class="text-center">Address</span>
                  <span
                    class="text-sm px-4 py-2 w-full text-parqay-primary font-medium"
                    >{{ r.address }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="flex items-center justify-center border rounded-lg bg-gray-50 text-gray-500 px-6 py-4 text-lg"
          >
            No request yet or no result meets your search criteria.
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .search-input {
    @apply border-none;
  }
}
</style>
