<script>
import { onMounted, reactive } from "@vue/composition-api";
import startCase from "lodash/startCase";

import { API } from "src/views/maintenance/api";

export default {
  name: "MaintenanceTable",
  props: {
    role: {
      type: String,
      default: "host",
    },
    filter: {
      type: Function,
      default: () => true,
    },
  },
  setup(props, ctx) {
    const shortUID = ctx.root.$shortUID;
    let headers = [
      {
        title: "Task",
        align: "center",
        dataIndex: "title",
        scopedSlots: { customRender: "title" },
        width: 150,
      },
      {
        title: "Status",
        align: "center",
        dataIndex: "status",
        scopedSlots: { customRender: "status" },
        width: 150,
      },
      {
        title: "Tenant Notes",
        align: "center",
        dataIndex: "notes",
        scopedSlots: { customRender: "note" },
      },
      {
        title: "Work Logs",
        align: "center",
        dataIndex: "logs",
        scopedSlots: { customRender: "logs" },
      },
      {
        title: "Reservation",
        align: "center",
        dataIndex: "reservation_title",
        scopedSlots: { customRender: "reservation" },
      },
      {
        title: "Created At",
        align: "center",
        dataIndex: "created_at",
        scopedSlots: { customRender: "created_at" },
      },
    ];
    if (props.role === "host") {
      headers.push({
        title: "Actions",
        align: "center",
        scopedSlots: { customRender: "actions" },
        fixed: "right",
      });
      headers.splice(1, 0, {
        title: "Tenant",
        align: "center",
        dataIndex: "tenant_name",
        scopedSlots: { customRender: "tenant" },
        width: 150,
      });
    }
    if (props.role === "tenant") {
      headers.splice(1, 0, {
        title: "Host",
        align: "center",
        dataIndex: "host_name",
        scopedSlots: { customRender: "host" },
        width: 150,
      });
    }
    const table = reactive({
      headers,
      data: [],
    });
    const controls = reactive({
      visible: false,
      loading: false,
    });
    const toggleNewRequestModal = () => {
      controls.visible = true;
    };
    const fetchData = async () => {
      let resp;
      controls.loading = true;
      try {
        resp = await API.list(props.role);
        table.data = resp.data;
      } catch (error) {
        console.log("maintenance API error: ", error);
      }
      controls.loading = false;
    };
    onMounted(() => {
      fetchData();
    });
    return {
      table,
      controls,
      fetchData,
      toggleNewRequestModal,
      console,
      startCase,
    };
  },
};
</script>
<template>
  <div>
    <a-table
      :loading="controls.loading"
      :columns="table.headers"
      :data-source="table.data.filter(filter)"
      :scroll="{ x: true, y: false }"
      row-key="id"
      :custom-row="
        (record, index) => {
          return {
            on: {
              click: (event) => {
                console.log(
                  'row click currentTarget',
                  event.currentTarget.tagName.toLowerCase()
                );
                console.log('row click target', event.target.tagName.toLowerCase());
                if (
                  event.target.tagName.toLowerCase() !== 'svg' &&
                  event.target.tagName.toLowerCase() !== 'use' &&
                  event.target.tagName.toLowerCase() !== 'span' &&
                  event.target.tagName.toLowerCase() !== 'a' &&
                  event.target.tagName.toLowerCase() !== 'button'
                ) {
                }
              },
            },
          };
        }
      "
      :pagination="false"
    >
      <template slot="status" slot-scope="status, record">
        <div class="flex flex-col space-y-2 px-2 py-4">
          <a-tag v-if="status == 'pending'" color="orange" class="py-1">
            Waiting
          </a-tag>
          <a-tag v-if="status == 'working'" color="geekblue" class="py-1">
            {{ startCase(status) }}
          </a-tag>
          <a-tag v-if="status == 'complete'" color="green" class="py-1">
            {{ startCase(status) }}
          </a-tag>
        </div>
      </template>

      <template slot="reservation" slot-scope="reservation">
        <div class="flex flex-col space-y-2">
          <a-tag color="geekblue" class="py-1 px-3">
            {{ reservation }}
          </a-tag>
        </div>
      </template>

      <template slot="logs" slot-scope="logs">
        <div class="flex flex-col space-y-2">
          <a-tag
            v-for="(log, i) in logs"
            :key="`log-${i}`"
            color="geekblue"
            closable
            @close="() => {}"
          >
            {{ log.action }}
          </a-tag>
        </div>
      </template>

      <template slot="created_at" slot-scope="created_at">
        <a-tag>
          {{ $formatDate(created_at.$date, "MM/DD, h:mm a") }}
        </a-tag>
      </template>

      <template slot="actions" slot-scope="_, record, i">
        <div class="flex flex-col space-y-2">
          <a-radio-group v-model="record.status">
            <a-radio-button value="pending">
              Pending
            </a-radio-button>
            <a-radio-button value="working">
              Working
            </a-radio-button>
            <a-radio-button value="complete">
              Complete
            </a-radio-button>
          </a-radio-group>
          <div>
            <a-input-search
              allow-clear
              placeholder="Log work progress"
              @search="
                (v, e) => {
                  console.log('v: ', v);
                  if (v) {
                    record.logs.push({ action: v, date: $serverNow() });
                    const target = e.target
                      .closest('.ant-input-wrapper')
                      .getElementsByTagName('input')[0];
                    target.value = null;
                  }
                }
              "
            >
              <a-button slot="enterButton" type="primary">
                Log
              </a-button>
            </a-input-search>
          </div>
        </div>
      </template>
    </a-table>
    <maintenance-request-modal
      :visible="controls.visible"
      @close="
        () => {
          controls.visible = false;
          fetchData();
        }
      "
    />
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .ant-btn {
    @apply text-xs;
  }
  .ant-table-row {
    td {
      @apply py-2;
    }
  }
  .ant-tag {
    @apply flex items-center justify-center space-x-2;
  }
}
</style>
