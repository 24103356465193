<script>
import { reactive, ref } from "@vue/composition-api";
import { API } from "src/views/auth/api";
import { saveUserData, authCallback } from "src/plugins/prototype";
import debounce from "lodash/debounce";

import SocialLogins from "./components/SocialLogins.vue";
import PhoneNumberManageCard from "src/views/auth/components/Step3.vue";

export default {
  name: "tenant-sign-up",
  components: { SocialLogins, PhoneNumberManageCard },
  setup(props, ctx) {
    const loading = reactive({
      verify: false,
      signin: false,
      facebook: true,
      google: true,
      apple: true
    });
    const visible = reactive({
      changePhoneNumber: false
    });
    const isEmailVerifyPass = ref(null);
    const account = reactive({
      email: null,
      password: null,
      first_name: null,
      last_name: null,
      phone_number: null,
      agreement_sign_at: null
    });
    const handleChange = k => {
      visible[k] = !visible[k];
    };
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const onEmailUpdate = debounce(async (e) => {
      console.log("onEmailUpdate -> ", account.email);
      isEmailVerifyPass.value = null;
      loading.verify = true;
      const isValidEmail = validateEmail(account.email);
      if (isValidEmail) {
        try {
          await API.checkEmailDuplication(account.email);
          isEmailVerifyPass.value = true;
        } catch (error) {
          isEmailVerifyPass.value = false;
          ctx.root.$openNotification(
            "Sign up email",
            error.message
          )
        }
      }
      loading.verify = false;
    },
      500,
      {
        leading: true
      }
    )
    const validateSignUp = () => {
      return (
        account.email &&
        account.password &&
        account.first_name &&
        account.last_name &&
        account.phone_number
      );
    };
    const signUp = async () => {
      loading.signin = true;
      account.agreement_sign_at = ctx.root.$serverNow();
      let resp;
      try {
        resp = await API.signUpTenant(account);
        console.log("signUp >> ", resp.data);
        resp = await API.login({
          email: account.email,
          password: account.password,
          automatic_login: true,
          source: "tenant"
        });
        console.log("login api result: ", resp.data);
        saveUserData(resp.data);
        authCallback();
        console.log("/next/" + resp.data.next_route);
        ctx.root.$router.push("/next/" + resp.data.next_route);
      } catch (error) {
        console.log("signUp >> error:", error);
        return;
      } finally {
        setTimeout(() => {
          loading.signin = false;
        }, 500);
      }
    };
    return {
      loading,
      visible,
      isEmailVerifyPass,
      account,
      handleChange,
      onEmailUpdate,
      validateSignUp,
      signUp
    };
  }
};
</script>
<template>
  <div class="bg-gray-50 flex flex-col justify-center h-screen">
    <smooth-scroll>
      <div class="flex flex-col justify-center">
        <div class="mt-20 flex items-center justify-center flex-shrink-0">
          <router-link tag="a" to="/" class="flex justify-center items-center">
            <svg-icon icon-class="PARQAY-logo" class="h-24 w-full pr-6" />
          </router-link>
        </div>
        <h2 class="mt-2 text-center text-2xl font-bold text-gray-900">
          Sign up as new tenant
        </h2>
        <span class="mt-2 text-center text-sm text-gray-600">
          If you are a host, please sign-in at your
          <router-link tag="a" to="/login" class="font-medium text-parqay-primary hover:text-blue-500">
            Online Portal
          </router-link>
        </span>
      </div>

      <div class="my-4 px-3 sm:mx-auto sm:w-full sm:max-w-2xl mb-24 md:mb-auto">
        <div class="bg-white rounded-lg shadow-sm py-6 border">
          <h3 class="hidden md:block font-medium text-base text-black border-b pb-4 pl-4">
            Enter required fields below:
          </h3>
          <div class="py-2 md:py-6 grid gap-x-8 gap-y-4 grid-cols-1 md:grid-cols-2 px-6">
            <label-item>
              <a-input v-model="account.first_name" placeholder="First Name" size="large">
                <svg-icon slot="prefix" icon-class="user-solid" class="h-4 w-4 text-gray-400" />
              </a-input>
            </label-item>
            <label-item>
              <a-input v-model="account.last_name" placeholder="Last Name" size="large">
                <svg-icon slot="prefix" icon-class="user-solid" class="h-4 w-4 text-gray-400" />
              </a-input>
            </label-item>
            <label-item>
              <a-input v-model="account.email" placeholder="Email" size="large" @change="onEmailUpdate">
                <svg-icon slot="prefix" icon-class="mail" class="h-4 w-4 text-gray-400" />
                <a-button v-if="loading.verify" slot="suffix" type="link" loading />
                <a-button v-if="isEmailVerifyPass === true" slot="suffix" type="link" icon="check-circle" />
                <a-button v-if="isEmailVerifyPass === false" slot="suffix" type="link" icon="close-circle"
                  style="color: red;" />
              </a-input>
            </label-item>
            <label-item>
              <a-input v-model="account.phone_number" placeholder="Phone Number" size="large" @click="e => {
                handleChange('changePhoneNumber');
                e.target.blur();
              }">
                <svg-icon slot="prefix" icon-class="phone" class="h-4 w-4 text-gray-400" />
                <a-button slot="suffix" type="primary" @click="handleChange('changePhoneNumber')">
                  {{ account.phone_number ? "Change" : "Add" }}
                </a-button>
              </a-input>
            </label-item>
            <label-item>
              <a-input-password v-model="account.password" placeholder="Password" size="large">
                <svg-icon slot="prefix" icon-class="lock" class="h-4 w-4 text-gray-400" />
              </a-input-password>
            </label-item>
          </div>
          <div class="hidden md:flex justify-between bg-white py-2 px-8">
            <a-button size="large" @click="$router.push('/login/tenant')">
              Login Instead
            </a-button>
            <a-button type="primary" size="large" :disabled="!validateSignUp()" :loading="loading.signin"
              @click="signUp()">
              Sign Up
            </a-button>
          </div>
          <div class="md:hidden bg-white py-2 px-6 space-y-4">
            <a-button type="primary" size="large" block :disabled="!validateSignUp()" :loading="loading.signin"
              @click="signUp()">
              Sign Up
            </a-button>
            <a-button type="link" size="large" block @click="$router.push('/login/tenant')">
              Login Instead
            </a-button>
          </div>
          <div class="mt-2">
            <div class="relative">
              <div class="absolute inset-0 flex items-center">
                <div class="w-full border-t border-gray-300" />
              </div>
              <div class="relative flex justify-center text-sm">
                <span class="px-2 bg-white text-gray-500">
                  Or continue with
                </span>
              </div>
            </div>

            <social-logins :loading="loading" tenant class="px-6" />
          </div>
        </div>
      </div>
    </smooth-scroll>
    <a-modal v-model="visible.changePhoneNumber" :footer="false" class="bg-gray-400">
      <phone-number-manage-card class="px-6 py-6" @verified="
        payload => {
          visible.changePhoneNumber = false;
          const { phone_number, subscribe } = payload;
          account.phone_number = phone_number;
          account.subscribe = [...subscribe, ...account.subscribe];
        }
      " />
    </a-modal>
  </div>
</template>

<style lang="scss" scoped>
.ant-btn {
  @apply text-sm;
}
</style>
