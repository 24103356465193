<script>
import { ref, onMounted } from "@vue/composition-api";
// import "tui-calendar/dist/tui-calendar.css";
// import "tui-date-picker/dist/tui-date-picker.css";
// import "tui-time-picker/dist/tui-time-picker.css";
import { Calendar } from "@toast-ui/vue-calendar";
import dayjs from "dayjs";
import ClickOutside from "vue-click-outside";

export default {
  name: "RCalendar",
  components: {
    Calendar,
  },
  directives: {
    ClickOutside,
  },
  props: {
    model: {
      type: Array,
      default: null,
    },
  },
  setup(props, ctx) {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const showMonthSelect = ref(false);

    const getCurrentMonth = () => {
      var mm = dayjs();
      var dt = mm.toDate();
      var monthName = months[dt.getMonth()];
      return {
        name: monthName + " " + dt.getFullYear(),
        ts: mm.format(),
        dt: dt,
      };
    };

    const selectedMonth = ref({});

    selectedMonth.value = getCurrentMonth();

    const getLatestMonths = () => {
      var monthsList = [];
      for (var i = -5; i < 6; i++) {
        var mm = dayjs().add(i, "months");
        var nextDt = mm.toDate();
        var nextM = nextDt.getMonth();
        var nextY = nextDt.getFullYear();
        var monthName = months[nextM];
        monthsList.push({
          name: monthName + " " + nextY,
          ts: mm.format(),
          dt: nextDt,
        });
      }
      console.log(monthsList);
      return monthsList;
    };

    const latestMonths = ref([]);

    latestMonths.value = getLatestMonths();

    const close = () => {
      showMonthSelect.value = false;
    };

    const tuiCalendar = ref(null);

    const setSelectedMonth = (m) => {
      selectedMonth.value = m;
      tuiCalendar.value.invoke("setDate", m.dt);
      close();
    };

    const setToday = (m) => {
      selectedMonth.value = getCurrentMonth();
      tuiCalendar.value.invoke("setDate", selectedMonth.value.dt);
      close();
    };

    const prev = () => {
      tuiCalendar.value.invoke("prev");
    };

    const next = () => {
      tuiCalendar.value.invoke("next");
    };

    const calendarList = ref([
      {
        id: "0",
        name: "home",
      },
      {
        id: "1",
        name: "office",
      },
    ]);

    const colorRandomizer = (i) => {
      var pool = [
        {
          color: "white",
          bgColor: "#6B8BFD",
        },
        {
          color: "white",
          bgColor: "#FD9827",
        },
        {
          color: "white",
          bgColor: "#6740B4",
        },
        {
          color: "white",
          bgColor: "#50AE55",
        },
        {
          color: "white",
          bgColor: "#757575",
        },
        {
          color: "white",
          bgColor: "#4154B3",
        },
      ];
      if (i > pool.length - 1) {
        var p = (i % pool.length) - 1;
        return pool[p];
      } else {
        return pool[i];
      }
    };

    const parseScheduleFromModel = (m) => {
      var sl = [];
      m.map((it, i) => {
        var colorD = colorRandomizer(i);
        sl.push({
          id: i,
          calendarId: i,
          title: `${it.name} - ${it.listing.name}`,
          category: "time",
          dueDateClass: "",
          start: it.duration.from,
          end: it.duration.to,
          color: colorD.color,
          bgColor: colorD.bgColor,
          dragBgColor: "rgba(129, 140, 248, 1)",
        });
      });
      return sl;
    };

    onMounted(() => {});

    const scheduleList = ref([]);
    scheduleList.value = parseScheduleFromModel(props.model);

    const view = ref("month");
    const toggleViewMode = (vm) => {
      view.value = vm;
    };
    const taskView = ref(false);
    const scheduleView = ref(["time"]);
    const theme = ref({
      "month.dayname.height": "30px",
      "month.dayname.borderLeft": "1px solid #ff0000",
      "month.dayname.textAlign": "center",
      "week.today.color": "#333",
      "week.daygridLeft.width": "100px",
      "week.timegridLeft.width": "100px",
    });
    const week = ref({
      narrowWeekend: false,
      showTimezoneCollapseButton: true,
      timezonesCollapsed: false,
    });
    const month = ref({
      visibleWeeksCount: 6,
      startDayOfWeek: 7,
    });
    const timezones = ref([
      //{
      //  timezoneOffset: 540,
      //  displayLabel: "GMT+09:00",
      //  tooltip: "Seoul",
      //},
      {
        timezoneOffset: -420,
        displayLabel: "GMT-08:00",
        tooltip: "Los Angeles",
      },
    ]);
    const disableDblClick = ref(true);
    const isReadOnly = ref(false);
    const template = ref({
      milestone: function (schedule) {
        return `<span style="color:red;">${schedule.title}</span>`;
      },
      milestoneTitle: function () {
        return "MILESTONE";
      },
    });
    const useCreationPopup = ref(true);
    const useDetailPopup = ref(true);
    return {
      tuiCalendar,
      showMonthSelect,
      selectedMonth,
      getLatestMonths,
      latestMonths,
      calendarList,
      setSelectedMonth,
      setToday,
      prev,
      next,
      close,
      scheduleList,
      view,
      toggleViewMode,
      taskView,
      scheduleView,
      theme,
      week,
      month,
      timezones,
      disableDblClick,
      isReadOnly,
      template,
      useCreationPopup,
      useDetailPopup,
    };
  },
};
</script>
<template>
  <div class="flex flex-col h-full pb-4">
    <div class="shadow-sm bg-white border rounded-lg py-4 flex flex-col">
      <div class="flex px-4 pb-4 space-x-4">
        <div
          v-click-outside="close"
          class="relative inline-block text-left w-48"
        >
          <div>
            <button
              id="options-menu"
              type="button"
              class="inline-flex justify-between w-full rounded border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
              aria-haspopup="true"
              aria-expanded="true"
              @click="showMonthSelect = !showMonthSelect"
            >
              {{ selectedMonth.name }}
              <svg-icon
                icon-class="chevron-down"
                class="-mr-1 ml-2 h-5 w-5"
              />
            </button>
          </div>
          <div
            v-show="showMonthSelect"
            class="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
          >
            <div
              class="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <span
                v-for="m in latestMonths"
                :key="m.name"
                class="block px-4 py-2 h-10 font-medium cursor-pointer text-gray-700 hover:bg-parqay-primary hover:text-white"
                role="menuitem"
                @click="setSelectedMonth(m)"
              >{{ m.name }}</span>
            </div>
          </div>
        </div>
        <div
          class="flex-0 rounded-full border bg-gray-50 w-40 h-10 cursor-pointer"
        >
          <div
            class="w-1/2 border-r h-full inline-flex flex justify-center hover:text-blue-700"
            :class="{ 'text-parqay-primary': view == 'month' }"
            @click="toggleViewMode('month')"
          >
            <span class="text-center w-full my-auto">Month</span>
          </div>
          <div
            class="w-1/2 inline-flex flex justify-center hover:text-blue-700"
            :class="{ 'text-parqay-primary': view == 'day' }"
            @click="toggleViewMode('day')"
          >
            <span class="text-center w-full my-auto">Day</span>
          </div>
        </div>
        <template>
          <div
            class="flex-0 rounded-full border bg-gray-50 w-10 h-10 cursor-pointer"
          >
            <div
              class="w-full h-full inline-flex flex justify-center hover:text-parqay-primary"
              @click="prev"
            >
              <svg-icon
                icon-class="chevron-left"
                class="h-5 w-5 hover:text-parqay-primary my-auto"
              />
            </div>
          </div>
          <div
            class="flex-0 rounded-full border bg-gray-50 w-10 h-10 cursor-pointer"
          >
            <div
              class="w-full h-full inline-flex flex justify-center hover:text-parqay-primary"
              @click="next"
            >
              <svg-icon
                icon-class="chevron-right"
                class="h-5 w-5 hover:text-parqay-primary my-auto"
              />
            </div>
          </div>
        </template>
        <div
          class="flex-0 rounded-full border bg-gray-50 w-20 h-10 cursor-pointer"
        >
          <div
            class="w-full h-full inline-flex flex justify-center hover:text-parqay-primary"
            @click="setToday"
          >
            <span class="text-center w-full my-auto">Today</span>
          </div>
        </div>
        <div class="w-5 h-10 cursor-pointer">
          <div class="flex justify-center h-full">
            <svg-icon
              icon-class="question"
              class="h-5 w-5 text-parqay-primary my-auto"
            />
          </div>
        </div>
      </div>
      <calendar
        ref="tuiCalendar"
        style="height: 800px"
        class="border-b"
        :calendars="calendarList"
        :schedules="scheduleList"
        :view="view"
        :task-view="taskView"
        :schedule-view="scheduleView"
        :theme="theme"
        :week="week"
        :month="month"
        :timezones="timezones"
        :disable-dbl-click="disableDblClick"
        :is-read-only="isReadOnly"
        :template="template"
        :use-creation-popup="useCreationPopup"
        :use-detail-popup="useDetailPopup"
      />
    </div>
  </div>
</template>
