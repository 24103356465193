<script>
import PropertyDropdown from "src/views/dropdowns/Property";

export default {
  name: "TeamInvite",
  components: {
    PropertyDropdown
  },
  setup() {
    return {};
  },
};
</script>
<template>
  <div class="py-10 h-full flex flex-col px-10">
    <div class="flex items-center justify-between border-b pb-2">
      <h2 class="font-bold text-xl">
        <router-link
          tag="a"
          to="/dashboard/team"
        >
          Team
        </router-link>
        /Member Invite
      </h2>
      <div class="flex justify-end items-center">
        <p class="font-medium text-base text-gray-690 mx-4 my-auto">
          Property
        </p>
        <property-dropdown />
      </div>
    </div>
    <div class="flex flex-col py-10 px-10 space-y-6">
      <div class="bg-white shadow-sm border rounded-lg py-4 mb-1">
        <h3 class="font-medium text-base leading-10 border-b pb-2 px-4">
          Contractor Invitation Form
        </h3>
        <div class="px-4 py-6 grid gap-x-8 gap-y-4 grid-cols-2">
          <label-item label="First Name">
            <a-input
              placeholder="First Name"
              size="large"
            />
          </label-item>
          <label-item label="Last Name">
            <a-input
              placeholder="Last Name"
              size="large"
            />
          </label-item>
          <label-item label="Company">
            <a-input
              placeholder="Company"
              size="large"
            />
          </label-item>
          <label-item label="Address">
            <a-input
              placeholder="Address"
              size="large"
            />
          </label-item>
          <label-item label="Email">
            <a-input
              placeholder="Email"
              size="large"
            />
          </label-item>
          <label-item label="Phone Number">
            <a-input
              placeholder="Phone Number"
              size="large"
            />
          </label-item>
        </div>
      </div>
      <div
        class="bg-white shadow-sm border rounded-lg py-4 px-4 grid grid-cols-3 gap-4"
      >
        <div class="">
          <h3 class="font-medium text-lg">
            Lease Information
          </h3>
        </div>
        <div class="">
          <div class="h-18 pb-2">
            <h3 class="text-base">
              Start Date
            </h3>
            <a-input placeholder="Start Date" />
          </div>
          <div class="h-18 pb-2">
            <h3 class="text-base">
              End Date
            </h3>
            <a-input placeholder="End Date" />
          </div>
        </div>
        <div class="">
          <div class="h-18 pb-2">
            <h3 class="text-base">
              Rate / hr
            </h3>
            <a-input placeholder="$100.00" />
          </div>
          <div class="h-18 pb-2">
            <h3 class="text-base">
              Contract License #
            </h3>
            <a-input placeholder="ABC12345678" />
          </div>
        </div>
      </div>

      <div class="bg-white shadow-sm border rounded-lg py-4">
        <h3 class="font-medium text-base leading-10 border-b pb-2 px-4">
          Select Documents to include in invite for contractor to sign
        </h3>
        <div class="flex items-center justify-between space-x-4 mx-4">
          <div class="w-1/6 text-center h-20 bg-gray-300 rounded">
            <svg-icon
              class="w-5 h-5 mt-4 text-black"
              icon-class="user"
            />
            <p class="mt-2 text-black">
              Drivers license
            </p>
          </div>
          <div class="w-1/6 text-center h-20 bg-gray-300 rounded">
            <svg-icon
              class="w-5 h-5 mt-4 text-black"
              icon-class="user"
            />
            <p class="mt-2 text-black">
              Registration
            </p>
          </div>
          <div class="w-1/6 text-center h-20 bg-gray-300 rounded">
            <svg-icon
              class="w-5 h-5 mt-4 text-black"
              icon-class="user"
            />
            <p class="mt-2 text-black">
              Insurance
            </p>
          </div>
          <div class="w-1/6 text-center h-20 bg-gray-300 rounded">
            <svg-icon
              class="w-5 h-5 mt-4 text-black"
              icon-class="user"
            />
            <p class="mt-2 text-black">
              Lease
            </p>
          </div>
          <div class="w-1/6 text-center h-20 bg-gray-300 rounded">
            <svg-icon
              class="w-5 h-5 mt-4 text-black"
              icon-class="user"
            />
            <p class="mt-2 text-black">
              Indeminifcation
            </p>
          </div>
          <div class="w-1/6 text-center h-20 bg-gray-300 rounded">
            <svg-icon
              class="w-5 h-5 mt-4 text-black"
              icon-class="user"
            />
            <p class="mt-2 text-black">
              Background Check
            </p>
          </div>
        </div>
      </div>

      <div
        class="bg-white border shadow-sm rounded-lg flex justify-between py-4 px-8"
      >
        <button
          type="button"
          class="bg-parqay-primary px-4 py-2 text-white rounded"
        >
          Cancel
        </button>
        <button
          type="button"
          class="bg-parqay-primary px-4 py-2 text-white rounded"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>
