import { render, staticRenderFns } from "./ExtraInfoModal.vue?vue&type=template&id=4ca970bc&"
import script from "./ExtraInfoModal.vue?vue&type=script&lang=js&"
export * from "./ExtraInfoModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports