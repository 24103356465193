<script>
import { ref } from "@vue/composition-api";
import { Tokens, Urls } from "services/constants";

export default {
  props: {
    loginLink: {
      type: String,
      required: true,
    },
    layout: {
      type: String,
      default: "v",
    },
  },
  setup(props, ctx) {
    const popUpBlockMessage = ref(null);
    const navigateToStripeDashboard = (toAccount = false) => {
      if (props.loginLink) {
        var loginLink = props.loginLink;
        if (toAccount) {
          loginLink = loginLink + "#/account";
        }
        var popUp = window.open(loginLink, "_blank");
        if (popUp == null || typeof popUp == "undefined") {
          popUpBlockMessage.value =
            "Looks like your browser is blocking our navigation toward Stripe, \
        please allow the pop up so you will be able to see how much you have earned.";
        } else {
          popUpBlockMessage.value =
            "Please view your payout details in another tab";
          popUp.focus();
        }
      }
    };
    return {
      popUpBlockMessage,
      navigateToStripeDashboard,
    };
  },
};
</script>
<template>
  <div class="px-4" :class="layout == 'v' ? 'py-8' : 'py-2'">
    <div v-if="popUpBlockMessage" class="border border-parqay-primary rounded-lg py-4 px-4 text-parqay-primary bg-white">
      {{ popUpBlockMessage }}
    </div>
    <div class="flex justify-center items-center" :class="layout == 'v' ? 'flex-col space-y-2' : 'space-x-4'">
      <button type="button"
        class="px-4 py-2 font-medium rounded-md text-white bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-300 focus:outline-none focus:border-blue-500 focus:shadow-outline-indigo active:bg-blue-500 transition ease-in-out duration-150"
        @click="navigateToStripeDashboard()">
        <div class="flex items-center space-x-2">
          <span>Check Earnings</span>
          <svg-icon icon-class="stripe" class="h-6 w-12 text-white" />
        </div>
      </button>
      <button type="button"
        class="px-4 py-2 font-medium rounded-md text-white bg-parqay-primary hover:bg-blue-500 focus:outline-none focus:border-blue-500 focus:shadow-outline-indigo active:bg-blue-500 transition ease-in-out duration-150"
        @click="navigateToStripeDashboard(true)">
        <span>Update Payout Bank/Card</span>
      </button>
      <slot name="more-action" />
    </div>
  </div>
</template>