import request from "src/services/api/request";

export const API = {
  listSchedules() {
    return request.get("events/schedule");
  },
  listPricingRules() {
    return request.get("events/pricing-rules");
  },
};
