<script>
export default {
  setup() {
    const navToAppLink = (sv) => {
      var link = "";
      if (sv == "android") {
        link = "https://play.google.com/store/apps/details?id=com.parqyt.android";
      }
      if (sv == "ios") {
        link = "https://itunes.apple.com/us/app/parqyt-parking/id1135271249?mt=8";
      }
      window.open(link, "_blank");
    };
    return {
      navToAppLink,
    };
  },
};
</script>
<template>
  <div class="flex flex-col space-y-4 md:max-w-lg">
    <div class="flex items-center justify-center space-x-2">
      <span class="text-base text-center font-semibold">
        Download our App
      </span>
      <svg-icon icon-class="external-link" class="w-5 h-5 text-parqay-primary" />
    </div>
    <div v-if="$isDesktopView()" class="grid grid-cols-2 gap-4 px-16 h-12">
      <div
        class="
          flex
          cursor-pointer
          hover:opacity-75
          justify-center
          h-12
        "
        @click="navToAppLink('android')"
      >
        <img
          class="object-contain rounded-xl -m-1"
          height="60"
          width="160"
          src="/static/img/google_play_store.png"
        />
      </div>
      <div
        class="
          flex
          cursor-pointer
          hover:opacity-75
          justify-center
          h-12
        "
        @click="navToAppLink('ios')"
      >
        <img
          class="object-contain rounded-xl -m-1"
          height="60"
          width="160"
          src="/static/img/Apple App Store Badge@2x.png"
        />
      </div>
    </div>
    <div v-else class="flex justify-between space-x-2">
      <div
        class="
          flex
          cursor-pointer
          hover:opacity-75
          justify-center
          h-12
        "
        @click="navToAppLink('android')"
      >
        <img
          class="object-contain rounded-xl -m-1"
          height="60"
          width="160"
          src="/static/img/google_play_store.png"
        />
      </div>
      <div
        class="
          flex
          cursor-pointer
          hover:opacity-75
          justify-center
          h-12
        "
        @click="navToAppLink('ios')"
      >
        <img
          class="object-contain rounded-xl -m-1"
          height="60"
          width="160"
          src="/static/img/Apple App Store Badge@2x.png"
        />
      </div>
    </div>
  </div>
</template>
