<script>
import { reactive, onMounted, nextTick, onBeforeUnmount } from "@vue/composition-api";
import LeaderLine from "leader-line";
import EventBus from "src/services/util/event-bus";

export default {
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    setup(props, ctx) {
        const controls = reactive({
            visible: {
                autoposterSaveButton: false
            },
            enable_webhook: false
        })
        var arrows = [];
        const refreshArrowConnections = () => {
            arrows.map((arrow, index) => {
                arrow.position();
            });
        }
        const initArrows = () => {
            nextTick(() => {
                const fromEl = document.querySelector(".automation-from");
                const toEl = document.querySelector(".automation-to");
                const action1El = document.querySelector(".automation-action-1");
                const action2El = document.querySelector(".automation-action-2");
                const action3El = document.querySelector(".automation-action-3");
                const action4El = document.querySelector(".automation-action-4");
                const action5El = document.querySelector(".automation-action-5");
                if (fromEl && toEl) {
                    arrows.push(
                        new LeaderLine(fromEl, toEl, {
                            dash: { animation: true },
                        })
                    );
                    arrows.push(
                        new LeaderLine(toEl, action1El, {
                            dash: {
                                animation: true,
                                startSocket: "right",
                                endSocket: "left"
                            }
                        })
                    );
                    arrows.push(
                        new LeaderLine(toEl, action2El, {
                            dash: {
                                animation: true,
                                startSocket: "right",
                                endSocket: "left"
                            }
                        })
                    );
                    arrows.push(
                        new LeaderLine(toEl, action3El, {
                            dash: {
                                animation: true,
                                startSocket: "right",
                                endSocket: "left"
                            }
                        })
                    );
                    arrows.push(
                        new LeaderLine(toEl, action4El, {
                            dash: {
                                animation: true,
                                startSocket: "right",
                                endSocket: "left"
                            }
                        })
                    );
                    arrows.push(
                        new LeaderLine(toEl, action5El, {
                            dash: {
                                animation: true,
                                startSocket: "right",
                                endSocket: "left"
                            }
                        })
                    );
                    console.log("arrows -> ", arrows);
                }
            })
        }
        const removeArrows = () => {
            if (arrows) {
                arrows.map((arrow, index) => {
                    arrow.remove();
                });
                arrows = [];
            }
        }
        const openSlide = () => {
            EventBus.$emit("open-slide-bar", "automation");
        };

        onMounted(() => {
            EventBus.$on("adjust-automation-arrows", refreshArrowConnections);
        });
        nextTick(() => {
            initArrows();
        })
        onBeforeUnmount(() => {
            EventBus.$off("adjust-automation-arrows", refreshArrowConnections);
            removeArrows();
        });
        return {
            controls,
            arrows,
            openSlide
        };
    },
};
</script>
<template>
    <div class="grid grid-cols-12 w-full items-center pb-24" style="min-height: 100vh;">
        <div class="col-start-2 col-end-5">
            <div class="automation-from w-36 h-36 bg-white border-4 rounded-full relative">
                <div class="flex flex-col space-y-2 h-full items-center justify-center text-parqay-primary text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-9 h-9">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                    </svg>

                    <span class="text-sm font-semibold" style="max-width: 6rem;">
                        New Reservation Created
                    </span>
                </div>
            </div>
        </div>
        <div class="col-start-5 col-end-8">
            <div class="automation-to bg-white rounded-full cursor-pointer relative" style="width: 6.5rem; height: 6.5rem;">
                <div class="absolute inset-0 rounded-full border-4 border-orange-400 border-dashed"
                    style="animation: 6s linear 0s infinite none running spin;" />
                <div class="absolute inset-0 flex flex-col space-y-2 h-full items-center justify-center text-orange-500">
                    <svg-icon icon-class="automation" class="w-8 h-8 animate-pulse" />
                    <span class="text-sm font-bold">Actions</span>
                </div>
            </div>
        </div>
        <div class="col-start-8 col-end-12 rounded-xl border-4 border-dashed hover:border-blue-400 cursor-pointer"
            style="min-height: 60%;">
            <div class="flex flex-col space-y-8 h-full bg-white p-4">
                <div class="flex rounded-lg bg-white shadow-md hover:shadow-xl relative">
                    <div
                        class="automation-action-1 absolute top-1/2 -translate-x-2 transform -translate-y-1/2 w-3 h-3 bg-parqay-primary" />
                    <div class="grid grid-cols-4 h-full w-full">
                        <div class="flex border-r py-2 col-span-1 items-center justify-center">
                            <div
                                class="flex flex-shrink-0 items-center justify-center w-16 h-16 rounded-full border-4 border-parqay-primary p-1">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-8 h-8 text-parqay-primary">
                                    <path
                                        d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                    <path
                                        d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                                </svg>
                            </div>
                        </div>
                        <div class="flex flex-col space-y-2 col-span-3 items-center justify-center">
                            <span>Email booking notification to</span>
                            <div class="flex flex-col space-y-2 px-4 pb-4 mt-1">
                                <div class="flex items-center space-x-2">
                                    <div v-if="form.email" class="flex-shrink-0 w-2 h-2 rounded-full bg-green-400" />
                                    <div v-else class="flex-shrink-0 w-2 h-2 rounded-full bg-red-500" />
                                    <span v-if="form.email" class="text-sm font-semibold">{{ form.email }}</span>
                                    <span v-else class="text-sm font-semibold text-red-500">Email: Not Set</span>
                                    <a-tooltip
                                        title="Edit will take you to account settings, this email must always align with your account email.">
                                        <a-icon type="edit"
                                            @click.stop="$router.push({ name: 'HostDashboardAccountSettings' })" />
                                    </a-tooltip>
                                </div>
                                <div class="flex items-center space-x-2">
                                    <div class="flex-shrink-0 w-2 h-2 rounded-full bg-green-400" />
                                    <a-tag color="geekblue">Tenant Email</a-tag>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex rounded-lg bg-white shadow-md hover:shadow-xl relative">
                    <div
                        class="automation-action-2 absolute top-1/2 -translate-x-2 transform -translate-y-1/2 w-3 h-3 bg-parqay-primary" />
                    <div class="grid grid-cols-4 h-full w-full">
                        <div class="flex border-r py-2 col-span-1 items-center justify-center">
                            <div
                                class="flex flex-shrink-0 items-center justify-center w-16 h-16 rounded-full border-4 border-parqay-primary p-1">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-8 h-8 text-parqay-primary">
                                    <path d="M10.5 18.75a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" />
                                    <path fill-rule="evenodd"
                                        d="M8.625.75A3.375 3.375 0 005.25 4.125v15.75a3.375 3.375 0 003.375 3.375h6.75a3.375 3.375 0 003.375-3.375V4.125A3.375 3.375 0 0015.375.75h-6.75zM7.5 4.125C7.5 3.504 8.004 3 8.625 3H9.75v.375c0 .621.504 1.125 1.125 1.125h2.25c.621 0 1.125-.504 1.125-1.125V3h1.125c.621 0 1.125.504 1.125 1.125v15.75c0 .621-.504 1.125-1.125 1.125h-6.75A1.125 1.125 0 017.5 19.875V4.125z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                        </div>
                        <div class="flex flex-col space-y-2 col-span-3 items-center justify-center">
                            <span>Send booking text message to</span>
                            <div class="flex flex-col space-y-2 px-4 pb-4 mt-1">
                                <div class="flex items-center space-x-2">
                                    <div v-if="form.phone_number" class="flex-shrink-0 w-2 h-2 rounded-full bg-green-400" />
                                    <div v-else class="flex-shrink-0 w-2 h-2 rounded-full bg-red-500" />
                                    <span v-if="form.phone_number" class="text-sm font-semibold">{{ form.phone_number
                                    }}</span>
                                    <span v-else class="text-sm font-semibold text-red-500">Phone Number: Not Set</span>
                                    <a-tooltip
                                        title="Edit will take you to account settings, phone number must always align with your account phone number.">
                                        <a-icon type="edit"
                                            @click.stop="$router.push({ name: 'HostDashboardAccountSettings' })" />
                                    </a-tooltip>
                                </div>
                                <div class="flex items-center space-x-2">
                                    <div class="flex-shrink-0 w-2 h-2 rounded-full bg-green-400" />
                                    <a-tag color="geekblue">Tenant Phone Numer</a-tag>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex rounded-lg bg-white shadow-md hover:shadow-xl relative">
                    <div
                        class="automation-action-3 absolute top-1/2 -translate-x-2 transform -translate-y-1/2 w-3 h-3 bg-parqay-primary" />
                    <div class="grid grid-cols-4 h-full w-full">
                        <div class="flex border-r py-2 col-span-1 items-center justify-center">
                            <div
                                class="flex flex-shrink-0 items-center justify-center w-16 h-16 rounded-full border-4 border-parqay-primary p-1">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-6 h-6 text-parqay-primary">
                                    <path
                                        d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                                </svg>
                            </div>
                        </div>
                        <div class="flex flex-col space-y-2 col-span-3 items-center justify-center">
                            <span>Send documents invitation to</span>
                            <div class="flex flex-col space-y-2 px-4 pb-4 mt-1">
                                <div class="flex items-center space-x-2">
                                    <div v-if="form.email" class="flex-shrink-0 w-2 h-2 rounded-full bg-green-400" />
                                    <div v-else class="flex-shrink-0 w-2 h-2 rounded-full bg-red-500" />
                                    <span v-if="form.email" class="text-sm font-semibold">{{ form.email }}</span>
                                    <span v-else class="text-sm font-semibold text-red-500">Email: Not Set</span>
                                    <a-tooltip
                                        title="Edit will take you to account settings, this email must always align with your account email.">
                                        <a-icon type="edit"
                                            @click.stop="$router.push({ name: 'HostDashboardAccountSettings' })" />
                                    </a-tooltip>
                                </div>
                                <div class="flex items-center space-x-2">
                                    <div class="flex-shrink-0 w-2 h-2 rounded-full bg-green-400" />
                                    <a-tag color="geekblue">Tenant Email</a-tag>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex rounded-lg bg-white shadow-md hover:shadow-xl relative">
                    <div
                        class="automation-action-4 absolute top-1/2 -translate-x-2 transform -translate-y-1/2 w-3 h-3 bg-parqay-primary" />
                    <div class="grid grid-cols-4 h-full w-full">
                        <div class="flex border-r py-2 col-span-1 items-center justify-center">
                            <div
                                class="flex flex-shrink-0 items-center justify-center w-16 h-16 rounded-full border-4 border-parqay-primary p-1">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-8 h-8 text-parqay-primary">
                                    <path fill-rule="evenodd"
                                        d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                        </div>
                        <div class="flex flex-col space-y-2 col-span-3 items-center justify-center">
                            <span>Add tenant to</span>
                            <div class="px-4 pb-2">
                                <div class="mt-1 flex items-center space-x-2">
                                    <a-tooltip
                                        title="&quot;Auto-add&quot; feature automatically add tenants of incoming reservations to your tenants list">
                                        <div class="flex items-center space-x-2 pr-2 cursor-pointer">
                                            <div class="w-2 h-2 bg-green-400 rounded-full" />
                                            <span class="text-xs font-semibold">Auto-add</span>
                                            <img src="/static/img/automatic-flash.png" class="w-5 h-5 object-contain">
                                        </div>
                                    </a-tooltip>
                                    <a-tag color="geekblue">Tenants List</a-tag>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex rounded-lg bg-white shadow-md hover:shadow-xl relative">
                    <div
                        class="automation-action-5 absolute top-1/2 -translate-x-2 transform -translate-y-1/2 w-3 h-3 bg-parqay-primary" />
                    <div class="grid grid-cols-4 h-full w-full">
                        <div class="flex border-r py-2 col-span-1 items-center justify-center">
                            <div
                                class="flex flex-shrink-0 items-center justify-center w-16 h-16 rounded-full border-4 border-parqay-primary p-1">
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192.000000 192.000000"
                                    preserveAspectRatio="xMidYMid meet" class="w-9 h-9 text-parqay-primary">
                                    <g transform="translate(0.000000,192.000000) scale(0.100000,-0.100000)"
                                        fill="currentColor" stroke="none">
                                        <path d="M855 1855 c-128 -25 -233 -98 -308 -213 -112 -172 -105 -375 20 -546
63 -87 184 -163 285 -181 63 -10 70 -24 68 -139 -3 -163 -5 -153 38 -160 97
-18 165 -60 210 -130 23 -36 26 -51 26 -128 0 -68 -3 -91 -17 -106 -9 -10 -17
-23 -17 -29 0 -17 -66 -70 -113 -92 -144 -66 -320 30 -342 186 -4 31 -14 58
-26 69 -18 16 -20 16 -40 -2 -57 -52 41 -251 152 -309 116 -60 265 -47 363 33
71 57 126 167 126 252 0 86 -69 220 -138 267 -40 26 -101 53 -123 53 -27 0
-39 38 -39 125 0 84 10 101 68 110 120 20 263 123 317 229 8 17 19 38 24 46
43 75 54 234 23 330 -56 177 -196 302 -377 337 -81 15 -98 15 -180 -2z m165
-268 c49 -17 72 -35 108 -86 29 -40 32 -51 32 -116 0 -65 -3 -76 -32 -116 -51
-71 -99 -97 -178 -97 -79 0 -127 26 -178 97 -29 40 -32 51 -32 116 0 65 3 76
32 116 17 24 41 51 52 59 53 38 134 49 196 27z" />
                                        <path d="M867 1499 c-75 -57 -76 -169 -1 -228 20 -16 41 -21 84 -21 43 0 64 5
84 21 76 60 74 173 -3 228 -40 29 -125 28 -164 0z" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div class="flex flex-col space-y-2 col-span-3 items-center justify-center">
                            <span>Webhook: Make POST request to</span>
                            <div class="flex items-center space-x-2 px-4">
                                <div v-if="controls.enable_webhook" class="w-2 h-2 rounded-full bg-green-400" />
                                <div v-else class="flex-shrink-0 w-2 h-2 rounded-full bg-pink-500" />
                                <span class="text-sm"
                                    :class="controls.enable_webhook ? 'font-semibold' : 'text-pink-500 font-medium'">{{
                                        controls.enable_webhook ? "Active" : "Disabled" }}</span>
                                <a-tooltip title="Available Soon">
                                    <a-switch v-model="controls.enable_webhook" disabled />
                                </a-tooltip>
                            </div>
                            <div class="px-4 pb-2">
                                <div class="flex items-center space-x-2">
                                    <a-tooltip title="Available Soon">
                                        <a-input placeholder="api.domain.com/webhook" allow-clear size="large" disabled
                                            class="icon-email-input">
                                            <a-icon type="link" slot="prefix" />
                                        </a-input>
                                    </a-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.icon-email-input::v-deep {
    input {
        padding-left: 36px !important;
    }
}
</style>