<script>
import {
  ref,
  reactive,
  onMounted,
  onBeforeUnmount,
  watch
} from "@vue/composition-api";
import EventBus from "src/services/util/event-bus";
import PhoneNumberManageCard from "src/views/auth/components/Step3.vue";
import EmailManageCard from "src/views/auth/components/Step2.vue";
import { API } from "src/views/auth/api";

import { loadUserData, saveUserData, authCallback } from "src/plugins/prototype";

export default {
  components: { PhoneNumberManageCard, EmailManageCard },
  setup(props, ctx) {
    const form = reactive({
    });
    const controls = reactive({
      key: 0,
      loading: false,
      visible: {
        main: false,
        email: false,
        phone_number: false
      },
      missingFields: [],
      occupationSelect: [
        {
          name: "Property Manager",
          image: "/static/img/dashboard/occupation-property-manager.png",
          checked: false
        },
        {
          name: "Home Owner",
          image: "/static/img/dashboard/occupation-home-owner.png",
          checked: false
        },
        {
          name: "Parking Operator",
          image: null,
          checked: false
        },
        {
          name: "Coworking Space",
          image: "/static/img/dashboard/occupation-doctor.png",
          checked: false
        },
        {
          name: "Hotel",
          image: "/static/img/dashboard/occupation-student.png",
          checked: false
        },
        {
          name: "Service Provider",
          image: null,
          checked: false
        },
        // "Other" is always the last element
        {
          name: "Other",
          image: null,
          checked: false,
          value: null
        }
      ],
      subscribe: [],
      isLegacyUser: true
    });
    const otherInput = ref(null);
    watch(() => controls.occupationSelect, (val) => {
      form.occupation = val.filter(v => v.checked).map(v => {
        if (v.name === "Other") {
          return `Other:${v.value}`
        } else {
          return v.name
        }
      }).join(", ")
    }, {
      deep: true
    })
    const handleChange = k => {
      controls.visible[k] = !controls.visible[k];
    };
    const openExtraInfo = () => {
      const { extra, legacy } = loadUserData();
      console.log("missing fields: ", extra);
      controls.missingFields = extra;
      Object.assign(form, {});
      controls.missingFields.forEach(v => {
        const k = v.replace("optional:", "");
        ctx.root.$set(form, k, k === 'subscribe' ? [] : null);
      })
      controls.visible.main = true;
      controls.isLegacyUser = legacy === true;
    };
    const toggleOccupation = (i, target = null) => {
      if (i == -1) {
        // "Other"'s index
        if (target == null) {
          controls.occupationSelect.slice(
            -1
          )[0].checked = !controls.occupationSelect.slice(-1)[0].checked;
          // Focus on input if click "Other" first
          otherInput.value.focus();
        } else {
          if (target == true) {
            controls.occupationSelect.slice(-1)[0].checked = true;
          } else {
            if (controls.occupationSelect.slice(-1)[0].value) {
              controls.occupationSelect.slice(-1)[0].checked = true;
            } else {
              controls.occupationSelect.slice(-1)[0].checked = false;
            }
          }
        }
      } else {
        controls.occupationSelect[i].checked = !controls.occupationSelect[i]
          .checked;
        if (target) {
          controls.occupationSelect[i].checked = target;
        }
      }
    };
    const fullfillProfile = async () => {
      controls.loading = true;
      try {
        const { data } = await API.fullfillProfile({
          source: "dashboard",
          ...form,
          subscribe: controls.subscribe.join(', '),
          missing_fields: controls.missingFields.filter(v => !v.startsWith("optional:"))
        });
        saveUserData(data, true);
        authCallback();
      } catch (error) {
        console.warn(error);
        return;
      } finally {
        controls.loading = false;
      }
      controls.visible.main = false;
      setTimeout(() => {
        ctx.root.$router.push("/dashboard")
      }, 500);
    };
    const shouldDisableComplete = () => {
      for (const field of controls.missingFields.filter(v => !v.startsWith("optional:"))) {
        if (!form[field]) {
          return true;
        }
      }
      return false;
    };
    const updateCheckbox = (identifier, e) => {
      console.log("updateCheckbox: ", e);
      const subIndex = controls.subscribe.findIndex(v => v === identifier);
      if (e.target.checked) {
        if (subIndex < 0) {
          controls.subscribe.push(identifier)
        }
      } else {
        if (subIndex >= 0) {
          ctx.root.$delete(controls.subscribe, subIndex);
        }
      }
    };
    onMounted(() => {
      EventBus.$on("open-auth-extra-info", openExtraInfo);
    });
    onBeforeUnmount(() => {
      EventBus.$off("open-auth-extra-info", openExtraInfo);
    });
    return {
      form,
      controls,
      otherInput,
      handleChange,
      toggleOccupation,
      fullfillProfile,
      shouldDisableComplete,
      updateCheckbox
    };
  }
};
</script>
<template>
  <a-modal v-model="controls.visible.main" width="60%" :mask-style="{ backgroundColor: 'rgba(0, 0, 0, 0.35)' }"
    :mask-closable="false" :footer="null" :closable="false" @cancel="() => (controls.visible.main = false)">
    <div class="mt-6 flex flex-col bg-white border rounded-xl px-6 py-4 space-y-6">
      <div v-if="controls.isLegacyUser" class="flex flex-col space-y-2 items-center">
        <h2 class="text-center text-lg font-semibold text-parqay-primary">
          Upgraded Service Requires Further Information
        </h2>
        <span class="text-base font-light text-center text-gray-600">
          Welcome back, our brand new dashboard requires following information to provide much more powerful service
          than before
        </span>
      </div>
      <div v-else class="flex flex-col space-y-2 items-center">
        <h2 class="text-center text-lg font-semibold text-parqay-primary">
          Further Infomation Required
        </h2>
        <span class="text-base font-light text-center text-gray-600">
          Before you start, we need following information
          to provide perfect services
        </span>
      </div>
      <div class="flex justify-center items-center">
        <a class="flex space-x-2 items-center border border-black rounded-full px-3 py-2">
          <span class="text-base font-medium text-black">
            Welcome
            {{ $deepGet($loadUserData(), "first_name") ? ", " : "" }}
            {{ $deepGet($loadUserData(), "first_name", "") }}
          </span>
          <svg-icon v-if="$deepGet($loadUserData(), 'oauth_provider') === 'facebook'" icon-class="facebook"
            class="w-8 h-8 text-blue-500" />
          <svg-icon v-if="$deepGet($loadUserData(), 'oauth_provider') === 'apple'" icon-class="apple"
            class="w-8 h-8 text-black" />
          <svg-icon v-if="$deepGet($loadUserData(), 'oauth_provider') === 'google'" icon-class="google"
            class="w-8 h-8" />
        </a>
      </div>
      <div class="grid grid-cols-2 gap-x-4 gap-y-4">
        <a-input v-if="controls.missingFields.map(v => v.replace('optional:', '')).includes('first_name')"
          v-model="form.first_name"
          :placeholder="`First Name` + ((controls.missingFields.find(v => v.includes('first_name')) || '').startsWith('optional:') ? ' (Optional)' : '')"
          size="large">
          <svg-icon slot="prefix" icon-class="user" class="h-4 w-4 text-gray-400" />
        </a-input>
        <a-input v-if="controls.missingFields.map(v => v.replace('optional:', '')).includes('last_name')"
          v-model="form.last_name"
          :placeholder="`Last Name` + ((controls.missingFields.find(v => v.includes('last_name')) || '').startsWith('optional:') ? ' (Optional)' : '')"
          size="large">
          <svg-icon slot="prefix" icon-class="user" class="h-4 w-4 text-gray-400" />
        </a-input>
        <a-input v-if="controls.missingFields.map(v => v.replace('optional:', '')).includes('email')"
          v-model="form.email"
          :placeholder="`Email` + ((controls.missingFields.find(v => v.includes('email')) || '').startsWith('optional:') ? ' (Optional)' : '')"
          size="large" @click="
            e => {
              handleChange('email');
              e.target.blur();
            }
          ">
          <svg-icon slot="prefix" icon-class="mail" class="h-4 w-4 text-gray-400" />
          <button slot="suffix" class="
                px-4
                py-1
                text-white
                bg-parqay-primary
                rounded
                hover:bg-blue-500
              " @click="handleChange('email')">
            {{ form.email ? "Change" : "Add" }}
          </button>
        </a-input>
        <a-input v-if="controls.missingFields.map(v => v.replace('optional:', '')).includes('phone_number')"
          v-model="form.phone_number"
          :placeholder="`Phone Number` + ((controls.missingFields.find(v => v.includes('phone_number')) || '').startsWith('optional:') ? ' (Optional)' : '')"
          size="large" @click="
            e => {
              handleChange('phone_number');
              e.target.blur();
            }
          ">
          <svg-icon slot="prefix" icon-class="phone" class="h-4 w-4 text-gray-400" />
          <button slot="suffix" class="
                px-4
                py-1
                text-white
                bg-parqay-primary
                rounded
                hover:bg-blue-500
              " @click="handleChange('phone_number')">
            {{ form.phone_number ? "Change" : "Add" }}
          </button>
        </a-input>

        <div v-if="controls.missingFields.map(v => v.replace('optional:', '')).includes('occupation')"
          class="col-span-2 flex flex-col space-y-4">
          <span class="text-base text-black font-medium">Select the <span class="text-blue-700">best way</span> to
            describe your occupation</span>
          <div class="rounded-2xl shadow-sm border bg-white hover:border-blue-300 cursor-pointer">
            <div class="grid grid-cols-3 gap-4 p-4">
              <template v-for="(occupation, i) in controls.occupationSelect">
                <div v-if="occupation.name != 'Other'" :key="`occupation-${i}`"
                  class="px-4 py-2 rounded-xl border hover:border-blue-300 hover:bg-blue-50"
                  @click="toggleOccupation(i)">
                  <div class="flex space-x-2 justity-center items-center">
                    <img v-if="!occupation.checked" :src="
                      occupation.image ||
                        '/static/img/dashboard/occupation-placeholder.svg'
                    " class="w-12 h-12 object-fit rounded-full bg-gray-200" />
                    <div v-else class="w-12 h-12 bg-parqay-primary rounded-full">
                      <svg-icon icon-class="solid-check" class="w-12 h-12 text-white" />
                    </div>
                    <span class="text-md font-medium text-center" :class="{
                      'text-pink-600': occupation.checked,
                      'text-parqay-primary': !occupation.checked
                    }">{{ occupation.name }}</span>
                  </div>
                </div>
              </template>

              <div class="grid grid-cols-2 col-span-2 gap-4">
                <div class="px-4 py-2 rounded-xl border hover:border-blue-300 hover:bg-blue-50"
                  @click="toggleOccupation(-1)">
                  <div class="flex space-x-2 justity-center items-center">
                    <img v-if="!controls.occupationSelect.slice(-1)[0].checked" :src="
                      controls.occupationSelect.slice(-1)[0].image ||
                        '/static/img/dashboard/occupation-placeholder.svg'
                    " class="w-12 h-12 object-fit rounded-full bg-gray-200" />
                    <div v-else class="w-12 h-12 bg-parqay-primary rounded-full">
                      <svg-icon icon-class="solid-check" class="w-12 h-12 text-white" />
                    </div>
                    <span class="text-md font-medium text-center" :class="{
                      'text-pink-600': controls.occupationSelect.slice(-1)[0]
                        .checked,
                      'text-parqay-primary': !controls.occupationSelect.slice(
                        -1
                      )[0].checked
                    }">Other</span>
                  </div>
                </div>
                <input id="other-occupation" ref="otherInput" v-model="controls.occupationSelect.slice(-1)[0].value"
                  type="text" name="other-occupation"
                  class="text-md font-medium text-center bg-gray-200 hover:border-indigo-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full h-16 sm:text-sm border-gray-200 rounded-full"
                  placeholder="Please specify" aria-describedby="other-occupation" @focus="toggleOccupation(-1, true)"
                  @blur="toggleOccupation(-1, false)" />
              </div>
            </div>
          </div>
        </div>

        <a-input v-if="controls.missingFields.map(v => v.replace('optional:', '')).includes('address')"
          v-model="form.address" placeholder="Address" size="large">
          <svg-icon slot="prefix" icon-class="home" class="h-4 w-4 text-gray-400" />
        </a-input>

        <template v-if="controls.missingFields.map(v => v.replace('optional:', '')).includes('subscribe')">
          <div class="col-span-2 flex flex-col space-y-4">
            <span class="text-base text-black font-medium">Email Settings</span>
            <div class="flex space-x-2">
              <a-checkbox :checked="$deepGet(controls, 'subscribe', []).includes('email_news_letter')"
                @change="e => (updateCheckbox('email_news_letter', e))">
                Subscribe to Parqay newsletter to receive tips, host stories,
                popular locations & events to increase your hosting income
              </a-checkbox>
            </div>
          </div>

          <div class="col-span-2 flex flex-col space-y-4">
            <span class="text-base text-black font-medium">SMS Settings</span>
            <div class="flex space-x-2">
              <a-checkbox :checked="$deepGet(controls, 'subscribe', []).includes('sms_booking_notification')"
                @change="e => (updateCheckbox('sms_booking_notification', e))">
                Receive incoming booking notifications
              </a-checkbox>
            </div>
            <div class="flex space-x-2">
              <a-checkbox :checked="$deepGet(controls, 'subscribe', []).includes('sms_guest_message')"
                @change="e => (updateCheckbox('sms_guest_message', e))">
                Receive guest messages
              </a-checkbox>
            </div>
            <div class="flex space-x-2">
              <a-checkbox :checked="$deepGet(controls, 'subscribe', []).includes('sms_alert')"
                @change="e => (updateCheckbox('sms_alert', e))">
                Receive alerts
              </a-checkbox>
            </div>
          </div>
        </template>
      </div>
      <div class="flex items-center justify-between pt-6">
        <a-button size="large" class="flex items-center justify-center px-4 space-x-2" :disabled="false"
          @click="$openIntercom(true)">
          <svg-icon icon-class="chat" class="w-5 h-5" />
          Questions? Ask Us!
        </a-button>
        <a-button type="primary" size="large" :loading="controls.loading"
          class="px-6 py-4 flex items-center justify-center" :disabled="shouldDisableComplete()"
          @click="fullfillProfile()">
          Complete Sign Up
        </a-button>
      </div>
      <a-modal :key="`phone-number-modal-${controls.key}`" v-model="controls.visible.phone_number" :footer="false"
        class="bg-gray-400">
        <phone-number-manage-card modal-mode class="px-6 py-6" @verified="
          payload => {
            controls.visible.phone_number = false;
            const { phone_number, subscribe } = payload;
            form.phone_number = phone_number;
            controls.subscribe = [...controls.subscribe, ...subscribe];
            controls.key += 1;
          }
        " />
      </a-modal>

      <a-modal :key="`email-modal-${controls.key}`" v-model="controls.visible.email" :footer="false"
        class="bg-gray-400">
        <email-manage-card modal-mode class="px-6 py-6" @verified="
          payload => {
            controls.visible.email = false;
            const { email, subscribe } = payload;
            form.email = email;
            controls.subscribe = [...controls.subscribe, ...subscribe];
            controls.key += 1;
          }
        " />
      </a-modal>
    </div>
  </a-modal>
</template>
