<script>
import { ref, onMounted } from "@vue/composition-api";
import { API } from "src/views/components/api";
export default {
    setup(props, ctx) {

        const loading = ref(true);

        const isInternalUrl = (url) => {
            const currentHost = window.location.host;
            const targetHost = new URL(url).host;
            return currentHost === targetHost;
        };

        const navigateToUrl = (url) => {
            if (isInternalUrl(url)) {
                // Convert absolute URLs to relative URLs
                const relativeUrl = url.replace(`${window.location.protocol}//${window.location.host}`, '');
                ctx.root.$router.push(relativeUrl);
            } else {
                window.location.replace(url);
            }
        };

        const checkRedirect = async () => {
            loading.value = true;
            try {
                const { data } = await API.checkRedirect(window.location.href);
                navigateToUrl(data.to);
            } catch (error) {
                console.log(error);
                loading.value = false;
            }
        }

        onMounted(() => {
            checkRedirect();
        })

        return {
            loading
        }
    }
}
</script>
<template>
    <div class="bg-white font-sans flex flex-col justify-center items-center h-screen">
        <a-icon v-if="loading" type="loading" style="font-size: 35px; margin-top: -60px;" />
        <template v-else>
            <img src="https://parqay-assets.s3.us-west-2.amazonaws.com/marketplace/no-parking.png" alt="404-not-found"
                class="w-24 h-24 object-contain mb-4">
            <h1 class="text-4xl font-extrabold text-gray-900">404</h1>
            <p class="mt-2 text-lg font-medium text-gray-500">Page not found.</p>
            <div class="mt-6">
                <a class="text-base font-medium text-parqay-primary hover:text-blue-500" @click="$router.push({
                    name: 'AddHostingList'
                })">
                    <span aria-hidden="true"> &plus;</span> Add a new listing
                </a>
            </div>
        </template>
    </div>
</template>