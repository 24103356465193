import request from "src/services/api/request";

export const API = {
  getVehicles(role) {
    switch (role) {
      case "tenant":
        return request.get("vehicle", {
          mobile: false
        });
      case "host":
        return request.get("reservations/vehicles");
      default:
        break;
    }
  }
};
