<script>
import TowingTable from "src/views/towing/Table";
import { ref } from "@vue/composition-api";
export default {
  name: "Towing",
  components: {
    TowingTable
  },
  setup(props, ctx) {
    const tableRef = ref(null);
    return { tableRef };
  }
};
</script>
<template>
  <div class="absolute inset-0">
    <div class="relative">
      <div class="py-10 flex flex-col px-10 space-y-4">
        <div class="flex my-2 justify-between items-center">
          <h2 class="font-bold text-xl">
            Towing
          </h2>
          <button
            class="bg-green-400 hover:bg-green-500 text-white font-medium py-2 px-4 rounded focus:outline-none"
            @click="() => tableRef.createRequest()"
          >
            + Tow Vehicle
          </button>
        </div>
        <div
          class="shadow-sm border overflow-hidden rounded-lg max-w bg-white pb-10"
        >
          <towing-table ref="tableRef" />
        </div>
      </div>
    </div>
  </div>
</template>
