<script>
import { reactive, ref, watch, computed, onMounted, nextTick, onBeforeUnmount } from "@vue/composition-api";
import TabHeader from "src/views/components/TabHeader";
import ConfirmationNumberTag from "src/views/reservations/components/ConfirmationNumberTag.vue";
import MissingInfo from "./components/MissingInfo.vue";
import uniq from "lodash/uniq";
import startCase from "lodash/startCase";

import { API } from "src/views/tenants/api";
import { API as MessagingAPI } from "src/views/messaging/api";

export default {
  name: "Tenants",
  components: {
    TabHeader,
    ConfirmationNumberTag,
    MissingInfo
  },
  setup(props, ctx) {
    const controls = reactive({
      loading: false,
      expandedRowKeys: []
    });

    const focus = computed(() => {
      const qf = ctx.root.$route.query.focus;
      console.log("focus -> ", qf);
      if (qf) {
        const [tenant, host] = qf.split(",");
        if (tenant) {
          const f = {
            tenant
          };
          if (host) {
            f.host = host;
          }
          return f;
        }
      }
      return;
    });

    const host = computed(() => {
      return ctx.root.$route.query.host;
    });

    const visible = reactive({
      tenant: false,
      awaiting: false,
      fullfill: false
    });

    const selected = reactive({
      contact: {}
    });

    const tenantsTable = reactive({
      headers: [
        {
          title: "Tenant Name",
          align: "center",
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "",
          align: "center",
          dataIndex: "oauth_provider",
          // width: 30,
          scopedSlots: { customRender: "oauth-provider" },
        },
        {
          title: "Email",
          align: "center",
          dataIndex: "email",
          scopedSlots: { customRender: "email" },
        },
        {
          title: "Phone Number",
          align: "center",
          dataIndex: "phone_number",
          scopedSlots: { customRender: "phone-number" },
        },
        {
          title: "Reservations",
          align: "center",
          dataIndex: "reservations_count",
          scopedSlots: { customRender: "reservations-count" },
        },
        {
          title: "Joined Since",
          align: "center",
          dataIndex: "created_at",
          // width: 160,
          scopedSlots: { customRender: "created-at" },
        }
      ],
      items: [],
    });

    const signatureTable = reactive({
      headers: [
        {
          title: "Lead Name",
          align: "center",
          dataIndex: "name",
          // width: 160,
          scopedSlots: { customRender: "name" },
        },
        {
          title: "Email",
          align: "center",
          dataIndex: "email",
          scopedSlots: { customRender: "email" },
        },
        {
          title: "Phone Number",
          align: "center",
          dataIndex: "phone_number",
          scopedSlots: { customRender: "phone-number" },
        },
        {
          title: "Reservation",
          align: "center",
          dataIndex: "reservations",
          scopedSlots: { customRender: "reservations" },
        },
        {
          title: "Invited Since",
          align: "center",
          dataIndex: "created_at",
          scopedSlots: { customRender: "invited-at" },
        }
      ],
      items: [],
    });

    if (ctx.root.$isAdmin()) {
      tenantsTable.headers.push({
        title: "Host Name",
        align: "center",
        dataIndex: "host",
        scopedSlots: { customRender: "host" },
      })
      signatureTable.headers.push({
        title: "Host Name",
        align: "center",
        dataIndex: "host",
        scopedSlots: { customRender: "host" },
      })
    }

    const tabs = ref([
      { name: "accepted", label: "Tenants" },
      { name: "invited", label: "Leads" },
    ]);

    const activeTab = ref("accepted");

    const pagination = reactive({
      accepted: {
        currentPage: 1,
        perPage: 10,
        total: 0
      },
      invited: {
        currentPage: 1,
        perPage: 10,
        total: 0
      }
    })

    watch(() => focus.value, (val) => {
      fetchData();
    });

    watch(() => host.value, (val) => {
      fetchData();
    });

    const setupHashRoute = (hashRoute) => {
      ctx.root.$router.push({ name: ctx.root.$route.name, hash: `#${hashRoute}`, query: ctx.root.$route.query });
    };

    const changeTab = (name) => {
      activeTab.value = name;
      setupHashRoute(name);
      if (name === "accepted" && tenantsTable.items.length > 0) {
        return;
      }
      if (name === "invited" && signatureTable.items.length > 0) {
        return;
      }
      fetchData();
    };

    const getCurrentStatus = () => {
      if (activeTab.value === "accepted") {
        return "joined"
      }
      if (activeTab.value === "invited") {
        return "invited"
      }
    }

    const fetchData = async () => {
      controls.loading = true;
      controls.expandedRowKeys = [];
      try {
        const payload = {
          status: getCurrentStatus(),
          page: pagination[activeTab.value].currentPage
        }
        if (focus.value) {
          payload['tenant_id'] = focus.value.tenant;
        }
        if (ctx.root.$isAdmin()) {
          if (focus.value) {
            payload['host_id'] = focus.value.host;
          }
          if (host.value) {
            payload['host_id'] = host.value;
          }
        }
        const { data, pagination: pag } = await API.list(payload);
        tenantsTable.items = [];
        signatureTable.items = [];
        data.forEach(contact => {
          if (contact.status === "joined") {
            tenantsTable.items.push(contact);
            getTenantReservations(contact.id, contact, true);
          }
          if (contact.status === "invited") {
            signatureTable.items.push(contact);
            getTenantReservations(contact.id, contact);
          }
        })
        if (focus.value && data.length == 1) {
          const contact = data[0];
          if (contact.status === "joined") {
            activeTab.value = "accepted";
            setupHashRoute("accepted");
            onExpandTenant(contact);
          }
          if (contact.status === "invited") {
            activeTab.value = "invited";
            setupHashRoute("invited");
          }
        }
        pagination[activeTab.value].currentPage = pag.page;
        pagination[activeTab.value].perPage = pag.per_page;
        pagination[activeTab.value].total = pag.total;
      } catch (error) {
        console.log("tenant list api -> error: ", error);
      } finally {
        controls.loading = false;
      }
    };

    const getTenantReservations = async (tenantId, record, countMode = false) => {
      if (record.reservations) {
        return;
      }
      ctx.root.$set(record, "$loading", true);
      try {
        let on_behalf_of = null;
        if (ctx.root.$isAdmin()) {
          on_behalf_of = record.host;
        }
        const { data } = await API.getTenantReservations(tenantId, countMode, on_behalf_of);
        if (countMode) {
          ctx.root.$set(record, "reservations_count", data.count);
        } else {
          ctx.root.$set(record, "reservations", data);
        }
      } catch (error) {
        console.log("getTenantReservations -> error: ", error);
      } finally {
        ctx.root.$set(record, "$loading", false);
      }
    };

    const openTenantDrawer = async (record, isPending = false) => {
      if (isPending) {
        ctx.root.$bus.$emit('open-pending-tenant-drawer', record);
      } else {
        ctx.root.$bus.$emit('open-tenant-drawer', { tenant: record });
      }
    }

    const scrollToTenantRow = (tenant) => {
      nextTick(() => {
        const tenantRowEl = document.querySelector(`[data-row-key=tenant-${tenant.id}-${tenant.host}]`);
        tenantRowEl.scrollIntoView({ behavior: "smooth", block: "center" });
        setTimeout(() => {
          tenantRowEl.classList.add("bg-blue-100");
          tenantRowEl.classList.add("animate__animated");
          tenantRowEl.classList.add("animate__flash");
          setTimeout(() => {
            tenantRowEl.classList.remove("bg-blue-100");
            tenantRowEl.classList.remove("animate__animated");
            tenantRowEl.classList.remove("animate__flash");
          }, 1000);
        }, 300);
      })
    }

    const handlePageChange = (page) => {
      pagination[activeTab.value].currentPage = page;
      fetchData();
    }

    const startDirectChat = async (contact) => {
      try {
        ctx.root.$set(contact, "$phoneNumberLoading", true);
        const { data } = await MessagingAPI.getDirectConversation(contact.host, contact.id, "host");
        ctx.root.$bus.$emit("open-contact-drawer", { room_id: data.id });
        ctx.root.$set(contact, "$phoneNumberLoading", false);
      } catch (error) {
        console.log("startDirectChat -> error: ", error);
        ctx.root.$set(contact, "$phoneNumberLoading", false);
      }
    }

    const onExpandTenant = (tenant) => {
      console.log("onExpandTenant -> ", tenant);
      getTenantReservations(tenant.id, tenant);
      controls.expandedRowKeys.push(`tenant-${tenant.id}-${tenant.host}`);
      controls.expandedRowKeys = uniq(controls.expandedRowKeys);
      scrollToTenantRow(tenant);
    }

    onMounted(async () => {
      ctx.root.$bus.$on("tenant-drawer-expand-tenant", onExpandTenant);
    });

    onBeforeUnmount(() => {
      ctx.root.$bus.$off("tenant-drawer-expand-tenant", onExpandTenant);
    })

    return {
      controls,
      selected,
      visible,
      tenantsTable,
      signatureTable,
      tabs,
      activeTab,
      focus,
      host,
      pagination,
      fetchData,
      getTenantReservations,
      changeTab,
      openTenantDrawer,
      scrollToTenantRow,
      handlePageChange,
      startDirectChat,
      uniq,
      startCase,
      console
    };
  },
};
</script>
<template>
  <div class="absolute inset-0">
    <div class="relative">
      <div class="py-10 flex flex-col px-10 space-y-4">
        <tab-header v-model="activeTab" :tabs="tabs" @change="changeTab">
          <template #right-actions>
            <a-tooltip
              title="&quot;Auto-add&quot; feature automatically add tenants of incoming reservations to your tenants list">
              <div class="flex items-center space-x-2 pr-4 cursor-pointer">
                <div class="w-2 h-2 bg-green-400 rounded-full" />
                <span class="text-xs font-semibold">Auto-add</span>
                <img src="/static/img/automatic-flash.png" class="w-5 h-5 object-contain">
              </div>
            </a-tooltip>
            <router-link v-if="!$isAdmin()" tag="button" to="tenant/invite"
              class="bg-green-400 hover:bg-green-500 text-white text-sm font-medium focus:outline-none py-2 px-4 rounded">
              + Invite
            </router-link>
          </template>
        </tab-header>
        <div v-show="activeTab === 'accepted'" class="flex flex-col space-y-2">
          <div class="flex items-center space-x-4 pb-2">
            <span class="text-black font-semibold text-lg">
              All Tenants ({{ pagination.accepted.total }})
            </span>
            <a-icon type="reload" :spin="controls.loading" style="font-size: 0.95rem;" @click.stop="fetchData()" />
            <a-tag v-if="focus" color="blue" closable class="focus-tag" @close="() => {
              $router.push({ name: $route.name, hash: $route.hash });
            }">
              Tenant ID: {{ focus.tenant.toUpperCase().substring(0, 8) }}
            </a-tag>
            <a-tag v-if="host" color="orange" closable class="focus-tag" @close="() => {
              $router.push({ name: $route.name, hash: $route.hash });
            }">
              Host ID: {{ host.toUpperCase().substring(0, 8) }}
            </a-tag>
          </div>
          <div class="flex flex-col space-y-2 shadow-sm border overflow-hidden rounded-lg max-w bg-white pb-2">
            <a-table :loading="controls.loading" :columns="tenantsTable.headers" :data-source="tenantsTable.items"
              :row-key="(record, i) => `tenant-${record.id}-${record.host}`" :custom-row="(record, index) => {
                return {
                  on: {
                    click: (event) => {
                      openTenantDrawer(record);
                    },
                  },
                };
              }
                " :expanded-row-keys="controls.expandedRowKeys" @expand="(expanded, record) => {
    if (expanded) {
      getTenantReservations(record.id, record);
      controls.expandedRowKeys.push(`tenant-${record.id}-${record.host}`);
      controls.expandedRowKeys = uniq(controls.expandedRowKeys);
    } else {
      const expandedIndex = controls.expandedRowKeys.indexOf(
        `tenant-${record.id}-${record.host}`
      );
      controls.expandedRowKeys.splice(expandedIndex, 1);
    }
  }
    " :pagination="false">
              <template slot="name" slot-scope="name, record">
                <div class="flex items-center space-x-3 px-3" style="max-width: 220px;">
                  <avatar :user-id="record.id" show-verify :admin-on-behalf-of="record.host" />
                  <span class="text-black text-xs font-medium">{{ name }}</span>
                </div>
              </template>

              <template slot="oauth-provider" slot-scope="provider, record">
                <div class="flex items-center justify-center">
                  <o-auth-badge :provider="record.oauth_provider" />
                </div>
              </template>

              <template slot="host" slot-scope="hostId, record">
                <div class="flex items-center justify-center px-3" style="max-width: 220px;">
                  <avatar :user-id="hostId" show-name show-verify relative-role="host" />
                </div>
              </template>

              <template slot="email" slot-scope="email, record">
                <div v-if="email" class="flex items-center justify-center space-x-2"
                  @click.stop="$bus.$emit('toggle-email-editor', { tenant_id: record.id })">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    class="w-4 h-4 text-parqay-primary hover:text-blue-500">
                    <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                    <path
                      d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                  </svg>
                  <div style="max-width: 150px;">
                    <span class="text-parqay-primary hover:text-blue-500 cursor-pointer text-xs font-medium">{{ email
                    }}</span>
                  </div>
                </div>
                <div v-else class="flex items-center justify-center space-x-2" @click.stop="() => {
                    selected.contact = record;
                    visible.fullfill = true;
                  }">
                  <svg-icon icon-class="plus" class="w-4 h-4 text-parqay-primary hover:text-blue-500" />
                  <div style="max-width: 150px;">
                    <span class="text-parqay-primary hover:text-blue-500 cursor-pointer text-xs font-medium">
                      Add Email
                    </span>
                  </div>
                </div>
              </template>

              <template slot="phone-number" slot-scope="phone_number, record">
                <div v-if="phone_number" class="flex items-center justify-center space-x-2"
                  @click.stop="startDirectChat(record)">
                  <a-icon v-if="$deepGet(record, '$phoneNumberLoading', false)" type="loading" />
                  <template v-else>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                      class="w-4 h-4 text-orange-500 hover:text-orange-400">
                      <path fill-rule="evenodd"
                        d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                        clip-rule="evenodd" />
                    </svg>
                    <div style="max-width: 150px;">
                      <span class="text-orange-500 hover:text-orange-400 cursor-pointer text-xs font-medium">{{
                        phone_number
                      }}</span>
                    </div>
                  </template>
                </div>
                <div v-else class="flex items-center justify-center">
                  <a-button add-number type="primary" size="small" style="font-size: 0.75rem; font-weight: 500;"
                    @click.stop="() => {
                          selected.contact = record;
                          visible.fullfill = true;
                        }">
                    <a-icon type="plus" style="padding-bottom: 2px; color: white; stroke-width: 1px;" />
                    <span>Add Number</span>
                  </a-button>
                </div>
              </template>

              <template slot="created-at" slot-scope="createdAt">
                <div class="
                  text-center text-xs
                  font-medium
                  border
                  rounded
                  bg-gray-100
                  px-2
                  py-1
                  whitespace-nowrap
                ">
                  {{ $formatDate(createdAt, "MMM DD, YYYY") }}
                </div>
              </template>

              <template slot="reservations-count" slot-scope="count, record, index">
                <div class="flex items-center justify-center m-2">
                  <a-icon v-if="$deepGet(record, '$loading', false)" type="loading" />
                  <template v-else>
                    <a-tag v-if="count > 0" color="geekblue" class="
                        flex justify-center items-center
                      text-xs
                      font-medium
                      cursor-pointer
                      space-x-2
                      px-2
                      py-1
                    " @click.stop="() => {
                      const expandedIndex = controls.expandedRowKeys.indexOf(
                        `tenant-${record.id}-${record.host}`
                      );
                      if (expandedIndex > -1) {
                        controls.expandedRowKeys.splice(expandedIndex, 1);
                      } else {
                        getTenantReservations(record.id, record);
                        controls.expandedRowKeys.push(`tenant-${record.id}-${record.host}`);
                        controls.expandedRowKeys = uniq(controls.expandedRowKeys);
                      }
                    }
                      ">
                      <a-badge :count="count" :number-style="{ backgroundColor: '#108ee9' }" />
                      <span>Reservations</span>
                    </a-tag>
                  </template>
                </div>
              </template>

              <template slot="expandedRowRender" slot-scope="record">
                <div class="grid grid-cols-2 gap-4">
                  <template v-if="record.reservations && record.reservations.length > 0">
                    <a-card v-for="(reservation, i) in record.reservations" :key="`tenant-reservation-${i}`" hoverable
                      size="small" @click.stop="$bus.$emit('open-reservation-drawer', reservation)">
                      <template #title>
                        <div class="flex items-center justify-between px-2 py-1">
                          <div class="flex items-center space-x-4">
                            <span class="text-sm font-medium">{{ reservation.title || reservation.listing.name }}</span>
                            <span v-if="$deepGet(reservation, 'event')"
                              class="text-xs ant-tag-geekblue border rounded-md px-3 py-1 truncate"
                              style="max-width: 240px;">
                              {{
                                reservation.event
                              }}
                            </span>
                          </div>
                          <a-icon type="arrow-right" />
                        </div>
                      </template>
                      <div class="flex flex-wrap items-center gap-2">
                        <a-tag reservation color="orange">{{ startCase(reservation.listing.type) }}</a-tag>
                        <a-tag reservation color="green">{{ reservation.host_income }}</a-tag>
                        <a-tag reservation color="geekblue">{{ startCase(reservation.source) }}</a-tag>
                        <a-tag reservation color="cyan">{{ startCase(reservation.status) }}</a-tag>
                        <confirmation-number-tag :confirmation-id="reservation.confirmation_id" />
                      </div>
                    </a-card>
                  </template>
                  <template v-else>
                    <a-icon v-if="$deepGet(record, '$loading', false)" type="loading" class="col-span-2" />
                    <a-tag v-else class="justify-center text-sm col-span-2">
                      This tenant is not associated with any reservations
                    </a-tag>
                  </template>
                </div>
              </template>

              <template slot="actions" slot-scope="tenant_id, record">
                <div class="flex items-center justify-center px-4 py-2">
                  <a-button type="primary" size="small" class="text-xs" @click.stop="openTenantDrawer(record)">
                    View Details
                  </a-button>
                </div>
              </template>
            </a-table>
            <div class="flex items-center justify-center pt-2 pb-4">
              <a-pagination :current="pagination.accepted.currentPage" :total="pagination.accepted.total"
                :page-size="pagination.accepted.perPage" @change="handlePageChange" />
            </div>
          </div>
        </div>

        <div v-show="activeTab === 'invited'" class="flex flex-col space-y-2">
          <div class="flex items-center space-x-4 pb-2">
            <span class="text-black font-semibold text-lg">
              All Leads ({{ pagination.invited.total }})
            </span>
            <!-- <a-badge :count="pagination.invited.total" /> -->
            <a-icon type="reload" :spin="controls.loading" style="font-size: 0.95rem;" @click.stop="fetchData()" />
            <a-tag v-if="focus" color="blue" closable class="focus-tag" @close="() => {
              $router.push({ name: $route.name, hash: $route.hash });
            }">
              Tenant ID: {{ focus.tenant.toUpperCase().substring(0, 8) }}
            </a-tag>
            <a-tag v-if="host" color="orange" closable class="focus-tag" @close="() => {
              $router.push({ name: $route.name, hash: $route.hash });
            }">
              Host ID: {{ host.toUpperCase().substring(0, 8) }}
            </a-tag>
          </div>
          <div class="flex flex-col space-y-2 shadow-sm border overflow-hidden rounded-lg max-w bg-white pb-2">
            <a-table :loading="controls.loading" :columns="signatureTable.headers" :data-source="signatureTable.items"
              :row-key="(record, i) => `lead-${record.id}-${record.host}-${i}`" :custom-row="(record, index) => {
                return {
                  on: {
                    click: (event) => {
                      openTenantDrawer(record, true);
                    },
                  },
                };
              }
                " :pagination="false">

              <template slot="name" slot-scope="name, record">
                <div class="flex items-center space-x-3 px-3">
                  <avatar :user-id="record.id" show-verify :admin-on-behalf-of="record.host" />
                  <span class="text-black text-xs font-medium">{{ name }}</span>
                </div>
              </template>

              <template slot="host" slot-scope="hostId, record">
                <div class="flex items-center justify-center px-3" style="max-width: 220px;">
                  <avatar :user-id="hostId" show-name show-verify relative-role="host" />
                </div>
              </template>

              <template slot="email" slot-scope="email, record">
                <div v-if="email" class="flex items-center justify-center space-x-2"
                  @click.stop="$bus.$emit('toggle-email-editor', { tenant_id: record.id })">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    class="w-4 h-4 text-parqay-primary hover:text-blue-500">
                    <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                    <path
                      d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                  </svg>
                  <div style="max-width: 160px;">
                    <span class="text-parqay-primary hover:text-blue-500 cursor-pointer text-xs font-medium">{{ email
                    }}</span>
                  </div>
                </div>
                <div v-else class="flex items-center justify-center space-x-2" @click.stop="() => {
                    selected.contact = record;
                    visible.fullfill = true;
                  }">
                  <svg-icon icon-class="plus" class="w-4 h-4 text-parqay-primary hover:text-blue-500" />
                  <div style="max-width: 150px;">
                    <span class="text-parqay-primary hover:text-blue-500 cursor-pointer text-xs font-medium">
                      Add Email
                    </span>
                  </div>
                </div>
              </template>

              <template slot="phone-number" slot-scope="phone_number, record">
                <div v-if="phone_number" class="flex items-center justify-center space-x-2"
                  @click.stop="startDirectChat(record)">
                  <a-icon v-if="$deepGet(record, '$phoneNumberLoading', false)" type="loading" />
                  <template v-else>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                      class="w-4 h-4 text-orange-500 hover:text-orange-400">
                      <path fill-rule="evenodd"
                        d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                        clip-rule="evenodd" />
                    </svg>
                    <div style="max-width: 150px;">
                      <span class="text-orange-500 hover:text-orange-400 cursor-pointer text-xs font-medium">{{
                        phone_number
                      }}</span>
                    </div>
                  </template>
                </div>
                <div v-else class="flex items-center justify-center">
                  <a-button chat type="primary" size="small" style="font-size: 0.75rem; font-weight: 500;" @click.stop="() => {
                        selected.contact = record;
                        visible.fullfill = true;
                      }">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                      class="w-4 h-4 text-white">
                      <path fill-rule="evenodd"
                        d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                        clip-rule="evenodd" />
                    </svg>
                    <span>Lead outreach</span>
                  </a-button>
                </div>
              </template>

              <template slot="invited-at" slot-scope="invitedAt">
                <div class="
                  text-center text-xs
                  font-medium
                  border
                  rounded
                  bg-gray-100
                  px-2
                  py-1
                  whitespace-nowrap
                ">
                  {{ invitedAt ? $formatDate(invitedAt, "MMM DD, YYYY") : "N/A" }}
                </div>
              </template>

              <template slot="reservations" slot-scope="reservations, record">
                <template v-if="reservations && reservations.length > 0">
                  <a v-for="(reservation, i) in reservations" :key="`tenant-awaiting-reservation-${i}`"
                    class="text-parqay-primary text-xs font-medium"
                    @click.stop="$bus.$emit('open-reservation-drawer', reservation)">
                    {{ reservation.listing.name }}
                  </a>
                </template>
                <template v-else>
                  <a-icon v-if="$deepGet(record, '$loading', false)" type="loading" />
                  <a-button v-else type="primary" size="small" style="font-size: 0.75rem; font-weight: 500;"
                    @click.stop="$router.push('/dashboard/reservations#calendar')">
                    assign
                  </a-button>
                </template>
              </template>

              <template slot="actions" slot-scope="tenant_id, record">
                <div class="flex items-center justify-center px-4 py-2">
                  <a-button type="primary" size="small" class="text-xs" @click.stop="openTenantDrawer(record, true)">
                    View Details
                  </a-button>
                </div>
              </template>
            </a-table>
            <div class="flex items-center justify-center pt-2 pb-4">
              <a-pagination :current="pagination.invited.currentPage" :total="pagination.invited.total"
                :page-size="pagination.invited.perPage" @change="handlePageChange" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal v-model="visible.fullfill" destroy-on-close :footer="false" @cancel="() => visible.fullfill = false">
      <missing-info :contact="selected.contact" @fullfill="({ phone_number, email }) => {
        selected.contact.phone_number = phone_number;
        selected.contact.email = email;
        visible.fullfill = false;
      }" />
    </a-modal>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .ant-tag {
    @apply flex py-1;
  }

  .ant-tag[reservation] {
    @apply flex py-1 text-xs font-semibold;
  }

  .ant-btn[add-number] {
    @apply flex items-center justify-center space-x-1 border-orange-400 bg-orange-400;
  }

  .ant-btn[chat] {
    @apply flex items-center justify-center space-x-1 border-orange-400 bg-orange-400;
  }

  .ant-table-row {
    @apply cursor-pointer;
  }

  .focus-tag {
    @apply flex items-center py-1;
  }
}
</style>