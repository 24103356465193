<script>
import { reactive } from "@vue/composition-api";
import ReservationSearchInput from "src/views/components/ReservationSearchInput.vue";
import cloneDeep from "lodash/cloneDeep";

import { API } from "src/views/documents/api";

export default {
    components: {
        ReservationSearchInput
    },
    props: {
        templates: {
            type: Array,
            default: () => ([])
        }
    },
    setup(props, ctx) {
        const controls = reactive({
            loading: false,
            visible: false,
            expand: {
                message: false,
                intervalMode: false,
                listingType: false
            },
            showComplete: false,
            selected: {
                reservation: null,
                tenant: null
            },
            currentStep: 0,
            templates: cloneDeep(props.templates).sort((a, b) => b.name.length - a.name.length).map(v => {
                v.checked = false;
                return v;
            }),
            key: 0
        })

        const getSteps = () => {
            const steps = [
                {
                    title: "Templates",
                    icon: "check-square"
                },
                {
                    title: "Recipient",
                    icon: "user"
                },
                {
                    title: "Sent",
                    icon: "file-done"
                },

            ]
            return steps;
        }

        const getStepStatus = (i) => {
            if (controls.currentStep < i) {
                return "wait";
            }
            if (controls.currentStep == i) {
                return "process";
            }
            if (controls.currentStep > i) {
                return "finish";
            }
        }

        const sendDocumentTemplateInvite = async () => {
            try {
                controls.loading = true;
                await API.sendDocumentTemplateInvite(controls.templates.filter(v => v.checked).map(v => v.id), controls.selected.reservation);
                controls.loading = false;
                controls.currentStep += 1;
            } catch (error) {
                controls.loading = false;
                ctx.root.$openNotification("Send Invite", error.message, 10, "error");
            }
        }

        return {
            controls,
            getSteps,
            getStepStatus,
            sendDocumentTemplateInvite
        }
    }
}
</script>
<template>
    <div class="doc-template-invite flex flex-col items-center justify-center space-y-4 border-gray-400">
        <a-steps>
            <a-step v-for="(step, i) in getSteps()" :key="`template-invite-step-${i}`" :status="getStepStatus(i)"
                :title="step.title">
                <a-icon slot="icon" :type="step.icon" />
            </a-step>
        </a-steps>
        <div :key="controls.key" class="w-full flex flex-col items-center justify-center space-y-2 px-8 py-4">
            <template v-if="controls.currentStep == 0">
                <a-icon type="file-text" class="text-parqay-primary" style="font-size: 4rem;" />
                <span class="text-lg font-semibold pt-2">Select Templates</span>
                <span class="text-center text-sm font-light pb-4">Choose templates to send documents invitation.</span>
                <div class="w-full bg-gray-50 flex flex-wrap items-center justify-center gap-2 p-4 border rounded-lg"
                    style="min-height: 100px;">
                    <div v-for="(temp, i) in controls.templates" :key="`temp-item-${i}`">
                        <a-checkbox :default-checked="temp.checked"
                            @change="({ target }) => { temp.checked = target.checked; controls.key += 1; }">{{ temp.name
                            }}</a-checkbox>
                    </div>
                </div>
                <div class="w-full pt-4">
                    <a-button type="primary" size="large" block
                        :disabled="controls.templates.filter(v => v.checked).length == 0"
                        @click.stop="() => controls.currentStep += 1">Choose
                        Recipient</a-button>
                </div>
            </template>
            <template v-if="controls.currentStep == 1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-16 h-16 text-parqay-primary transform -rotate-45">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                </svg>
                <span class="text-lg font-semibold pt-2">Select A Recipient</span>
                <span class="text-center text-sm font-light pb-4">Choose a tenant to send invitations of <b
                        class="text-parqay-primary font-semibold">{{
                            controls.templates.filter(v => v.checked).length }} documents</b> through email
                    and notifications.</span>
                <a-divider></a-divider>
                <label-item label="Select A Reservation" desc="Send invite to the tenant of an existing reservation."
                    class="w-full pt-4">
                    <reservation-search-input @select="reservation => controls.selected.reservation = reservation"
                        @select-tenant="tenant => controls.selected.tenant = tenant" />
                </label-item>
                <div class="w-full flex items-center pt-4 space-x-4">
                    <a-button icon="left" size="large" @click.stop="() => {
                        controls.currentStep -= 1;
                        controls.selected.reservation = null;
                        controls.selected.tenant = null;
                    }"></a-button>
                    <a-button type="primary" :loading="controls.loading" size="large" block
                        :disabled="!controls.selected.reservation" @click.stop="sendDocumentTemplateInvite()">Send
                        Invite</a-button>
                </div>
            </template>
            <template v-if="controls.currentStep == 2">
                <a-icon type="check-circle" class="text-green-400" style="font-size: 4rem;" />
                <span class="text-lg font-semibold pt-2">Invitations Sent</span>
                <span class="text-center text-sm font-light pb-4">Document invitations have been sent through email and
                    notifications to your tenant</span>
                <div v-if="controls.selected.tenant" class="flex items-center space-x-4">
                    <b>{{ controls.selected.tenant.name }}</b>
                    <a-tag>{{ controls.selected.tenant.email }}</a-tag>
                </div>
                <div class="w-full flex items-center pt-4 space-x-4">
                    <a-button type="primary" size="large" block @click.stop="$emit('dismiss')">Done</a-button>
                </div>
            </template>
        </div>
    </div>
</template>

<style lang="scss">
.doc-template-invite {
    .ant-steps-icon {
        @apply flex items-center justify-end;
    }

    .ant-btn {
        @apply flex items-center justify-center;
    }
}
</style>