import axios from "axios";
import { Urls } from "src/services/constants";
import EventBus from "src/services/util/event-bus";
import TokenManager from "src/services/authentication/token-manager";

const baseUrl = (url) => {
  return Urls.API_BASE_URL() + url;
};
export const API = {
  // Use Blob to make progress trackable
  startDataUpload(
    formData,
    uploadPercentage,
    current_retry = 1,
    max_retry = 2
  ) {
    return new Promise((resolve, reject) => {
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      if (TokenManager().accessToken) {
        headers["Authorization"] = "Bearer " + TokenManager().accessToken;
      }
      axios({
        method: "post",
        url: baseUrl("listings/new/upload"),
        data: formData,
        headers,
        onUploadProgress: (progressEvent) => {
          const { loaded, total, lengthComputable, target } = progressEvent;
          // console.log("progressEvent", progressEvent);
          const totalLength = lengthComputable
            ? total
            : target.getResponseHeader("content-length") ||
            target.getResponseHeader("x-decompressed-content-length");
          if (totalLength !== null) {
            EventBus.$emit(
              "data-upload-progress-update",
              Math.round((loaded * 100) / totalLength)
            );
          }
        },
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (current_retry <= max_retry) {
              console.log("***** re-auth error ***** " + error.response.status);
              var next_retry = current_retry + 1;
              API.refreshAccessToken().then(
                () => {
                  EventBus.$emit("access-token-refresh");
                  API.startDataUpload(formData, uploadPercentage, next_retry);
                },
                (error) => {
                  // notify to resign-in
                  console.warn("Failed to refresh the auth token...", error);
                  EventBus.$emit("auth-required-login");
                  reject(error);
                }
              );
            } else {
              EventBus.$emit("auth-required-login");
            }
          } else {
            reject(error);
          }
        });
    });
  },
  refreshAccessToken() {
    //Check whether there's refresh token set or not.
    if (!TokenManager().refreshToken) {
      return Promise.reject("no refresh token");
    }

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + TokenManager().refreshToken;

    return axios["post"](baseUrl("auth/refresh_token"), {}).then(
      (success) => {
        // update access token
        TokenManager().accessToken = success.data.data.access_token;
        console.log(
          "success.data.access_token: " + success.data.data.access_token
        );
        return Promise.resolve();
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
};
