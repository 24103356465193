<script>
import {
  ref,
  reactive,
  computed,
  onMounted,
  onBeforeUnmount
} from "@vue/composition-api";
import ConnectWithStripeButton from "src/views/components/ConnectWithStripeButton";
import CheckStripeEarningsButton from "src/views/components/CheckStripeEarningsButton";
import { API as SettingsAPI } from "src/views/settings/api";
import { API as ListingAPI } from "src/views/listings/new/api";
import { imageDataUploader } from "src/views/components/util";
import EventBus from "src/services/util/event-bus";

import forOwn from 'lodash/forOwn';
import cloneDeep from 'lodash/cloneDeep';

export default {
  components: {
    ConnectWithStripeButton,
    CheckStripeEarningsButton
  },
  setup(props, ctx) {
    const store = ctx.root.$store;
    const openNotification = ctx.root.$openNotification;
    const hasStripeAccount = ref(false);
    const popUpBlockMessage = ref(null);
    const currentUploadType = ref(null);
    const updateImagesUploadProgress = progress => {
      uploadPercentage.image = Math.round(progress * 100);
    };
    const notificationMessage = computed(() => {
      var message = "...";
      switch (currentUploadType.value) {
        case "data":
          message = "Listing to marketplace...";
          if (uploadPercentage[currentUploadType.value] === 100) {
            message = "Upload Completed";
          }
          break;
        case "image":
          message = "Uploading images...";
          break;
        default:
          break;
      }
      return message;
    });
    const loading = reactive({
      checkConnect: false,
      retrieveConnectURL: false
    });
    const stripeConnectDashboardUrl = ref(null);
    const uploadLotMessage = reactive({
      show: false,
      message: "",
      type: "info"
    });
    const uploadPercentage = reactive({
      image: 0,
      data: 0
    });
    const getStripeConnectDashboardUrl = async () => {
      loading.retrieveConnectURL = true;
      try {
        const { data } = await SettingsAPI.getStripeConnectAccountDashboardUrl()
        stripeConnectDashboardUrl.value = data.login_link;
        loading.retrieveConnectURL = false;
      } catch (error) {
        stripeConnectDashboardUrl.value = null;
        loading.retrieveConnectURL = false;

      }
    };
    const assembledPayload = ref(null);
    const fetchAssembledPayload = async () => {
      const formPayload = store.state.listingForm;
      forOwn(formPayload, (payload, stepName) => {
        console.log("stepName", stepName);
        console.log("payload", payload);
      });
      assembledPayload.value = cloneDeep(formPayload);
      const images = await ctx.root.$getValueFromIndexedDB(
        "listing-new-images"
      );
      assembledPayload.value.images_urls = [];
      for (let [i, dataUrl] of images.entries()) {
        try {
          const s3_path = await imageDataUploader(
            dataUrl,
            i,
            images.length
          );
          assembledPayload.value.images_urls.push(s3_path);
        } catch (error) {
          openNotification("Image Upload Error", `${error}`);
          continue
        }
      }
    };

    const startDataUpload = async () => {
      if (!assembledPayload.value) {
        return;
      }
      currentUploadType.value = "data";
      const payload = assembledPayload.value;
      const payloadString = JSON.stringify(payload);
      var payloadBlob = new Blob([payloadString], { type: "application/json" });
      let formData = new FormData();
      formData.append("file", payloadBlob);
      try {
        await ListingAPI.startDataUpload(formData);
      } catch (error) {
        console.log("startDataUpload error: ", error);
      }
    };
    const getPerc = () => {
      return uploadPercentage[currentUploadType.value] < 20
        ? ""
        : `${uploadPercentage[currentUploadType.value]}%`;
    };
    const startListing = async () => {
      loading.checkConnect = true;
      currentUploadType.value = "image";
      await fetchAssembledPayload();
      console.log("startListing -> ", assembledPayload.value);
      await startDataUpload();
    }
    onMounted(async () => {
      EventBus.$on("image-upload-progress-update", updateImagesUploadProgress);
      EventBus.$on("data-upload-progress-update", progress => {
        console.log("data-upload-progress-update => ", progress);
        uploadPercentage.data = progress;
      });
      getStripeConnectDashboardUrl();
    });

    onBeforeUnmount(() => {
      EventBus.$off("image-upload-progress-update", updateImagesUploadProgress);
      EventBus.$off("data-upload-progress-update", progress => {
        console.log("data-upload-progress-update => ", progress);
        uploadPercentage.data = progress;
      });
    });

    return {
      loading,
      hasStripeAccount,
      popUpBlockMessage,
      notificationMessage,
      stripeConnectDashboardUrl,
      uploadLotMessage,
      uploadPercentage,
      currentUploadType,
      startListing,
      getStripeConnectDashboardUrl,
      getPerc
    };
  }
};
</script>
<template>
  <div class="flex flex-col h-full w-4/5 mx-auto px-24 py-6">
    <div class="flex justify-between items-center border-b p-2">
      <h2 class="text-base text-parqay-primary font-medium">
        {{ $t("general.payInfo") }}
      </h2>
    </div>
    <div class="flex justify-center items-center pt-6 pb-4">
      <!-- <v-alert
          v-model="uploadLotMessage.show"
          transition="scale-transition"
          outline
          :type="uploadLotMessage.type"
        >
          {{ uploadLotMessage.message }}
        </v-alert> -->
      <div class="overflow-hidden rounded-full border border-gray-300 flex-0">
        <div class="overflow-hidden rounded-full border-4 border-white h-48 w-48">
          <img class="bg-gray-200 h-48 w-48 object-cover" :class="(!currentUploadType || uploadPercentage[currentUploadType] == 100) ? '' : 'animate-pulse'
            " src="/static/img/dashboard/property-listing.png" alt="Listing..." />
        </div>
      </div>
    </div>
    <div class="flex justify-center py-2">
      <span v-if="currentUploadType" class="font-medium text-md text-center" :class="currentUploadType == 'image' ? 'text-pink-600' : 'text-parqay-primary'
        ">{{ notificationMessage }}</span>
    </div>
    <div class="flex justify-center">
      <div v-if="currentUploadType" class="
            overflow-hidden
            rounded-full
            border border-gray-300
            flex-0
            w-1/2
            h-6
          ">
        <div class="
              w-full
              h-full
              rounded-full
              bg-gray-300
              overflow-hidden
              border-2 border-white
            ">
          <div class="
                h-full
                text-xs
                py-1
                leading-none
                text-center text-white
                transition-width
                duration-500
                ease-in-out
              " :class="currentUploadType == 'image' ? 'bg-pink-500' : 'bg-green-400'
                " :style="`width: ${uploadPercentage[currentUploadType]}%`">
            {{ getPerc() }}
          </div>
        </div>
      </div>
      <div v-else class="flex items-center justify-center pb-4">
        <button class="
              flex items-center justify-center space-x-3
              pl-4 pr-2
              py-2
              border border-transparent
              font-medium
              rounded-md
              text-white
              bg-parqay-primary
              hover:bg-blue-500
              focus:outline-none
              focus:border-indigo-800
              focus:shadow-outline-indigo
              active:bg-indigo-800
              transition
              ease-in-out
              duration-150
              animate-pulse
          " @click="startListing()">
          <span>Start listing upload</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-6 h-6 pb-1 transform -rotate-45">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
          </svg>
        </button>
      </div>
    </div>

    <p v-if="popUpBlockMessage" class="account-message">
      {{ popUpBlockMessage }}
    </p>

    <template v-if="currentUploadType === 'data' && uploadPercentage[currentUploadType] == 100">
      <div class="flex flex-col space-x-4 py-1 justify-center payout-settings">
        <div v-if="loading.retrieveConnectURL" class="flex items-center justify-center py-6">
          <a-icon type="loading" />
        </div>
        <div v-else class="flex flex-col space-y-4">
          <template v-if="!stripeConnectDashboardUrl">
            <span class="mt-4 pt-3 text-sm font-semibold text-center border-t">
              Final step, receive your payouts 💰💰💰
            </span>
            <connect-with-stripe-button @refresh="getStripeConnectDashboardUrl">
              <template slot="more-action">
                <a-button size="large" :disabled="uploadPercentage[currentUploadType] < 100" class="payout-later-btn"
                  @click="$router.push('/dashboard/listings')">
                  Setup payout later
                </a-button>
              </template>
            </connect-with-stripe-button>
          </template>

          <template v-else>
            <div class="flex items-center justify-center pt-4 pb-2">
              <button class="
                  px-4
                  py-2
                  border border-transparent
                  font-medium
                  rounded-md
                  text-white
                  bg-parqay-primary
                  hover:bg-blue-500
                  focus:outline-none
                  focus:border-indigo-800
                  focus:shadow-outline-indigo
                  active:bg-indigo-800
                  transition
                  ease-in-out
                  duration-150
                " @click="$router.push('/dashboard/listings')">
                View my listings
              </button>
            </div>
            <a-divider>OR, adjust payout settings</a-divider>
            <div class="flex items-center justify-center">
              <check-stripe-earnings-button layout="h" :login-link="stripeConnectDashboardUrl"
                style="max-width: 500px;" />
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>
<style lang="scss" scoped>
.payout-settings::v-deep {
  .payout-later-btn {
    @apply text-sm font-medium;
  }

  .ant-divider-inner-text {
    @apply text-sm;
  }
}
</style>
