<script>
import { ref, computed, reactive, onMounted } from "@vue/composition-api";
import EventBus from "src/services/util/event-bus";
import Calendar from "src/views/events/components/Calendar";
import PropertyDropdown from "src/views/dropdowns/Property";
import { API } from "src/views/events/api";

import { Reservation } from "src/views/components/mockData";

export default {
  name: "EventSchedule",
  components: {
    Calendar,
    PropertyDropdown,
  },
  setup(props, ctx) {
    const model = ref(Reservation.table1);
    const showEdit = ref(
      computed(() => {
        return false;
      })
    );
    const showCalendar = ref(false);
    const table = reactive({
      headers: [
        {
          title: "Title",
          align: "center",
          dataIndex: "title",
          scopedSlots: { customRender: "event-title" },
        },
        {
          title: "Venue",
          align: "center",
          dataIndex: "venue",
          scopedSlots: { customRender: "venue" },
        },
        {
          title: "Type",
          align: "center",
          dataIndex: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "Properties",
          align: "center",
          dataIndex: "properties",
          scopedSlots: { customRender: "properties" },
        },
        {
          title: "From",
          align: "center",
          dataIndex: "from",
          scopedSlots: { customRender: "from" },
        },
        {
          title: "To",
          align: "center",
          dataIndex: "to",
          scopedSlots: { customRender: "to" },
        },
        {
          title: "Pricing Rule",
          align: "center",
          dataIndex: "pricing_rule",
          scopedSlots: { customRender: "pricing-rule" },
        },
        {
          title: "Active",
          align: "center",
          dataIndex: "active",
          scopedSlots: { customRender: "active" },
          width: 90,
          fixed: "right",
        },
        {
          title: "Actions",
          align: "center",
          dataIndex: "actions",
          scopedSlots: { customRender: "actions" },
          width: 100,
          fixed: "right",
        }
      ],
      items: [
        // {
        //   id: "example_event_schedule_id_1",
        //   title: "Super Bowl 2018",
        //   venue: "Venue A",
        //   type: "Baseball",
        //   from: "2020-05-05T12:00:00-07:00",
        //   to: "2020-05-06T12:00:00-07:00",
        //   pricing_rule: {
        //     id: "example_pricing_rule_id_1",
        //     name: "Super Bowl 2018 - Pricing Rule",
        //     details: [
        //       { tier: "Tier 1", price: 50, inventory: 5 },
        //       { tier: "Tier 2", price: 70, inventory: 2 },
        //       { tier: "Tier 3", price: 100, inventory: 1 },
        //     ],
        //   },
        // },
      ],
    });

    const navToPricingRule = () => {
      ctx.emit("change", "event-pricing");
    };

    const toggleCalendar = () => {
      showCalendar.value = !showCalendar.value;
    };

    const openEditSlide = () => {
      EventBus.$emit("open-slide-bar", "editEventSchedule", {});
    };

    onMounted(() => {
      API.listSchedules().then((res) => {
        console.log("event schedule api result: " + JSON.stringify(res));
        table.items = res.data;
        // tableLoading.value = false;
      });
    });

    return {
      model,
      showEdit,
      showCalendar,
      table,
      navToPricingRule,
      toggleCalendar,
      openEditSlide,
      console,
    };
  },
};
</script>
<template>
  <div
    class="shadow-sm border overflow-hidden rounded-lg max-w bg-white"
    :style="{ width: $calculateDataTableWidth() }"
  >
    <div class="flex items-center justify-between px-4 py-4 border-b">
      <span class="font-medium text-lg">Event Schedule</span>
      <div class="flex justify-end space-x-4">
        <button
          class="bg-green-400 text-white font-normal py-2 px-4 rounded"
          @click="openEditSlide"
        >
          + Create Event
        </button>
        <button
          class="bg-parqay-primary text-white font-normal py-2 px-4 rounded"
          @click="toggleCalendar()"
        >
          {{ showCalendar ? "Hide Calendar" : "View Calendar" }}
        </button>
        <button class="bg-parqay-primary text-white font-normal py-2 px-4 rounded">
          Filter
        </button>
        <button
          v-show="showEdit"
          class="bg-parqay-primary text-white font-bold py-2 px-4 rounded"
        >
          Edit Selected
        </button>
      </div>
    </div>
    <template v-if="showCalendar">
      <Calendar :model="model" />
    </template>
    <template v-else>
      <!-- <template v-slot:no-data>
          <div
            class="border shadow-sm rounded-lg bg-white p-4 my-4 flex items-center justify-center"
            style="min-height: 300px"
          >
            <span class="font-semibold text-xl text-gray-400">No Event</span>
          </div>
        </template> -->

      <a-table
        :scroll="{ x: true, y: 500 }"
        :loading="false"
        :columns="table.headers"
        :data-source="table.items"
        row-key="id"
        :custom-row="
          (record, index) => {
            return {
              on: {
                click: (event) => {
                  console.log('row click', event.target.tagName.toLowerCase());
                  if (
                    event.target.tagName.toLowerCase() !== 'svg' &&
                    event.target.tagName.toLowerCase() !== 'use'
                  ) {
                    // openEditSlide();
                  }
                },
              },
            };
          }
        "
        :pagination="false"
      >
        <template
          slot="event-title"
          slot-scope="title"
        >
          <div class="text-center text-parqay-primary text-md font-bold px-2 py-1">
            {{ title }}
          </div>
        </template>
        <template
          slot="venue"
          slot-scope="venue"
        >
          <span class="text-gray-900">{{ venue }}</span>
        </template>

        <template
          slot="type"
          slot-scope="type"
        >
          <span class="text-gray-900">{{ type }}</span>
        </template>

        <template
          slot="properties"
          slot-scope="properties"
        >
          <div class="flex flex-col space-y-2 my-2">
            <div
              v-for="(_, i) in [1, 2, 3]"
              :key="`property-${i}`"
              class="
                text-center text-xs
                font-medium
                border
                rounded
                bg-gray-100
                px-2
                py-1
                whitespace-nowrap
              "
            >
              Example Property #{{ i }}
            </div>
            <div class="flex space-x-1 items-center">
              <property-dropdown />
              <button class="px-2 text-sm bg-green-400 text-white rounded h-8">
                Add
              </button>
            </div>
          </div>
        </template>

        <template
          slot="from"
          slot-scope="from"
        >
          <div
            class="
              text-center text-xs
              font-medium
              border
              rounded
              bg-gray-100
              px-2
              py-1
              whitespace-nowrap
            "
          >
            {{ $formatDate(from) }}
          </div>
        </template>

        <template
          slot="to"
          slot-scope="to"
        >
          <div
            class="
              text-center text-xs
              font-medium
              border
              rounded
              bg-gray-100
              px-2
              py-1
              whitespace-nowrap
            "
          >
            {{ $formatDate(to) }}
          </div>
        </template>

        <template
          slot="pricing-rule"
          slot-scope="pricingRule"
        >
          <div
            class="flex py-2 space-x-1 cursor-pointer"
            style="min-width: 130px"
          >
            <div class="w-4/5 flex-col space-y-2">
              <template v-for="(detail, i) in pricingRule.details">
                <a-tooltip
                  :key="`schedule-pricing-rule-${i}`"
                  placement="top"
                >
                  <template slot="title">
                    <span>{{ i == 0 ? "First" : "Then," }}
                      {{ detail.inventory }} spots will be sold with price
                      {{ detail.price }}</span>
                  </template>
                  <div
                    class="
                      flex
                      items-center
                      justify-between
                      h-8
                      rounded-full
                      bg-white
                      border
                      shadow-sm
                      px-1
                      py-1
                      hover:shadow-lg
                    "
                  >
                    <div
                      class="
                        h-6
                        px-2
                        bg-blue-500
                        rounded-full
                        text-center text-white
                      "
                    >
                      {{ detail.inventory }} spots
                    </div>
                    <div class="text-center font-medium flex-grow">
                      {{ detail.price }}
                    </div>
                  </div>
                </a-tooltip>
              </template>
            </div>
            <div class="w-1/5 flex items-center justify-center">
              <button
                class="text-blue-500 hover:text-blue-800"
                @click="navToPricingRule"
              >
                <svg-icon
                  icon-class="arrow-circle-right"
                  class="w-6 h-6"
                />
              </button>
            </div>
          </div>
        </template>

        <template
          slot="active"
          slot-scope="active"
        >
          <a-switch
            :checked="true"
            checked-children="Yes"
            un-checked-children="No"
          />
        </template>

        <template
          slot="actions"
          slot-scope="actions"
        >
          <button
            class="bg-parqay-primary text-white font-normal py-1 px-4 rounded"
            @click="openEditSlide"
          >
            Edit
          </button>
        </template>
      </a-table>
    </template>
  </div>
</template>
