var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-table',{attrs:{"loading":_vm.controls.loading,"columns":_vm.table.headers,"data-source":_vm.table.data.filter(_vm.filter),"scroll":{ x: true, y: false },"row-key":"id","custom-row":function (record, index) {
        return {
          on: {
            click: function (event) {
              _vm.console.log(
                'row click currentTarget',
                event.currentTarget.tagName.toLowerCase()
              );
              _vm.console.log('row click target', event.target.tagName.toLowerCase());
              if (
                event.target.tagName.toLowerCase() !== 'svg' &&
                event.target.tagName.toLowerCase() !== 'use' &&
                event.target.tagName.toLowerCase() !== 'span' &&
                event.target.tagName.toLowerCase() !== 'a' &&
                event.target.tagName.toLowerCase() !== 'button'
              ) {
              }
            },
          },
        };
      },"pagination":false},scopedSlots:_vm._u([{key:"status",fn:function(status, record){return [_c('div',{staticClass:"flex flex-col space-y-2 px-2 py-4"},[(status == 'pending')?_c('a-tag',{staticClass:"py-1",attrs:{"color":"orange"}},[_vm._v("\n          Waiting\n        ")]):_vm._e(),(status == 'working')?_c('a-tag',{staticClass:"py-1",attrs:{"color":"geekblue"}},[_vm._v("\n          "+_vm._s(_vm.startCase(status))+"\n        ")]):_vm._e(),(status == 'complete')?_c('a-tag',{staticClass:"py-1",attrs:{"color":"green"}},[_vm._v("\n          "+_vm._s(_vm.startCase(status))+"\n        ")]):_vm._e()],1)]}},{key:"reservation",fn:function(reservation){return [_c('div',{staticClass:"flex flex-col space-y-2"},[_c('a-tag',{staticClass:"py-1 px-3",attrs:{"color":"geekblue"}},[_vm._v("\n          "+_vm._s(reservation)+"\n        ")])],1)]}},{key:"logs",fn:function(logs){return [_c('div',{staticClass:"flex flex-col space-y-2"},_vm._l((logs),function(log,i){return _c('a-tag',{key:("log-" + i),attrs:{"color":"geekblue","closable":""},on:{"close":function () {}}},[_vm._v("\n          "+_vm._s(log.action)+"\n        ")])}),1)]}},{key:"created_at",fn:function(created_at){return [_c('a-tag',[_vm._v("\n        "+_vm._s(_vm.$formatDate(created_at.$date, "MM/DD, h:mm a"))+"\n      ")])]}},{key:"actions",fn:function(_, record, i){return [_c('div',{staticClass:"flex flex-col space-y-2"},[_c('a-radio-group',{model:{value:(record.status),callback:function ($$v) {_vm.$set(record, "status", $$v)},expression:"record.status"}},[_c('a-radio-button',{attrs:{"value":"pending"}},[_vm._v("\n            Pending\n          ")]),_c('a-radio-button',{attrs:{"value":"working"}},[_vm._v("\n            Working\n          ")]),_c('a-radio-button',{attrs:{"value":"complete"}},[_vm._v("\n            Complete\n          ")])],1),_c('div',[_c('a-input-search',{attrs:{"allow-clear":"","placeholder":"Log work progress"},on:{"search":function (v, e) {
                _vm.console.log('v: ', v);
                if (v) {
                  record.logs.push({ action: v, date: _vm.$serverNow() });
                  var target = e.target
                    .closest('.ant-input-wrapper')
                    .getElementsByTagName('input')[0];
                  target.value = null;
                }
              }}},[_c('a-button',{attrs:{"slot":"enterButton","type":"primary"},slot:"enterButton"},[_vm._v("\n              Log\n            ")])],1)],1)],1)]}}])}),_c('maintenance-request-modal',{attrs:{"visible":_vm.controls.visible},on:{"close":function () {
        _vm.controls.visible = false;
        _vm.fetchData();
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }