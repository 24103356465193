<script>
import { ref, onMounted, reactive } from "@vue/composition-api";
import TabHeader from "src/views/components/TabHeader";
import MaintenanceTable from "src/views/maintenance/Table";
export default {
  name: "HostMaintenance",
  components: {
    TabHeader,
    MaintenanceTable
  },
  setup(props, ctx) {
    const route = ctx.root.$route;
    const router = ctx.root.$router;
    const tableRef = ref(null);
    const tabs = ref([
      { name: "all", label: "All Tasks" },
      { name: "working", label: "Working" },
      { name: "pending", label: "Waiting" },
      { name: "complete", label: "Complete" }
    ]);

    const activeTab = ref("all");

    const setupHashRoute = hashRoute => {
      router.push({ name: route.name, hash: `#${hashRoute}` });
    };

    const changeTab = name => {
      activeTab.value = name;
      setupHashRoute(name);
    };

    const filter = v => {
      if (activeTab.value === "all") {
        return true;
      }
      return v.status === activeTab.value;
    };

    return {
      tableRef,
      tabs,
      activeTab,
      changeTab,
      filter
    };
  }
};
</script>
<template>
  <div class="absolute inset-0">
    <div class="relative">
      <div class="py-10 flex flex-col px-10 space-y-4">
        <tab-header v-model="activeTab" :tabs="tabs" @change="changeTab" />
        <div class="flex my-2 justify-between items-center">
          <h2 class="font-bold text-xl">
            Maintenance
          </h2>
        </div>
        <div
          class="shadow-sm border overflow-hidden rounded-lg max-w bg-white pb-10"
        >
          <maintenance-table ref="tableRef" :filter="filter" />
        </div>
      </div>
    </div>
  </div>
</template>
