var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col justify-center items-center px-4 py-2"},[_c('div',{staticClass:"w-full bg-white rounded-lg border overflow-hidden"},[_c('div',{staticClass:"px-6 py-6 space-y-4"},[_c('h2',{staticClass:"text-xl text-center font-semibold text-gray-900"},[_vm._v("\n                    Fullfill Missing "+_vm._s(_vm.getRole())+" Info\n                ")]),_c('a-divider',[_vm._v(_vm._s(_vm.contact.name))]),(_vm.contact.status === 'invited' && !_vm.contact.phone_number)?_c('div',{staticClass:"flex justify-between pb-5 border-b"},[_c('div',{staticClass:"text-sm font-medium text-parqay-primary hover:text-blue-500 cursor-pointer",on:{"click":function($event){$event.stopPropagation();return (function () {
                        _vm.isOutreach = !_vm.isOutreach;
                    })($event)}}},[_vm._v("Reach out to "+_vm._s(_vm.getRole())+"?")]),_c('a-switch',{model:{value:(_vm.isOutreach),callback:function ($$v) {_vm.isOutreach=$$v},expression:"isOutreach"}})],1):_vm._e(),_c('div',{staticClass:"mb-4 space-y-4"},[_c('div',{staticClass:"flex flex-col mb-4 space-y-4"},[(!_vm.contact.phone_number)?_c('label-item',{attrs:{"label":"Phone Number","error-message":_vm.form.errorMessage.phoneNumber},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('div',{staticClass:"flex-grow flex items-center justify-end"},[(_vm.form.isPhoneNumberInsideUS)?_c('a-button',{attrs:{"country-picker":"","type":"link"},on:{"click":function($event){$event.stopPropagation();return (function () { _vm.form.isPhoneNumberInsideUS = false; _vm.formatPhoneNumber() })($event)}}},[_vm._v("Outside\n                                        US")]):_c('a-button',{attrs:{"country-picker":"","type":"link"},on:{"click":function($event){$event.stopPropagation();return (function () { _vm.form.isPhoneNumberInsideUS = true; _vm.formatPhoneNumber(); _vm.form.errorMessage.country = null; })($event)}}},[_vm._v("Inside\n                                        US")])],1)]},proxy:true}],null,false,189761685)},[_c('a-input',{attrs:{"placeholder":"Contact's phone number","size":"large","allow-clear":""},on:{"input":function($event){return _vm.isPhoneNumberValid()},"blur":function($event){return _vm.formatPhoneNumber()}},model:{value:(_vm.form.phoneNumberFormatted),callback:function ($$v) {_vm.$set(_vm.form, "phoneNumberFormatted", $$v)},expression:"form.phoneNumberFormatted"}},[_c('svg-icon',{staticClass:"h-4 w-4 text-gray-400",attrs:{"slot":"prefix","icon-class":"phone"},slot:"prefix"})],1)],1):_vm._e(),(!_vm.form.isPhoneNumberInsideUS)?_c('label-item',{attrs:{"label":"Choose Phone Number Country","error-message":_vm.form.errorMessage.country}},[_c('country-select',{staticClass:"w-full",attrs:{"country":_vm.form.country,"topCountry":"US"},on:{"input":function (selected) {
                                    if (_vm.form.country) {
                                        _vm.form.errorMessage.country = null
                                    } else {
                                        _vm.form.errorMessage.country = 'Country must be specified.'
                                    }
                                    _vm.updatePhoneComponents();
                                }},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})],1):_vm._e(),(!_vm.contact.phone_number && _vm.contact.status === 'invited' && _vm.isOutreach)?_c('label-item',{staticClass:"col-span-2",attrs:{"label":"Message","desc":"Following message will be forwarding through SMS to the phone number above.","error-message":_vm.form.errorMessage.message}},[_c('a-textarea',{attrs:{"rows":3,"maxlength":500,"placeholder":"Enter your message","allow-clear":"","size":"large"},on:{"focus":function () {
                                    if (_vm.form.message) {
                                        _vm.form.errorMessage.message = null
                                    } else {
                                        _vm.form.errorMessage.message = 'Please enter message to lead.'
                                    }
                                },"input":function () {
    if (_vm.form.message) {
        _vm.form.errorMessage.message = null
    } else {
        _vm.form.errorMessage.message = 'Please enter message to lead.'
    }
}},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.message"}})],1):_vm._e(),(!_vm.contact.email)?_c('label-item',{attrs:{"label":"Email","error-message":_vm.form.errorMessage.email}},[_c('a-input',{attrs:{"placeholder":"Contact's email address","allow-clear":"","size":"large"},on:{"input":function($event){return _vm.isEmailValid()},"blur":function($event){return _vm.isEmailValid()}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}})],1):_vm._e()],1),(!_vm.contact.phone_number)?_c('a-alert',{attrs:{"message":"SMS/MMS Reachability","description":"Please double check contact's phone number. The chat could not start if the phone number is not reachable.","type":"warning"}}):_vm._e(),_c('div',{staticClass:"flex items-center justify-center"},[(!_vm.contact.phone_number && _vm.contact.status === 'invited' && _vm.isOutreach)?_c('a-button',{attrs:{"type":"primary","loading":_vm.loading,"block":"","disabled":_vm.shouldDisableNextButton,"size":"large"},on:{"click":function($event){$event.stopPropagation();return _vm.fullfillInfo(true)}}},[_vm._v("\n                            Start Outreach\n                        ")]):_c('a-button',{attrs:{"type":"primary","loading":_vm.loading,"block":"","disabled":_vm.shouldDisableNextButton,"size":"large"},on:{"click":function($event){$event.stopPropagation();return _vm.fullfillInfo()}}},[_vm._v("\n                            Complete\n                        ")])],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }