<script>
import { ref, computed, onMounted } from "@vue/composition-api";

import { API } from "src/views/components/api";

export default {
    props: {
        listingId: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            default: "100"
        },
        height: {
            type: String,
            default: "80"
        },
        rounded: {
            type: String,
            default: "md"
        },
        tooltipItemSize: {
            type: Number,
            default: 50
        },
        tooltipPlacement: {
            type: String,
            default: "left"
        }
    },
    setup(props) {
        const majorImage = ref("");
        const images = ref([]);

        const fetchImages = async () => {
            try {
                const { data } = await API.getListingImages(props.listingId);
                majorImage.value = data.major;
                images.value = data.images;
            } catch (error) {
                console.error(error);
            }
        };

        onMounted(() => {
            fetchImages();
        });

        const imageDesc = computed(() => {
            const firstImage = images.value[0];
            return firstImage ? firstImage.desc : "";
        });

        return { majorImage, images, imageDesc };
    },
};
</script>

<template>
    <a-tooltip :placement="tooltipPlacement" overlay-class-name="listing-avatar">
        <template #title>
            <div class="grid grid-cols-3 gap-2"
                :style="{ minWidth: `${3 * tooltipItemSize}px`, minHeight: `${tooltipItemSize}px` }">
                <div v-for="(image, i) in images" :key="`tooltip-image-${i}`" class="hover:opacity-50 cursor-pointer">
                    <viewer>
                        <lazy-img v-if="image.url" :src="image.url" src-placeholder="/static/img/PARQAY-watermark.svg"
                            :alt="image.desc" :crossOrigin="$imageFetchPolicy()" class="w-full h-full object-cover rounded"
                            :width="tooltipItemSize" :height="tooltipItemSize"
                            :style="{ width: `${tooltipItemSize}px`, height: `${tooltipItemSize}px` }" />
                    </viewer>
                </div>
            </div>
        </template>
        <lazy-img v-if="majorImage" :src="majorImage" :crossOrigin="$imageFetchPolicy()"
            src-placeholder="/static/img/PARQAY-watermark.svg" :alt="imageDesc" class="flex-shrink-0 object-cover"
            :class="`rounded-${rounded}`" :style="{
                width: `${width}px`,
                height: `${height}px`
            }" />
    </a-tooltip>
</template>
  
<style>
.listing-avatar .ant-tooltip-inner {
    background-color: white !important;
}
</style>