<script>
import { ref } from "@vue/composition-api";
const menuItemFactory = (label, iconName, link = "") => ({
  label,
  iconName,
  link,
  isNativeLink: link.startsWith("http")
});

export default {
  name: "TenantMenu",
  components: {},
  setup(_, ctx) {
    // Variable to control the margin value if notification is shown
    const notificationReady = ref(true);

    const menus = ref([]);

    if (ctx.root.$isLoggedIn()) {
      menus.value = [
        menuItemFactory("Home", "home", "/tenant/home"),
        menuItemFactory("Reservations", "calendar", "/tenant/reservations"),
        menuItemFactory("Vehicles", "car-keys-outlined", "/tenant/vehicles"),
        menuItemFactory("Documents", "document", "/tenant/documents"),
        menuItemFactory("Payments", "card", "/tenant/payments"),
        menuItemFactory("Maintenance", "truck", "/tenant/maintenance"),
        menuItemFactory("Settings", "adjustments", "/tenant/settings")
      ];
    } else {
      if (ctx.root.$route.path.startsWith("/tenant/reservations")) {
        menus.value = [
          menuItemFactory("Reservations", "calendar", "/tenant/reservations")
        ]
      }
      if (ctx.root.$route.path.startsWith("/tenant/documents")) {
        menus.value = [
          menuItemFactory("Documents", "document", "/tenant/documents")
        ]
      }
    }

    console.log("ctx.root.$route.path --> ", ctx.root.$route.path);

    const isActive = path => {
      const currentPath = ctx.root.$route.path;
      return currentPath.startsWith(path);
    };

    return {
      notificationReady,
      menus,
      isActive
    };
  }
};
</script>

<template>
  <div id="tenant-menu" class="hidden md:flex md:flex-shrink-0 overflow-hidden">
    <div class="flex flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col h-0 flex-1 border-r border-gray-200 bg-white" style="height: 100vh">
        <smooth-scroll>
          <div class="flex flex-col overflow-y-auto">
            <div class="flex flex-col items-center justify-center px-4 pt-6 pb-5 space-y-2">
              <router-link tag="a" to="/" class="flex justify-center items-center">
                <img v-if="$getWhiteLabelImages().logoURL" :src="$getWhiteLabelImages().logoURL"
                  class="h-16 object-contain" :style="{ width: '220px' }">
                <svg-icon v-else icon-class="PARQAY-logo" class="h-16" :style="{ width: '220px' }" />
              </router-link>
              <a-tag color="orange">Tenant Access</a-tag>
            </div>
            <nav class="flex-1 px-1 bg-white space-y-2">
              <component :is="menu.isNativeLink ? 'a' : 'router-link'" v-for="(menu, index) in menus" :key="menu.link"
                :href="menu.link" :to="menu.link" class="
                  group
                  h-12
                  flex
                  items-center
                  relative
                  px-4
                  py-1
                  text-sm
                  leading-5
                  font-light
                  rounded
                  hover:font-medium
                  focus:outline-none
                  focus:text-white
                  focus:bg-blue-600
                  transition
                  ease-in-out
                  duration-150
                " :class="{
                  'bg-white text-gray-600 hover:text-blue-600': !isActive(menu.link),
                  'bg-blue-600 text-white font-medium hover:font-semibold hover:bg-blue-500 hover:text-white': isActive(
                    menu.link
                  )
                }">
                <svg-icon :icon-class="menu.iconName" class="h-5 w-5 group-focus:text-white" :class="{
                  'text-white': isActive(menu.link),
                  'mr-3': notificationReady,
                  'mr-6': !notificationReady
                }" />
                {{ menu.label }}
                <div v-if="index == 1 && $isLoggedIn()" class="absolute right-2 my-auto">
                  <a-badge :count="$store.getters.getUnreadMessagesCount()" />
                </div>
              </component>
            </nav>
          </div>
        </smooth-scroll>
        <div v-if="$isLoggedIn()" class="flex items-center justify-center border-t pt-2 pb-3 px-6">
          <a class="text-base text-gray-500 flex w-full items-center justify-center space-x-3"
            @click="$router.push('/dashboard')">
            <svg-icon icon-class="switch" class="h-5 w-5 text-gray-500" />
            <span>Switch to Host</span>
          </a>
        </div>
        <div v-else class="flex items-center justify-center border-t pt-2 pb-3 px-6">
          <a class="text-base text-gray-500 flex w-full items-center justify-center space-x-3"
            @click="$router.push(`/login/tenant?next=${$route.fullPath}`)">
            <span>Login to View More</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
