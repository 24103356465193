<script>
import Dropdown from "src/views/dropdowns/View";
import { ref } from "@vue/composition-api";

export default {
  name: "CountryDropdown",
  components: {
    Dropdown,
  },
  setup() {
    const countryModel = ref([
      {
        id: 0,
        name: "English",
        icon: "/static/img/dashboard/united-states-flag.png",
      },
    ]);

    return {
      countryModel,
    };
  },
};
</script>
<template>
  <dropdown
    :model="countryModel"
    :width="36"
  />
</template>