<script>
import { reactive, ref, computed, onMounted } from "@vue/composition-api";
import EventBus from "src/services/util/event-bus";
import { API } from "src/views/events/api";

export default {
  name: "Pricing",
  components: {},
  setup(props, ctx) {
    const showEdit = ref(
      computed(() => {
        return false;
      })
    );
    const slideBarVisible = ref(false);
    const navToEventSchedule = () => {
      ctx.emit("change", "event-schedule");
    };
    const table = reactive({
      headers: [
        {
          title: "Name",
          align: "center",
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "Associated Events",
          align: "center",
          dataIndex: "events",
          scopedSlots: { customRender: "events" },
        },
        {
          title: "Tiers, Price & Inventory",
          align: "center",
          dataIndex: "details",
          scopedSlots: { customRender: "details" },
        },
        {
          title: "Actions",
          align: "center",
          dataIndex: "actions",
          scopedSlots: { customRender: "actions" },
          width: 100,
          fixed: "right",
        },
      ],
      items: [
        {
          name: "Super Bowl 2018 - Pricing Rule",
          // Associated Events, if events = null, means apply to ALL events
          events: [
            {
              id: "example_event_id_1",
              name: "Super Bowl 2018",
            },
          ],
          details: [
            {
              tier: "Tier 1",
              price: 50,
              inventory: 5,
            },
            {
              tier: "Tier 2",
              price: 70,
              inventory: 2,
            },
            {
              tier: "Tier 3",
              price: 100,
              inventory: 1,
            },
          ],
        },
      ],
    });

    const openEditSlide = () => {
      EventBus.$emit("open-slide-bar", "editEventPricing", {});
    };

    onMounted(() => {
      API.listPricingRules().then((res) => {
        console.log("event pricing api result: " + JSON.stringify(res));
        table.items = res.data;
        // tableLoading.value = false;
      });
    });

    return {
      showEdit,
      table,
      slideBarVisible,
      openEditSlide,
      navToEventSchedule,
      console
    };
  },
};
</script>
<template>
  <div
    class="shadow-sm border overflow-hidden rounded-lg max-w bg-white"
    :style="{ width: $calculateDataTableWidth() }"
  >
    <div class="flex items-center justify-between px-4 py-4 border-b">
      <span class="font-medium text-lg">Event Pricing</span>
      <div class="flex justify-end space-x-4">
        <button
          class="bg-green-400 text-white font-normal py-2 px-4 rounded"
          @click="openEditSlide"
        >
          + Create Rule
        </button>
        <button class="bg-parqay-primary text-white font-normal py-2 px-4 rounded">
          Filter
        </button>
        <button
          v-show="showEdit"
          class="bg-parqay-primary text-white font-bold py-2 px-4 rounded"
        >
          Edit Selected
        </button>
      </div>
    </div>
    <div>
      <!-- <template v-slot:no-data>
          <div
            class="border shadow-sm rounded-lg bg-white p-4 my-4 flex items-center justify-center"
            style="min-height: 300px"
          >
            <span class="font-semibold text-xl text-gray-400"
              >No Pricing Rule</span
            >
          </div>
        </template> -->
      <a-table
        :scroll="{ x: true, y: 500 }"
        :loading="false"
        :columns="table.headers"
        :data-source="table.items"
        row-key="id"
        :custom-row="
          (record, index) => {
            return {
              on: {
                click: (event) => {
                  console.log('row click', event.target.tagName.toLowerCase());
                  if (
                    event.target.tagName.toLowerCase() !== 'svg' &&
                    event.target.tagName.toLowerCase() !== 'use'
                  ) {
                    // openEditSlide();
                  }
                },
              },
            };
          }
        "
        :pagination="false"
      >
        <template
          slot="name"
          slot-scope="name"
        >
          <div class="text-center text-parqay-primary text-md font-bold px-2 py-1">
            {{ name }}
          </div>
        </template>
        <template
          slot="events"
          slot-scope="events"
        >
          <div class="flex flex-col m-2 space-y-2 cursor-pointer">
            <template v-for="(event, i) in events">
              <div
                :key="`associated-event-${i}`"
                class="
                  flex
                  h-8
                  px-1
                  rounded-full
                  bg-blue-500
                  items-center
                  justify-between
                  hover:shadow-lg
                "
                style="min-width: 150px"
                @click="navToEventSchedule"
              >
                <div class="h-6 w-6 bg-white rounded-full">
                  <span class="text-blue-500 font-medium text-base">S</span>
                </div>
                <div class="flex-grow text-white">
                  {{ event.name }}
                </div>
              </div>
            </template>
          </div>
        </template>

        <template
          slot="details"
          slot-scope="details"
        >
          <div class="flex flex-col m-2 space-y-2 cursor-pointer">
            <template v-for="(detail, i) in details">
              <div
                :key="`pricing-detail-${i}`"
                class="
                  flex
                  h-8
                  px-1
                  rounded-lg
                  border border-blue-500
                  items-center
                  justify-between
                  hover:shadow-lg
                "
                style="min-width: 280px"
              >
                <div
                  class="
                    flex-grow
                    text-blue-500
                    border-r border-blue-500
                    font-medium
                  "
                >
                  Tier: {{ detail.tier }}
                </div>
                <div
                  class="
                    flex-grow
                    text-blue-500
                    border-r border-blue-500
                    font-medium
                  "
                >
                  Price: {{ detail.price }}
                </div>
                <div class="flex-grow text-blue-500 font-medium">
                  Inventory: {{ detail.inventory }}
                </div>
              </div>
            </template>
          </div>
        </template>

        <template
          slot="actions"
          slot-scope="actions"
        >
          <button
            class="bg-parqay-primary text-white font-normal py-1 px-4 rounded"
            @click="openEditSlide"
          >
            Edit
          </button>
        </template>
      </a-table>
    </div>
  </div>
</template>
