<script>
export default {
  name: "TenantHeader"
};
</script>
<template>
  <div
    class="tenant-header shadow-sm border-b border-gray-300 inline-flex justify-between flex-grow-0 items-center px-10"
  >
    <div>
      <slot name="left" />
    </div>
    <div class="flex items-center justify-end">
      <slot name="right" />
    </div>
    <slot name="bottom" />
  </div>
</template>
<style lang="scss" scoped>
.tenant-header {
  z-index: 30;
  height: 80px;
  // box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}
</style>
