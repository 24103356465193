<script>
import { ref, computed, onMounted } from "@vue/composition-api";
import First from "./Step3First";
import Next from "./Step3Next";

export default {
  name: "SignupStep3",
  components: {
    First,
    Next,
  },
  props: {
    // Use in account settings - change phone number modal
    modalMode: {
      type: Boolean,
      default: false,
    },
    hashRoute: {
      type: String,
      default: "step-three",
    },
  },
  setup(props, ctx) {
    const currentStep = ref(0);
    const enableNext = ref(false);

    const fullNumber = ref(null);
    const phoneNumber = ref(null);
    const countryCode = ref(null);
    const subscribe = ref([]);

    const showPrevious = ref(
      computed(() => {
        if (props.modalMode) {
          if (currentStep.value == 0) {
            return false;
          }
        }
        return true;
      })
    );

    const handleFullNumberUpdate = (pn) => {
      if (pn) {
        countryCode.value = pn.countryCallingCode;
        phoneNumber.value = pn.nationalNumber;
        fullNumber.value = pn.number;
        ctx.emit("payload-update", "phone_number", pn.number);
      }
    };

    const getSubscribe = (sub, val) => {
      const subIndex = subscribe.value.findIndex(v => v === sub);
      if (val) {
        if (subIndex < 0) {
          subscribe.value.push(sub)
        }
      } else {
        if (subIndex >= 0) {
          subscribe.value.splice(subIndex, 1)
        }
      }
      ctx.emit("payload-update", "subscribe", [sub, val]);
    };

    const prevAction = () => {
      if (currentStep.value == 0) {
        ctx.emit("prev");
        return;
      }
      if (currentStep.value == 1) {
        currentStep.value -= 1;
        return;
      }
    };

    const nextAction = () => {
      if (currentStep.value == 0 && fullNumber.value) {
        currentStep.value += 1;
        return;
      }
      if (currentStep.value == 1) {
        if (!props.modalMode) {
          ctx.emit("next");
        } else {
          ctx.emit("verified", {
            phone_number: fullNumber.value,
            subscribe: subscribe.value
          });
        }
        return;
      }
    };

    const nextButtonStates = () => {
      if (currentStep.value == 0) {
        return {
          title: "Verify",
          enabled: fullNumber.value,
          colorClass: fullNumber.value
            ? "bg-parqay-primary hover:bg-blue-500"
            : "bg-gray-300",
        };
      }
      if (currentStep.value == 1) {
        return {
          title: "Next",
          enabled: enableNext.value,
          colorClass: enableNext.value
            ? "bg-parqay-primary hover:bg-blue-500"
            : "bg-gray-300",
        };
      }
    };

    const route = ctx.root.$route;
    const router = ctx.root.$router;
    onMounted(() => {
      if (!props.modalMode) {
        router.push({ name: route.name, hash: `#${props.hashRoute}` });
      }
    });

    return {
      currentStep,
      countryCode,
      phoneNumber,
      fullNumber,
      enableNext,
      showPrevious,
      handleFullNumberUpdate,
      getSubscribe,
      prevAction,
      nextButtonStates,
      nextAction,
    };
  },
};
</script>
<template>
  <div class="w-full overflow-hidden" style="min-height: 460px">
    <div class="flex flex-col overflow-auto space-y-4">
      <!-- Subcomponents -->
      <first v-if="currentStep == 0" @full-number-updated="handleFullNumberUpdate" @toggle-subscribe="getSubscribe" />
      <next v-if="currentStep == 1" :country-code="countryCode" :phone-number="phoneNumber"
        @verify-complete="enableNext = true" @prev="prevAction" />
      <div class="flex space-x-4">
        <button v-if="showPrevious"
          class="text-base bg-parqay-primary hover:bg-blue-500 rounded-lg text-white px-4 py-2 w-1/2" @click="prevAction">
          Previous
        </button>
        <div v-else class="w-1/2" />
        <button :disabled="!nextButtonStates().enabled" class="text-base rounded-lg text-white px-4 py-2 w-1/2"
          :class="nextButtonStates().colorClass" @click="nextAction">
          {{ nextButtonStates().title }}
        </button>
      </div>
    </div>
  </div>
</template>