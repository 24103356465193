<script>
import { reactive, watch } from "@vue/composition-api";
import { API } from "src/views/documents/api";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        templatesToReview: {
            type: Array,
            require: true
        }
    },
    setup(props, ctx) {
        const controls = reactive({
            loading: {
                continue: false
            },
            consent: false
        })
        watch(() => props.visible, (val) => {
            if (val) {
                controls.consent = false;
                form.templates = props.templatesToReview.map(t => {
                    delete t['_id'];
                    return t;
                });
            }
        })
        const form = reactive({
            signature_type: "simple_signature",
            request_message: null,
            contact_type: "email",
            contact_method: null,
            templates: props.templatesToReview
        })
        const createTemplateReviewRequest = async () => {
            controls.loading.continue = true;
            try {
                await API.createTemplateReviewRequest(form);
                setTimeout(() => {
                    controls.loading.continue = false;
                }, 2000);
                ctx.emit("close");
                ctx.emit("continue");
            } catch (error) {
                ctx.root.$openNotification("Error", error.message);
                controls.loading.continue = false;
            }
        }
        return {
            controls,
            form,
            createTemplateReviewRequest
        }
    }
}
</script>

<template>
    <a-modal :visible="visible" title="Template Review" width="65vw" @cancel="$emit('close')">
        <div class="flex flex-col space-y-5">
            <span class="text-center text-base font-medium">Let our team know how your document
                template is
                going to work
            </span>
            <p class="text-sm text-gray-500">
                Choose an option that best fits your requirement below. If you prefer more template fields customization,
                choose the second option. Please specify the details in the text box and our team will help you insert those
                fields into your template.
                A review normally takes about less than a day, after that, your template will be available to your tenants
                for all new reservations.
            </p>
            <div class="flex flex-col space-y-2 p-6 rounded-lg border bg-white">
                <a-radio-group v-model="form.signature_type">
                    <a-radio :style="{
                        display: 'block',
                        height: '30px',
                        lineHeight: '30px',
                    }" value="simple_signature">
                        I just need a signature field at the bottom of the template
                    </a-radio>
                    <a-radio :style="{
                        display: 'block',
                        height: '30px',
                        lineHeight: '30px',
                    }" value="advanced_signature">
                        I need more customizations: more fields for tenants to input
                    </a-radio>
                </a-radio-group>
            </div>
            <a-textarea v-if="form.signature_type === 'advanced_signature'" v-model="form.request_message"
                placeholder="Enter any instructions or guidance for our review team about positions of each input fields, and any questions you wish to ask"
                :auto-size="{ minRows: 5, maxRows: 8 }" />
            <a-divider>Associated templates</a-divider>
            <div class="grid grid-cols-2 gap-4 py-4">
                <div v-for="(temp, i) in templatesToReview" :key="`template-to-review-${i}`"
                    class="flex items-center border rounded space-x-4 px-4 py-2">
                    <svg-icon class="w-8 h-8 text-parqay-primary" icon-class="document" />
                    <div class="flex items-center justify-between w-full">
                        <span class="text-sm font-medium text-black">{{ temp.name }}</span>
                        <a-tag color="geekblue">{{ temp.docs_to_sign.length }} files attached</a-tag>
                    </div>
                </div>
            </div>
            <a-divider>Contact info</a-divider>
            <div class="grid grid-cols-3 justify-items-center">
                <span class="text-sm my-auto col-span-2">
                    If your contact method is different from that in your account setting, please specify
                </span>
                <a-input-group compact v-model="form.contact_type">
                    <a-select default-value="email">
                        <a-select-option value="email">
                            Email
                        </a-select-option>
                        <a-select-option value="phone_number">
                            Phone
                        </a-select-option>
                    </a-select>
                    <a-input v-model="form.contact_method" placeholder="Contact method" style="width: 65%" />
                </a-input-group>
            </div>
        </div>
        <template #footer>
            <div class="flex items-center justify-end space-x-2">
                <a-checkbox v-model="controls.consent">
                    I understand the process of templates review, let me continue
                </a-checkbox>
                <a-button type="primary" :loading="controls.loading.continue" :disabled="!controls.consent"
                    @click="createTemplateReviewRequest()">
                    Continue
                </a-button>
            </div>
        </template>
    </a-modal>
</template>