export const Dashboard = {
  table1: [
    {
      name: "John Michael",
      avator: "/static/img/dashboard/girl-profile-demo.jpg",
      date: "10/09/2018",
      requestType: "parking",
      price: "$100.00",
      location: "123 Main St, San Francisco, CA",
    },
  ],
  table2: [
    {
      name: "John Michael",
      avator: "/static/img/dashboard/girl-profile-demo.jpg",
      date: "10/09/2018",
      confirmation: "ABC123",
      price: "$100.00",
      location: "123 Main St, San Francisco, CA",
    },
  ],
  side: {
    name: "John Doe",
    requestType: "parking",
    dates: [new Date(2020, 9, 1), new Date(2020, 11, 1)],
    revenue: "1000000",
  },
};

export const Reservation = {
  table1: [
    {
      avator: "/static/img/dashboard/guy-glass-profile-demo.jpg",
      id: "example_id", //注：UI设计里的 confirmation # 指的是 "confirmation_id" 不是这个id. 这个id的用途是定位approve/deny到指定订单(见，home - 更新订单 - 通过/拒绝订单)
      profile_picture: "https://example.url",
      name: "John Michael",
      date: "2020-05-05T12:00:00-07:00", //注： 时间格式为YYYY-MM-DDTHH:mm:ssZZ 最后要加上相对于utc的时差，前端显示可以忽略时差部分，但是包括时差部分可以用于未来加入的过滤功能
      confirmation_id: "9oiQDoLy1FwkRgZyoSm8",
      price: "$100.00",
      listing: {
        id: "example_listing_id",
        name: "Evanson N - Monthly Parking Garage",
        type: "parking", // 注：listing > type 变量用于过滤订单类型，5种返回值中的一种: Parking, Coworking, Stays, Car Rentals, Services
      },
      // 预定开始结束时间，时间格式：从上
      duration: {
        from: "2021-02-22T08:00:00-07:00",
        to: "2021-02-26T10:00:00-07:00",
      },
      contact_id: "example_contact_id", // 注：这个id用于定位联系人的相关端点(见，通用 - 联系人面板 - *)
      // 类型：array
      docs: [
        {
          name: "doc1",
          id: "example_doc1_id",
        },
        {
          name: "doc2",
          id: "example_doc2_id",
        },
      ],
      approved: null, //三种类型返回值: true/false/null, null表示还未进行选择
      deny_message: null, //如果被deny，该值返回deny原因
    },
    {
      avator: "/static/img/dashboard/guy-profile-demo.jpg",
      id: "example_id2", //注：UI设计里的 confirmation # 指的是 "confirmation_id" 不是这个id. 这个id的用途是定位approve/deny到指定订单(见，home - 更新订单 - 通过/拒绝订单)
      profile_picture: "https://example.url",
      name: "Guanjiu Zhang",
      date: "2020-05-05T12:00:00-07:00", //注： 时间格式为YYYY-MM-DDTHH:mm:ssZZ 最后要加上相对于utc的时差，前端显示可以忽略时差部分，但是包括时差部分可以用于未来加入的过滤功能
      confirmation_id: "TVYXsm8at7h0FuML70jv",
      price: "$200.00",
      listing: {
        id: "example_listing_id",
        name: "Evanson N - Monthly Parking Garage",
        type: "Car Share", // 注：listing > type 变量用于过滤订单类型，5种返回值中的一种: Parking, Coworking, Stays, Car Rentals, Services
      },
      // 预定开始结束时间，时间格式：从上
      duration: {
        from: "2021-02-06T08:00:00-07:00",
        to: "2021-03-22T10:00:00-07:00",
      },
      contact_id: "example_contact_id", // 注：这个id用于定位联系人的相关端点(见，通用 - 联系人面板 - *)
      // 类型：array
      docs: [
        {
          name: "doc1",
          id: "example_doc1_id",
        },
        {
          name: "doc2",
          id: "example_doc2_id",
        },
      ],
      approved: false, //三种类型返回值: true/false/null, null表示还未进行选择
      deny_message: "Testing Reason", //如果被deny，该值返回deny原因
    },
    {
      avator: "/static/img/dashboard/girl-profile-demo.jpg",
      id: "example_id3", //注：UI设计里的 confirmation # 指的是 "confirmation_id" 不是这个id. 这个id的用途是定位approve/deny到指定订单(见，home - 更新订单 - 通过/拒绝订单)
      profile_picture: "https://example.url",
      name: "Victoria Palmer",
      date: "2020-05-05T12:00:00-07:00", //注： 时间格式为YYYY-MM-DDTHH:mm:ssZZ 最后要加上相对于utc的时差，前端显示可以忽略时差部分，但是包括时差部分可以用于未来加入的过滤功能
      confirmation_id: "yXt9rBQup7mnbOrjyR2I",
      price: "$200.00",
      listing: {
        id: "example_listing_id",
        name: "Evanson N - Monthly Parking Garage",
        type: "Car Share", // 注：listing > type 变量用于过滤订单类型，5种返回值中的一种: Parking, Coworking, Stays, Car Rentals, Services
      },
      // 预定开始结束时间，时间格式：从上
      duration: {
        from: "2021-02-01T08:00:00-07:00",
        to: "2021-02-01T10:00:00-07:00",
      },
      contact_id: "example_contact_id", // 注：这个id用于定位联系人的相关端点(见，通用 - 联系人面板 - *)
      // 类型：array
      docs: [
        {
          name: "doc1",
          id: "example_doc1_id",
        },
        {
          name: "doc2",
          id: "example_doc2_id",
        },
      ],
      approved: true, //三种类型返回值: true/false/null, null表示还未进行选择
      deny_message: null, //如果被deny，该值返回deny原因
    },
    {
      avator: "/static/img/dashboard/girl-profile-demo.jpg",
      id: "example_id4", //注：UI设计里的 confirmation # 指的是 "confirmation_id" 不是这个id. 这个id的用途是定位approve/deny到指定订单(见，home - 更新订单 - 通过/拒绝订单)
      profile_picture: "https://example.url",
      name: "Victoria Palmer",
      date: "2020-05-05T12:00:00-07:00", //注： 时间格式为YYYY-MM-DDTHH:mm:ssZZ 最后要加上相对于utc的时差，前端显示可以忽略时差部分，但是包括时差部分可以用于未来加入的过滤功能
      confirmation_id: "yXt9rBQup7mnbOrjyR2I",
      price: "$200.00",
      listing: {
        id: "example_listing_id",
        name: "Evanson N - Monthly Parking Garage",
        type: "Car Share", // 注：listing > type 变量用于过滤订单类型，5种返回值中的一种: Parking, Coworking, Stays, Car Rentals, Services
      },
      // 预定开始结束时间，时间格式：从上
      duration: {
        from: "2021-02-03T08:00:00-07:00",
        to: "2021-02-05T10:00:00-07:00",
      },
      contact_id: "example_contact_id", // 注：这个id用于定位联系人的相关端点(见，通用 - 联系人面板 - *)
      // 类型：array
      docs: [
        {
          name: "doc1",
          id: "example_doc1_id",
        },
        {
          name: "doc2",
          id: "example_doc2_id",
        },
      ],
      approved: true, //三种类型返回值: true/false/null, null表示还未进行选择
      deny_message: null, //如果被deny，该值返回deny原因
    },
    {
      avator: "/static/img/dashboard/girl-profile-demo.jpg",
      id: "example_id5", //注：UI设计里的 confirmation # 指的是 "confirmation_id" 不是这个id. 这个id的用途是定位approve/deny到指定订单(见，home - 更新订单 - 通过/拒绝订单)
      profile_picture: "https://example.url",
      name: "Victoria Palmer",
      date: "2020-05-05T12:00:00-07:00", //注： 时间格式为YYYY-MM-DDTHH:mm:ssZZ 最后要加上相对于utc的时差，前端显示可以忽略时差部分，但是包括时差部分可以用于未来加入的过滤功能
      confirmation_id: "yXt9rBQup7mnbOrjyR2I",
      price: "$200.00",
      listing: {
        id: "example_listing_id",
        name: "Evanson N - Monthly Parking Garage",
        type: "Car Share", // 注：listing > type 变量用于过滤订单类型，5种返回值中的一种: Parking, Coworking, Stays, Car Rentals, Services
      },
      // 预定开始结束时间，时间格式：从上
      duration: {
        from: "2021-02-11T08:00:00-07:00",
        to: "2021-02-15T10:00:00-07:00",
      },
      contact_id: "example_contact_id", // 注：这个id用于定位联系人的相关端点(见，通用 - 联系人面板 - *)
      // 类型：array
      docs: [
        {
          name: "doc1",
          id: "example_doc1_id",
        },
        {
          name: "doc2",
          id: "example_doc2_id",
        },
      ],
      approved: true, //三种类型返回值: true/false/null, null表示还未进行选择
      deny_message: null, //如果被deny，该值返回deny原因
    },
  ],
  table2: [
    {
      avator: "/static/img/dashboard/girl-profile-demo.jpg",
      name: "ABC",
      date: "10/09/2018",
      confirmation: "ABC123",
      price: "$100.00",
      location: "123 Main St, San Francisco, CA",
    },
  ],
};

export const Listing = {
  table: [
    {
      id: 1,
      name: "ABC",
      location: "San Francisco",
      type: "parking",
      active: true,
    },
    {
      id: 2,
      name: "ABC",
      location: "San Francisco",
      type: "coworking",
      active: false,
    },
  ],
};

export const Events = {
  table: [
    {
      date: "10/09/2018",
      venue: "Venue A",
      eventType: "Baseball",
      beforeOffest: "3 hours",
      afterOffest: "1 hours",
      tiers: "2",
      inventory: "3",
      price: "$100.00",
    },
  ],
};

export const Tenants = {
  table1: [
    {
      name: "John Michael",
      avator: "/static/img/dashboard/girl-profile-demo.jpg",
      date: "10/09/2018",
      requestType: "parking",
      price: "$100.00",
      location: "123 Main St, San Francisco, CA",
    },
  ],
  table2: [
    {
      name: "John Michael",
      avator: "/static/img/dashboard/girl-profile-demo.jpg",
      date: "10/09/2018",
      confirmation: "ABC123",
      price: "$100.00",
      location: "123 Main St, San Francisco, CA",
    },
  ],
};

export const Pricing = {
  table: [
    {
      name: "ABC",
      type: "All Events",
      beforeOffset: "3 HOURS",
      affterOffset: "1 HOUR",
      tiers: "2",
      inventory: "ABC123",
      price: "$100.00",
    },
  ],
  side: {
    venue: "John",
    type: "John",
    tiers: [{ name: "11", price: "11", inventory: "22" }],
  },
};

export const Document = {
  table1: [
    {
      avator: "/static/img/dashboard/girl-profile-demo.jpg",
      name: "ABC",
      desc: "Plate # 1234567",
      date: "10/09/2018",
      connect: "N/A",
      checkbox: true,
    },
  ],

  side: {
    name: "John",
    type: "123",
    dates: [new Date(2020, 9, 1), new Date(2020, 11, 1)],
    payout: "1000000",
  },
};

export const Team = {
  table1: [
    {
      contractor: "John Michael",
      skill: "Plumber",
      id: "ABC123",
      rate: "$100.00",
      location: "123 Main St, San Francisco, CA",
    },
  ],
  side: {
    name: "John Doe",
    requestType: "parking",
    dates: [new Date(2020, 9, 1), new Date(2020, 11, 1)],
    revenue: "1000000",
  },
};
