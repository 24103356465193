<script>
import { Dropbox } from "dropbox";
import { Tokens } from "src/services/constants";
import { onMounted, ref } from "@vue/composition-api";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// import "filepond-plugin-image-pdf-overlay/dist/filepond-plugin-image-overlay.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
// import FilePondPluginImagePdfOverlay from "filepond-plugin-image-pdf-overlay";
import SparkMD5 from "spark-md5";

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImagePreview,
  // FilePondPluginImagePdfOverlay
);

export const getDbxTemporaryLink = async file_path => {
  const dbx = new Dropbox({ accessToken: Tokens.DROPBOX_ACCESS_TOKEN });
  let resp;
  try {
    resp = await dbx.filesGetTemporaryLink({ path: file_path });
    return resp.result.link;
  } catch (error) {
    console.error(error);
  }
};

export const fileUrlsLoader = async docs => {
  let urls = [];
  for (let doc of docs) {
    if (doc.url) {
      urls.push(doc.url);
    }
    if (doc.file_url) {
      urls.push(doc.file_url);
    }
    if (doc.file_path) {
      urls.push(await getDbxTemporaryLink(doc.file_path));
    }
    if (doc.dbx_path) {
      urls.push(await getDbxTemporaryLink(doc.dbx_path));
    }
  }
  return urls;
};

// Update Dropbox download behavior through query string update
const updateQueryStringParameter = (uri, key, value) => {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
};
export const dropboxDocUploader = async (payload, savePath = null) => {
  const { file, name, id, hash } = payload;
  const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;
  const dbx = new Dropbox({ accessToken: Tokens.DROPBOX_ACCESS_TOKEN });

  let resp;

  if (file.size < UPLOAD_FILE_SIZE_LIMIT) {
    // File is smaller than 150 Mb - use filesUpload API

    try {
      resp = await dbx.filesUpload({
        path: savePath
          ? savePath + "/" + hash + "." + name.split(".").pop()
          : "/" + hash + "." + name.split(".").pop(),
        contents: file
      });
      console.log("dropboxDocUploader >> resp: ", resp);
    } catch (error) {
      console.error(error);
      return { error: error, ...payload };
    }
  } else {
    // File is bigger than 150 Mb - use filesUploadSession* API
    const maxBlob = 8 * 1000 * 1000; // 8Mb - Dropbox JavaScript API suggested max file / chunk size

    var workItems = [];

    var offset = 0;

    while (offset < file.size) {
      var chunkSize = Math.min(maxBlob, file.size - offset);
      workItems.push(file.slice(offset, offset + chunkSize));
      offset += chunkSize;
    }

    const task = workItems.reduce((acc, blob, idx, items) => {
      if (idx == 0) {
        // Starting multipart upload of file
        return acc.then(function () {
          return dbx
            .filesUploadSessionStart({ close: false, contents: blob })
            .then(response => response.session_id);
        });
      } else if (idx < items.length - 1) {
        // Append part to the upload session
        return acc.then(function (sessionId) {
          var cursor = { session_id: sessionId, offset: idx * maxBlob };
          return dbx
            .filesUploadSessionAppendV2({
              cursor: cursor,
              close: false,
              contents: blob
            })
            .then(() => sessionId);
        });
      } else {
        // Last chunk of data, close session
        return acc.then(function (sessionId) {
          var cursor = { session_id: sessionId, offset: file.size - blob.size };
          var commit = {
            path: "/" + file.name,
            mode: "add",
            autorename: true,
            mute: false
          };
          return dbx.filesUploadSessionFinish({
            cursor: cursor,
            commit: commit,
            contents: blob
          });
        });
      }
    }, Promise.resolve());

    try {
      resp = await task;
      console.log("dropboxDocUploader[>150MB] >> resp: ", resp);
    } catch (error) {
      console.error(error);
      return { error: error, ...payload };
    }
  }

  const fileLink = resp.result.path_lower;
  try {
    // resp = await dbx.sharingCreateSharedLinkWithSettings({ path: fileLink });
    // let permalink = updateQueryStringParameter(resp.result.url, "dl", "1");
    // console.log("permalink: ", permalink);
    resp = await dbx.filesGetTemporaryLink({ path: fileLink });
    let templink = resp.result.link;
    payload.file_path = fileLink;
    delete payload["upload_pending"];
    // console.log("filesGetTemporaryLink: ", resp.result.link)
    return { templink, ...payload };
  } catch (error) {
    console.error(error);
    return { error: error, ...payload };
  }
};

export default {
  name: "doc-dropzone",
  components: {
    FilePond
  },
  props: {
    templateId: String,
    docs: {
      type: Array,
      default: () => []
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    const dropzoneOptions = ref({
      url: "post-images",
      autoProcessQueue: false,
      createImageThumbnails: false,
      previewTemplate: "<div/>"
    });
    const pond = ref(null);
    const preloadedFiles = ref([]);

    const showDropzoneHoverEffect = ref(false);

    const hoverDropzone = () => {
      showDropzoneHoverEffect.value = true;
    };
    const leaveDropzone = () => {
      showDropzoneHoverEffect.value = false;
    };

    const generateFileMD5Hash = (file, callback) => {
      var blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice,
        chunkSize = 2097152, // Read in chunks of 2MB
        chunks = Math.ceil(file.size / chunkSize),
        currentChunk = 0,
        spark = new SparkMD5.ArrayBuffer(),
        fileReader = new FileReader();

      fileReader.onload = function (e) {
        console.log("read chunk nr", currentChunk + 1, "of", chunks);
        spark.append(e.target.result); // Append array buffer
        currentChunk++;

        if (currentChunk < chunks) {
          loadNext();
        } else {
          const fileHash = spark.end(); // Compute hash
          console.log("finished loading");
          console.info("computed hash", fileHash);
          callback(fileHash);
        }
      };

      fileReader.onerror = function () {
        console.warn("oops, something went wrong.");
      };

      function loadNext() {
        var start = currentChunk * chunkSize,
          end = start + chunkSize >= file.size ? file.size : start + chunkSize;

        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
      }

      loadNext();
    };

    const docFileParser = fileObj => {
      const { id, file, fileType, source } = fileObj;
      console.log("docFileParser: ", fileObj);
      generateFileMD5Hash(file, fileHash => {
        console.log("hash: ", fileHash);
        const existed = props.docs.find(v => v.hash === fileHash);
        const existedIndex = props.docs.findIndex(v => v.hash === fileHash);
        if (!existed) {
          // A hack to bypass addfile event trigger when initialize dropzone
          file.processed = true;
          props.docs.push({
            id: id,
            file: file,
            name: file.name,
            hash: fileHash,
            type: fileType,
            upload_pending: true
          });
          // nextTick(() => {
          //   document.getElementById(id).scrollIntoView({ behavior: "smooth" });
          // });
        } else {
          if (typeof source === "string") {
            // Loaded from file_url or file_path
            props.docs.splice(existedIndex, 1, existed);
          } else {
            // Loaded from file Blob
            // Newly dropped file won't have processed=true
            if (!file.processed) {
              // Remove file from preview (Not from props.docs)
              pond.value.removeFile(file);
            }
          }
        }
      });
    };

    const handleAddNewDoc = (error, file) => {
      console.log("file: ", file);
      if (props.docs.filter(v => v.file_url).map(v => v.file_url).includes(file.source)) {
        console.log("Dropzone: handleAddNewDoc --> predefined file detected");
        return
      }
      if (!error) {
        docFileParser(file);
      } else {
        console.log("error: ", error);
      }
    };

    const handleRemoveDoc = (error, file) => {
      console.log("removing file: ", file);
      console.log("error: ", error);
      generateFileMD5Hash(file.file, fileHash => {
        const docIndex = props.docs.findIndex(v => v.hash === fileHash);
        if (docIndex >= 0) {
          // const deleted = _.cloneDeep(props.docs[docIndex]);
          // console.log("uploaded doc deleted: ", deleted);
          // ctx.emit("deleted-uploaded", {
          //   templateId: props.templateId,
          //   doc: deleted
          // });
          props.docs.splice(docIndex, 1);
        }
      });
    };

    onMounted(async () => {
      preloadedFiles.value = [
        ...(await fileUrlsLoader(props.docs)),
        ...props.docs.filter(v => v.file).map(v => v.file)
      ];
    });

    return {
      dropzoneOptions,
      pond,
      preloadedFiles,
      showDropzoneHoverEffect,
      hoverDropzone,
      leaveDropzone,
      handleAddNewDoc,
      handleRemoveDoc,
      console
    };
  }
};
</script>
<template>
  <file-pond ref="pond" :files="preloadedFiles" allow-multiple :allow-remove="isEditable" :allow-browse="isEditable"
    :allow-drop="isEditable" :allow-paste="isEditable" :instant-upload="false" drop-validation max-file-size="3MB"
    :image-preview-max-height="150" credits="{}"
    :label-idle="isEditable ? '+ Drop / Paste PDF(s) here' : 'Required files are shown below'"
    accepted-file-types="image/png, image/jpeg, application/pdf" disabled @init="() => {
      console.log('FilePond has initialized');
    }" @addfile="handleAddNewDoc" @removefile="handleRemoveDoc" />
</template>
