<script>
import { ref, reactive, onMounted } from "@vue/composition-api";
import DownloadAppBadge from "src/views/components/DownloadAppBadge";
import { saveUserData, authCallback } from "src/plugins/prototype";
import { API } from "src/views/auth/api";
import SocialLogins from "./components/SocialLogins.vue";

export default {
  name: "tenant-login",
  components: {
    DownloadAppBadge,
    SocialLogins,
  },
  setup(props, ctx) {
    const email = ref(null);
    const password = ref(null);
    const loading = reactive({
      signin: false,
      facebook: true,
      google: true,
      apple: true,
    });
    const normalLogin = async () => {
      if (email.value && password.value) {
        var res;
        try {
          res = await API.login({
            email: email.value,
            password: password.value,
            automatic_login: true,
            source: "tenant",
          });
          loading.signin = false;
          console.log("login api result: ", res);
          saveUserData(res.data);
          authCallback();
          console.log("/next/" + res.data.next_route);
          const previousRoute = ctx.root.$route.query.next;
          if (res.data.extra.length > 0) {
            ctx.root.$router.push({
              name: "fullfill-profile",
              params: previousRoute || res.data.next_route
            });
          } else {
            if (previousRoute) {
              ctx.root.$router.push(previousRoute);
            } else {
              ctx.root.$router.push("/next/" + res.data.next_route);
            }
          }
        } catch (error) {
          loading.signin = false;
          ctx.root.$openNotification(
            "Login Error",
            error.message
          )
        }
      }
    };
    const handleLogin = async (provider) => {
      if (loading[provider]) {
        return;
      }
      loading.signin = true;
      switch (provider) {
        case "facebook":
          // facebookLogin();
          break;
        case "google":
          // googleLogin();
          break;
        case "apple":
          // appleLogin();
          break;
        default:
          normalLogin();
          break;
      }
    };
    onMounted(() => {
      console.log("tenant-login -> previous route: ", ctx.root.$route.query.next)
    })
    return {
      loading,
      email,
      password,
      handleLogin,
    };
  },
};
</script>

<template>
  <div class="hero-pattern flex flex-col justify-center h-screen relative">
    <smooth-scroll>
      <img class="md:hidden object-cover h-52 w-full" src="/static/img/dashboard/landing-hero-city.jpg" alt="" />

      <div class="hidden md:flex flex-col justify-center items-center pb-6">
        <div class="mt-20 flex items-center justify-center flex-shrink-0">
          <router-link tag="a" to="/" class="flex justify-center items-center">
            <svg-icon icon-class="PARQAY-logo" class="h-24 w-full pr-6" />
          </router-link>
        </div>
        <h2 class="mt-2 text-center text-2xl font-bold text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div class="md:my-4 md:mx-auto md:w-full md:max-w-md">
        <div class="bg-white pt-6 pb-4 px-8 shadow-sm border md:rounded-lg md:px-10">
          <div class="md:hidden flex flex-col space-y-1 mb-6">
            <router-link tag="a" to="/" class="flex justify-center items-center">
              <svg-icon icon-class="PARQAY-logo" class="h-20 w-auto pr-6" />
            </router-link>
            <h4 class="text-xl font-bold text-gray-900 text-center">
              Tenant Access
            </h4>
            <h2 class="pt-3 text-center text-base font-medium text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <div class="space-y-6">
            <div class="space-y-2">
              <label for="email" class="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <a-input required v-model="email" size="large" placeholder="Enter email address"
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 md:text-sm" />
            </div>

            <div class="space-y-2">
              <label for="password" class="block text-sm font-medium text-gray-700">
                Password
              </label>
              <a-input-password required v-model="password" size="large" placeholder="Enter your password" />
            </div>

            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <input id="remember_me" name="remember_me" type="checkbox"
                  class="h-4 w-4 text-parqay-primary focus:ring-indigo-500 border-gray-300 rounded" />
                <label for="remember_me" class="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div class="text-sm">
                <a class="font-medium text-parqay-primary hover:text-blue-500"
                  @click.stop="$bus.$emit('open-password-reset')">
                  Forgot your password?
                </a>
              </div>
            </div>

            <div class="flex flex-col space-y-2">
              <a-button :loading="loading.signin" type="primary" size="large" @click="handleLogin()">
                Sign in
              </a-button>
              <a-button size="large" @click="$router.push('/sign-up/tenant')">
                Sign Up
              </a-button>
              <a-button size="large" @click="$router.push('/org/login')">
                Company / Org Login
              </a-button>
            </div>
          </div>
          <div class="pt-5">
            <span class="text-center text-sm text-gray-500">
              If you are a host, please sign-in at our
              <router-link tag="a" to="/login" class="font-medium text-parqay-primary hover:text-blue-500">
                Online Portal
              </router-link>
            </span>
          </div>

          <div class="mt-6">
            <div class="relative">
              <div class="absolute inset-0 flex items-center">
                <div class="w-full border-t border-gray-300" />
              </div>
              <div class="relative flex justify-center text-sm">
                <span class="px-2 bg-white text-gray-500">
                  Or continue with
                </span>
              </div>
            </div>

            <social-logins :loading="loading" tenant />
          </div>
          <download-app-badge class="border-t mt-4 pt-4 mb-20 md:mb-auto" />
        </div>
      </div>
    </smooth-scroll>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .ant-btn {
    @apply text-sm;
  }
}
</style>

<style scoped>
.hero-pattern {
  @apply bg-gray-50;
}

@media (min-width: 1024px) {
  .hero-pattern {
    background-size: cover;
    background-position: bottom;
    background-blend-mode: multiply, luminosity;
    background-image: url("/static/img/dashboard/landing-hero-city.jpg");
    @apply bg-gray-50;
  }
}
</style>
