<script>
import {
  nextTick,
  onMounted,
  reactive,
  ref,
  toRef,
  computed
} from "@vue/composition-api";
import Draggable from "vuedraggable";
import vue2Dropzone from "vue2-dropzone";
import {
  getAvatarURL,
  imageDataUploader,
  recognizeCarInImage
} from "src/views/components/util";
import round from "lodash/round";

export default {
  name: "images-uploader",
  components: {
    Draggable,
    vueDropzone: vue2Dropzone
  },
  props: {
    model: {
      type: Array,
      default: () => {
        // return PropertyImages;
        return [];
      }
    },
    instantUpload: {
      type: Boolean,
      default: false
    },
    uploadRequester: {
      type: Function,
      default: () => { }
    },
    requesterPayload: {
      type: Object,
      default: () => {
        return {};
      }
    },
    recognize: {
      type: String
    }
  },
  setup(props, ctx) {
    const openNotification = ctx.root.$openNotification;
    const images = ref([]);
    props.model.forEach(async (url, i) => {
      const image = {
        url: await getAvatarURL(url),
        progress: 0,
        loading: false,
        dataUrl: null,
        hovering: false
      }
      images.value.push(image);
    });
    // const images = toRef(props, 'model')
    // const images = ref(props.imagesModel);
    const dropzoneActive = ref(false);
    const dropzoneOptions = reactive({
      url: "post-images",
      autoProcessQueue: false,
      createImageThumbnails: false,
      previewTemplate: "<div/>"
    });

    const showDropzoneHoverEffect = ref(false);

    const previewImages = computed(() => {
      var pi = [];
      images.value.forEach((image, i) => {
        pi.push(image.url || image.dataUrl);
      });
      return pi;
    });

    const hoverDropzone = () => {
      showDropzoneHoverEffect.value = true;
      toggleDropzoneActive(true);
    };
    const leaveDropzone = () => {
      showDropzoneHoverEffect.value = false;
      toggleDropzoneActive(false);
    };

    const addedNewImage = file => {
      console.log("addedNewImage", file);
      var fr = new FileReader();
      fr.onload = function () {
        const image = {
          url: null,
          progress: 0,
          loading: false,
          dataUrl: fr.result,
          hovering: false,
          recognizing: false,
          vehicleData: null
        };
        console.log("image dataUrl", image);
        images.value.push(image);
        if (props.instantUpload || props.recognize === "vehicle") {
          uploadImage(fr.result, image);
        }
      };
      fr.readAsDataURL(file);
    };

    const getAllAddedImagesDataUrls = () => {
      var dataUrls = [];
      images.value.forEach((image, i) => {
        if (image.dataUrl) {
          dataUrls.push(image.dataUrl);
        }
      });
      return dataUrls;
    };

    const getAllAddedImagesUrls = () => {
      var urls = [];
      images.value.forEach((image, i) => {
        if (image.url) {
          urls.push(image.url);
        }
      });
      return urls;
    };

    const toggleDropzoneActive = v => {
      dropzoneActive.value = v;
    };

    const uploadImage = async (dataUrl, imageObj) => {
      if (props.recognize === "vehicle") {
        imageObj.recognizing = true;
      } else {
        imageObj.loading = true;
      }
      try {
        const downloadURL = await imageDataUploader(
          dataUrl,
          0,
          1
        );
        if (props.recognize === "vehicle") {
          imageObj.progress = 100;
          imageObj.loading = false;
          imageObj.url = downloadURL;
          imageObj.dataUrl = null;
          recognizeCarInImage(imageObj);
        } else {
          props
            .uploadRequester(props.requesterPayload.listingId, downloadURL)
            .then(() => {
              imageObj.progress = 100;
              imageObj.loading = false;
              imageObj.url = downloadURL;
              imageObj.dataUrl = null;
            })
            .catch(error => {
              openNotification("Image Upload Error", `${error}`);
            });
        }
      } catch (error) {
        openNotification("Image Upload Error", `${error}`);
      }
    };

    onMounted(() => {
      nextTick(() => {
        // dropzoneOptions.previewTemplate = document.querySelector('#dropzone-mask').innerHTML
      });
    });

    return {
      images,
      dropzoneActive,
      dropzoneOptions,
      showDropzoneHoverEffect,
      previewImages,
      hoverDropzone,
      leaveDropzone,
      addedNewImage,
      toggleDropzoneActive,
      getAllAddedImagesDataUrls,
      getAllAddedImagesUrls,
      round
    };
  }
};
</script>
<template>
  <div>
    <div class="images-uploader my-2">
      <viewer :images="previewImages">
        <draggable class="grid grid-cols-3 gap-4">
          <div v-for="(image, i) in images" :key="`image-${i}`" class="h-36 relative overflow-hidden"
            @mouseenter="() => (image.hovering = true)" @mouseleave="() => (image.hovering = false)">
            <img class="w-full h-full object-cover" :src="image.url || image.dataUrl" />
            <div v-if="image.hovering && image.vehicleData" class="
                absolute
                inset-0
                bg-gray-500
                opacity-50
                pointer-events-none
              " />
            <div v-if="image.hovering && image.vehicleData" class="absolute inset-0 pointer-events-none">
              <div class="flex h-full items-center">
                <div class="flex flex-col items-center justify-center space-y-0.5">
                  <div class="
                      text-center text-base
                      font-medium
                      text-white
                      border-b
                    ">
                    AI Detect Result
                  </div>
                  <div class="
                      flex
                      items-center
                      text-center text-lg
                      font-medium
                      text-white
                    ">
                    {{ image.vehicleData.make }}
                  </div>
                  <div class="flex items-center text-center text-xs text-white">
                    {{ image.vehicleData.model }}
                  </div>
                  <div class="flex items-center text-center text-xs text-white">
                    {{ image.vehicleData.body_style }} |
                    {{ image.vehicleData.year }}
                  </div>
                  <div class="flex items-center text-center text-xs text-white">
                    Confidence:
                    <div class="ml-2 py-0.5 px-1 bg-white rounded text-pink-500" style="font-size: 0.5rem">
                      {{
                          round(image.vehicleData.confidence, 1).toFixed(1)
                      }}%
                    </div>
                  </div>
                  <div class="flex items-center text-center text-xs text-white">
                    Plate # {{ image.vehicleData.plate_number || "N/A" }}
                    <div v-if="image.vehicleData.plate_number" class="ml-2 py-0.5 px-1 bg-white rounded text-pink-500"
                      style="font-size: 0.5rem">
                      {{
                          round(
                            image.vehicleData.plate_number_confidence,
                            1
                          ).toFixed(1)
                      }}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a-icon v-if="image.hovering && !image.recognizing" type="close-circle" class="absolute right-1 top-1"
              theme="twoTone" two-tone-color="#eb2f96" @click="
                () => {
                  images.splice(i, 1);
                }
              " />
            <svg-icon v-if="image.recognizing" slot="suffix" icon-class="circular-loader" class="
                absolute
                right-1
                top-1
                animate-spin
                h-5
                w-5
                text-parqay-primary
                bg-white
                p-0.5
                rounded-full
              " />
            <div v-if="image.loading" class="
                absolute
                left-0
                top-0
                right-0
                bottom-0
                flex
                bg-black bg-opacity-25
              ">
              <div class="m-auto">
                <a-progress type="circle" :stroke-color="{
                  '0%': '#108ee9',
                  '100%': '#87d068'
                }" :percent="image.progress" />
              </div>
            </div>
          </div>
          <div class="
              flex
              h-36
              justify-center
              overflow-hidden
              rounded
              border-dashed border-2
              hover:border-blue-500
            " :class="showDropzoneHoverEffect ? 'dropzone-animate-pulse' : ''" @mouseover="hoverDropzone"
            @mouseleave="leaveDropzone">
            <vue-dropzone id="image-uploader" class="flex" :options="dropzoneOptions" use-custom-slot
              @vdropzone-file-added="addedNewImage" @vdropzone-drag-over="toggleDropzoneActive(true)"
              @vdropzone-drag-leave="toggleDropzoneActive(false)">
              <div class="bg-white w-full h-full">
                <div class="
                    flex flex-col
                    space-y-2
                    w-full
                    h-full
                    items-center
                    justify-center
                  " :class="{
                    'text-blue-500': showDropzoneHoverEffect,
                    'text-gray-400': !showDropzoneHoverEffect
                  }">
                  <svg-icon icon-class="cloud-upload" class="transition-width ease-in-out duration-800"
                    :class="dropzoneActive ? 'w-12 h-12' : 'w-8 h-8'" />
                  <span class="text-center dz-message" :class="dropzoneActive ? 'animate-pulse' : ''">Drop images
                    here</span>
                </div>
              </div>
            </vue-dropzone>
          </div>
        </draggable>
      </viewer>
    </div>
  </div>
</template>
<style scoped src="vue2-dropzone/dist/vue2Dropzone.min.css">
/* NOTE: MUST use scoped vue2-dropzone style as it will have conflict with Tailwind CSS pulse animation! */
</style>
<style lang="scss" scoped>
.images-uploader ::v-deep .ant-progress-text {
  color: white;
  font-size: 2rem;
}

.images-uploader ::v-deep .vue-dropzone {
  @apply border-none;
}

.images-uploader ::v-deep .dz-message {
  margin: 0;
  width: 100%;
}

.images-uploader ::v-deep .dz-clickable {
  padding: 0;
}

.images-uploader ::v-deep .dropzone-animate-pulse {
  @apply animate-pulse;
}

.images-uploader ::v-deep .dz-started .dz-message {
  display: block;
}

.images-uploader ::v-deep .anticon {
  font-size: 24px;
  @apply cursor-pointer;
}
</style>
