<script>
import VehiclesTable from "src/views/vehicles/Table.vue";
export default {
  components: {
    VehiclesTable
  },
  setup(props, ctx) {
    return {};
  }
};
</script>
<template>
  <div class="absolute inset-0">
    <div class="relative">
      <div class="py-10 flex flex-col px-10 space-y-4">
        <div class="flex my-2 justify-between items-center">
          <div class="flex items-center space-x-4">
            <span class="text-black text-lg font-semibold">
              Vehicles
            </span>
            <a-icon type="reload" :spin="$deepGet($refs, 'table.controls.loading', false)" style="font-size: 0.95rem;"
              @click.stop="$refs.table.fetchData()" />
          </div>
        </div>
        <div class="shadow-sm border overflow-hidden rounded-lg max-w bg-white pb-10">
          <vehicles-table ref="table" role="host" />
        </div>
      </div>
    </div>
  </div>
</template>
