<script>
import {
  ref,
  watch,
  onMounted,
} from "@vue/composition-api";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import debounce from "lodash/debounce";

export default {
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    initialValue: {
      type: String,
      default: null,
    },
    textSize: {
      type: String,
      default: "xl",
    },
    placeholder: {
      type: String,
      default: "",
    },
    debounceDelay: {
      type: Number,
      default: 300,
    },
    dataSourceMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const keywords = ref(props.initialValue);
    const loading = ref(false);
    const displayClose = ref(false);
    const updateKeywords = debounce((kw) => {
      loading.value = true;
      if (kw.newVal) {
        // ONLY for Vue Google Auto-Complete
        const { newVal, oldVal } = kw;

        console.log("updateKeywords", newVal);
        if (newVal) {
          displayClose.value = true;
        } else {
          displayClose.value = false;
        }
        ctx.emit("keywords-update", newVal, loading);
      } else {
        console.log("updateKeywords", kw);
        ctx.emit("keywords-update", kw, loading);
      }
    }, props.debounceDelay);
    watch(
      () => keywords.value,
      (val) => {
        if (val) {
          updateKeywords(val);
        }
      }
    );
    const updateSearchBar = (val) => {
      console.log("updateSearchBar >>> updated >>> " + val);
      ctx.refs["searchbar-datasource"].update(val);
    };
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    const getAddressData = (addressData, placeResultData, id) => {
      console.log("id: " + JSON.stringify(id));
      console.log("addressData: " + JSON.stringify(addressData));
      console.log("placeResultData: " + JSON.stringify(placeResultData));
      ctx.emit("selected-data-source", {
        lat: addressData.latitude,
        lon: addressData.longitude,
        address: placeResultData.formatted_address,
      });
    };
    onMounted(() => {
      if (props.dataSourceMode) {
        ctx.refs["searchbar-datasource"].update(props.initialValue);
      }
    });
    return {
      loading,
      keywords,
      displayClose,
      updateKeywords,
      getAddressData,
      updateSearchBar,
    };
  },
};
</script>
<template>
  <div class="w-full">
    <div id="pq-search-container"
      class="flex justify-center items-center rounded-full border-4 border-parqay-primary h-16 pl-4 pr-4 py-1">
      <span class="w-12 flex-grow-0 justify-center items-center text-gray-500 p-2">
        <svg-icon v-if="!loading" icon-class="search-icon" class="h-8 w-8 text-parqay-primary" />
        <svg-icon v-else icon-class="circular-loader" class="animate-spin h-8 w-8 text-parqay-primary" />
      </span>
      <input v-if="!dataSourceMode" v-model="keywords"
        class="flex-grow rounded px-4 h-full border-none focus:outline-none focus:ring-0" :class="`text-${textSize}`"
        type="search" :placeholder="placeholder" @focus="$emit('focus')">
      <vue-google-autocomplete v-else id="searchbar-datasource" ref="searchbar-datasource"
        :classname="`flex-grow rounded px-4 h-full border-none focus:outline-none focus:ring-0 text-${textSize}`"
        :placeholder="placeholder" @inputChange="updateKeywords" @placechanged="getAddressData" @focus="$emit('focus')" />
      <div class="h-6 w-6 mr-2" @click="updateSearchBar('')">
        <svg-icon v-if="displayClose" icon-class="close"
          class="h-6 w-6 text-gray-400 hover:text-gray-600 cursor-pointer my-auto" />
      </div>

      <slot />
    </div>
  </div>
</template>

<style lang="scss">
/* Define Google Auto-Complete menu item height (no-scoped-style: the element is attached to root DOM) */
.pac-container {
  @apply rounded bg-white;

  .pac-item {
    line-height: initial;
    min-height: 50px;
    @apply grid grid-cols-6 grid-rows-2 py-4 px-4 gap-y-2 cursor-pointer;

    span {
      @apply col-start-2 col-end-6 whitespace-normal;
    }

    .pac-icon {
      @apply m-auto col-span-1 row-span-2 w-2/3 h-2/3;
      width: 30px;
      height: 30px;
      background-size: contain;
      background-image: url("/static/img/dashboard/PARQAY-marker.svg");
      background-repeat: no-repeat;
      background-position: unset;
    }

    .pac-item-query {
      @apply col-span-5 font-semibold;

      .pac-matched {
        @apply p-1 bg-parqay-primary text-white rounded;
      }
    }
  }
}

.pac-logo:after {
  height: 50px;
  @apply mx-2 my-0;
}
</style>
