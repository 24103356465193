<script>
import TokenManager from "src/services/authentication/token-manager";
import TenantMenu from "src/views/non-host/TenantMenu.vue";
import MobileHeader from "src/views/non-host/header/View.vue";
import HostHeader from "./Header.vue";
import GPTSearchModal from "./common/GPTSearchModal.vue";
// import PropertySearch from "./common/PropertySearch.vue";
import CountryPicker from "src/views/dropdowns/Country";
import AccountDropdown from "src/views/dropdowns/Account";
import SearchBar from "./SearchBar.vue";
import NotificationSlideCard from "src/views/components/NotificationSlideCard.vue";
// import ReservationSlideBar from "src/views/non-host/common/ReservationSlideBar";
import ContactSlideCard from "src/views/components/ContactSlideCard";
import {
  onBeforeUnmount,
  onMounted,
  reactive,
  ref
} from "@vue/composition-api";

import { API as AuthAPI } from "src/views/auth/api";
import { API as AccountAPI } from "src/views/components/api/account";
import { API as ReservationAPI } from "src/views/non-host/reservations/api";

import throttle from "lodash/throttle";

export default {
  name: "tenant-dashboard",
  components: {
    TenantMenu,
    MobileHeader,
    HostHeader,
    "GPT-search-modal": GPTSearchModal,
    SearchBar,
    CountryPicker,
    AccountDropdown,
    NotificationSlideCard,
    // ReservationSlideBar,
    ContactSlideCard
  },
  setup(props, ctx) {
    // Slide Bar Setup
    const slideBarVisible = ref({
      notification: false,
      reservation: false,
      contact: false,
      automation: false,
      doc: false,
      plan: false,
      editEventSchedule: false,
      editEventPricing: false
    });

    const account = ref(null);

    const controls = reactive({
      listingType: "parking",
      propertySearch: {
        visible: false
      },
      pendingInvite: {
        visible: false,
        data: null
      },
      splash: {
        visible: false
      }
    });

    const slideBarModel = ref({
      notification: null,
      reservation: {},
      contact: {},
      automation: {},
      doc: {},
      plan: {},
      editEventSchedule: {},
      editEventPricing: {}
    });

    // Slide Bar Methods

    const closeSlideBar = name => {
      slideBarVisible.value[name] = false;
      toggleIntercomPosition(false);
    };
    const openNotification = ctx.root.$openNotification;
    const route = ctx.root.$route;
    const router = ctx.root.$router;

    const accessTokenRefreshFn = throttle(
      () => {
        console.log("***** access-token-refresh ***** ");
        openNotification(
          "Automatic Login",
          "Parqay is automatically refreshing token to keep your account secure"
        );
      },
      10000,
      // Fire token refresh ONLY ONCE every 10 seconds to avoid 401 response through multiple requests at once
      { trailing: false }
    );

    const authRequiredLoginFn = throttle(
      ({ target = "dashboard", silent = false } = { target: "dashboard", silent: false }) => {
        console.log("***** auth-required-login ***** ");
        router.push(target === "dashboard" ? "/login" : "/login/tenant");
        if (silent) {
          return
        }
        const automaticLogin = ctx.root.$store.state.automaticLogin;
        var notificationTitle, notificationMessage;
        if (automaticLogin) {
          notificationTitle = "Automatic Login";
          notificationMessage =
            "For security reason, Parqay requires user to login again after 2 months";
        } else {
          notificationTitle = "Secure Login";
          notificationMessage =
            "Current session expired,Parqay requires user to login again after 2 hours. You can check automatic login option for extended login session up to 2 months";
        }
        openNotification(notificationTitle, notificationMessage);
      },
      10000,
      // Fire token refresh ONLY ONCE every 10 seconds to avoid 401 response through multiple requests at once
      { trailing: false }
    );

    const toggleIntercomPosition = (toggle, displacement = 400) => {
      // if (toggle) {
      //   window.intercomSettings.horizontal_padding = displacement + 20;
      // } else {
      //   window.intercomSettings.horizontal_padding = 20;
      // }
      // window.Intercom("boot", window.intercomSettings);
    };

    const showPendingReservationInviteIfNeeded = async () => {
      const code = localStorage.getItem("reservation-invite");
      if (code) {
        let resp, detailsResp;
        try {
          resp = await ReservationAPI.associateInvitationCode(code);
          console.log("showPendingReservationInviteIfNeeded", resp.message);
        } catch (error) {
          console.log("showPendingReservationInviteIfNeeded >> error: ", error);
          return;
        }
        try {
          detailsResp = await ReservationAPI.details(resp.data.reservation_id);
          console.log("reservation details api result: ", detailsResp.data);
        } catch (error) {
          console.log("getReservationDetails error: ", error);
          return;
        }
        controls.pendingInvite.visible = true;
        controls.pendingInvite.data = detailsResp.data;
        localStorage.removeItem("reservation-invite");
      }
    };

    const removeURLParameter = (url, parameter) => {
      //prefer to use l.search if you have a location/link object
      var urlparts = url.split('?');
      if (urlparts.length >= 2) {

        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0;) {
          //idiom for string.startsWith
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {
            pars.splice(i, 1);
          }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
      }
      return url;
    }

    const verifyDashboardToken = async () => {
      try {
        const tid = ctx.root.$route.query.tid;
        if (tid) {
          const { data: { token } } = await AuthAPI.verifyDashboardToken(tid);
          TokenManager().accessToken = token;
          TokenManager().removeRefreshToken();
          const { data: userData } = await AccountAPI.getUser();
          localStorage.setItem(
            "userData",
            JSON.stringify({
              id: userData.id,
              first_name: userData.first_name,
              last_name: userData.last_name,
              email: userData.email,
              picture: userData.picture
            })
          );
          ctx.root.$router.push({
            name: "app-splash",
            params: {
              next: ctx.root.$route.name
            }
          });
        } else {
          if (!ctx.root.$isLoggedIn()) {
            const nextRoute = ctx.root.$route.fullPath;
            ctx.root.$router.push(`/login/tenant?next=${nextRoute}`);
          }
        }
      } catch (error) {
        console.warn("verifyDashboardToken -> error: ", error);
        const nextRoute = removeURLParameter(ctx.root.$route.fullPath, "tid");
        ctx.root.$router.push(`/login/tenant?next=${nextRoute}`);
        return;
      }
    };

    onMounted(async () => {
      verifyDashboardToken();
      ctx.root.$bus.$on("access-token-refresh", accessTokenRefreshFn);
      // ctx.root.$bus.$on("auth-required-login", authRequiredLoginFn);
      ctx.root.$bus.$on("open-slide-bar", (name, model) => {
        slideBarModel.value[name] = model;
        slideBarVisible.value[name] = true;
        toggleIntercomPosition(true);
      });
      showPendingReservationInviteIfNeeded();
    });

    onBeforeUnmount(() => {
      ctx.root.$bus.$off("access-token-refresh", accessTokenRefreshFn);
      // ctx.root.$bus.$off("auth-required-login", authRequiredLoginFn);
    });

    return {
      controls,
      slideBarModel,
      slideBarVisible,
      closeSlideBar,
      toggleIntercomPosition
    };
  }
};
</script>

<template>
  <div id="tenant-dashbord" class="w-screen">
    <template v-if="$isDesktopView()">
      <div class="h-screen flex overflow-hidden bg-white">
        <tenant-menu />
        <main class="flex flex-col w-full">
          <host-header>
            <template #left>
              <search-bar @update-listing-type="t => (controls.listingType = t)" @toggle-property-search="show => (controls.propertySearch.visible = show)
      " />
            </template>

            <template #right>
              <div v-if="$isLoggedIn()" class="flex items-center px-4">
                <button class="
                bg-blue-600
                border
                hover:bg-blue-500
                focus:outline-none
                text-white
                rounded-full
                p-2
                flex
                flex-shrink-0
              " @click="$openNotificationSlideCard">
                  <a-badge :count="$store.getters.getUnreadNotificationsCount('tenant')">
                    <svg-icon icon-class="notifications-outline" class="w-5 h-5" />
                  </a-badge>
                  <!-- <a-badge :count="0">
                <svg-icon icon-class="notifications-outline" class="w-5 h-5" />
              </a-badge> -->
                </button>
              </div>
              <div class="flex space-x-2">
                <div class="inline-flex my-auto">
                  <country-picker />
                </div>
                <div v-if="$isLoggedIn()" class="inline-flex my-auto">
                  <account-dropdown />
                </div>
              </div>
            </template>

            <template #bottom>
              <!-- <property-search v-show="controls.propertySearch.visible" :listing-type="controls.listingType"
              class="border-l" @toggle-property-search="
                show => (controls.propertySearch.visible = show)
              " /> -->
              <GPT-search-modal :visible="controls.propertySearch.visible"
                @close="() => controls.propertySearch.visible = false" />
            </template>
          </host-header>
          <div class="flex-grow overflow-hidden" style="height: calc(100vh - 100px); background-color: #f9f9f9">
            <div class="h-full overflow-y-auto relative">
              <router-view />
            </div>
          </div>
        </main>
      </div>
      <notification-slide-card :visible="slideBarVisible.notification" role="tenant"
        @close-notification-slide-card="closeSlideBar('notification')" />
      <contact-slide-card />
      <a-modal v-model="controls.pendingInvite.visible" title="You have a new reservation" @ok="() => { }">
        <p>New reservation</p>
        <div
          class="flex flex-col items-center space-y-2 px-4 py-2 rounded-lg border mt-2 hover:bg-blue-100 cursor-pointer">
          <a class="text-base font-medium text-parqay-primary">{{
      $deepGet(controls.pendingInvite.data, "title")
    }}</a>
          <span>{{
        $deepGet(
          controls.pendingInvite.data,
          "listing.summary.location.address"
        )
      }}</span>
          <div v-for="(interval, i) in $deepGet(
      controls.pendingInvite.data,
      'intervals',
      []
    )" class="flex items-center space-x-2 justify-center text-center text-xs font-medium border rounded bg-gray-100 w-full py-1 whitespace-nowrap"
            :key="i">
            <span>{{ $formatDate(interval.start) }} </span>
            <svg-icon icon-class="arrow-circle-right" class="w-6 h-6 text-parqay-primary" />
            <span>{{ $formatDate(interval.end) }} </span>
          </div>
        </div>

        <template #footer>
          <a-button @click="() => {
      $router.push('/tenant/reservations');
      controls.pendingInvite.visible = false;
    }
      ">
            Go to Reservations
          </a-button>
        </template>
      </a-modal>
    </template>
    <div v-else class="flex-grow flex flex-col overflow-hidden h-screen" style="background-color: #f9f9f9">
      <mobile-header />
      <div class="h-full overflow-y-auto relative">
        <keep-alive>
          <router-view />
        </keep-alive>
      </div>
    </div>
    <documents-drawer />
    <splash v-if="controls.splash.visible" />
  </div>
</template>
