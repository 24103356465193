<script>
import { reactive, ref, onMounted } from "@vue/composition-api";
import { API } from "src/views/documents/api";

export default {
    setup(props, ctx) {

        const controls = reactive({
            loading: false
        })

        const form = ref([]);

        const getAdminTemplateReviewRequests = async () => {
            controls.loading = true;
            try {
                const { data } = await API.getAdminTemplateReviewRequests();
                form.value = data;
            } catch (error) {
                console.log(error);
            } finally {
                setTimeout(() => {
                    controls.loading = false;
                }, 2000);
            }
        }

        const updateAdminTemplateReviewRequestStatus = async (request_id, status, templates) => {
            try {
                await API.updateAdminTemplateReviewRequestStatus(request_id, status, templates);
                await getAdminTemplateReviewRequests();
            } catch (error) {
                console.log(error);
            }
        }

        const getZohoSignTemplateEditorURL = (zs_template_id) => {
            return `https://sign.zoho.com/zs#/template/viewer/${zs_template_id}`
        }

        onMounted(() => {
            getAdminTemplateReviewRequests();
        })

        return {
            controls,
            form,
            getAdminTemplateReviewRequests,
            updateAdminTemplateReviewRequestStatus,
            getZohoSignTemplateEditorURL
        }
    }
}
</script>
<template>
    <div class="admin-template-requests-list flex flex-col space-y-4">
        <div class="flex items-center justify-center bg-white py-4 px-8">
            <a-button v-if="controls.loading" type="link" loading />
            <template v-else>
                <div class="flex flex-col items-center justify-start space-y-10 w-full" :style="{ minHeight: '30vh' }">
                    <div class="flex items-center justify-center border-b space-x-5 pb-4 w-full">
                        <div class="flex items-center space-x-2">
                            <a-tag color="geekblue">Admin Only</a-tag>
                            <span class="text-base text-black font-medium">Recent Template Reviewing Requests</span>
                        </div>
                        <a-icon type="reload" @click="getAdminTemplateReviewRequests()" />
                    </div>
                    <div v-if="form.length == 0" class="flex items-center justify-center w-full h-full">
                        <a-empty description="No request yet" />
                    </div>
                    <template v-for="(request, i) in form">
                        <div :key="`template-review-request-${i}`"
                            class="flex space-x-4 rounded border w-full px-5 pt-6 pb-6" :style="{ minHeight: '100px' }">
                            <div class="flex flex-col space-y-4" :style="{ minWidth: '220px' }">
                                <div class="flex items-center border rounded-full h-12 px-2 shadow-md cursor-pointer">
                                    <avatar :user-id="request.host" show-name @user-data-ready="(user) => {
                                        $set(request, '$host', user);
                                    }" />
                                </div>
                                <span class="text-center text-xs font-medium">Host info</span>
                                <div class="flex items-center justify-center space-x-1">
                                    <a-tag color="geekblue">
                                        {{ request.contact_type || "email" }}
                                    </a-tag>
                                    <span class="text-xs font-medium text-blue-500">
                                        {{ request.contact_method || $deepGet(request, "$host.email", "N/A") }}
                                    </span>
                                </div>
                                <span class="text-center text-xs font-medium">Template status</span>
                                <div class="flex items-center justify-center">
                                    <a-tag v-if="request.is_reviewed" color="green">Approved</a-tag>
                                    <a-tag v-else color="orange">Under Review</a-tag>
                                </div>
                                <span class="text-center text-xs font-medium">Admin actions</span>
                                <div class="flex items-center justify-center space-x-2">
                                    <a-tag color="green" class="flex items-center justify-center p-1 cursor-pointer"
                                        @click="() => updateAdminTemplateReviewRequestStatus(request.id, 'reviewed', request.templates)">
                                        <span class="px-1.5">Approve</span>
                                        <svg-icon icon-class="check-var-1" class="w-4 h-4 text-green-400" />
                                    </a-tag>
                                    <a-tag color="pink" class="flex items-center justify-center p-1 cursor-pointer"
                                        @click="() => updateAdminTemplateReviewRequestStatus(request.id, 'pending', request.templates)">
                                        <span class="px-1.5">Deny</span>
                                        <svg-icon icon-class="close" class="w-4 h-4 text-pink-500" />
                                    </a-tag>
                                </div>
                            </div>
                            <div class="flex flex-col space-y-2 w-full">
                                <span>Request message: </span>
                                <p v-if="request.request_message"
                                    class="px-6 py-2 rounded-lg border bg-blue-50 text-blue-500">
                                    {{ request.request_message }}
                                </p>
                                <p v-else class="px-6 py-2 rounded-lg border bg-gray-50 text-gray-400">
                                    This host did not leave any review message
                                </p>
                                <span>Signature type: </span>
                                <div class="flex flex-col space-y-2 p-6 rounded-lg border bg-white pointer-events-none">
                                    <a-radio-group v-model="request.signature_type">
                                        <a-radio :style="{
                                            display: 'block',
                                            height: '30px',
                                            lineHeight: '30px',
                                        }" value="simple_signature">
                                            I just need a signature field at the bottom of the template
                                        </a-radio>
                                        <a-radio :style="{
                                            display: 'block',
                                            height: '30px',
                                            lineHeight: '30px',
                                        }" value="advanced_signature">
                                            I need more customizations: more fields for tenants to input
                                        </a-radio>
                                    </a-radio-group>
                                </div>
                                <a-divider>
                                    <span class="text-sm text-black">Associated templates</span>
                                </a-divider>
                                <div class="grid grid-cols-2 gap-4 py-2">

                                    <div v-for="(temp, j) in request.templates" :key="`template-to-review-${j}`"
                                        class="flex flex-col border rounded">
                                        <div class="flex items-center space-x-4 px-4 py-2">
                                            <svg-icon class="w-8 h-8 text-parqay-primary" icon-class="document" />
                                            <div class="flex items-center justify-between w-full">
                                                <span class="text-sm font-medium text-black">{{ temp.name }}</span>
                                                <a-tag color="geekblue">
                                                    {{ temp.docs_to_sign.length }} files attached
                                                </a-tag>
                                            </div>
                                        </div>
                                        <div v-if="temp.zs_template_id"
                                            class="flex items-center justify-between hover:bg-blue-100 cursor-pointer border-t px-4 py-2"
                                            @click="$openUrlInNewTab(getZohoSignTemplateEditorURL(temp.zs_template_id))">
                                            <a-button type="link">Edit template fields in Zoho Sign</a-button>
                                            <svg-icon icon-class="arrow-circle-right" class="w-6 h-6 text-blue-500" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a-divider :key="`template-review-request-divider-${i}`">
                            <span class="text-xs text-gray-500">{{ $formatDate(request.created_at) }}</span>
                        </a-divider>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.admin-template-requests-list::v-deep {
    .anticon-spin {
        font-size: 30px;
    }
}
</style>