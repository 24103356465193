<script>
import { reactive, ref, computed } from "@vue/composition-api";
import AdminHostSearchInput from "@/views/components/AdminHostSearchInput.vue";
import parsePhoneNumber from "libphonenumber-js";
import debounce from "lodash/debounce";
import startCase from "lodash/startCase";

import { API } from "./api";

export default {
    components: {
        AdminHostSearchInput
    },
    props: {
        size: {
            type: String,
            default: "small"
        }
    },
    setup(props, ctx) {
        const loading = ref(false);
        const isBulkSendingSameMessage = ref(false);
        const bulkSendingMessage = ref(null);
        const selectedHost = ref(null);
        const errorMessage = reactive({
            message: null,
            on_behalf_of: null
        })
        const defaultLead = {
            // Form
            first_name: null,
            last_name: null,
            email: null,
            phone_number: null,
            message: null,
            send_invitation: false,
            send_welcome_letter: false,
            // Controls
            phoneNumberFormatted: "",
            phoneComponents: {},
            country: "US",
            errorMessage: {
                first_name: null,
                last_name: null,
                email: null,
                phone_number: null,
                message: null,
                otherOptions: null
            },
            isPhoneNumberInsideUS: true
        }
        const leads = ref([
            {
                ...defaultLead
            }
        ]);

        const isEmailValid = (lead) => {
            if (!lead.email) {
                lead.errorMessage.email = null;
                return;
            }
            // Regex for validating email format
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValid = emailRegex.test(lead.email);
            if (isValid) {
                lead.errorMessage.email = null;
                checkDuplicateLeadMeta(lead, "email");
            } else {
                lead.errorMessage.email = "Invalid email.";
            }
            return isValid;
        };

        const isPhoneNumberValid = (lead) => {
            // Regex for validating phone number format
            const phoneNumberRegex = /^\+?\d{1,3}[-.\s]?\(?\d{2,4}\)?[-.\s]?\d{3,5}[-.\s]?\d{4}$/;
            const isValid = phoneNumberRegex.test(lead.phoneNumberFormatted);
            if (isValid) {
                lead.errorMessage.phone_number = null;
            } else {
                lead.errorMessage.phone_number = "Invalid phone number.";
            }
        };

        const formatPhoneNumber = (lead, unformatted = false) => {
            if (lead.isPhoneNumberInsideUS && !unformatted) {
                // Format phone number to (XXX) XXX-XXXX
                lead.phoneNumberFormatted = lead.phoneNumberFormatted.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
            } else {
                // Format phone number to only digits
                lead.phoneNumberFormatted = lead.phoneNumberFormatted.replace(/\D/g, "");
            }
            updatePhoneComponents(lead);
        };

        const updatePhoneComponents = (lead) => {
            try {
                lead.phoneComponents = parsePhoneNumber(
                    lead.phoneNumberFormatted,
                    lead.country
                );
                lead.phone_number = lead.phoneComponents.number;
                checkDuplicateLeadMeta(lead, "phone_number");
            } catch (error) {
                lead.phoneComponents = {};
                lead.phone_number = null;
            }
        };

        const checkDuplicateLeadMeta = debounce(async (lead, type) => {
            try {
                lead.errorMessage[type] = "Checking...";
                const { data } = await API.checkDuplicateLeadMeta(type, lead[type]);
                if (data.is_duplicate === true) {
                    lead.errorMessage[type] = `The ${startCase(type)}: ${lead[type]} is taken by another account.`
                } else {
                    lead.errorMessage[type] = null;
                }
            } catch (error) {
                lead.errorMessage[type] = error.message;
            }
        }, 500, { trailing: true })

        const shouldDisableInviteButton = computed(() => {
            if (isBulkSendingSameMessage.value) {
                if (!bulkSendingMessage.value || Object.values(errorMessage).some(v => v !== null)) {
                    return true;
                }
            }
            if (ctx.root.$isAdmin()) {
                if (!selectedHost.value) {
                    return true;
                }
            }
            const errorItems = leads.value.filter(lead => {
                if (
                    !lead.first_name ||
                    !lead.last_name ||
                    !lead.phone_number ||
                    (!isBulkSendingSameMessage.value && !lead.message) ||
                    Object.values(lead.errorMessage).some(v => v !== null)
                ) {
                    return true;
                }
            })
            return errorItems.length > 0;
        })

        const addLead = () => {
            leads.value.push({
                ...defaultLead
            });
        };

        const getPayload = () => {
            return {
                on_behalf_of: selectedHost.value,
                leads: leads.value.map(v => {
                    let message = v.message;
                    if (isBulkSendingSameMessage.value) {
                        message = bulkSendingMessage.value;
                    }
                    return {
                        first_name: v.first_name,
                        last_name: v.last_name,
                        email: v.email,
                        phone_number: v.phone_number,
                        message,
                        send_invitation: v.send_invitation,
                        send_welcome_letter: v.send_welcome_letter
                    }
                })
            }
        }

        const inviteLeads = async () => {
            console.log("inviteLeads -> ", getPayload());
            try {
                loading.value = true;
                const { data } = await API.createLeads(getPayload());
                ctx.emit("next", { contact: data, loading });
            } catch (error) {
                ctx.root.$openNotification("New Lead Error", error.message);
                loading.value = false;
            }
        };

        return {
            loading,
            isBulkSendingSameMessage,
            bulkSendingMessage,
            selectedHost,
            errorMessage,
            leads,
            shouldDisableInviteButton,
            isEmailValid,
            isPhoneNumberValid,
            formatPhoneNumber,
            updatePhoneComponents,
            checkDuplicateLeadMeta,
            addLead,
            inviteLeads,
            console
        };
    },
};
</script>

<template>
    <div class="flex flex-col justify-center items-center px-4 py-2">
        <div class="w-full bg-white rounded-lg border overflow-hidden">
            <div class="px-6 py-6 space-y-4">
                <div class="flex items-center justify-center space-x-3">
                    <span class="text-2xl">👋</span>
                    <h2 class="text-xl text-center font-semibold text-gray-900">
                        Say Hi to Your {{ leads.length > 1 ? "Leads" : "Lead" }}
                    </h2>
                </div>
                <div class="text-sm text-gray-500">
                    You can start one or multiple lead outreaches. The invites will be sent through both SMS and email.
                    Please make sure the phone numbers are reachable.
                </div>
                <a-divider>Options</a-divider>
                <div class="flex justify-between">
                    <div class="text-sm font-medium text-parqay-primary hover:text-blue-500 cursor-pointer" @click.stop="() => {
                        isBulkSendingSameMessage = !isBulkSendingSameMessage
                    }">Bulk sending same message to leads?</div>
                    <a-switch v-model="isBulkSendingSameMessage" />
                </div>
                <label-item v-if="isBulkSendingSameMessage" label="Message" :error-message="errorMessage.message"
                    class="col-span-2">
                    <a-textarea v-model="bulkSendingMessage" :rows="3" :maxlength="500" placeholder="Enter your message"
                        size="large" @focus="() => {
                            if (bulkSendingMessage) {
                                errorMessage.message = null
                            } else {
                                errorMessage.message = 'Please enter message.'
                            }
                        }" @input="() => {
    if (bulkSendingMessage) {
        errorMessage.message = null
    } else {
        errorMessage.message = 'Please enter message.'
    }
}" />
                </label-item>
                <label-item v-if="$isAdmin()" :error-message="errorMessage.on_behalf_of">
                    <template #label>
                        <span class="flex-shrink-0 text-gray-700 text-sm font-semibold text-left">On Behalf of Host</span>
                    </template>
                    <div class="flex flex-col space-y-2">
                        <span class="text-gray-500">As admin, you are only allowed to create leads on behalf of a normal
                            host. Use the searchbar below to find host.</span>
                        <admin-host-search-input @select="host => selectedHost = host"
                            @error="error => errorMessage.on_behalf_of = error" />
                    </div>
                </label-item>
                <div class="mb-4 space-y-4">
                    <template v-for="(lead, index) in leads">
                        <a-divider :key="`divider-${index}`">Lead #{{ index + 1 }}</a-divider>
                        <div class="grid mb-4 gap-4" :class="{
                            'grid-cols-2': size === 'small',
                            'grid-cols-4': size === 'large'
                        }" :key="`form-${index}`">
                            <label-item label="First Name" :error-message="lead.errorMessage.first_name">
                                <a-input v-model.trim="lead.first_name" placeholder="Lead's first name" allow-clear
                                    size="large" @input="() => {
                                        if (lead.first_name) {
                                            lead.errorMessage.first_name = null
                                        } else {
                                            lead.errorMessage.first_name = 'Please enter lead\'s first name.'
                                        }
                                    }" />
                            </label-item>
                            <label-item label="Last Name" :error-message="lead.errorMessage.last_name">
                                <a-input v-model.trim="lead.last_name" placeholder="Lead's last name" allow-clear
                                    size="large" @input="() => {
                                        if (lead.last_name) {
                                            lead.errorMessage.last_name = null
                                        } else {
                                            lead.errorMessage.last_name = 'Please enter lead\'s last name.'
                                        }
                                    }" />
                            </label-item>
                            <label-item label="Phone Number" :error-message="lead.errorMessage.phone_number"
                                class="col-span-2">
                                <template #desc>
                                    <a-button v-if="lead.isPhoneNumberInsideUS" country-picker type="link" size="small"
                                        @click.stop="() => { lead.isPhoneNumberInsideUS = false; formatPhoneNumber(lead) }">Outside
                                        US</a-button>
                                    <a-button v-else country-picker type="link" size="small"
                                        @click.stop="() => { lead.country = 'US'; lead.isPhoneNumberInsideUS = true; formatPhoneNumber(lead); lead.errorMessage.country = null; }">Inside
                                        US</a-button>
                                </template>
                                <a-input v-model="lead.phoneNumberFormatted" placeholder="Lead's phone number" size="large"
                                    allow-clear @focus="formatPhoneNumber(lead, true)" @input="isPhoneNumberValid(lead)"
                                    @blur="formatPhoneNumber(lead)">
                                    <svg-icon slot="prefix" icon-class="phone" class="h-4 w-4 text-gray-400" />
                                </a-input>
                            </label-item>
                            <label-item v-show="!lead.isPhoneNumberInsideUS" label="Choose Phone Number Country"
                                :error-message="lead.errorMessage.country" class="col-span-2">
                                <country-select v-model="lead.country" :country="lead.country" topCountry="US"
                                    class="w-full" @input="selected => {
                                        if (lead.country) {
                                            lead.errorMessage.country = null
                                        } else {
                                            lead.errorMessage.country = 'Country must be specified.'
                                        }
                                        updatePhoneComponents(lead);
                                    }" />
                            </label-item>
                            <label-item v-if="!isBulkSendingSameMessage" label="Message"
                                :error-message="lead.errorMessage.message" class="col-span-2">
                                <a-textarea v-model.trim="lead.message" :rows="3" :maxlength="500"
                                    placeholder="Enter your message" allow-clear size="large" @focus="() => {
                                        if (lead.message) {
                                            lead.errorMessage.message = null
                                        } else {
                                            lead.errorMessage.message = 'Please enter message to lead.'
                                        }
                                    }" @input="() => {
    if (lead.message) {
        lead.errorMessage.message = null
    } else {
        lead.errorMessage.message = 'Please enter message to lead.'
    }
}" />
                            </label-item>
                            <label-item :class="{
                                'col-span-2': size === 'small',
                                'col-span-4': size === 'large'
                            }" :error-message="lead.errorMessage.otherOptions">
                                <a-divider>Extra</a-divider>
                                <div class="flex flex-wrap items-center gap-y-4" :class="{
                                    'gap-x-2': size === 'small',
                                    'gap-x-4': size === 'large'
                                }">
                                    <label-item label="Email (Optional)" :error-message="lead.errorMessage.email" :class="{
                                        'w-full': size === 'small',
                                        'w-1/2': size === 'large'
                                    }">
                                        <a-input v-model.trim="lead.email" placeholder="Lead's email address" allow-clear
                                            size="large" @input="isEmailValid(lead)" @blur="isEmailValid(lead)" />
                                    </label-item>
                                    <div v-if="size === 'large'" class="w-1/3" />
                                    <a-checkbox v-model="lead.send_invitation" @change="checked => {
                                        if ((lead.send_invitation || lead.send_welcome_letter) && !isEmailValid(lead)) {
                                            lead.errorMessage.otherOptions = 'A valid email address is required to choose any option above.';
                                        } else {
                                            lead.errorMessage.otherOptions = null;
                                        }
                                    }">Send Tenant Invitation Email</a-checkbox>
                                    <a-checkbox v-model="lead.send_welcome_letter" @change="checked => {
                                        if ((lead.send_invitation || lead.send_welcome_letter) && !isEmailValid(lead)) {
                                            lead.errorMessage.otherOptions = 'A valid email address is required to choose any option above.';
                                        } else {
                                            lead.errorMessage.otherOptions = null;
                                        }
                                    }">Send Welcome Letter</a-checkbox>
                                </div>
                            </label-item>
                        </div>
                        <div v-if="index != 0" :key="`form-action-${index}`" class="flex justify-end">
                            <a-button type="danger" icon="close" delete @click.stop="() => {
                                leads.splice(index, 1);
                            }">Delete Lead</a-button>
                        </div>
                    </template>
                    <a-alert message="New User Account"
                        description="Each successful lead outreach will initiate a new user account and add to your tenants list. If the phone number for certain lead is not reachable, the new account will not be created."
                        type="warning" />
                    <div class="flex justify-end">
                        <a-button icon="plus" lead class="mr-4" @click.prevent="addLead">One More Lead</a-button>
                        <a-button type="primary" :loading="loading" :disabled="shouldDisableInviteButton"
                            @click.stop="inviteLeads()">Send {{
                                leads.length > 1 ?
                                leads.length : "" }}
                            Invitation{{ leads.length > 1 ? "s" : "" }}</a-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.ant-btn[country-picker] {
    @apply flex-grow flex items-center justify-end text-xs font-medium;
}

.ant-btn[lead] {
    @apply flex items-center justify-center;
}
</style>