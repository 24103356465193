<script>
import {
  onBeforeUnmount,
  onMounted,
  reactive,
  watch
} from "@vue/composition-api";
import { API } from "@/views/components/api/account";
import AccountAvatar from "./AccountAvatar.vue";

export default {
  components: { AccountAvatar },
  setup(props, ctx) {
    const controls = reactive({
      visible: false
    });
    const form = reactive({
      email: null,
      phone_number: null,
      first_name: null,
      last_name: null
    });
    watch(
      () => controls.visible,
      val => {
        if (val) {
          fetchData();
        }
      }
    );
    const openModal = () => {
      controls.visible = true;
    };
    const fetchData = async () => {
      let resp;
      try {
        resp = await API.getUser();
        form.email = resp.data.email;
        form.phone_number = resp.data.phone_number;
        form.first_name = resp.data.first_name;
        form.last_name = resp.data.last_name;
      } catch (error) {
        console.warn(error);
        // controls.visible = false;
      }
    };
    onMounted(() => {
      ctx.root.$bus.$on("open-account-modal", openModal);
      ctx.root.$bus.$on("login-refresh", fetchData);
    });
    onBeforeUnmount(() => {
      ctx.root.$bus.$off("open-account-modal", openModal);
      ctx.root.$bus.$off("login-refresh", fetchData);
    });
    return {
      controls,
      form
    };
  }
};
</script>
<template>
  <a-modal v-model="controls.visible" closable :mask-style="{ backgroundColor: 'rgba(0, 0, 0, 0.35)' }" :footer="null"
    wrap-class-name="mobile-account-modal" @cancel="() => (controls.visible = false)">
    <div class="flex flex-col space-y-4 py-4">
      <span class="text-lg text-center font-medium text-black pb-0">
        Your Profile
      </span>
      <div class="flex flex-col items-center justify-center space-y-3 border-t px-8 py-8">
        <!-- <div class="flex-shrink-0 flex flex-col items-center justify-center py-5">
          <account-avatar />
        </div> -->
        <div class="flex-grow flex flex-col items-start justify-center space-y-4 w-full">
          <div class="flex flex-col w-full space-y-4">
            <div class="space-y-2">
              <b class="text-sm font-medium">Email</b>
              <a class="text-base text-parqay-primary block">
                {{ form.email }}
              </a>
            </div>
            <div class="space-y-2">
              <b class="text-sm font-medium">Phone Number</b>
              <a class="text-base text-parqay-primary block">
                {{ form.phone_number }}
              </a>
            </div>
          </div>
          <div class="space-y-2 w-full">
            <b class="text-sm font-medium">Firstname</b>
            <a-input v-model="form.first_name" size="large" class="block" />
          </div>
          <div class="space-y-2 w-full">
            <b class="text-sm font-medium">Lastname</b>
            <a-input v-model="form.last_name" size="large" class="block" />
          </div>
          <div class="w-full pt-3">
            <a-button type="primary" size="large" block>
              Save
            </a-button>
          </div>
        </div>
      </div>
      <a class="flex items-center justify-center pt-3 border-t" @click.prevent="() => {
    controls.visible = false;
    $emit('more-settings');
  }">
        <span class="font-medium text-parqay-primary hover:text-parqay-primary text-base">
          More settings
        </span>
      </a>
    </div>
  </a-modal>
</template>
<style lang="scss">
.mobile-account-modal {
  .ant-modal-body {
    @apply p-0;
  }
}
</style>
