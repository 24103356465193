<script>
import { reactive } from "@vue/composition-api";
import { getTime, getDay } from "./util";
import startCase from 'lodash/startCase';

export default {
  name: "AvailabilityManual",
  props: {
    model: {
      type: Array,
      default: () => {
        return [];
      },
    }
  },
  setup(props, ctx) {

    const addListItem = () => {
      props.model.push({
        day: null,
        from: null,
        to: null,
        active: false,
      });
    };
    const removeItem = (i) => {
      props.model.splice(i, 1);
    };

    const options = reactive({
      time: getTime(),
      day: getDay(),
    });

    return {
      options,
      addListItem,
      removeItem,
      startCase
    };
  },
};
</script>
<template>
  <div class="flex flex-col rounded bg-white shadow-sm border">
    <div class="flex items-center px-4 py-2">
      <slot />
    </div>
    <div>
      <div class="border-t border-b bg-gray-50 mb-4">
        <div class="grid grid-cols-12 text-center text-base font-medium py-3 mx-4">
          <span class="col-span-3">Day</span>
          <span class="col-span-3">From</span>
          <span class="col-span-3">To</span>
          <span class="col-span-2">Off/On</span>
          <span class="col-span-1" />
        </div>
      </div>
      <div>
        <template>
          <div v-for="(dayModel, i) in model" :key="`${Date.now()}-${i}`" class="
              grid grid-cols-12
              gap-3
              text-center
              border
              rounded-lg
              mx-4
              mt-2
              py-3
              px-3
              border-gray-100
              bg-white
              hover:bg-blue-100
            ">
            <div class="col-span-3">
              <a-select v-model="dayModel.day" class="font-medium w-full">
                <a-select-option v-for="t in options.day" :key="t" :value="t">
                  {{ startCase(t) }}
                </a-select-option>
              </a-select>
            </div>
            <div class="col-span-3">
              <a-select v-model="dayModel.from" class="w-full">
                <a-select-option v-for="t in options.time" :key="t" :value="t">
                  {{ t }}
                </a-select-option>
              </a-select>
            </div>
            <div class="col-span-3">
              <a-select v-model="dayModel.to" class="w-full">
                <a-select-option v-for="t in options.time" :key="t" :value="t">
                  {{ t }}
                </a-select-option>
              </a-select>
            </div>
            <div class="col-span-2 flex justify-center">
              <a-switch v-model="dayModel.active" class="my-auto" checked-children="On" un-checked-children="Off" />
            </div>
            <div class="col-span-1 border-red-500 text-red-500 flex justify-center">
              <button class="rounded-lg border border-red-500 bg-white" @click="removeItem(i)">
                <div class="flex justify-center">
                  <svg-icon icon-class="minus" class="h-6 w-10 my-auto text-red" />
                </div>
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="flex items-center justify-center space-x-4 p-4">
      <a-button icon="plus" size="large" @click="addListItem()" />
      <slot name="footer" />
    </div>
  </div>
</template>
