<script>
import { reactive, ref, onMounted, nextTick, onBeforeUnmount } from "@vue/composition-api";
import LeaderLine from "leader-line";

import EventBus from "src/services/util/event-bus";

export default {
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    setup(props, ctx) {
        const controls = reactive({
            visible: {
                autoposterSaveButton: false
            },
            enable_autoposter: true,
            craigslist_email: props.form.email
        })
        var arrows = [];
        const refreshArrowConnections = () => {
            arrows.map((arrow, index) => {
                arrow.position();
            });
        }
        const initArrows = () => {
            nextTick(() => {
                const fromEl = document.querySelector(".automation-from");
                const toEl = document.querySelector(".automation-to");
                const action1El = document.querySelector(".automation-action-1");
                const action2El = document.querySelector(".automation-action-2");
                const action3El = document.querySelector(".automation-action-3");
                const action4El = document.querySelector(".automation-action-4");
                console.log(fromEl);
                console.log(toEl);
                if (fromEl && toEl) {
                    arrows.push(
                        new LeaderLine(fromEl, toEl, {
                            dash: { animation: true }
                        })
                    );
                    arrows.push(
                        new LeaderLine(toEl, action1El, {
                            dash: { animation: true },
                            startSocket: "right",
                            endSocket: "left"
                        })
                    );
                    arrows.push(
                        new LeaderLine(toEl, action2El, {
                            dash: { animation: true },
                            startSocket: "right"
                        })
                    );
                    arrows.push(
                        new LeaderLine(toEl, action3El, {
                            dash: { animation: true },
                            startSocket: "right"
                        })
                    );
                    arrows.push(
                        new LeaderLine(toEl, action4El, {
                            dash: { animation: true },
                            startSocket: "right",
                            endSocket: "left"
                        })
                    );
                    console.log("arrows -> ", arrows);
                }
            })
        }
        const removeArrows = () => {
            if (arrows) {
                arrows.map((arrow, index) => {
                    arrow.remove();
                });
                arrows = [];
            }
        }
        const openSlide = () => {
            EventBus.$emit("open-slide-bar", "automation");
        };

        onMounted(() => {
            EventBus.$on("adjust-automation-arrows", refreshArrowConnections);
        });
        nextTick(() => {
            initArrows();
        })
        onBeforeUnmount(() => {
            EventBus.$off("adjust-automation-arrows", refreshArrowConnections);
            removeArrows();
        });
        return {
            controls,
            arrows,
            openSlide
        };
    },
};
</script>
<template>
    <div class="grid grid-cols-12 w-full items-center pb-12" style="min-height: 100vh;">
        <div class="col-start-2 col-end-5">
            <div class="automation-from w-36 h-36 bg-white border-4 rounded-full relative">
                <div class="flex flex-col space-y-2 h-full items-center justify-center text-parqay-primary text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-9 h-9">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z" />
                    </svg>
                    <span class="text-sm font-semibold" style="max-width: 6rem;">
                        New Listing Created
                    </span>
                </div>
            </div>
        </div>
        <div class="col-start-5 col-end-8">
            <div class="automation-to bg-white rounded-full cursor-pointer relative" style="width: 6.5rem; height: 6.5rem;">
                <div class="absolute inset-0 rounded-full border-4 border-orange-400 border-dashed"
                    style="animation: 6s linear 0s infinite none running spin;" />
                <div class="absolute inset-0 flex flex-col space-y-2 h-full items-center justify-center text-orange-500">
                    <svg-icon icon-class="automation" class="w-8 h-8 animate-pulse" />
                    <span class="text-sm font-bold">Actions</span>
                </div>
            </div>
        </div>
        <div class="col-start-8 col-end-12 rounded-xl border-4 border-dashed hover:border-blue-400 cursor-pointer mt-4"
            style="min-height: 60%;">
            <div class="flex flex-col justify-center space-y-8 h-full bg-white p-4">
                <div class="flex rounded-lg bg-white shadow-md hover:shadow-xl relative">
                    <div
                        class="automation-action-1 absolute top-1/2 -translate-x-2 transform -translate-y-1/2 w-3 h-3 bg-parqay-primary" />
                    <div class="grid grid-cols-4 h-full w-full">
                        <div class="flex border-r py-2 col-span-1 items-center justify-center">
                            <div
                                class="flex flex-shrink-0 items-center justify-center w-16 h-16 rounded-full border-4 border-parqay-primary p-1">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-8 h-8 text-parqay-primary">
                                    <path
                                        d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                    <path
                                        d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                                </svg>
                            </div>
                        </div>
                        <div class="flex flex-col space-y-2 col-span-3 items-center justify-center">
                            <span>Email marketing links to</span>
                            <div class="flex flex-col space-y-2 px-4 pb-4 mt-1">
                                <div class="flex items-center space-x-2">
                                    <div v-if="form.email" class="flex-shrink-0 w-2 h-2 rounded-full bg-green-400" />
                                    <div v-else class="flex-shrink-0 w-2 h-2 rounded-full bg-red-500" />
                                    <span v-if="form.email" class="text-sm font-semibold">{{ form.email }}</span>
                                    <span v-else class="text-sm font-semibold text-red-500">Email: Not Set</span>
                                    <a-tooltip
                                        title="Edit will take you to account settings, this email must always align with your account email.">
                                        <a-icon type="edit"
                                            @click.stop="$router.push({ name: 'HostDashboardAccountSettings' })" />
                                    </a-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col rounded-lg bg-white shadow-md hover:shadow-xl relative">
                    <div
                        class="automation-action-2 absolute top-1/2 -translate-x-2 transform -translate-y-1/2 w-3 h-3 bg-parqay-primary" />
                    <div class="grid grid-cols-4 h-full">
                        <div class="flex border-r col-span-1 items-center justify-center">
                            <div
                                class="flex flex-shrink-0 items-center justify-center w-16 h-16 rounded-full border-4 border-parqay-primary p-3">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-8 h-8 text-parqay-primary">
                                    <path fill-rule="evenodd"
                                        d="M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 01.75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 019.75 22.5a.75.75 0 01-.75-.75v-4.131A15.838 15.838 0 016.382 15H2.25a.75.75 0 01-.75-.75 6.75 6.75 0 017.815-6.666zM15 6.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"
                                        clip-rule="evenodd" />
                                    <path
                                        d="M5.26 17.242a.75.75 0 10-.897-1.203 5.243 5.243 0 00-2.05 5.022.75.75 0 00.625.627 5.243 5.243 0 005.022-2.051.75.75 0 10-1.202-.897 3.744 3.744 0 01-3.008 1.51c0-1.23.592-2.323 1.51-3.008z" />
                                </svg>
                            </div>
                        </div>
                        <div class="col-span-3 flex flex-col space-y-2 items-center justify-center">
                            <span>Autopost to Craigslist (CL)</span>
                            <div class="flex items-center space-x-2 px-4">
                                <div v-if="controls.craigslist_email" class="w-2 h-2 rounded-full bg-green-400" />
                                <div v-else class="flex-shrink-0 w-2 h-2 rounded-full bg-pink-500" />
                                <span class="text-sm"
                                    :class="controls.craigslist_email ? 'font-semibold' : 'text-pink-500 font-medium'">{{
                                        controls.craigslist_email ? "Active" : "Disabled" }}</span>
                                <a-tooltip title="Available Soon">
                                    <a-switch v-model="controls.enable_autoposter" disabled />
                                </a-tooltip>
                            </div>
                            <a-divider></a-divider>
                            <div class="flex flex-col items-center space-y-2 px-4 pb-4">
                                <div class="flex items-center space-x-2">
                                    <span class="text-xs">Your CL account email</span>
                                    <a-tooltip
                                        title="We need your CL account email to post this listing on behalf of you, you will receive CL email confirmation when our autoposter finish posting">
                                        <a-icon type="info-circle" theme="twoTone" />
                                    </a-tooltip>
                                </div>
                                <a-tooltip title="Available Soon">
                                    <a-input v-model="controls.craigslist_email" placeholder="user@domain.com" allow-clear
                                    size="large" disabled class="icon-email-input">
                                    <a-icon type="mail" slot="prefix" />
                                </a-input>
                                </a-tooltip>
                                <a-button v-if="controls.visible.autoposterSaveButton" type="primary" block>Save</a-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col rounded-lg bg-white shadow-md hover:shadow-xl relative">
                    <div
                        class="automation-action-3 absolute top-1/2 -translate-x-2 transform -translate-y-1/2 w-3 h-3 bg-parqay-primary" />
                    <div class="grid grid-cols-4 h-full">
                        <div class="flex border-r col-span-1 items-center justify-center">
                            <div
                                class="flex flex-shrink-0 items-center justify-center w-16 h-16 rounded-full border-4 border-parqay-primary p-3">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-8 h-8 text-parqay-primary">
                                    <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                                    <path fill-rule="evenodd"
                                        d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                        </div>
                        <div class="col-span-3 flex flex-col space-y-2 items-center justify-center">
                            <span>Trigger SEO indexing</span>
                            <div class="flex items-center space-x-2 px-4">
                                <div class="w-2 h-2 rounded-full bg-green-400" />
                                <span class="text-sm font-semibold">Google.com</span>
                                <div class="w-2 h-2 rounded-full bg-green-400" />
                                <span class="text-sm font-semibold">Bing.com</span>
                            </div>
                            <a-divider></a-divider>
                            <div class="flex flex-col items-center space-y-2 px-4 pb-4">
                                <div class="flex items-center space-x-2">
                                    <span class="text-xs">Why it matters your sales?</span>
                                    <a-tooltip
                                        title="We use SEO indexing to boost listing sales by increasing listings visibility and making them more accessible to potential buyers.">
                                        <a-icon type="info-circle" theme="twoTone" />
                                    </a-tooltip>
                                </div>
                                <a-tooltip title="Available Soon">
                                    <a-button type="primary" block disabled>Edit SEO tags</a-button>
                                </a-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col rounded-lg bg-white shadow-md hover:shadow-xl relative">
                    <div
                        class="automation-action-4 absolute top-1/2 -translate-x-2 transform -translate-y-1/2 w-3 h-3 bg-parqay-primary" />
                    <div class="grid grid-cols-4 h-full">
                        <div class="flex border-r col-span-1 items-center justify-center">
                            <div
                                class="flex flex-shrink-0 items-center justify-center w-16 h-16 rounded-full border-4 border-parqay-primary p-3">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-8 h-8 text-parqay-primary">
                                    <path fill-rule="evenodd"
                                        d="M1.5 6.375c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v3.026a.75.75 0 01-.375.65 2.249 2.249 0 000 3.898.75.75 0 01.375.65v3.026c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 17.625v-3.026a.75.75 0 01.374-.65 2.249 2.249 0 000-3.898.75.75 0 01-.374-.65V6.375zm15-1.125a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0V6a.75.75 0 01.75-.75zm.75 4.5a.75.75 0 00-1.5 0v.75a.75.75 0 001.5 0v-.75zm-.75 3a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0v-.75a.75.75 0 01.75-.75zm.75 4.5a.75.75 0 00-1.5 0V18a.75.75 0 001.5 0v-.75zM6 12a.75.75 0 01.75-.75H12a.75.75 0 010 1.5H6.75A.75.75 0 016 12zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                        </div>
                        <div class="col-span-3 flex flex-col space-y-2 items-center justify-center">
                            <span>Event pages indexing</span>
                            <div class="grid grid-cols-2 gap-2 py-2">
                                <div class="flex items-center space-x-2">
                                    <div class="w-2 h-2 rounded-full bg-green-400" />
                                    <span class="text-sm font-semibold">Event</span>
                                </div>
                                <div class="flex items-center space-x-2">
                                    <div class="w-2 h-2 rounded-full bg-green-400" />
                                    <span class="text-sm font-semibold">City</span>
                                </div>
                                <div class="flex items-center space-x-2">
                                    <div class="w-2 h-2 rounded-full bg-green-400" />
                                    <span class="text-sm font-semibold">Airport</span>
                                </div>
                                <div class="flex items-center space-x-2">
                                    <div class="w-2 h-2 rounded-full bg-green-400" />
                                    <span class="text-sm font-semibold">White-label</span>
                                </div>
                            </div>
                            <a-divider></a-divider>
                            <div class="flex flex-col items-center space-y-2 px-4 pt-2 pb-4">
                                <div class="flex items-center space-x-2">
                                    <span class="text-xs">Why it matters your sales?</span>
                                    <a-tooltip
                                        title="Upon creating a new listing, we will distribute it to a multitude of our event pages, which includes local events, nearby cities, nearby airports, and your own white-label pages. This extensive exposure is sure to significantly boost your sales rate.">
                                        <a-icon type="info-circle" theme="twoTone" />
                                    </a-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.icon-email-input::v-deep {
    input {
        padding-left: 36px !important;
    }
}
</style>