<script>
import { onMounted, ref } from "@vue/composition-api";
import ClickOutside from "vue-click-outside";

import { API } from "src/views/components/api/account";

export default {
  directives: {
    ClickOutside
  },
  setup(props, ctx) {
    const account = ref(null);
    const isOpened = ref(false);
    const close = () => {
      isOpened.value = false;
    };
    const getTenantAccountDetails = async () => {
      try {
        const { data } = await API.getUser(null, true);
        account.value = data;
      } catch (error) {
        console.log("getTenantAccountDetails -> error: ", error);
      }
    };
    onMounted(() => {
      getTenantAccountDetails();
    });
    return {
      account,
      isOpened,
      close
    };
  }
};
</script>

<template>
  <div v-click-outside="close" class="relative border shadow-sm hover:shadow-xl bg-white">
    <button type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label"
      class="relative w-full pl-3 pr-10 py-2 text-left focus:outline-none sm:text-sm" @click="() => (isOpened = true)">
      <span class="flex items-center">
        <avatar v-if="$deepGet(account, 'id')" :user-id="account.id" :popover="false" />
        <span class="ml-3 block truncate text-black font-medium">
          Hello, {{ $deepGet(account, "first_name") }}
        </span>
      </span>
      <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <!-- Heroicon name: selector -->
        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
          aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </span>
    </button>
    <div :class="isOpened ? 'display-block' : 'hidden'" class="absolute w-full bg-white">
      <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3"
        class="max-h-56 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
        <li id="listbox-item-0" role="option" class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
          <p align="center" class="my-auto">
            <a tag="a" class="text-pink-500 py-2" @click="() => {
    $logout();
    $router.push('/login/tenant');
  }">
              Log Out
            </a>
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>
