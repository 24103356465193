<script>
import { reactive, ref, onMounted, nextTick } from "@vue/composition-api";
import Typewriter from 'typewriter-effect/dist/core';
import ClickOutside from "vue-click-outside";

export default {
  directives: {
    ClickOutside,
  },
  setup(props, ctx) {
    const controls = reactive({
      typewriterEffect: null,
      searchTypewriterActivated: false
    })

    const handleKeyPress = event => {
      // Use ⌘ + K to quick open search
      // console.log(event.keyCode);
      if (event.key == "k" && (event.ctrlKey || event.metaKey)) {
        console.log("dashboard keypress: search - ⌘ + " + event.key);
        trigger();
      }
    };

    const hoverActiveSearchBot = () => {
      nextTick(() => {
        if (!controls.searchTypewriterActivated) {
          controls.searchTypewriterActivated = true;
          if (!controls.typewriterEffect) {
            controls.typewriterEffect = new Typewriter('#typewriter', {
              strings: ["Search and book more properties", "Write your question in natural language"],
              autoStart: true,
              loop: true,
              pauseFor: 2000
            });
          } else {
            controls.typewriterEffect.start();
          }
        }
      })
    }

    const removeActiveSearchBot = () => {
      nextTick(() => {
        if (controls.typewriterEffect) {
          controls.typewriterEffect.stop();
        }
      });
    }

    const trigger = () => {
      ctx.emit("toggle-property-search", true);
    };

    onMounted(() => {
      window.addEventListener("keydown", handleKeyPress);
    });

    return {
      controls,
      hoverActiveSearchBot,
      removeActiveSearchBot,
      trigger
    };
  }
};
</script>
<template>
  <div v-click-outside="() => { controls.searchTypewriterActivated = false; removeActiveSearchBot(); }">
    <div class="flex items-center relative rounded-md shadow-sm border border-gray-300" style="width: 350px;"
      @click.stop="() => { controls.searchTypewriterActivated = false; trigger(); }" @mouseenter="hoverActiveSearchBot()">
      <div class="w-full px-2 py-1 cursor-pointer">
        <div v-show="controls.searchTypewriterActivated"
          class="flex items-center justify-center rounded border border-blue-200 bg-blue-100 text-xs font-semibold text-blue-500 w-full px-2 py-1">
          <span id="typewriter">Search and book more properties</span>
        </div>
        <div v-show="!controls.searchTypewriterActivated"
          class="flex items-center justify-center rounded border border-blue-200 bg-blue-100 text-xs font-semibold text-blue-500 w-full px-2 py-1">
          <span>Search and book more properties</span>
        </div>
      </div>
      <div class="pr-2">
        <div class="flex items-center space-x-2 h-full">
          <button class="w-6 h-6 rounded bg-gray-50 border border-gray-300 text-gray-400 text-center text-sm"
            @click="trigger">
            ⌘
          </button>
          <button class="w-6 h-6 rounded bg-gray-50 border border-gray-300 text-gray-400 text-center text-sm"
            @click="trigger">
            K
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
