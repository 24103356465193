<script>
import { reactive, ref } from "@vue/composition-api";
import { Tokens, Urls } from "services/constants";

export default {
  setup(props, ctx) {
    const loading = reactive({
      refresh: false
    });
    const closingRedirectUrl = window.location.origin + "/redirects/stripe-connect";
    const stripeConnectUrl =
      "https://connect.stripe.com/express/oauth/authorize?client_id=" +
      Tokens.STRIPE_CONNECT_CLIENT_ID +
      "&redirect_uri=" + closingRedirectUrl;
    const popUpBlockMessage = ref(null);
    const connectWithStripe = () => {
      // Set the width and height of the popup window
      const popupWidth = window.innerWidth * 0.9;
      const popupHeight = window.innerHeight * 0.95;

      // Calculate the position of the popup window to center it
      const left = (window.innerWidth - popupWidth) / 2;
      const top = (window.innerHeight - popupHeight) / 2;
      var popUp = window.open(stripeConnectUrl, "Connect with Stripe", `width=${popupWidth},height=${popupHeight},left=${left},top=${top},status=0,toolbar=0`);
      if (popUp == null || typeof popUp == "undefined") {
        popUpBlockMessage.value =
          "Looks like your browser is blocking our navigation toward Stripe, \
          please allow the pop up so you will be able to set up payment methods to receive your earnings.";
        return
      } else {
        popUpBlockMessage.value =
          "Please create your Stripe Connect account in the pop-up window to receive our payouts.";
        popUp.focus();
      }
      // Listen for changes to the URL of the popup window
      var stripeInterval = setInterval(() => {
        try {
          console.log("checking popup url -> ", popUp.location.href);
          if (popUp.closed) {
            console.log("Stripe connect popup window: turning off timer...");
            clearInterval(stripeInterval);
            popUpBlockMessage.value = null;
            return
          }
          if (popUp.location.href.indexOf(closingRedirectUrl) !== -1 && popUp.location.hash === '#stripe-code-connected') {
            clearInterval(stripeInterval);
            refresh();
            setTimeout(() => {
              popUp.close();
            }, 3000);
          }
        } catch (e) {
          console.log("error -> ", e);
        }
      }, 1000);
    };
    const refresh = () => {
      ctx.emit("refresh");
      loading.refresh = true;
      setTimeout(() => {
        loading.refresh = false;
      }, 8000);
    };
    return {
      loading,
      popUpBlockMessage,
      connectWithStripe,
      refresh
    };
  }
};
</script>
<template>
  <div class="py-2 px-4">
    <div v-if="popUpBlockMessage" class="border border-parqay-primary rounded-lg py-4 px-4 text-parqay-primary bg-white">
      {{ popUpBlockMessage }} Click
      <button class="bg-parqay-primary hover:bg-blue-500 rounded text-white px-2 py-1" @click="refresh">
        refresh
        <svg-icon v-if="loading.refresh" icon-class="circular-loader"
          class="animate-spin h-4 w-4 ml-2 my-auto text-white" />
      </button>
      if you have complete the steps
    </div>
    <div class="flex space-x-4 justify-center items-center">
      <button type="button"
        class="my-2 px-4 py-2 font-medium rounded-md text-white bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-300 focus:outline-none focus:border-indigo-800 focus:shadow-outline-indigo active:bg-indigo-800 transition ease-in-out duration-150"
        @click="connectWithStripe">
        <div class="flex items-center space-x-2">
          <span>Connect With</span>
          <svg-icon icon-class="stripe" class="h-6 w-12 text-white" />
        </div>
      </button>
      <slot name="more-action" />
    </div>
  </div>
</template>
