<script>
import { onMounted, reactive } from "@vue/composition-api";
import { API } from "src/views/towing/api";
import startCase from "lodash/startCase";

export default {
  name: "TowingTable",
  setup(props, ctx) {
    const shortUID = ctx.root.$shortUID;
    const table = reactive({
      headers: [
        {
          title: "Image",
          align: "center",
          dataIndex: "vehicle.image",
          scopedSlots: { customRender: "image" },
          width: 200,
        },
        {
          title: "Status",
          align: "center",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          width: 120,
        },
        {
          title: "Make",
          align: "center",
          dataIndex: "vehicle.make",
          scopedSlots: { customRender: "make" },
          width: 80,
        },
        {
          title: "Model",
          align: "center",
          dataIndex: "vehicle.model",
          scopedSlots: { customRender: "model" },
          width: 80,
        },
        {
          title: "Color",
          align: "center",
          dataIndex: "vehicle.color",
          scopedSlots: { customRender: "color" },
          width: 80,
        },
        {
          title: "Plate #",
          align: "center",
          dataIndex: "vehicle.plate_number",
          scopedSlots: { customRender: "model" },
          width: 100,
        },
        {
          title: "Address",
          align: "center",
          dataIndex: "address",
          scopedSlots: { customRender: "address" },
          width: 150,
        },
        {
          title: "Your Notes",
          align: "center",
          scopedSlots: { customRender: "notes" },
        },
        {
          title: "Created At",
          align: "center",
          dataIndex: "created_at",
          scopedSlots: { customRender: "created_at" },
        },
        {
          title: "Actions",
          align: "center",
          scopedSlots: { customRender: "actions" },
          fixed: "right",
        },
      ],
      data: [],
    });
    const controls = reactive({
      loading: false,
      visible: false,
    });
    const createRequest = () => {
      controls.visible = true;
    };
    const fetchData = async () => {
      controls.loading = true;
      let resp = await API.list({ role: "host" });
      table.data = resp.data;
      controls.loading = false;
    };
    onMounted(() => {
      fetchData();
    });
    return {
      table,
      controls,
      fetchData,
      createRequest,
      startCase,
      console,
    };
  },
};
</script>
<template>
  <div>
    <a-table
      :loading="controls.loading"
      :columns="table.headers"
      :data-source="table.data"
      :scroll="{ x: true, y: false }"
      row-key="id"
      :custom-row="
        (record, index) => {
          return {
            on: {
              click: (event) => {
                console.log(
                  'row click currentTarget',
                  event.currentTarget.tagName.toLowerCase()
                );
                console.log('row click target', event.target.tagName.toLowerCase());
                if (
                  event.target.tagName.toLowerCase() !== 'svg' &&
                  event.target.tagName.toLowerCase() !== 'use' &&
                  event.target.tagName.toLowerCase() !== 'span' &&
                  event.target.tagName.toLowerCase() !== 'a' &&
                  event.target.tagName.toLowerCase() !== 'button'
                ) {
                }
              },
            },
          };
        }
      "
      :pagination="false"
    >
      <template slot="image" slot-scope="image, record">
        <div class="flex flex-col items-center justify-center py-2 space-y-2">
          <viewer v-if="image" :images="[image]">
            <lazy-img
              :src="image"
              src-placeholder="/static/img/dashboard/vehicle-placeholder.png"
              class="h-24 w-auto object-cover hover:opacity-80 cursor-pointer"
            />
          </viewer>
          <img
            v-else
            src="/static/img/dashboard/vehicle-placeholder.png"
            class="h-24 w-auto object-cover hover:opacity-80 cursor-pointer"
          />
        </div>
      </template>

      <template slot="status" slot-scope="status, record">
        <div class="flex flex-col space-y-2 px-2 py-4">
          <a-tag v-if="status == 'pending'" color="orange" class="py-1">
            Waiting
          </a-tag>
          <a-tag v-if="status == 'rejected'" color="pink" class="py-1">
            {{ startCase(status) }}
          </a-tag>
          <a-tag v-if="status == 'complete'" color="green" class="py-1">
            Towed
          </a-tag>
        </div>
      </template>

      <template slot="address" slot-scope="address">
        <a class="text-xs font-medium text-parqay-primary">
          {{ address }}
        </a>
      </template>

      <template slot="created_at" slot-scope="created_at">
        <a-tag>
          {{ $formatDate(created_at.$date, "MM/DD, h:mm a") }}
        </a-tag>
      </template>

      <template slot="notes" slot-scope="_, record, i">
        <div class="flex flex-col space-y-2 text-xs">
          <div class="flex flex-col space-y-1 items-center justify-center">
            <span class="text-sm font-medium">Title</span>
            <span class="text-sm text-gray-600 px-4 py-1 border rounded bg-white">
              {{ record.title }}
            </span>
          </div>
          <div class="flex flex-col space-y-1 items-center justify-center">
            <span class="text-sm font-medium">Description</span>
            <span class="text-sm border px-4 py-1 rounded bg-white">
              {{ record.desc }}
            </span>
          </div>
        </div>
      </template>

      <template slot="actions" slot-scope="_, record, i">
        <div class="flex flex-col space-y-1">
          <a-button type="danger" size="small">
            Remove
          </a-button>
          <a-button size="small">
            Remind
          </a-button>
        </div>
      </template>
    </a-table>
    <tow-request-modal
      :visible="controls.visible"
      @close="
        () => {
          controls.visible = false;
          fetchData();
        }
      "
    />
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .ant-btn {
    @apply text-xs;
  }
  .ant-table-row {
    td {
      @apply py-1;
    }
  }
}
</style>
