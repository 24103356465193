<script>
import VehiclesTable from "src/views/vehicles/Table";
export default {
  name: "TenantVehicles",
  components: {
    VehiclesTable
  },
  setup(props, ctx) {
    return {};
  }
};
</script>
<template>
  <div class="absolute inset-0">
    <div class="relative">
      <div class="py-10 flex flex-col px-10 space-y-4">
        <div class="flex my-2 justify-between items-center">
          <h2 class="font-bold text-xl">
            Vehicles
          </h2>
        </div>
        <div
          class="shadow-sm border overflow-hidden rounded-lg max-w bg-white pb-10"
        >
          <vehicles-table role="tenant" />
        </div>
      </div>
    </div>
  </div>
</template>
