<script>
import { reactive, ref, onMounted, onBeforeUnmount, nextTick } from "@vue/composition-api";
import TabHeader from "src/views/components/TabHeader.vue";
import ListingFlow from "./ListingFlow.vue";
import ReservationFlow from "./ReservationFlow.vue";
import EventBus from "src/services/util/event-bus";

import { API } from "src/views/components/api/account";

export default {
  components: {
    TabHeader,
    ListingFlow,
    ReservationFlow
  },
  setup(props, ctx) {
    const loading = ref(true);
    const form = ref({});
    const activeTab = ref("listing-flow");

    const openSlide = () => {
      EventBus.$emit("open-slide-bar", "automation");
    };

    const setupHashRoute = hashRoute => {
      ctx.root.$router.push({ name: ctx.root.$route.name, hash: `#${hashRoute}` });
    };

    const changeTab = async name => {
      activeTab.value = name;
      setupHashRoute(name);
    };

    const fetchData = async () => {
      loading.value = true;
      try {
        const { data } = await API.getUser();
        form.value = data;
        loading.value = false;
      } catch (error) {
        console.warn(error);
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchData();
    });

    nextTick(() => {

    })
    onBeforeUnmount(() => {
    });
    return {
      loading,
      form,
      activeTab,
      openSlide,
      changeTab,
      fetchData
    };
  },
};
</script>
<template>
  <div class="h-screen bg-white overflow-hidden p-4">
    <tab-header v-model="activeTab" :tabs="[
      { name: 'listing-flow', label: 'Listing Flow', action: fetchData },
      { name: 'reservation-flow', label: 'Reservation Flow', action: fetchData }
    ]" @change="changeTab" />
    <div class="relative mx-auto">
      <!-- Dashed background -->
      <svg class="absolute inset-0 top-4 pointer-events-none" fill="none" viewBox="0 0 404 404" aria-hidden="true">
        <defs>
          <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="10" height="10"
            patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="1" height="1" class="text-gray-300" fill="currentColor" />
          </pattern>
        </defs>
        <rect class="h-full w-full" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
      </svg>
      <div v-if="loading" class="flex items-center justify-center" style="height: 65vh;">
        <div class="bg-white flex flex-col items-center justify-center border rounded-md px-12 py-12 z-10">
          <div class="bg-white rounded-full cursor-pointer relative" style="width: 6.5rem; height: 6.5rem;">
            <div class="absolute inset-0 rounded-full border-4 border-orange-400 border-dashed"
              style="animation: 6s linear 0s infinite none running spin;" />
            <div class="absolute inset-0 flex flex-col space-y-2 h-full items-center justify-center text-orange-500">
              <svg-icon icon-class="automation" class="w-8 h-8 animate-pulse" />
              <span class="text-sm font-bold">Loading...</span>
            </div>
          </div>
        </div>
      </div>
      <template v-else>
        <listing-flow v-if="$route.hash === '#listing-flow'" :form="form" />
        <reservation-flow v-if="$route.hash === '#reservation-flow'" :form="form" />
      </template>
    </div>
  </div>
</template>
<style>
.arrow {
  pointer-events: none;
}

.arrow__path {
  stroke: gray;
  fill: transparent;
  stroke-dasharray: 10 2;
  stroke-width: 3px;
}

.arrow__head line {
  stroke: gray;
  stroke-width: 2px;
}
</style>